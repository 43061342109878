import conferenceModeratorService from "@/services/conference/moderator/conferenceModerator.service"

const conferenceModerator = {
  namespaced: true,
  actions: {
    async GET_ARTICLES({commit}) {
      try {
        const {data} = await conferenceModeratorService.getArticles();
        if (data) {
          console.log('GET_ARTICLES', data)
          commit('SET_CONFERENCE_DATA', {property: 'moderatorArticles', value: data})
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_CONFERENCE_ARTICLE_COMMENT({commit}, postData) {
      try {
        const {status} = await conferenceModeratorService.postConferenceArticleComment(postData);
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_SECTION_MODERATORS({commit}) {
      try {
        const {data} = await conferenceModeratorService.getSectionModerators();
        if (data) {
          commit('SET_CONFERENCE_DATA', {property: 'sectionModerators', value: data})
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_BIND_SECTION_MODERATOR({commit}, postData) {
      try {
        const {status} = await conferenceModeratorService.postBindSectionModerator(postData);
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_REVIEWERS({commit}) {
      try {
        const {data} = await conferenceModeratorService.getReviewers();
        if (data) {
          commit('SET_CONFERENCE_DATA', {property: 'reviewers', value: data})
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_BIND_REVIEWERS({commit}, postData) {
      try {
        const {status} = await conferenceModeratorService.postBindReviewers(postData);
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_REVIEWER({commit, state}, putData) {
      console.log(putData, 'putData')

      try {
        const {status} = await conferenceModeratorService.putReviewer(putData, state.conference.updateConferenceReviewId);
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_SECTION_MODERATOR({commit, state}, putData) {

      console.log(putData, 'putData')

      try {
        const {data} = await conferenceModeratorService.putSectionModerator(putData, state.conference.updateConferenceSectionModeratorId);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {
    SET_CONFERENCE_DATA(state, {property, value}) {
      state.conference[property] = value
    },
    SET_UPDATE_CONFERENCE_REVIEW_ID(state,updateConferenceReviewId) {
      state.conference.updateConferenceReviewId = updateConferenceReviewId
    },
    UPDATE_CONFERENCE_SECTION_MODERATOR_ID(state, updateConferenceSectionModeratorId) {
      state.conference.updateConferenceSectionModeratorId = updateConferenceSectionModeratorId
    }
  },
  state: {
    conference: {
      moderatorArticles: [],
      sectionModerators: [],
      reviewers: [],
      updateConferenceReviewId: null,
      updateConferenceSectionModeratorId: 0
    }
  },
  getters: {}
}

export default conferenceModerator