import httpClient from "@/services/http.service"
import router from "@/router"


const uploadGraduateWorkService = {
  getStudentGraduateWorkTheme() {
    return httpClient.get(`graduate/selection-theme/get-student-graduate-work-theme`)
  },
  getGraduateWorkChapter(theme_id = 0) {
    return httpClient.get(`graduate/graduate-work-chapter/get-chapter-graduate-work-calendar?theme_id=${theme_id}`)
  },
  getStudentGraduateWorkChapterFiles() {
    return httpClient.get(`graduate/graduate-work-student/get-student-graduate-work-chapter-files`)
  },
  getCheckAccess() {
    return httpClient.get(`graduate/graduate-files/check-access`)
  },
  getDiplomaResults() {
    return httpClient.get(`graduate/graduate-files/get-diploma-results`)
  },
  // getStudentGraduateWorkComment() {
  //     return httpClient.get(`graduate/graduate-work-comment/index`)
  // },
  postGraduateWorkChapterFile(formData) {
    return httpClient.post(`graduate/graduate-work-student/create`, formData)
  },

  postFinalChapterFile(formData) {
    return httpClient.post(`graduate/graduate-work-student/create`, formData)
  },
  postFinalFileToPlagiat(data) {
    return httpClient.post(`graduate/graduate-files/sent-diploma-files`, data)
  },

  //Из-за formData обновление файлов через PUT не работает
  putGraduateWorkChapterFile(formData, id) {
    console.log(id, 'putGraduateWorkChapterFile id')

    // console.log(Object.fromEntries(formData), 'put ссисисми')

    return httpClient.post(`graduate/graduate-work-student/update-file?id=` + id, formData)
  },
}

export default uploadGraduateWorkService