import oldMoodleService from "@/services/old-moodle/oldMoodle.service"
import axios from 'axios'

const oldSummerRatings = {
    namespaced: true,
    actions: {

        async GET_OLD_MOODLE_SUMMER_COURSE({commit}) {
            try {
                const {data} = await oldMoodleService.getOldMoodleSummerCourse();
                if (data) {
                    console.log(data, 'getOldMoodleSummerCourse')
                    commit('SET_STUDENTS_OLD_AND_NEW_RATINGS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },



    },
    mutations: {
        SET_STUDENTS_OLD_AND_NEW_RATINGS(state, studentsOldAndNewRatings) {
            state.studentsOldAndNewRatings = studentsOldAndNewRatings
        },

    },
    state: {
        studentsOldAndNewRatings: []
    },
    getters: {

    }
}

export default oldSummerRatings