import docOrdersService from "@/services/doc-orders/docOrders.service"
import axios from 'axios'

const docOrders = {
    namespaced: true,
    actions: {

        async GET_SP_ACADEMIC_YEAR({commit}) {
            try {
                const {data} = await docOrdersService.getSpAcademicYear();
                if (data) {
                    console.log(data, 'GET_SP_ACADEMIC_YEAR')
                    commit('SET_ACADEMIC_YEAR', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_SP_DOC_ORDER_TYPE({commit}) {
            try {
                const {data} = await docOrdersService.getSpDocOrderType();
                if (data) {
                    console.log(data, 'GET_SP_DOC_ORDER_TYPE')
                    commit('SET_DOC_ORDER_TYPE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_SP_DOC_ORDER_SUB_TYPE({commit}) {
            try {
                const {data} = await docOrdersService.getSpDocOrderSubType();
                if (data) {
                    console.log(data, 'GET_SP_DOC_ORDER_SUB_TYPE')
                    commit('SET_DOC_ORDER_SUB_TYPE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ACADEMIC_LEAVE_BY_ID({commit}, doc_order_id) {
            try {
                const {data} = await docOrdersService.getAcademicLeaveById(doc_order_id);
                if (data) {
                    console.log(data, 'GET_ACADEMIC_LEAVE_BY_ID')
                    commit('SET_ACADEMIC_LEAVE_BY_ID', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_SECTIONS({commit}, doc_order_id){
            try {
                const {data} = await docOrdersService.getSections(doc_order_id);
                if (data) {
                    console.log(data, 'GET_SECTIONS')
                    commit('SET_SECTIONS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async CREATE_SECTION({commit}, dataSection){
            try {
                const {data} = await docOrdersService.createSection(dataSection);
                if (data) {
                    console.log(data, 'GET_SECTIONS')
                    commit('SET_SECTIONS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async CREATE_ACADEMIC_LEAVE({commit}, academic) {
            try {
                const {data} = await docOrdersService.createAcademicLeave(academic);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async EXTEND_ACADEMIC_LEAVE({commit}, academic) {
            try {
                const {data} = await docOrdersService.createAcademicExtend(academic);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_DOC_ORDERS({commit}, doc_order_id) {
            try {
                const {data} = await docOrdersService.getDocOrders(doc_order_id);
                if (data) {
                    console.log(data, 'GET_DOC_ORDERS')
                    commit('SET_DOC_ORDERS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_DOC_ORDERS_INFO({commit}, doc_order_id) {
            try {
                const {data} = await docOrdersService.getDocOrdersInfo(doc_order_id);
                if (data) {
                    console.log(data, 'GET_DOC_ORDERS_INFO')
                    commit('SET_DOC_ORDERS_INFO', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async DELETE_DOC_ORDERS({commit}, id) {
            try {
                const {data} = await docOrdersService.deleteDocOrders(id);
                if (data) {
                    console.log(data, 'Doc Orders deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_DOC_ORDERS({commit, state}) {

            let docOrdersForm = state.docOrders_form.newDocOrdersInfo


            console.log(docOrdersForm, 'post docOrdersForm')
            try {
                const {data} = await docOrdersService.postDocOrders(docOrdersForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_DOC_ORDERS_STUDENTS({commit}, queryParams) {
            try {
                const {data} = await docOrdersService.postDocOrdersStudents(queryParams);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_DOC_ORDERS({commit, state}) {

            let docOrdersForm = state.docOrders_form.docOrdersInfo.filter(i=>i.id == state.docOrders_form.editDocOrdersId)
            delete docOrdersForm[0]['academicYear']
            delete docOrdersForm[0]['created_at']
            delete docOrdersForm[0]['docOrderStudents']
            delete docOrdersForm[0]['docOrderSubType']
            delete docOrdersForm[0]['docOrderType']
            delete docOrdersForm[0]['status']
            delete docOrdersForm[0]['updated_at']
            delete docOrdersForm[0]['user_id']
            delete docOrdersForm[0]['id']


            docOrdersForm = docOrdersForm[0]


            console.log(docOrdersForm, 'put docOrdersForm')
            try {
                const {data} = await docOrdersService.putDocOrders(docOrdersForm, state.docOrders_form.editDocOrdersId);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
    },
    mutations: {
        SET_ACADEMIC_YEAR(state, academicYear) {
            state.docOrders_form.academicYear = academicYear
        },
        SET_DOC_ORDER_TYPE(state, docOrderType) {
            state.docOrders_form.docOrderType = docOrderType
        },
        SET_DOC_ORDER_SUB_TYPE(state, docOrderSubType) {
            state.docOrders_form.docOrderSubType = docOrderSubType
        },
        SET_DOC_ORDERS(state, docOrdersInfo) {
            state.docOrders_form.docOrdersInfo = docOrdersInfo
        },
        SET_NEW_DOC_ORDERS_DATA(state, {property, value}) {
            state.docOrders_form.newDocOrdersInfo[property] = value
            console.log(state.docOrders_form.newDocOrdersInfo, "STATE")
        },
        SET_NEW_DOC_ORDER_DATE(state, {property, value}) {
            state.docOrders_form.newDocOrdersInfo[property] = parseInt((new Date(value)).getTime() / 1000)
            console.log(state.docOrders_form.newDocOrdersInfo, "STATE")
        },
        SET_DOC_ORDER_DATE(state, {item, property, value}) {
            const index = state.docOrders_form.docOrdersInfo.indexOf(item)
            state.docOrders_form.docOrdersInfo[index][property] = parseInt((new Date(value)).getTime() / 1000)
            console.log(state.docOrders_form.docOrdersInfo, "STATE")
        },
        UPDATE_DOC_ORDERS_DATA(state, {item, property, value}) {
            const index = state.docOrders_form.docOrdersInfo.indexOf(item)
            state.docOrders_form.docOrdersInfo[index][property] = value
            console.log(state.docOrders_form.docOrdersInfo, 'STATE')
        },
        SET_EDIT_DOC_ORDERS_ID(state, editDocOrdersId) {
            state.docOrders_form.editDocOrdersId = editDocOrdersId
        },
        SET_ACADEMIC_LEAVE(state, data){
            state.academic_leave = data
        },
        SET_ACADEMIC_LEAVE_BY_ID(state, data){
            state.academic_leave_by_id = data
        },
        SET_DOC_ORDERS_INFO(state, data){
            state.docOrders_form.docOrdersInfos = data
        },
        SET_SECTIONS(state, data){
            state.docOrders_form.sections = data
        }
    },
    state: {
        docOrders_form: {
            academicYear: [],
            docOrderType: [],
            docOrderSubType: [],
            docOrdersInfo: [],
            newDocOrdersInfo: {},
            editDocOrdersId: 0,
            docOrdersInfos: {},
            sections: []
        },
        academic_leave_by_id: null
    },
    getters: {
        UPDATE_ITEM(state) {
            return state.docOrders_form.docOrdersInfo.find(i => i.id == state.docOrders_form.editDocOrdersId)

        }
    }
}

export default docOrders