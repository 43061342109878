import httpClient from "@/services/http.service"

const kpiCommissionService = {
    getKpiTeachers() {
        return httpClient.get(`kpi/user-kpi/get-users-kpi-commission`)
    },
    getKpiTeacherScore(userId) {
        return httpClient.get(`kpi/user-kpi-commission-decision/get-user-kpi-commission-decision?user_id=${userId}`)
    },

    postUserKpiCommissionDecision(form) {
        return httpClient.post(`kpi/user-kpi-commission-decision/create`, form)
    }
}

export default kpiCommissionService
