import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const abiturient = {
    namespaced: true,
    actions: {
        async GET_ABITURIENT_PROGRAMS({commit, state}) {
            try {

                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-program/get-program-for-filter' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log(data.success, 'GET_ABITURIENT_PROGRAMS')
                    commit('SET_ABITURIENT_PROGRAMS', data.success)

                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_ABITURIENTS({commit, state}, {payment_type, discount, abit_orders_item_id}) {
            console.log(abit_orders_item_id, 'abit_orders_item_id')

            try {
                const discountUrl = discount ? `&discount=${discount}`: ''
                const abitOrdersItemUrl = abit_orders_item_id ? `&abit_orders_item_id=${abit_orders_item_id}`: ''
                //const preUniversityUrl = pre_university ? `&pre_university=${pre_university}`: ''
                const urlGetParam = `&payment_type=${payment_type}${discountUrl}${abitOrdersItemUrl}`
                const {
                    status,
                    data
                } = await httpClient.get(`abiturients/abiturient-data/get-abiturients?access-token=${getCookie('ACCESS_TOKEN')}${urlGetParam}`);

                if (status === 200) {
                    console.log(data.success, 'GET_ABITURIENTS')
                    commit('SET_ABITURIENTS', data.success)
                    if(data.success.length){
                        const confirmedAbiturient = data.success.find(i => i.status == 1)
                        const isConfirmAbiturient = confirmedAbiturient ? true : false
                        commit('SET_IS_CONFIRM', isConfirmAbiturient)
                    }

                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },


        async GET_ABITURIENTS_NEW({commit, state}, {abit_orders_item_id}) {
            console.log(abit_orders_item_id, 'abit_orders_item_id')
            try {
                //const discountUrl = discount ? `&discount=${discount}`: ''
                const abitOrdersItemUrl = abit_orders_item_id ? `&abit_orders_item_id=${abit_orders_item_id}`: ''
                //const preUniversityUrl = pre_university ? `&pre_university=${pre_university}`: ''
                const urlGetParam = `${abitOrdersItemUrl}`
                const {
                    status,
                    data
                } = await httpClient.get(`abiturients/abiturient-data/get-abiturients?access-token=${getCookie('ACCESS_TOKEN')}${urlGetParam}`);

                if (status === 200) {
                    console.log(data, 'GET_ABITURIENTS_NEW')
                    commit('SET_ABITURIENTS', data)
                    console.log(data.filter(i=>i.status == 1), 'filtered')
                    if(data.length){
                        const confirmedAbiturient = data.find(i => i.status == 1)
                        const isConfirmAbiturient = confirmedAbiturient ? true : false
                        commit('SET_IS_CONFIRM', isConfirmAbiturient)
                    }

                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async POST_ABITURIENT_DATA({state}, abiturientsList) {

            console.log(abiturientsList, 'abiturientsList')
            let abiturientDataForm = abiturientsList

            // abiturientDataForm.forEach(i => {
            //     abiturientDataForm.push({
            //         abit_orders_item_id: state.abit_orders_item_id
            //     })
            // })
            for (let i= 0; i<abiturientDataForm.length; i++) {
                // abiturientDataForm[i].push({
                //     abit_orders_item_id: state.abit_orders_item_id
                // })
                abiturientDataForm[i]['abit_orders_item_id'] = state.abitOrdersItemId

            }

            console.log(abiturientDataForm, 'abiturientDataForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('abiturients/abit-order-item-students/create-abit-data' + '?access-token=' + getCookie('ACCESS_TOKEN'), abiturientDataForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return true
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    errors: e.response.data.errors
                }
            }
        },

        async DELETE_ABITURIENT_DATA({commit, state}, deleteAbiturientDataBarcode) {
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('abiturients/abit-order-item-students/delete-abit-from-order-item' + '?barcode=' + deleteAbiturientDataBarcode + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('abiturient data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_STUDENT_DATA({state}, form) {

            console.log(form, 'form')


            try {
                const {
                    status,
                    data
                } = await httpClient.post('abiturients/abit-order-item-students/create-student-data' + '?access-token=' + getCookie('ACCESS_TOKEN'), form);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return true
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    errors: e.response.data.errors
                }
            }
        },

        async POST_ABITURIENT_ORDERS_ITEM_STUDENTS({state}, abiturientsList) {

            let abiturientOrdersItemStudentsForm = []
            for (let i=0; i < abiturientsList.length; i++) {
                abiturientOrdersItemStudentsForm.push({
                    abiturient_data_id: abiturientsList[i].id,
                    abit_orders_item_id: state.abitOrdersItemId
                })
            }
            console.log(abiturientOrdersItemStudentsForm, 'abiturientOrdersItemStudentsForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('abiturients/abit-order-item-students/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), abiturientOrdersItemStudentsForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async PUT_ABITURIENT_DATA({commit, state}) {

            //let abiturientData = (abiturient.state.abiturients).find(v => v.id == id)
            //console.log(abiturientData, 'abiturientData')


            try {
                const {
                    status,
                    data
                } = await httpClient.put('abiturients/abiturient-data/update?id='+ state.editAbiturientId  + '&access-token=' + getCookie('ACCESS_TOKEN'), state.abiturientData);
                if (status === 200) {
                    console.log(data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }

        },


        async UPDATE_ABITURIENT_DATA_STATUS({commit, state}, id) {
            console.log(id, "update ID")
            try {
                const {
                    status,
                    data
                } = await httpClient.put('abiturients/abiturient-data/update-status' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('abiturient data update')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

    },
    mutations: {

        SET_ABITURIENT_PROGRAMS(state, abiturientPrograms) {
            state.abiturientPrograms = abiturientPrograms
        },
        SET_ABITURIENTS(state, abiturients) {
            state.abiturients = []
            state.abiturients = abiturients
        },
        SET_ABIT_ORDERS_ITEM_ID(state, abitOrdersItemId) {
            state.abitOrdersItemId = abitOrdersItemId
        },
        CHANGE_ABITURIENT_EPD(state, {epd}) {
            console.log(epd, 'epd')
            let abiturientData = (state.abiturients).find(v => v.id == state.editAbiturientId)
            console.log(abiturientData, 'abiturientData')
            state.abiturientData = abiturientData
            state.abiturientData.bitrix_epd_code = epd.trim()
            //abiturientData.bitrix_epd_code = epd.substr(0, 7)
            console.log(state.abiturientData)
        },
        SET_EDIT_ABITURIENT_ID(state, editAbiturientId) {
            state.editAbiturientId = editAbiturientId
            console.log(state.editAbiturientId, 'editAbiturientId')
        },
        CHANGE_ABITURIENT_CHECKED_STATUS(state, {barcode, status}) {
            let abituruient = state.abiturients.find(i=>i.barcode == barcode)
            abituruient.status = status
        },
        CHANGE_ALL_ABITURIENT_CHECKED_STATUS(state, status){
            state.abiturients.forEach(i=>{
                if (i.status != 2) {
                    i.status = status
                }

            })
        },
        SET_IS_CONFIRM(state, isConfirmAbiturient){
            state.isConfirmAbiturient = isConfirmAbiturient
            console.log(state.isConfirmAbiturient, 'isConfirmAbiturient')
        }


    },
    state: {
        abiturientPrograms: [],
        abiturients: [],
        abitOrdersItemId: null,
        abitOrdersItemStudentsInfo: {},
        editAbiturientId: null,
        abiturientData: [],
        isConfirmAbiturient: false
    },
    getters: {}
}

export default abiturient