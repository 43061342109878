import httpClient from "@/services/http.service"
import router from "@/router"

const teachersContractsService = {
    getTeachersContracts() {
        return httpClient.get(`labor_contract/teacher-labor-contract/get-teachers-contracts`)
    },
    getAdditionalAgreement(parent_id){
        return httpClient.get('kkk/contract-templates/get-additional-agreement?parent_id=' + parent_id)
    },
    getContractTemplates(){
        return httpClient.get('kkk/contract-templates/get-contract-templates');
    }
    // postCommissionDecision(form) {
    //     return httpClient.post(`kkk/commission-decision/create`, form)
    // },

}

export default teachersContractsService