import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const gosoTemplates = {
    namespaced: true,

    actions: {
        async GET_STUDY_LEVEL({commit}) {
            const url = 'education_program/study-level/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200 && data.length) {
                    commit("SET_STUDY_LEVEL_DATA", data)
                    commit("SET_STUDY_LEVEL_ID", data[0].id)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_GOSO_CYCLE_NAME({commit, state}) {
            const url = 'education_program/goso-cycle-name/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
                + '&study_level=' + state.gosoTemplate_form.selectedStudyLevelId
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_GOSO_CYCLE_NAME", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_GOSO_COMPONENTS_NAME({commit, state}) {
            const url = 'education_program/goso-components-name/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
                + '&study_level=' + state.gosoTemplate_form.selectedStudyLevelId
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_GOSO_COMPONENTS_NAME", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_GOSO_TEMPLATE_DATA({commit, state}) {
            const url = 'education_program/goso-templates/get-parent' + '?access-token=' + getCookie('ACCESS_TOKEN')
                + '&study_level_id=' + state.gosoTemplate_form.selectedStudyLevelId + '&decree_goso_templates_id=' + state.gosoTemplate_form.decreeGosoTemplatesId
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_GOSO_TEMPLATE_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_GOSO_TEMPLATE_DATA_ALL({commit, state}) {
            const url = 'education_program/goso-templates/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_GOSO_TEMPLATE_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_GOSO_TEMPLATE_DATA({state}, parent_id = 0) {
            const gosoData = state.gosoTemplate_form

            const goso_cycle_name_id = parent_id === 0
                ? gosoData.cycleNames.length ? gosoData.cycleNames[0].id : null
                : null

            const goso_components_name_id = parent_id !== 0
                ? gosoData.componentNames.length ? gosoData.componentNames[0].id : null
                : null

            let gosoTemplateData = [{
                goso_cycle_name_id,
                goso_components_name_id,
                parent_id,
                credit: 0,
                study_level_id: state.gosoTemplate_form.selectedStudyLevelId,
                note: '',
                decree_goso_templates_id: state.gosoTemplate_form.decreeGosoTemplatesId
            }]

            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/goso-templates/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), gosoTemplateData);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }

        },

        async DELETE_GOSO_TEMPLATE_DATA({commit, state}, id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('education_program/goso-templates/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('education direction data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async PUT_GOSO_TEMPLATE({commit, state}) {
            let gosoTemplateForm = state.gosoTemplate_form.gosoTemplateInfos
            let studyLevelId = state.gosoTemplate_form.selectedStudyLevelId
            gosoTemplateForm=gosoTemplateForm.filter(i=>!!i.id)
            //let decreeGosoTemplatesId = state.gosoTemplate_form.decreeGosoTemplatesId

            //console.log(decreeGosoTemplatesId, "decreeGosoTemplatesId")

            gosoTemplateForm.filter(i => i.parent_id == 0).forEach((parentItem => {
                parentItem.credit = gosoTemplateForm.filter(i => i.parent_id == parentItem.id).reduce((sum, item) => +item.credit + sum, 0)
            }))


            if (gosoTemplateForm.length > 0) {

                try {
                    console.log(gosoTemplateForm)
                    const {
                        status,
                        data
                    } = await httpClient.put('education_program/goso-templates/update' + '?access-token=' + getCookie('ACCESS_TOKEN'),
                        gosoTemplateForm
                    );
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
    },

    mutations: {
        ADD_ROW_GOSO_TEMPLATE_INFO(state) {
            state.gosoTemplate_form.gosoTemplateInfos.push({
                goso_cycle_name_id: null,
                goso_components_name_id: null,
                parent_id: 0,
                credit: 0,
                study_level_id: state.gosoTemplate_form.selectedStudyLevelId,
                note: '',
                put_status: 0,
            });
        },
        DELETE_ROW_GOSO_TEMPLATE_INFO(state, index) {
            state.gosoTemplate_form.gosoTemplateInfos.splice(index, 1);
        },
        DELETE_CHILD_ROW_GOSO_TEMPLATE_INFO(state, item) {
            const index = state.gosoTemplate_form.gosoTemplateInfos.indexOf(item)
            state.gosoTemplate_form.gosoTemplateInfos.splice(index, 1);
        },
        ADD_ROW_GOSO_TEMPLATE_COMPONENT(state, item) {

            // console.log(item, "parent_id GOSO")
            // const index = state.gosoTemplate_form.gosoTemplateInfos.indexOf(item)
            //
            // state.gosoTemplate_form.gosoTemplateInfos.push({
            //     goso_cycle_name_id: item.parent_id,
            //     parent_id: state.gosoTemplate_form.gosoTemplateInfos[index].goso_cycle_name_id,
            //     credit: 0,
            //     study_level_id: state.gosoTemplate_form.selectedStudyLevelId,
            //     note: '',
            //     put_status: 0,
            // });
            // console.log(state.gosoTemplate_form.gosoTemplateInfos, "DADADADAD")
        },
        UPDATE_GOSO_TEMPLATE_DATA(state, {item, property, value}) {
            console.log(state.gosoTemplate_form.gosoTemplateInfos, 'state.gosoTemplate_form.gosoTemplateInfos')
            const index = state.gosoTemplate_form.gosoTemplateInfos.indexOf(item)
            state.gosoTemplate_form.gosoTemplateInfos[index][property] = value


        },
        SET_STUDY_LEVEL_DATA(state, studyLevels) {
            state.gosoTemplate_form.studyLevels = studyLevels
            console.log(state.studyLevels)
        },

        SET_STUDY_LEVEL_ID(state, id) {
            state.gosoTemplate_form.selectedStudyLevelId = id
            console.log(state.gosoTemplate_form.selectedStudyLevelId)
        },

        SET_GOSO_CYCLE_NAME(state, cycleNames) {
            state.gosoTemplate_form.cycleNames = cycleNames
            console.log(state.gosoTemplate_form.cycleNames)
        },
        SET_GOSO_COMPONENTS_NAME(state, componentNames) {
            state.gosoTemplate_form.componentNames = componentNames
            console.log(state.gosoTemplate_form.componentNames)
        },
        SET_GOSO_TEMPLATE_DATA(state, gosoTemplate) {
            state.gosoTemplate_form.gosoTemplateInfos = gosoTemplate
            console.log(state.gosoTemplate_form)
        },
        SET_DECREE_GOSO_TEMPLATES_ID(state, decreeGosoTemplatesId) {
            state.gosoTemplate_form.decreeGosoTemplatesId = decreeGosoTemplatesId
            console.log(state.decreeGosoTemplatesId, "SET_DECREE_GOSO_TEMPLATES_ID decreeGosoTemplatesId")
        }
    },
    state: {
        gosoTemplate_form: {
            studyLevels: [],
            selectedStudyLevelId: 0,
            cycleNames: [],
            componentNames: [],
            gosoTemplateInfos: [],
            decreeGosoTemplatesId: null
        }

    }
}


export default gosoTemplates