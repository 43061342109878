import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const education = {
    namespaced: true,
    actions: {
        async DELETE_EDUCATION_DATA({commit, state}, index) {
            let id = state.education_form.educationInfos[index].id
            console.log(index + ' id ' + id)
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/pps-data-education/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('Education data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async DELETE_EDUCATION_FILES({commit, state}, index) {
            let id = state.education_form.educationFileLinks[index].id
            console.log(index + ' id ' + id)
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/pps-data-files/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('Education file DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_EDUCATION_DATA() {
            let educationForm = (education.state.education_form.educationInfos).filter(v => v.put_status == 0)
            if (educationForm.length > 0) {
                try {
                    console.log(educationForm)
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/pps-data-education/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e);
                    return {
                        success: false,
                        errors: e.response.data
                    }
                }
            } else {
                return 0
            }
        },
        async PUT_EDUCATION_DATA() {
            let educationForm = (education.state.education_form.educationInfos).filter(v => v.put_status == 1)
            if (educationForm.length > 0) {

                try {
                    console.log(educationForm)
                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/pps-data-education/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_EDUCATION_DATA({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/pps-data-education/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_FILES({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/pps-data-files/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_EDUCATION_FILES", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async getLanguages({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/pps-data-education/language' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateLanguages', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_EDUCATION_DATA(state, educationInfos) {
            state.education_form.educationInfos = educationInfos
        },
        SET_EDUCATION_FILES(state, educationFileLinks) {
            state.education_form.educationFileLinks = educationFileLinks
        },
        newRowEducationInfo(state) {
            state.education_form.educationInfos.push({
                name: '',
                address: '',
                start_year: '',
                end_year: '',
                specialization: '',
                faculty: '',
                study_level_id: 0,
                nostrification: 0,
                lang_id: 82,
                put_status: 0
            });
        },
        DELETE_ROW_EDUCATION_INFO(state, index) {
            state.education_form.educationInfos.splice(index, 1);
        },
        updateEducationName(state, payload) {
            state.education_form.educationInfos[payload.index].name = payload.value
        },
        updateEducationAddress(state, payload) {
            state.education_form.educationInfos[payload.index].address = payload.value
        },
        updateAdmissionYear(state, payload) {
            state.education_form.educationInfos[payload.index].start_year = payload.value
        },
        updateGraduationYear(state, payload) {
            state.education_form.educationInfos[payload.index].end_year = payload.value
        },
        updateEducationSpecialization(state, payload) {
            state.education_form.educationInfos[payload.index].specialization = payload.value
        },
        updateEducationFaculty(state, payload) {
            state.education_form.educationInfos[payload.index].faculty = payload.value
        },
        updateEducationStudyLevel(state, payload) {
            state.education_form.educationInfos[payload.index].study_level_id = payload.value
        },
        updateEducationLanguage(state, payload) {
            state.education_form.educationInfos[payload.index].lang_id = payload.value
        },
        updateEducationNostrification(state, payload) {
            state.education_form.educationInfos[payload.index].nostrification = payload.value === true ? 1 : 0
        },
        updateLanguages(state, languages) {
            state.languages = languages
        },
        updateEducationHeadHunterData(state, headHunterData) {
            state.headHunterData = headHunterData
            console.log(state.headHunterData, 'state edu hhData')
        },
        SET_HEADHUNTER_RESUME_EDUCATION(state, index) {
            state.resumeIndex = index
        },
        SET_HEADHUNTER_TO_EDUCATION_DATA(state, hhEducationData) {

            console.log(hhEducationData, 'hhData education.js')

            let level = hhEducationData.level.id

            hhEducationData.primary.forEach(item => {
                const {name, year, organization, result} = item

                let start = level == 'bachelor' ? year - 4 : year - 2
                state.education_form.educationInfos.push({
                    name,
                    address: 'Алматы',
                    start_year: start,
                    end_year: year,
                    specialization: result,
                    faculty: organization,
                    lang_id: 82,
                    put_status: 0
                })
            })

        }
    },
    state: {
        education_form: {
            educationFileLinks: [],
            educationFiles: [{file: ''}],
            educationInfos: [{
                name: '',
                address: '',
                start_year: '',
                end_year: '',
                specialization: '',
                faculty: '',
                study_level_id: 0,
                nostrification: 0,
                lang_id: 82,
                put_status: 0
            }],
        },
        languages: [],
        headHunterData: [],
        resumeIndex: 0
    },
    getters: {
        allLanguages(state) {
            return state.languages
        },
    }
}

export default education