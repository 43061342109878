import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const educationCurriculum = {
  namespaced: true,
  actions: {
    async GET_EDUCATION_PROGRAMS_BY_NAME({commit, state}, name = '') {
      const url = 'education_program/education-program/get-programs-by-name' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&name=' + name
      try {
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data.success, 'GET_EDUCATION_PROGRAM_DATA')
          return data.success
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async GET_FORMATION_EDUCATION_PROGRAM_DATA({commit, state}, {
      education_program_id,
      study_course,
      academic_year_id
    }) {
      console.log(study_course, 'study_course')
      const url = 'education_program/formation-education-program/get-disciplines-by-module' + '?access-token=' + getCookie('ACCESS_TOKEN') +
        '&education_program_id=' + education_program_id + '&study_course=' + study_course + '&academic_year_id=' + academic_year_id
      try {
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data, 'GET_FORMATION_EDUCATION_PROGRAM_DATA')

          commit("SET_FORMATION_EDUCATION_PROGRAM_DATA", data)
          //commit("SET_FORMATION_EDUCATION_PROGRAM_APPROVED_STATUS", data.is_confirm)
          commit("SET_EDUCATION_CURRICULUM_CONFIRM_STATUS", data.is_confirm)

        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_STUDY_FORM_NAMES({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-program/get-study-form' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_STUDY_FORM_NAMES', data.success)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_SP_ACADEMIC_YEAR({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-curriculum/get-academic-year' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_SP_ACADEMIC_YEAR', data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_EDUCATION_CURRICULUM_DATA({commit, getters}, {page, department_id, academic_year_id, language_id, admission_year}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-curriculum/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&page=' + page + '&department_id=' + department_id + '&academic_year_id=' + academic_year_id + '&language_id=' + language_id + '&admission_year=' + admission_year);
        if (status === 200) {
          console.log(data.success, 'GET_EDUCATION_CURRICULUM_DATA')
          commit('SET_EDUCATION_CURRICULUM_DATA', data.success)
          commit("SET_PAGE_COUNT", data.page_count)

        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_DISCIPLINE_SCHEDULE({commit}, {discipline_id, semester, academic_year}) {
      console.log(discipline_id, semester, academic_year)
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-curriculum/get-schedule' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&discipline_id=' + discipline_id + '&semester=' + semester + '&academic_year=' + academic_year);
        if (status === 200) {
          commit("SET_SCHEDULE_DISCIPLINES", data)
        }
      } catch (e) {
        console.log(e);
        return {
          success: false,
          // error: e.response.data.errors
        }
      }
    },
    async POST_EDUCATION_CURRICULUM_DATA({state}) {
      let educationCurriculumForm = state.educationCurriculum_form.newEducationCurriculumInfos
      // let educationCurriculumForm = [...state.educationCurriculum_form.newEducationCurriculumInfos]
      // educationCurriculumForm.forEach(item => {
      //     item.study_course = state.educationCurriculum_form.selectedStudyCourse
      //     item.admission_year = admission_year
      // })
      console.log(educationCurriculumForm, "POST educationCurriculumForm")
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/education-curriculum/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationCurriculumForm);
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async GET_CURRICULUM_DISCIPLINES_BY_DEPARTMENT({commit}, department_id) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-curriculum/get-curriculum-discipline' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&department_id=' + department_id);
        if (status === 200) {
          console.log(data, 'GET_CURRICULUM_DISCIPLINES_BY_DEPARTMENT')
          commit("SET_CURRICULUM_DISCIPLINES", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE({commit}, {discipline_id, semester}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('teaching_load/teacher-courses/get-groups-and-teachers-by-discipline' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&discipline_id=' + discipline_id + '&semester_discipline=' + semester);
        if (status === 200) {
          commit("SET_TEACHERS_GROUPS_FLOWS", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async POST_EDUCATION_COURSE({state}, courses) {
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/education-courses/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), courses);
        if (status === 200) {
          console.log(data, 'create courses')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async PUT_EDUCATION_COURSE({state}, courses) {
      try {
        const {
          status,
          data
        } = await httpClient.put('education_program/education-courses/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), courses);
        if (status === 200) {
          console.log(data, 'update courses')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async UPLOAD_TO_MOODLE({state}, courses) {
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/education-courses/upload-to-moodle' + '?access-token=' + getCookie('ACCESS_TOKEN'), courses);
        if (status === 200) {
          console.log(data, 'upload-to-moodle courses')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async CREATE_MDL_COURSE({commit}, ids) {
      try {
        const {
          status,
          data
        } = await httpClient.post(`education_program/education-courses/unite-education-courses?access-token=${getCookie('ACCESS_TOKEN')}`, ids);
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },
    async TAKE_TOKEN_FROM_MOODLE({commit}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('moodle_api/moodle-api/connect');
        if (status === 200) {
          commit("SET_MOODLE_TOKEN", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async CREATE_COURSE({state}) {
      try {
        const {
          status,
          data
        } = await httpClient.get(`moodle_api/moodle-api/create-course?token=${state.moodle.token}&course_fullname=hello world&course_shortname=hello&categoryid=3`);
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async POST_FORMATION_EDUCATION_PROGRAM_APPROVED({state}, {academic_year_id, study_course, education_program_id}) {
      //let formationForm = state.educationCurriculum_form.formationEducationProgramInfos.rup

      console.log(academic_year_id, study_course, education_program_id, 'DFHDFHDFH')
      let formationForm = {}
      formationForm['rup'] = state.educationCurriculum_form.formationEducationProgramInfos.rup
      formationForm['academic_year_id'] = academic_year_id
      formationForm['education_program_id'] = education_program_id
      formationForm['study_course'] = study_course

      //let formationApprovedForm = []

      // for (let i = 0; i < formationForm.length; i++) {
      //   formationApprovedForm.push({
      //     id: formationForm[i].id,
      //     education_module_id: formationForm[i].education_module_id,
      //     education_program_id: formationForm[i].education_program_id,
      //     goso_cycle_name_id: formationForm[i].goso_cycle_name_id,
      //     semester: formationForm[i].semester,
      //     education_discipline_id: formationForm[i].education_discipline_id,
      //     goso_component_name_id: formationForm[i].goso_component_name_id,
      //     credit: formationForm[i].credit,
      //     is_cv: formationForm[i].is_cv,
      //     reference_id: formationForm[i].reference_id,
      //     f_group_id: formationForm[i].f_group_id,
      //     esuvo_tup_subject_id: formationForm[i].esuvo_tup_subject_id,
      //     is_confirmed: formationForm[i].is_confirmed,
      //     component_status: formationForm[i].component_status,
      //     created_at: formationForm[i].created_at,
      //     updated_at: formationForm[i].updated_at,
      //   })
      // }


      console.log(formationForm, "POST formationForm")
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/formation-education-program-approved/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), formationForm);
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          data: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          data: false,
          errors: e.response.data.errors
        }
      }
    },


    async DELETE_FORMATION_EDUCATION_PROGRAM_APPROVED({commit}, {academic_year_id, study_course, education_program_id}) {
      try {
        await httpClient.delete('education_program/formation-education-program-approved/delete?education_program_id='+education_program_id+'&study_course='+study_course+'&academic_year_id='+academic_year_id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_EDUCATION_PROGRAM_DATA(state, educationProgramInfos) {
      state.educationCurriculum_form.educationProgramInfos = educationProgramInfos
    },
    SET_EDUCATION_CURRICULUM_DATA(state, educationCurriculumInfos) {
      state.educationCurriculum_form.educationCurriculumInfos = educationCurriculumInfos
    },
    SET_SELECTED_EDUCATION_PROGRAM_ID(state, id) {
      //state.educationCurriculum_form.selectedEducationProgramId = id
      state.educationCurriculum_form.newEducationCurriculumInfos['education_program_id'] = id
      console.log(state.educationCurriculum_form.newEducationCurriculumInfos, "STATE")
    },
    SET_SELECTED_STUDY_COURSE(state, selectedStudyCourse) {
      state.educationCurriculum_form.selectedStudyCourse = selectedStudyCourse
      console.log(state.educationCurriculum_form.selectedStudyCourse)
    },
    SET_FORMATION_EDUCATION_PROGRAM_DATA(state, formationEducationProgramInfos) {
      state.educationCurriculum_form.formationEducationProgramInfos = formationEducationProgramInfos
    },
    SET_FORMATION_EDUCATION_PROGRAM_APPROVED_STATUS(state, formationEducationProgramApprovedStatus) {
      state.educationCurriculum_form.formationEducationProgramApprovedStatus = formationEducationProgramApprovedStatus
      console.log(state.educationCurriculum_form.formationEducationProgramApprovedStatus, 'STATE formationEducationProgramApprovedStatus')
    },
    SET_EDUCATION_CURRICULUM_CONFIRM_STATUS(state, educationCurriculumConfirmStatus) {
      state.educationCurriculum_form.educationCurriculumConfirmStatus = educationCurriculumConfirmStatus
      console.log(state.educationCurriculum_form.educationCurriculumConfirmStatus, 'STATE educationCurriculumConfirmStatus')
    },
    SET_CREDIT_TIME_NORM_MODAL_VALUE(state, creditTimeNormModalValue) {
      state.educationCurriculum_form.creditTimeNormModalValue = creditTimeNormModalValue
    },
    SET_NEW_EDUCATION_CURRICULUM_DATA(state, {property, value}) {

      // for (let i = 0; i < state.educationCurriculum_form.newEducationCurriculumInfos.length; i++) {
      //     state.educationCurriculum_form.newEducationCurriculumInfos[i][property] = value
      // }
      state.educationCurriculum_form.newEducationCurriculumInfos[property] = value
      //state.educationCurriculum_form.newEducationCurriculumInfos['education_program_id'] = state.educationCurriculum_form.selectedEducationProgramId

      console.log(state.educationCurriculum_form.newEducationCurriculumInfos, "STATE")
    },
    SET_STUDY_FORM_NAMES(state, studyForm) {
      state.educationCurriculum_form.studyForm = studyForm
    },
    SET_SP_ACADEMIC_YEAR(state, academicYear) {
      state.educationCurriculum_form.academicYear = academicYear
    },
    SET_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount
    },
    SET_CURRICULUM_DISCIPLINES(state, curriculumDisciplines) {
      state.curriculumDisciplinesData.curriculumDisciplines = curriculumDisciplines
    },
    SET_TEACHERS_GROUPS_FLOWS(state, data) {
      state.curriculumDisciplinesData.discipline = data.discipline
      state.curriculumDisciplinesData.teachers = data.teachers
      state.curriculumDisciplinesData.groups = data.groups
      state.curriculumDisciplinesData.flows = Object.values(data.flows)
      console.log(state.curriculumDisciplinesData, 'SET_TEACHERS_GROUPS_FLOWS')
    },
    SET_SCHEDULE_DISCIPLINES(state, scheduleDisciplines) {
      state.scheduleDisciplinesData.scheduleDisciplines = scheduleDisciplines
    },
    CHANGE_GROUP(state, {item, prop, value}) {
      const index = state.curriculumDisciplinesData.groups.indexOf(item)
      state.curriculumDisciplinesData.groups[index][prop] = value
    },
    CHANGE_FLOW(state, {item, prop, value}) {
      const index = state.curriculumDisciplinesData.flows.indexOf(item)
      state.curriculumDisciplinesData.flows[index][prop] = value
      console.log(state.curriculumDisciplinesData.flows, 'CHANGE_FLOW')
    },
    SET_MOODLE_TOKEN(state, token) {
      state.moodle.token = token
      console.log(state.moodle.token)
    }
    // SET_NEW_EDUCATION_CURRICULUM_DATA_FORMATION_EDUCATION_PROGRAM_ID(state, formation_education_program_id) {
    //     state.educationCurriculum_form.newEducationCurriculumInfos.push({
    //         formation_education_program_id
    //     })
    //     console.log(state.educationCurriculum_form.newEducationCurriculumInfos, "STATE")
    // },

  },
  state: {
    educationCurriculum_form: {
      educationProgramInfos: [],
      educationCurriculumInfos: [],
      newEducationCurriculumInfos: {},
      formationEducationProgramInfos: [],
      selectedEducationProgramId: null,
      creditTimeNormModalValue: null,
      studyForm: [],
      academicYear: [],
      selectedStudyCourse: null,
      formationEducationProgramApprovedStatus: null,
      educationCurriculumConfirmStatus: null
    },
    curriculumDisciplinesData: {
      curriculumDisciplines: [],
      discipline: {},
      teachers: [],
      groups: [],
      flows: []
    },
    scheduleDisciplinesData: {
        scheduleDisciplines: []
      },
    pageCount: 0,
    moodle: {
      token: ''
    }
  },
  getters: {}

}

export default educationCurriculum
