import httpClient from "@/services/http.service"
import router from "@/router"


const adviserChooseService = {
    getStudyLevels() {
        return httpClient.get(`education_program/study-level/index`)
    },
    getEducationPrograms(study_level_id, study_course) {
        return httpClient.get(`education_program/education-program/get-education-program-names` + '?study_level_id=' + study_level_id + '&study_course=' + study_course)
    },
    getStudentGroupsByEducationProgramId(education_program_id) {
        return httpClient.get(`student_data/student-data/get-student-groups-by-education-program-id?education_program_id=${education_program_id}`)
    },
    getPpsByDepartmentId() {
        return httpClient.get(`graduate/selection-supervisors/get-pps-by-department-id`)
    },
    postAdviserChoose(form) {
        return httpClient.post(`adviser_choose/adviser-choose/create`, form)
    },
    putAdviserChoose(form) {
        return httpClient.put(`adviser_choose/adviser-choose/update`, form)
    },
    // getAdviserChoose() {
    //     return httpClient.get(`adviser_choose/adviser-choose/index`)
    // },

}

export default adviserChooseService