import graduateWorkAttestationService from "@/services/graduate-work/graduateWorkAttestation.service"

const graduateWorkAttestation = {
  namespaced: true,
  actions: {
    async GET_GRADUATE_WORK_USERS_BY_GROUP({commit}, groupId) {
      try {
        const {data} = await graduateWorkAttestationService.getGraduateWorkUsersByGroup(groupId)
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },
    async POST_GRADUATE_WORK_USERS({commit}, postData) {
      try {
        const {data} = await graduateWorkAttestationService.postGraduateWorkUsers(postData)
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_GRADUATE_WORK_USERS({commit}, putData) {
      try {
        const {data} = await graduateWorkAttestationService.putGraduateWorkUsers(putData)
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_TEACHERS({commit}) {
      try {
        const {data} = await graduateWorkAttestationService.getTeachers()
        if (data) {
          commit('SET_USERS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_LAST_EDUCATION_PROGRAMS({commit}, study_level_id) {
      try {
        const {data} = await graduateWorkAttestationService.getLastEducationProgramsByStudyLevel(study_level_id)
        if (data) {
          commit('SET_EDUCATION_PROGRAMS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_USERS(state, users) {
      state.graduateWorkAttestation.users = users
    },
    SET_EDUCATION_PROGRAMS(state, programs) {
      state.graduateWorkAttestation.programs = programs
    },
  },
  state: {
    graduateWorkAttestation: {
      users: {
        chairmain: [],
        commission: []
      },
      programs: []
    }
  },
}

export default graduateWorkAttestation