import complaintService from "@/services/complaint.service";

const complaintForm = {
  namespaced: true,
  actions: {
    async POST_COMPLAINT({commit}, complaintForm) {
      try {
        const {status, data} = await complaintService.postComplaint(complaintForm)
        console.log(data, 'create')
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },
    async GET_COMPLAINTS({commit}) {
      try {
        const {status, data} = await complaintService.getComplaints()
        console.log(data, 'index')
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_EDUCATION_PROGRAM_DATA(state, educationProgramInfos) {
      state.educationprogram_form.educationProgramInfos = educationProgramInfos
    },
  },
  state: {},
  getters: {}
}

export default complaintForm