import bigFiveService from "@/services/big-five/bigFive.service.js";

const bigFive = {
  namespaced: true,
  actions: {
    async GET_BIG_FIVE_QUESTIONS({commit}) {
      try {
        const {status, data} = await bigFiveService.getBigFiveQuestions()
        if (status === 200) {
          console.log('GET_BIG_FIVE_QUESTIONS', data)
          commit('SET_BIG_FIVE_QUESTIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_BIG_FIVE_SCORE({commit}) {
      try {
        const {status, data} = await bigFiveService.getBigFiveScore()
        if (status === 200) {
          console.log('GET_BIG_FIVE_SCORE', data)
          commit('SET_BIG_FIVE_SCORE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_BIG_FIVE_ANSWERS({state, commit}, answers) {
      try {
        console.log(answers)
        await bigFiveService.postBigFiveAnswers(answers)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_BIG_FIVE_RESULTS({commit}) {
      try {
        const {status, data} =  await bigFiveService.getBigFiveResults()
        if (status === 200) {
          console.log(data)
          commit('SET_BIG_FIVE_RESULTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    }

  },
  mutations: {
    SET_BIG_FIVE_QUESTIONS(state, data) {
      state.bigFiveQuestions = data
    },
    SET_BIG_FIVE_SCORE(state,data){
      state.bigFiveScore = data
    },
    SET_BIG_FIVE_RESULTS(state, data){
      state.bigFiveResults = data
    }
  },
  state: {
    bigFiveQuestions: [],
    bigFiveScore: [],
    bigFiveResults: []
  }
}

export default bigFive
