import httpClient from "@/services/http.service"
import router from "@/router"


const commissionDecisionService = {
    getVacancyApplications() {
        return httpClient.get(`kkk/user-syllabus/get-vacancy-applications-for-commission`)
    },
    postCommissionDecision(form) {
        return httpClient.post(`kkk/commission-decision/create`, form)
    },
    getEducationProgramsByDiscipline(discipline_id) {
        return httpClient.get(`education_program/education-program/get-education-programs-by-discipline` + '?discipline_id='+discipline_id)
    },

}

export default commissionDecisionService