import graduateWorkScoreService from "@/services/graduate-work/graduateWorkScore.service"

const graduateWorkScore = {
    namespaced: true,
    actions: {
        async GET_STUDENTS_BY_GROUP_ID({commit}) {
            try {
                const {data} = await graduateWorkScoreService.getStudentsByGroupId();
                if (data) {
                    console.log(data, 'GET_STUDENTS_BY_GROUP_ID')
                    commit('SET_STUDENT_GROUPS', data.groups)
                    commit('SET_STUDENTS', data.students)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_GRADUATE_WORK_SCORE_STUDENTS({commit}, postData) {
            try {
                const {data} = await graduateWorkScoreService.postGraduateWorkScoreStudents(postData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_STUDENT_GRADUATE_WORK_FILES({commit}, student_id) {
            try {
                const {data} = await graduateWorkScoreService.getStudentGraduateWorkFiles(student_id);
                if (data) {
                    commit('SET_STUDENT_GRADUATE_WORK_FILES', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_STUDENT_GROUPS(state, studentGroups) {
            state.graduateWorkScore_form.studentGroups = studentGroups
        },
        SET_STUDENTS(state, students) {
            state.graduateWorkScore_form.students = students
        },
        SET_STUDENT_GRADUATE_WORK_FILES(state, studentGraduateWorkFiles) {
            state.graduateWorkScore_form.studentGraduateWorkFiles = studentGraduateWorkFiles
        },
    },
    state: {
        graduateWorkScore_form: {
            studentGroups: [],
            students: [],
            studentGraduateWorkFiles: []
        }
    },
    getters: {}
}

export default graduateWorkScore