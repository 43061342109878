import commissionDecisionService from "@/services/kkk/commission/commissionDecision.service"
import axios from 'axios'

const commissionDecision = {
    namespaced: true,
    actions: {

        async GET_VACANCY_APPLICATIONS({commit}) {
            try {
                const {data} = await commissionDecisionService.getVacancyApplications();
                if (data) {
                    console.log(data, 'GET_VACANCY_APPLICATIONS')
                    commit('SET_VACANCY_APPLICATIONS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_COMMISSION_DECISION({commit, state}, form) {

            console.log(form, 'post form')
            try {
                const {data} = await commissionDecisionService.postCommissionDecision(form);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_EDUCATION_PROGRAMS_BY_DISCIPLINE({commit}, discipline_id) {
            try {
                const {data} = await commissionDecisionService.getEducationProgramsByDiscipline(discipline_id);
                if (data) {
                    console.log(data, 'GET_EDUCATION_PROGRAMS_BY_DISCIPLINE')
                    commit('SET_EDUCATION_PROGRAMS_BY_DISCIPLINE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_VACANCY_APPLICATIONS(state, vacancyApplications) {
            state.commissionDecision_form.vacancyApplications = vacancyApplications
        },
        SET_EDUCATION_PROGRAMS_BY_DISCIPLINE(state, educationProgramsByDiscipline) {
            state.commissionDecision_form.educationProgramsByDiscipline = educationProgramsByDiscipline
        },
    },
    state: {
        commissionDecision_form: {
            vacancyApplications: [],
            educationProgramsByDiscipline: []

        }
    },
    getters: {

    }
}

export default commissionDecision