import httpClient from "@/services/http.service"
import router from "@/router"


const finalExamService = {
    getEducationCourseDataById(education_courses_id) {
        return httpClient.get(`education_program/education-courses/get-course-by-id?id=` + education_courses_id)
    },
    getStudentsByMdlCourseId(mdl_course_id) {
        return httpClient.get(`student/student-ratings/get-students-by-mdl-course-id?mdl_course_id=` + mdl_course_id)
    },
    postFinalExamReport(data) {
        return httpClient.post(`student/student-ratings/update-exam-grades`, data)
    },

}

export default finalExamService