import semesterCalendarService from "@/services/calendar/semesterCalendar.service"

const semesterCalendar = {
  namespaced: true,
  actions: {
    async GET_SEMESTER({commit}) {
      try {
        const {status, data} = await semesterCalendarService.getSemester()
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_EDUCATION_CALENDAR({commit}) {
      try {
        const {status, data} = await semesterCalendarService.getEducationCalendar()
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_SEMESTER_CALENDAR({commit}) {
      try {
        const {status, data} = await semesterCalendarService.get()
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_SEMESTER_CALENDAR({commit}, form) {
      try {
        await semesterCalendarService.post(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {},
  state: {},
  getters: {}
}

export default semesterCalendar