import httpClient from "@/services/http.service"
const eqiShortService = {
  getEqiShortQuestions() {
    return httpClient.get(`sei/eqi-short-questions/get-eqi-short-questions`)
  },
  postEqiShortAnswers(answers){
    return httpClient.post(`sei/eqi-short-answers/create`, answers)
  },
  getEqiShortAnswers() {
    return httpClient.get(`sei/eqi-short-answers/get-eqi-short-answers`)
  },
}

export default eqiShortService