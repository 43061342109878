import kpiTeachersDepartmentHeadService from "@/services/kpi/kpiTeachersDepartmentHead.service"

const chooseLanguageLevel = {
  namespaced: true,
  actions: {
    async GET_KPI_TEACHERS_BY_DEPARTMENT_ID({commit}) {
      try {
        const {status, data} = await kpiTeachersDepartmentHeadService.getKpiTeachersByDepartmentId()
        if (status === 200) {
          commit('SET_KPI_TEACHERS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async PUT_KPI_TEACHER_STATUS({commit}, {id, status, comment}) {
      try {
        await kpiTeachersDepartmentHeadService.putKpiTeacherStatus(id, status, comment)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


  },
  mutations: {
    SET_KPI_TEACHERS(state, kpiTeachers) {
      state.kpiTeachers = kpiTeachers
    },
  },
  state: {
    kpiTeachers: []
  },
  getters: {}
}

export default chooseLanguageLevel