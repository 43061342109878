import httpClient from "@/services/http.service"
import router from "@/router"


const complaintService = {
  getComplaints() {
    return httpClient.get(`complaints/complaints/index`)
  },
  postComplaint(formData) {
    return httpClient.post(`complaints/complaints/create`, formData)
  },

}

export default complaintService