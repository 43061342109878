import httpClient from "@/services/http.service"


const syllabusMainTasksService = {
    getSyllabusMainTasks(syllabus_id) {
        return httpClient.get(`syllabus_data/syllabus-main-tasks/index?syllabus_id=${syllabus_id}`)
    },
    postSyllabusMainTasks(form) {
        return httpClient.post(`syllabus_data/syllabus-main-tasks/create`, form)
    },
    putSyllabusMainTasks(id, form) {
        return httpClient.put(`syllabus_data/syllabus-main-tasks/update?id=${id}`, form)
    },
    deleteSyllabusMainTasks(id) {
        return httpClient.delete(`syllabus_data/syllabus-main-tasks/delete?id=${id}`)
    },
}

export default syllabusMainTasksService