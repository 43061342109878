import httpClient from "@/services/http.service"


const foreignDisciplineService = {
  get(page) {
    return httpClient.get(`academic_mobility/academic-mobility/index?page=${page}`)
  },
  post(data) {
    return httpClient.post(`academic_mobility/academic-mobility/create`, data)
  },
  put(data) {
    return httpClient.put(`academic_mobility/academic-mobility/update?id=${data.id}`, data)
  },
  delete(id) {
    return httpClient.delete(`academic_mobility/academic-mobility/delete?id=${id}`)
  },
  getForeignUniversity() {
    return httpClient.get(`academic_mobility/academic-mobility/get-foreign-university`)
  },
  getFrontAndForeignDisciplines(doc_order_id) {
    return httpClient.get(`academic_mobility/academic-mobility/get-front-and-foreign-disciplines?doc_order_id=${doc_order_id}`)
  },
  postForeignAndFrontDisciplines(data) {
    return httpClient.post(`academic_mobility/academic-mobility/create-changed-disciplines`, data)
  },
  deleteDisciplineGroup(grouped_disciplines_id) {
    return httpClient.delete(`academic_mobility/academic-mobility/delete-changed-disciplines?grouped_disciplines_id=${grouped_disciplines_id}`)
  },
  getGradesOfForeignDisciplines(student_id) {
    return httpClient.get(`student/student-ratings/get-foreign-ratings?student_id=${student_id}`)
  },
  putGradesOfForeignDisciplines(data) {
    return httpClient.post(`student/student-ratings/update-total`, data)
  },
}

export default foreignDisciplineService