import commissionResearchCupService from "@/services/student-research-cup/commission/commissionResearchCup.service"

const commissionResearchCup = {
    namespaced: true,
    actions: {
        async GET_RESEARCH_CUP_WINNERS({commit}) {
            try {
                const {status, data} = await commissionResearchCupService.getResearchCupWinners()
                if (status === 200) {
                    console.log('GET_RESEARCH_CUP_WINNERS', data)
                    commit('SET_RESEARCH_CUP_WINNERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_RESEARCH_CUP_STEPS_PROTOCOL_CONDITION({commit}) {
            try {
                const {status, data} = await commissionResearchCupService.getResearchCupStepsProtocolCondition()
                if (status === 200) {
                    console.log('GET_RESEARCH_CUP_STEPS_PROTOCOL_CONDITION', data)
                    commit('SET_RESEARCH_CUP_STEPS_PROTOCOL_CONDITION', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async GET_RESEARCH_CUP_STEPS({commit}) {
            try {
                const {status, data} = await commissionResearchCupService.getResearchCupSteps()
                if (status === 200) {
                    console.log('GET_RESEARCH_CUP_STEPS', data)
                    commit('SET_RESEARCH_CUP_STEPS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        async GET_RESEARCH_CUP_STEPS_CRITERIONS({commit}) {
            try {
                const {status, data} = await commissionResearchCupService.getResearchCupStepsCriterions()
                if (status === 200) {
                    console.log('GET_RESEARCH_CUP_STEPS_CRITERIONS', data)
                    commit('SET_RESEARCH_CUP_STEPS_CRITERIONS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async POST_RESEARCH_CUP_STUDENT_SCORE({commit}, form) {
            console.log('form', form)
            try {
                await commissionResearchCupService.postResearchCupStudentScore(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },




    },
    mutations: {
        SET_RESEARCH_CUP_WINNERS(state, winners) {
            state.commissionResearchCup_form.winners = winners
        },
        SET_RESEARCH_CUP_STEPS_PROTOCOL_CONDITION(state, stepsProtocolCondition) {
            state.commissionResearchCup_form.stepsProtocolCondition = stepsProtocolCondition
        },
        SET_RESEARCH_CUP_STEPS(state, steps) {
            state.commissionResearchCup_form.steps = steps
        },
        SET_RESEARCH_CUP_STEPS_CRITERIONS(state, stepsCriterions) {
            state.commissionResearchCup_form.stepsCriterions = stepsCriterions
        },
    },
    state: {
        commissionResearchCup_form: {
            stepsProtocolCondition: [],
            steps: [],
            stepsCriterions: [],
            winners: []
        }
    },
    getters: {}
}

export default commissionResearchCup