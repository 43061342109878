import teacherScheduleService from "@/services/schedule/teacherSchedule.service"

const teacherSchedule = {
  namespaced: true,
  actions: {

    async DELETE_PPS_FREETIME({commit}, deleteId) {
      try {
        const {status, data} = await teacherScheduleService.deletePpsFreetime(deleteId)
        if (status === 200) {
          console.log('deleted', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_PPS_FREETIME({commit}) {
      try {
        const {status, data} = await teacherScheduleService.getPpsFreetime()
        if (status === 200) {
          console.log('GET_PPS_FREETIME', data)
          commit('SET_PPS_FREETIME', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },


    async POST_PPS_FREETIME({commit}, form) {
      try {
        await teacherScheduleService.postPpsFreetime(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


  },
  mutations: {
    SET_PPS_FREETIME(state, ppsFreetime) {
      state.teacherSchedule_form.ppsFreetime = ppsFreetime
    },
  },
  state: {
    teacherSchedule_form: {
      ppsFreetime: []
    },
  },
  getters: {}
}

export default teacherSchedule