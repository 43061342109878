import httpClient from "@/services/http.service"

const studentAgreementService = {
    getEducationProgramChangeStudents() {
        return httpClient.get(`doc_orders/doc-orders/get-education-program-change-students`)
    },

    postStudentAgreement(form) {
        return httpClient.post(`labor_contract/student-contract/create-contract`, form)
    },

}

export default studentAgreementService