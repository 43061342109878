import syllabusMainTasksService from "@/services/syllabus/syllabusMainTasks.service";

const syllabusMainTasks = {
    namespaced: true,
    actions: {
        async GET_SYLLABUS_MAIN_TASKS({commit}, syllabus_id) {
            try {
                const {status, data} = await syllabusMainTasksService.getSyllabusMainTasks(syllabus_id)
                if (status === 200) {
                    commit("SET_MAIN_TASKS", data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async DELETE_SYLLABUS_MAIN_TASKS({commit}, id) {
            try {
                const {status} = await syllabusMainTasksService.deleteSyllabusMainTasks(id);
                return status == 200;
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_SYLLABUS_MAIN_TASKS({commit}, form) {
            try {
                const {status} = await syllabusMainTasksService.postSyllabusMainTasks(form);
                return status === 200
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async PUT_SYLLABUS_MAIN_TASKS({commit}, form) {
            try {
                for (let syllabusOfficeHour of form) {
                    await syllabusMainTasksService.putSyllabusMainTasks(syllabusOfficeHour.id, syllabusOfficeHour);
                }
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_MAIN_TASKS(state, data) {
            state.syllabusMainTasks = data
        },
        ADD_ROW_MAIN_TASKS(state, syllabus_id) {
            state.syllabusMainTasks.push({
                syllabus_id: syllabus_id,
                task: '',
                description: '',
                deadline: '',
                criterions: '',
            });
        },
        DELETE_ROW_MAIN_TASKS(state, index) {
            state.syllabusMainTasks.splice(index, 1);
        },
        SET_NEW_MAIN_TASKS(state, {index, property, value}) {
            state.syllabusMainTasks[index][property] = value
            console.log(state.syllabusMainTasks, 'STATE')
        },
    },
    state: {
        syllabusMainTasks: []
    },
    getters: {}

}

export default syllabusMainTasks