import studentResearchCupRegistrationService
  from "@/services/student-research-cup/student/studentResearchCupRegistration.service"

const studentResearchCupRegistration = {
  namespaced: true,
  actions: {
    async GET_STUDENT_RESEARCH_CUP_REGISTRATION({commit}) {
      try {
        const {status, data} = await studentResearchCupRegistrationService.getStudentRegistration()
        if (status === 200) {
          commit('SET_STUDENT_DATA', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async REGISTER_TO_RESEARCH_CUP({commit}, studentData) {
      try {
        const {status} = await studentResearchCupRegistrationService.registerToResearchCup(studentData)
        if (status === 200) {
          return true
        }
      } catch (e) {
        return false
      }
    },
    async GET_PROJECT_THEME_DIRECTION({commit}) {
      try {
        const {status, data} = await studentResearchCupRegistrationService.getProjectThemeDirection()
        if (status === 200) {
          commit('SET_PROJECT_THEME_DIRECTION', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_RESEARCH_CUP_STEPS({commit}) {
      try {
        const {status, data} = await studentResearchCupRegistrationService.getResearchCupSteps()
        if (status === 200) {
          commit('SET_RESEARCH_CUP_STEPS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_RESEARCH_CUP_FILE({commit}, formData) {
      try {
        const {status} = await studentResearchCupRegistrationService.postResearchCupVideo(formData)
        if (status === 200) {
          return true
        }
      } catch (e) {
        return false
      }
    },
    async GET_CUP_SCHEDULE({commit}) {
      try {
        const {status, data} = await studentResearchCupRegistrationService.getCupSchedule()
        if (status === 200) {
          commit('SET_CUP_SCHEDULE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },

  mutations: {
    SET_STUDENT_DATA(state, studentData) {
      state.studentRegistrationData = studentData
    },
    SET_PROJECT_THEME_DIRECTION(state, projectThemeDirection) {
      state.projectThemeDirection = projectThemeDirection
    },
    SET_RESEARCH_CUP_STEPS(state, researchCupSteps) {
      state.researchCupSteps = researchCupSteps
    },
    SET_CUP_SCHEDULE(state, cupSchedule){
      state.cupSchedule = cupSchedule
    }
  },
  state: {
    studentRegistrationData: {},
    projectThemeDirection: {},
    researchCupSteps: [],
    cupSchedule: []
  },
}

export default studentResearchCupRegistration
