import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const teacher = {
  namespaced: true,
  actions: {
    async GET_QUESTION_BANK_PARENT_CATEGORIES_BY_MDL_COURSE_ID({commit}, mdl_course_id) {
      try {
        const url = `education_program/education-courses/get-question-bank-parent-categories-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${mdl_course_id}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data, 'GET_QUESTION_BANK_PARENT_CATEGORIES_BY_MDL_COURSE_ID')
          commit("SET_QUESTION_CATEGORIES", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async POST_QUIZ_CATEGORY({state}, postData) {
      try {
        const {
          status,
          data
        } = await httpClient.post(`exam_print/moodle-question-category/create?access-token=${getCookie('ACCESS_TOKEN')}`, postData);
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false
        }
      }
    },

    async GET_EDUCATION_COURSES_DATA_BY_PPS_ID({commit}) {
      try {
        const url = 'education_program/education-courses/get-courses-by-pps-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&pps_id=' + getCookie('USER_ID')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data.success, 'GET_EDUCATION_COURSES_DATA_BY_PPS_ID')
          commit("SET_EDUCATION_COURSES_DATA_BY_PPS_ID", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_SELECTABLE_COURSES_DATA_BY_PPS_ID({commit}) {
      try {
        const url = 'education_program/education-courses/get-selectable-courses-by-pps-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&pps_id=' + getCookie('USER_ID')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit("SET_SELECTABLE_COURSES_DATA_BY_PPS_ID", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async POST_IMPORT_SYLLABUS({state}, syllabusId) {

      try {
        const {
          status,
          data
        } = await httpClient.post('syllabus_data/syllabus/add-topics-and-other-categories-to-moodle' + '?access-token=' + getCookie('ACCESS_TOKEN'),
          {mdl_course_id: state.teacher_form.mdl_course_id, syllabus_id: syllabusId});
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false
        }
      }
    },

    async PUT_EDUCATION_COURSES_SYLLABUS_STATUS({state}) {
      try {
        const {
          status,
          data
        } = await httpClient.put('education_program/education-courses/update-syllabus-status' + '?access-token=' + getCookie('ACCESS_TOKEN'),
          {education_courses_id: state.teacher_form.education_courses_id});
        if (status === 200) {
          console.log(data, 'syllabus status updated')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async GET_EXAM_TYPES({commit}) {
      try {
        const url = 'exam/exam-type' + '?access-token=' + getCookie('ACCESS_TOKEN')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data, 'GET_EXAM_TYPES')
          commit("SET_EXAM_TYPES", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async PUT_EDUCATION_COURSE_EXAM_TYPE({state}, updateData) {
      let educationCourseExamTypeForm = {}
      if (updateData == null) {
        educationCourseExamTypeForm = state.teacher_form.educationCourseExamInfos
        educationCourseExamTypeForm['mdl_course_id'] = state.teacher_form.mdl_course_id
      }
      else {
        educationCourseExamTypeForm = updateData
      }


      console.log(educationCourseExamTypeForm, 'educationCourseExamTypeForm')
      try {
        const {
          status,
          data
        } = await httpClient.put('education_program/education-courses/update-exam-type' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationCourseExamTypeForm);
        if (status === 200) {
          console.log(data, 'update')
        }
        return {
          success: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false
        }
      }
    },


  },
  mutations: {
    SET_EDUCATION_COURSES_DATA_BY_PPS_ID(state, teacherCoursesInfos) {
      state.teacher_form.teacherCoursesInfos = teacherCoursesInfos
      console.log(state.teacher_form.teacherCoursesInfos, "STATE")
    },
    SET_EDUCATION_COURSES_ID(state, education_courses_id) {
      state.teacher_form.education_courses_id = education_courses_id
    },
    SET_MDL_COURSE_ID(state, mdl_course_id) {
      state.teacher_form.mdl_course_id = mdl_course_id
    },
    SET_SELECTABLE_COURSES_DATA_BY_PPS_ID(state, selectableCourses) {
      state.teacher_form.selectableCourses = selectableCourses
      console.log(state.teacher_form.selectableCourses, "selectableCourses")
    },
    SET_EXAM_TYPES(state, examTypes) {
      state.teacher_form.examTypes = examTypes
    },
    SET_EDUCATION_COURSE_EXAM_TYPE(state, {property, value}) {

      state.teacher_form.educationCourseExamInfos[property] = value
      console.log(state.teacher_form.educationCourseExamInfos, value)
    },
    SET_QUESTION_CATEGORIES(state, questionCategories) {
      state.teacher_form.questionCategories = questionCategories
    }

  },
  state: {
    teacher_form: {
      teacherCoursesInfos: [],
      education_courses_id: null,
      mdl_course_id: null,
      selectableCourses: [],
      examTypes: [],
      educationCourseExamInfos: {},
      questionCategories: []
    }

  },
  getters: {}

}

export default teacher