import finalExamService from "@/services/teacher/finalExam.service"

const finalExam = {
    namespaced: true,
    actions: {

        async GET_EDUCATION_COURSE_DATA_BY_ID({commit}, education_courses_id) {
            try {
                const {data} = await finalExamService.getEducationCourseDataById(education_courses_id);
                if (data) {
                    console.log(data.success, 'GET_EDUCATION_COURSE_DATA_BY_ID')
                    commit('SET_EDUCATION_COURSE_DATA_BY_ID', data.success)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_STUDENTS_BY_MDL_COURSE_ID({commit}, mdl_course_id) {
            try {
                const {data} = await finalExamService.getStudentsByMdlCourseId(mdl_course_id);
                if (data) {
                    console.log(data, 'GET_STUDENTS_BY_COURSE_ID')
                    commit("SET_STUDENTS", data.students)
                    commit("SET_STUDENTS_GROUPS", data.groups)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async GET_TEACHER_BY_MDL_COURSE_ID({commit}, mdl_course_id) {
            try {
                const {data} = await finalExamService.getTeacherByMdlCourseId(mdl_course_id);
                console.log(data, 'GET_TEACHER_BY_MDL_COURSE_ID')
                commit('SET_TEACHER', data)
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

        async POST_FINAL_EXAM_REPORT({commit, state}) {
            let finalExamReportForm = state.finalExam_form.finalExamReport
            //console.log(state.graduateWorkTemp.newGraduateWorkThemes, 'newGraduateWorkThemes')
            try {
                const {data} = await finalExamService.postFinalExamReport(finalExamReportForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


    },
    mutations: {
        SET_EDUCATION_COURSE_DATA_BY_ID(state, courseInfos) {
            state.finalExam_form.courseInfos = courseInfos
            console.log(state.finalExam_form.courseInfos, "courseInfos")
        },
        SET_STUDENTS_GROUPS(state, studentGroups) {
            state.finalExam_form.studentGroups = studentGroups
            console.log(state.finalExam_form.studentGroups, "studentGroups")
        },
        SET_STUDENTS(state, students) {
            state.finalExam_form.students = students
            console.log(state.finalExam_form.students, "students")
        },
        SET_FINAL_EXAM_REPORT(state, studentDatas) {
            let finalExamReport = []
            for (let i = 0; i < studentDatas.length; i++){
                // attendanceReport['student_id'] = studentDatas[i].id
                // attendanceReport['attendance_type_id'] = 1
                finalExamReport.push({
                    //student_id: studentDatas[i].id,
                    exam: 0,
                    student_ratings_id: studentDatas[i].student_ratings_id
                })
            }
            state.finalExam_form.finalExamReport = finalExamReport
            console.log(state.finalExam_form.finalExamReport, "finalExamReport")
        },
        SET_EDUCATION_COURSES_ID(state, education_courses_id) {
            state.finalExam_form.education_courses_id = education_courses_id
        },
        UPDATE_FINAL_EXAM_REPORT(state, value){
            let student_ratings_id = value.student_ratings_id
            let exam = value.exam


            let updateFinalExamReport = state.finalExam_form.finalExamReport.find(i => i.student_ratings_id == student_ratings_id)
            updateFinalExamReport['exam'] = exam

            console.log(state.finalExam_form.finalExamReport, "updateFinalExamReport")

        },
        CLEAR_FINAL_EXAM_REPORT(state) {
            state.finalExam_form.finalExamReport = []
        }
    },
    state: {
        finalExam_form: {
            courseInfos: [],
            studentGroups: [],
            students: [],
            finalExamReport: [],
            education_courses_id: null
        }
    },
    getters: {}
}

export default finalExam