import httpClient from "@/services/http.service"


const combinedTeachingLoadService = {
  getPpsTeachingLoadByDiscipline(queryParam = {}) {
    return httpClient.get(`load_calculation/pps-teaching-load/get-pps-teaching-load-by-discipline?${Object.keys(queryParam).map(q => `${q}=${queryParam[q]}`).join('&')}`)
  },
  postUnionCourse(formData) {
    return httpClient.post(`education_program/union-course/create`, formData)
  },
  deleteUnionCourse(id) {
    return httpClient.delete(`education_program/union-course/delete?id=${id}`)
  },
  getDisciplinesWithCourses(departmentId, academicYearId) {
    return httpClient.get(`load_calculation/pps-teaching-load/get-pps-teaching-load-disciplines?department_id=${departmentId}&academic_year_id=${academicYearId}`)
  },
  publishToMoodle(formData) {
    return httpClient.post(`education_program/education-courses/create-union-course`, formData)
  },

  getStudentsByUnionCourseId(union_course_id) {
    return httpClient.get(`education_program/union-course/get-union-course?union_course_id=${union_course_id}`)
  },
  deleteScheduleChooseByStudent(student_id) {
    return httpClient.get(`education_program/union-course/delete-schedule-choose-by-student?student_id=${student_id}`)
  },

  addStudentToCourse(form) {
    return httpClient.post(`education_program/education-courses/add-student-to-moodle-course`, form)
  },
}

export default combinedTeachingLoadService