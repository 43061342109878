import graduateWorkCalendarService from "@/services/graduate-work/graduateWorkCalendar.service"

const graduateWorkCalendar = {
    namespaced: true,
    actions: {
        async GET_STUDY_LEVELS({commit}) {
            try {
                const {data} = await graduateWorkCalendarService.getStudyLevels();
                if (data) {
                    commit('SET_STUDY_LEVELS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_GRADUATE_WORK_CALENDAR({commit, state}, study_level_id) {

            try {
                const {status,data} = await graduateWorkCalendarService.getGraduateWorkCalendar(study_level_id);
                if (status === 200) {
                    commit('SET_GRADUATE_WORK_CALENDAR', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },


        async PUT_GRADUATE_WORK_CALENDAR({commit, state}, study_level_id) {
            try {
                await graduateWorkCalendarService.putGraduateWorkCalendar(study_level_id, state.graduateWorkCalendar_form.graduateWorkCalendarInfo)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_STUDY_LEVELS(state, studyLevels) {

            state.graduateWorkCalendar_form.studyLevels = studyLevels
        },

        SET_GRADUATE_WORK_CALENDAR(state, graduateWorkCalendarInfo) {

            state.graduateWorkCalendar_form.graduateWorkCalendarInfo = graduateWorkCalendarInfo
        },
    },
    state: {
        graduateWorkCalendar_form: {
            studyLevels: [],
            graduateWorkCalendarInfo: []
        }

    },
    getters: {

    }

}

export default graduateWorkCalendar
