import httpClient from "@/services/http.service"
import router from "@/router"


const languageScillsService = {
  index() {
    return httpClient.get(`questionnaire/user-lang-level/index`)
  },
  delete(id) {
    return httpClient.delete(`questionnaire/user-lang-level/delete?id=${id}`)
  },
}

export default languageScillsService