import officeRegistrationDifferenceService
  from "@/services/office-registration/officeRegistrationDifference.service"


const officeRegistrationDifference = {
  namespaced: true,
  actions: {
    async GET_DIFFERENCE({commit}, studentId) {
      try {
        const {data} = await officeRegistrationDifferenceService.getDifference(studentId)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_DIFFERENCE({commit}, form) {
      try {
        await officeRegistrationDifferenceService.postDifference(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_DIFFERENCE({commit}, form) {
      try {
        await officeRegistrationDifferenceService.putDifference(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async DELETE_DIFFERENCE({commit}, id) {
      try {
        await officeRegistrationDifferenceService.deleteDifference(id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    }
  },
  mutations: {},
  state: {},
  getters: {}
}

export default officeRegistrationDifference