import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const orders = {
    namespaced: true,
    actions: {
        async DELETE_ABITURIENT_ORDERS_DATA({commit, state}, id) {
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('abiturients/abit-orders/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('abit orders data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },



        async GET_SP_ACADEMIC_YEAR({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-curriculum/get-academic-year' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_SP_ACADEMIC_YEAR', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async POST_ABITURIENT_ORDERS_DATA() {

            let abiturientOrdersForm = (orders.state.orders_form.ordersInfos)
            console.log(abiturientOrdersForm, 'abiturientOrdersForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('abiturients/abit-orders/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), abiturientOrdersForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_ABITURIENT_ORDERS_DATA({commit}, sp_academic_year_id = '') {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('abiturients/abit-orders/index' + (sp_academic_year_id.length>0 ? "?sp_academic_year_id=" + sp_academic_year_id : ""));
                if (status === 200) {
                    commit('SET_ABITURIENT_ORDERS_DATA', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_SP_ACADEMIC_YEAR(state, academicYear) {
            state.orders_form.academicYear = academicYear
        },
        SET_ABITURIENT_ORDERS_DATA(state, abiturientOrders) {
            state.orders_form.abiturientOrders = abiturientOrders
        },
        UPDATE_ABITURIENT_ORDERS_DATA(state, {property, value}) {
            state.orders_form.ordersInfos[property] = value
            console.log(state.orders_form.ordersInfos, "STATE")
        },
        UPDATE_ORDER_DATE(state, {property, value}) {
            state.orders_form.ordersInfos[property] = parseInt((new Date(value)).getTime() / 1000)
            console.log(state.orders_form.ordersInfos, "STATE")
        }
    },
    state: {
        orders_form: {
            ordersInfos: {},
            academicYear: [],
            abiturientOrders: []
        }
    },
    getters: {}
}

export default orders