import httpClient from "@/services/http.service"
import router from "@/router"


const graduateWorkScoreService = {
    getStudentsByGroupId() {
        return httpClient.get(`graduate/graduate-work-score/get-students-by-group-id`)
    },
    postGraduateWorkScoreStudents(postData) {
        return httpClient.post(`graduate/graduate-work-score/create`, postData)
    },
    getStudentGraduateWorkFiles(student_id) {
        return httpClient.get(`graduate/graduate-work-comment/get-comments-by-student-id` + '?student_id='+student_id)
    },
}

export default graduateWorkScoreService