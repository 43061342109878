import httpClient from "@/services/http.service"

const officeRegistrationDifferenceStudentRequestService = {
    getStudentDifferenceRequest() {
        return httpClient.get(`requisites/student-difference-request/get-difference-request-or`)
    },
    putStudentDifferenceRequestStatus(form) {
        return httpClient.put(`requisites/student-difference-request/update-status?id=${form.id}`, form)
    },

}

export default officeRegistrationDifferenceStudentRequestService