import httpClient from "@/services/http.service"
import router from "@/router"


const curriculumDisciplinesService = {
    getCurriculumDisciplines(departmentID) {
        return httpClient.get(`education_program/education-curriculum/get-curriculum-discipline`+'?department_id='+departmentID)
    },
    getCurriculumDisciplinesNew(departmentID) {
        return httpClient.get(`education_program/education-curriculum/new-get-curriculum-discipline`+'?department_id='+departmentID)
    },
}

export default curriculumDisciplinesService