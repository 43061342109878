export function generateURL(baseURL, queryParams) {
  const urlParams = [];

  for (const key in queryParams) {
    const value = queryParams[key];
    if (value !== null && value !== undefined && value !== '') {
      urlParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  if (urlParams.length > 0) {
    return `${baseURL}?${urlParams.join('&')}`;
  } else {
    return baseURL;
  }
}