<template>
  <student-attestation v-if="decryptCheckRole('student')"></student-attestation>
  <my-courses v-else></my-courses>
</template>

<script>
import StudentAttestation from '@/components/student/StudentAttestation.vue'
import MyCourses from "@/components/teacher/MyCourses.vue";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: 'Main',
    components: {
      MyCourses,
      StudentAttestation
    },
    computed: {
      role() {
        return getCookie('ROLE')
      },

    },
    methods: {
      decryptCheckRole
      // checkRole(i) {
      //   const roles = JSON.parse(getCookie('ROLES')) || []
      //   if (!roles.length && getCookie('ROLE')) {
      //     roles.push(getCookie('ROLE'))
      //   }
      //   return roles.includes(i)
      // },
    },
    mounted() {
      if (this.role !== 'student') {
        const code = this.$route.query.code || 0
        const urlQuery = getCookie('URL_QUERY_REDIRECT_MS_GRAPH')
        // console.log(code, urlQuery, 'message home')

        if (code && urlQuery) {
          const query = {...JSON.parse(urlQuery), code}
          this.$router.push({path: '/education-courses-list', query})
        }
      }
    }
  }
</script>
