import {getCookie} from "@/utils/helpers/cookies.helpers";
import {decryptText} from "@/utils/helpers/crypto.helpers";


export function getDecryptedRoles() {
    const roles = JSON.parse(getCookie('ROLES')) || [];
    const decryptedRoles = Object.fromEntries(
        Object.entries(roles).map(([key, value]) => [key, decryptText(value)])
    );
    return Object.values(decryptedRoles);
}

export function decryptCheckRole(i) {
    let roles = getDecryptedRoles()
    return roles.includes(i)
}