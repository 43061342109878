import signingDocumentService from "@/services/document/signing-document/signingDocument.service";

const signingDocument = {
    namespaced: true,
    actions: {
        async GET_DOCUMENTS_FOR_SIGNING({commit}) {
            try {
                const {data, status} = await signingDocumentService.getDocumentsForSigning();
                if (status == 200) {
                    console.log('GET_DOCUMENTS_FOR_SIGNING', data)
                    commit('SET_DOCUMENTS_FOR_SIGNING', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
    },
    mutations: {
        SET_DOCUMENTS_FOR_SIGNING(state, data) {
            state.signingDocument_form.documentsForSigning = data
        }
    },
    state: {
        signingDocument_form: {
            documentsForSigning: []
        },
    },
    getters: {}
}

export default signingDocument