import educationCalendarService from "@/services/academic-calendar/educationCalendar.service"

const educationCalendar = {
    namespaced: true,
    actions: {

        async GET_EDUCATION_CALENDAR({commit}) {
            try {
                const {status, data} = await educationCalendarService.getEducationCalendar()
                if (status === 200) {
                    console.log('GET_EDUCATION_CALENDAR', data)
                    commit('SET_EDUCATION_CALENDAR', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async POST_EDUCATION_CALENDAR({commit, state}) {
            let form = state.educationCalendar_form.educationCalendar.filter(i=>!i.id)
            console.log(form, 'post form')
            try {
                await educationCalendarService.postEducationCalendar(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_EDUCATION_CALENDAR({commit, state}) {
            let form = state.educationCalendar_form.educationCalendar.filter(i=>i.id)
            console.log(form, 'put form')
            try {
                await educationCalendarService.putEducationCalendar(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async DELETE_EDUCATION_CALENDAR({commit}, deleteId) {
            try {
                const {status, data} = await educationCalendarService.deleteEducationCalendar(deleteId)
                if (status === 200) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})

            }
            return false
        },


    },
    mutations: {
        SET_EDUCATION_CALENDAR(state, educationCalendar) {
            state.educationCalendar_form.educationCalendar = educationCalendar
        },

        ADD_ELEMENT(state, study_level_id) {
            state.educationCalendar_form.educationCalendar.push({
                study_course: null,
                study_level_id: study_level_id,
                start_date: null,
                end_date: null,
            })

            console.log(state.educationCalendar_form.educationCalendar, 'educationCalendar')
        },
        DELETE_ELEMENT(state, element) {
            let index = state.educationCalendar_form.educationCalendar.indexOf(element)
            state.educationCalendar_form.educationCalendar.splice(index, 1);
            console.log(state.educationCalendar_form.educationCalendar, 'educationCalendar')
        },
        SET_STUDY_COURSE(state, {elementIndex, studyLevelId, value}) {
            let element = state.educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevelId)
            element[elementIndex].study_course = value
            console.log(element, 'educationCalendar')
        },
        SET_DATE(state, {elementIndex, studyLevelId, property, date}) {
            let element = state.educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevelId)
            element[elementIndex][property] = date
            console.log(element, 'educationCalendar')
        }
    },
    state: {
        educationCalendar_form: {
            educationCalendar: []

        }
    },
    getters: {}
}

export default educationCalendar