import predictListService from "@/services/predict/predictList.service";

const predictList = {
    namespaced: true,
    actions: {
        async GET_PREDICT({commit}, params = {}) {
            try {
                const {status, data} = await predictListService.getPredict(params)
                if (status == 200){
                    console.log('GET_PREDICT_LIST', data)
                    commit('SET_PREDICT_LIST', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
                return false
            }
        },
        async GET_EDUCATION_PROGRAMS_BY_DEPARTMENT_ID({commit}, params) {
            try {
                const {status, data} = await predictListService.getEducationPrograms(params)
                if (status == 200){
                    console.log('GET_EDUCATION_PROGRAMS', data)
                    commit('SET_EDUCATION_PROGRAMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
                return false
            }
        },
    },
    mutations: {
        SET_PREDICT_LIST(state, data) {
            state.predictList = data
        },
        SET_EDUCATION_PROGRAMS(state, data) {
            state.education_programs = data
        },
    },
    state: {
        predictList: [],
        education_programs: []
    },
    getters: {}
}

export default predictList