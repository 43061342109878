import httpClient from "@/services/http.service"

const chooseDisciplineCalendarService = {
    get() {
        return httpClient.get(`/academic_calendar/choose-discipline-calendar/index`)
    },
    getEducationCalendar() {
        return httpClient.get(`academic_calendar/semester-calendar/get-education-calendar`)
    },
    post(form) {
        return httpClient.post(`academic_calendar/choose-discipline-calendar/create`, form)
    },
    put(disciplines) {
        console.log(disciplines)
        return httpClient.put(`/academic_calendar/choose-discipline-calendar/update`, disciplines)
    },
    delete(deletedId) {
        return httpClient.delete(`/academic_calendar/choose-discipline-calendar/delete?id=${deletedId}`)
    },
}

export default chooseDisciplineCalendarService