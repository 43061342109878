import studentResearchCupService from "@/services/student-research-cup/studentResearchCup.service"

const studentResearchCup = {
  namespaced: true,
  actions: {
    async GET_ROLES({commit}) {
      try {
        const {status, data} = await studentResearchCupService.getRoles()
        if (status === 200) {
          commit('SET_ROLES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_COMMISSION_MEMBERS({commit}) {
      try {
        const {status, data} = await studentResearchCupService.getCommissionMembers()
        if (status === 200) {
          commit('SET_COMMISSION_MEMBERS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_COMMISSION_MEMBERS({commit}, form) {
      try {
        const {status, data} = await studentResearchCupService.postCommissionMembers(form)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


  },
  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_COMMISSION_MEMBERS(state, commissionMembers) {
      state.commissionMembers = commissionMembers
    },
  },
  state: {
    commissionMembers: [],
    roles: []
  },
}

export default studentResearchCup