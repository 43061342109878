import httpClient from "@/services/http.service"
import router from "@/router"


const conferenceModeratorService = {
  getArticles() {
    return httpClient.get(`conference/conference-article/get-article`)
  },
  postConferenceArticleComment(postData) {
    return httpClient.post(`conference/conference-article-comment/create`, postData)
  },
  getSectionModerators() {
    return httpClient.get(`conference/conference-section-moderator/get-section-moderator`)
  },
  postBindSectionModerator(postData) {
    return httpClient.post(`conference/conference-section-moderator/create`, postData)
  },
  getReviewers() {
    return httpClient.get(`conference/conference-review/get-reviewers`)
  },
  postBindReviewers(postData) {
    return httpClient.post(`conference/conference-review/create`, postData)
  },
  putReviewer(putData, updateConferenceReviewId) {
    return httpClient.post(`conference/conference-review/update-reviewer`+'?id='+updateConferenceReviewId, putData)
  },
  putSectionModerator(putData, updateConferenceSectionModeratorId) {
    return httpClient.put(`conference/conference-section-moderator/update` + '?id='+updateConferenceSectionModeratorId,putData)
  },
}

export default conferenceModeratorService