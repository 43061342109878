import httpClient from "@/services/http.service"
import router from "@/router"


const teacherMagistracyPrerequisiteService = {
    getMagistracyPrerequisiteDisciplines() {
        return httpClient.get(`student/prerequisites-ratings/get-students-prerequisites-ratings`)
    },
    postUpdateMagistracyPrerequisiteRatings(form) {
        return httpClient.post(`student/prerequisites-ratings/update-score`, form)
    },

}

export default teacherMagistracyPrerequisiteService