import httpClient from "@/services/http.service"

const semesterSummerCalendarService = {
    getSemesterSummerCalendar() {
        return httpClient.get(`academic_calendar/semester-summer-calendar/index`)
    },
    postSemesterSummerCalendar(form) {
        return httpClient.post(`academic_calendar/semester-summer-calendar/create`, form)
    },
    putSemesterSummerCalendar(form) {
        return httpClient.put(`academic_calendar/semester-summer-calendar/update`, form)
    },
    deleteSemesterSummerCalendar(deleteId) {
        return httpClient.delete(`academic_calendar/semester-summer-calendar/delete` + '?id='+ deleteId)
    },

}

export default semesterSummerCalendarService