import httpClient from "@/services/http.service"
import router from "@/router"


const teacherGrantsService = {
  getTeacherGrantType() {
    return httpClient.get(`teacher_grants/foreign-educational-grants/get-teacher-grant-type`)
  },
  getForeignEducationalGrants(user_id = null) {
    const query = user_id ? `?user_id=${user_id}` : ``
    return httpClient.get(`teacher_grants/foreign-educational-grants/index${query}`)
  },
  postForeignEducationalGrants(postData) {
    return httpClient.post(`teacher_grants/foreign-educational-grants/create`, postData)
  },
  putForeignEducationalGrants(putData) {
    return httpClient.put(`teacher_grants/foreign-educational-grants/update?id=${putData.id}`, putData)
  },
  deleteForeignEducationalGrants(deleteId) {
    return httpClient.delete(`teacher_grants/foreign-educational-grants/delete?id=${deleteId}`)
  },
  getFormsOfParticipation() {
    return httpClient.get(`teacher_grants/research-grants/get-teacher-grant-participation-form`)
  },
  getFinancingSource() {
    return httpClient.get(`teacher_grants/research-grants/get-financing-source`)
  },
  getResearchGrants(user_id = null) {
    const query = user_id ? `?user_id=${user_id}` : ``
    return httpClient.get(`teacher_grants/research-grants/index${query}`)
  },
  postResearchGrants(form) {
    return httpClient.post(`teacher_grants/research-grants/create`, form)
  },
  putResearchGrants(form) {
    return httpClient.put(`teacher_grants/research-grants/update?id=${form.id}`, form)
  },
  deleteResearchGrants(id) {
    return httpClient.delete(`teacher_grants/research-grants/delete?id=${id}`)
  },
}

export default teacherGrantsService