import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const syllabusLiterature = {
    namespaced: true,
    actions: {

        async DELETE_SYLLABUS_LITERATURE_DATA({commit, state}, index) {
            let id = state.syllabusLiterature_form.newSyllabusLiteratureInfos[index].id
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('syllabus_data/syllabus-literature/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('syllabus literature data DELETED')

                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_SYLLABUS_LITERATURE_DATA({state}) {

            //let syllabusLiteratureForm = state.syllabusLiterature_form.newSyllabusLiteratureInfos.filter(v => v.put_status == 0)
            let syllabusLiteratureForm = state.syllabusLiterature_form.newSyllabusLiteratureInfos.filter(v => v.id == undefined)
            console.log(syllabusLiteratureForm, 'syllabusLiteratureForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('syllabus_data/syllabus-literature/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), syllabusLiteratureForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async PUT_SYLLABUS_LITERATURE_DATA({state}) {

            //let syllabusLiteratureForm = state.syllabusLiterature_form.newSyllabusLiteratureInfos.filter(v => v.put_status == 1)
            let syllabusLiteratureForm = state.syllabusLiterature_form.newSyllabusLiteratureInfos.filter(v => v.id != undefined)
            let id = syllabusLiteratureForm.id
            console.log(id, "syllabusLiteratureForm ID")
            console.log(syllabusLiteratureForm, 'syllabusLiteratureForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.put('syllabus_data/syllabus-literature/update' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id='+id, syllabusLiteratureForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },


        async GET_SYLLABUS_LITERATURE_DATA({commit}, syllabus_id) {
            try {
                const url = 'syllabus_data/syllabus-literature/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&syllabus_id=' + syllabus_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data.success, 'GET_SYLLABUS_LITERATURE_DATA')
                    commit("SET_SYLLABUS_LITERATURE_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        ADD_ROW_SYLLABUS_LITERATURE_INFO(state, syllabus_id) {
            state.syllabusLiterature_form.newSyllabusLiteratureInfos.push({
                syllabus_id: syllabus_id,
                title: '',
                author: '',
                publishing_year: '',
                literature_type: '',
                put_status: 0
            });
        },
        DELETE_ROW_SYLLABUS_LITERATURE_INFO(state, index) {
            state.syllabusLiterature_form.newSyllabusLiteratureInfos.splice(index, 1);
        },

        SET_NEW_SYLLABUS_LITERATURE_DATA(state, {index, property, value}) {

            state.syllabusLiterature_form.newSyllabusLiteratureInfos[index][property] = value
            console.log(state.syllabusLiterature_form.newSyllabusLiteratureInfos, 'STATE')
        },

        SET_SYLLABUS_LITERATURE_DATA(state, newSyllabusLiteratureInfos) {
            state.syllabusLiterature_form.newSyllabusLiteratureInfos = newSyllabusLiteratureInfos
            console.log(state.syllabusLiterature_form.newSyllabusLiteratureInfos, "newSyllabusLiteratureInfos")
        },
    },
    state: {
        syllabusLiterature_form: {
            newSyllabusLiteratureInfos: [],
        }

    },
    getters: {

    }

}

export default syllabusLiterature
