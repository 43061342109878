import httpClient from "@/services/http.service"
import {getCookie} from "@/utils/helpers/cookies.helpers";

const documentSignatureService = {

    getBase64Document(category, file_id) {
        return httpClient.get(`syllabus_data/syllabus/syllabus-api?file_id=${file_id}&category=${category}`)
    },
    getCheckSignature(file_id, sign_id) {
        if (sign_id){
            return httpClient.get(`api/signature/check-signature?file_id=${file_id}&sign_id=${sign_id}`)
        } else {
            return httpClient.get(`api/signature/check-signature?file_id=${file_id}`)
        }
    },
    postSignedDocument(form) {
        return httpClient.post(`api/signature/insert-signature`, form)
    },


}

export default documentSignatureService