import httpClient from "@/services/http.service"
import router from "@/router"


const commissionHeadDecisionService = {
    getCommissionDecision() {
        return httpClient.get(`kkk/commission-decision/index`)
    },
    putCommissionHeadDecision(form) {
        return httpClient.put(`kkk/commission-decision/update`, form)
    },


}

export default commissionHeadDecisionService