import combinedTeachingLoadService from "@/services/combined-teaching-load/combinedTeachingLoad.service"

const combinedTeachingLoad = {
  namespaced: true,
  actions: {
    async GET_PPS_TEACHING_LOAD_BY_DISCIPLINE({commit}, queryParam) {
      try {
        const {data} = await combinedTeachingLoadService.getPpsTeachingLoadByDiscipline(queryParam)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_UNION_COURSE({commit}, formData) {
      try {
        await combinedTeachingLoadService.postUnionCourse(formData)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async DELETE_UNION_COURSE({commit}, id) {
      try {
        await combinedTeachingLoadService.deleteUnionCourse(id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_DISCIPLINES_WITH_COURSES({commit}, {departmentId, academicYearId}) {
      try {
        const {data} = await combinedTeachingLoadService.getDisciplinesWithCourses(departmentId, academicYearId)
        commit('SET_DISCIPLINES_WITH_COURSES', data)
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async PUBLISH_TO_MOODLE({commit}, formData) {
      try {
        await combinedTeachingLoadService.publishToMoodle(formData)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async GET_STUDENTS_BY_UNION_COURSE_ID({commit}, union_course_id) {
      try {
        const {data} = await combinedTeachingLoadService.getStudentsByUnionCourseId(union_course_id)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async DELETE_SCHEDULE_CHOOSE_BY_STUDENT({commit}, student_id) {
      try {
        await combinedTeachingLoadService.deleteScheduleChooseByStudent(student_id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async ADD_STUDENT_TO_COURSE({commit}, form) {
      try {
        await combinedTeachingLoadService.addStudentToCourse(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_DISCIPLINES_WITH_COURSES(state, disciplinesWithCourses) {
      state.disciplinesWithCourses = disciplinesWithCourses
    }
  },
  state: {
    disciplinesWithCourses: []
  },
  getters: {}
}

export default combinedTeachingLoad