import eqiShortService from "@/services/eqi-short/eqiShort.service";

const eqiShort = {
  namespaced: true,
  actions: {
    async GET_EQI_SHORT_QUESTIONS({commit}) {
      try {
        const {status, data} = await eqiShortService.getEqiShortQuestions()
        if (status === 200) {
          console.log('GET_EQI_SHORT_QUESTIONS', data)
          commit('SET_EQI_SHORT_QUESTIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_EQI_SHORT_ANSWERS({state, commit}, answers) {
      try {
        await eqiShortService.postEqiShortAnswers(answers)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_EQI_SHORT_ANSWERS({commit}) {
      try {
        const {status, data} = await eqiShortService.getEqiShortAnswers()
        if (status === 200) {
          console.log('SET_EQI_SHORT_ANSWERS', data)
          commit('SET_EQI_SHORT_ANSWERS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    }
  },
  mutations: {
    SET_EQI_SHORT_QUESTIONS(state, data) {
      state.eqiShortQuestions = data
    },
    SET_EQI_SHORT_ANSWERS(state, data) {
      state.eqiShortAnswers = data
    }
  },
  state: {
    eqiShortQuestions: [],
    eqiShortAnswers: []
  }
}

export default eqiShort
