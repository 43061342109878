import httpClient from "@/services/http.service"

const dispatcherEventsService = {
  getEvents() {
    return httpClient.get(`events/events/get-events`)
  },
  putEventsStatus(id, confirm_status_id){
    return httpClient.put(`events/events/update?id=${id}`, {confirm_status: confirm_status_id})
  },

}

export default dispatcherEventsService