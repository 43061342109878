import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const job = {
    namespaced: true,
    actions: {
        async DELETE_JOB_DATA({commit, state}, index) {
            let id = state.job_form.jobInfos[index].id
            console.log(index + ' id ' + id)
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/pps-data-job/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('Job data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_JOB_DATA() {
            let jobForm = (job.state.job_form.jobInfos).filter(v => v.put_status == 0)
            if (jobForm.length > 0) {
                try {
                    console.log(jobForm)
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/pps-data-job/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), jobForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data
                    }
                }
            } else {
                return 0
            }
        },
        async PUT_JOB_DATA() {
            let jobForm = (job.state.job_form.jobInfos).filter(v => v.put_status == 1)

            if (jobForm.length > 0) {
                try {
                    console.log(jobForm)
                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/pps-data-job/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), jobForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_JOB_DATA({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/pps-data-job/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_JOB_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_JOB_DATA(state, jobInfos) {
            state.job_form.jobInfos = jobInfos
        },
        ADD_ROW_JOB_INFO(state) {
            state.job_form.jobInfos.push({
                name: '',
                address: '',
                start_year: '',
                end_year: '',
                title: '',
                description: '',
                put_status: 0,
                working_now_status: 0,
            });
        },
        DELETE_ROW_JOB_INFO(state, index) {
            state.job_form.jobInfos.splice(index, 1);
        },
        UPDATE_JOB_NAME(state, payload) {
            state.job_form.jobInfos[payload.index].name = payload.value
        },
        UPDATE_JOB_ADDRESS(state, payload) {
            state.job_form.jobInfos[payload.index].address = payload.value
        },
        UPDATE_JOB_START_YEAR(state, payload) {
            state.job_form.jobInfos[payload.index].start_year = parseInt((new Date(payload.value)).getTime() / 1000)
        },
        UPDATE_JOB_END_YEAR(state, payload) {
            state.job_form.jobInfos[payload.index].end_year = parseInt((new Date(payload.value)).getTime() / 1000)
        },
        UPDATE_JOB_TITLE(state, payload) {
            state.job_form.jobInfos[payload.index].title = payload.value
        },
        UPDATE_JOB_DESCRIPTION(state, payload) {
            state.job_form.jobInfos[payload.index].description = payload.value
        },
        UPDATE_JOB_FIELD_OF_ACTIVITY(state, payload) {
            state.job_form.jobInfos[payload.index].field_of_activity = payload.value
        },
        UPDATE_JOB_WORKING_NOW_STATUS(state, payload) {
            state.job_form.jobInfos[payload.index].working_now_status = payload.value === true ? 1 : 0
            if (payload.value === true) {
                state.job_form.jobInfos[payload.index].end_year = parseInt((new Date()).getTime() / 1000)
            }
        },
        SET_HEADHUNTER_TO_JOB_DATA(state, hhJobData) {
            console.log(hhJobData, 'hhData job.js')

            hhJobData.forEach(item => {
                const {company, area, start, end, industries, position} = item
                let workStatus = end ? 0 : 1
                let endWork = workStatus == 0 ? Date.now() : parseInt((new Date(end)).getTime() / 1000)

                state.job_form.jobInfos.push({
                    name: company,
                    address: area.name,
                    start_year: parseInt((new Date(start)).getTime() / 1000),
                    end_year: endWork,
                    title: position,
                    field_of_activity: industries[0].name,
                    description: '',
                    put_status: 0,
                    working_now_status: workStatus,
                });
            })

        }
    },
    state: {
        job_form: {
            jobInfos: [{
                name: '',
                address: '',
                field_of_activity: '',
                start_year: '',
                end_year: '',
                title: '',
                description: '',
                put_status: 0,
                working_now_status: 0,
            }]
        }
    },
    getters: {}
}

export default job