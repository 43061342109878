import httpClient from "@/services/http.service"

const eventsService = {
    getEvents() {
        return httpClient.get(`events/events/get-events`)
    },
    getEventType() {
        return httpClient.get(`events/events/get-event-type`)
    },
    getCompetencies() {
        return httpClient.get(`events/events/get-competencies`)
    },
    deleteEvents(id) {
        return httpClient.delete(`events/events/delete` + '?id='+ id)
    },
    updateEvents(id, data) {
        return httpClient.put(`events/events/update` + '?id='+ id, data)
    },
    postFreeRoomsForEvents(start_date, end_date){
        return httpClient.post(`events/events/get-free-rooms?start_date=${start_date}&end_date=${end_date}`)
    },
    postEvents(form) {
        return httpClient.post(`events/events/create`, form)
    },
    getOrganization() {
        return httpClient.get(`events/events/get-organization`)
    }
}

export default eventsService
