import httpClient from "@/services/http.service"


const chooseDisciplinesService = {
  getElectiveDisciplines(barcode, education_module_id) {
    let queryParam = null
    if (barcode === null){
      queryParam = education_module_id ? `?education_module_id=${education_module_id}` : ``
    } else {
      queryParam = barcode ? `?barcode=${barcode}` : ''
      queryParam += education_module_id ? `&education_module_id=${education_module_id}` : ``
    }
    return httpClient.get(`education_program/education-discipline/get-new-elective-disciplines${queryParam}`)
  },
  // getSummerDisciplines() {
  getSummerDisciplines(barcode = '') {
    let queryParam = barcode ? `?barcode=${barcode}` : ''
    return httpClient.get(`education_program/education-discipline/get-summer-semester${queryParam}`)
  },
  postChooseDisciplines(postData) {
    return httpClient.post(`education_program/education-discipline-choose/create`, postData)
  },
  putChooseDisciplines(putData) {
    return httpClient.put(`education_program/education-discipline-choose/update`, putData)
  },
  getDisciplineInfo(formation_education_program_id) {
    return httpClient.get(`education_program/education-discipline/get-info?formation_education_program_id=${formation_education_program_id}`)
  },
  getStudentInfo(barcode) {
    return httpClient.get(`student_data/student-data/get-info-by-barcode?barcode=${barcode}`)
  },
  getStudentCreditsInfo(barcode) {
    return httpClient.get(`/student/transcript/get-transcript-credit?barcode=${barcode}`)
  },
  getMinorDisciplines(barcode) {
    return httpClient.get(`education_program/formation-education-program/get-education-program-minor?barcode=${barcode}`)
  },
  resetChosenDisciplines(student_id) {
    let queryParam = student_id !== null ? `?student_id=${student_id}` : ''
    return httpClient.get(`education_program/education-discipline-choose/reset${queryParam}`)
  },
}

export default chooseDisciplinesService
