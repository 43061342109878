import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";
import syllabusOfficeHoursService from "@/services/syllabus/syllabusOfficeHours.service";

const syllabusOfficeHours = {
    namespaced: true,
    actions: {
        async GET_SYLLABUS_OFFICE_HOURS({commit}, syllabus_id) {
            try {
                const {status, data} = await syllabusOfficeHoursService.getSyllabusOfficeHours(syllabus_id)
                if (status === 200) {
                    commit("SET_OFFICE_HOURS", data.success)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async DELETE_SYLLABUS_OFFICE_HOURS({commit}, id) {
            try {
                const {status} = await syllabusOfficeHoursService.deleteSyllabusOfficeHours(id);
                return status == 200;
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_SYLLABUS_OFFICE_HOURS({commit}, form) {
            try {
                const {status} = await syllabusOfficeHoursService.postSyllabusOfficeHours(form);
                return status === 200
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

        async PUT_SYLLABUS_OFFICE_HOURS({commit}, form) {
            try {
                for (let syllabusOfficeHour of form) {
                    await syllabusOfficeHoursService.putSyllabusOfficeHours(syllabusOfficeHour.id, syllabusOfficeHour);
                }
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_OFFICE_HOURS(state, data) {
            state.syllabusOfficeHours = data
        },
        ADD_ROW_OFFICE_HOURS(state, syllabus_id) {
            state.syllabusOfficeHours.push({
                syllabus_id: syllabus_id,
                day_id: 0,
                interval_id: 0,
            });
        },
        DELETE_ROW_OFFICE_HOURS(state, index) {
            state.syllabusOfficeHours.splice(index, 1);
        },
        SET_NEW_OFFICE_HOURS(state, {index, property, value}) {
            state.syllabusOfficeHours[index][property] = value
            console.log(state.syllabusOfficeHours, 'STATE')
        },
    },
    state: {
        syllabusOfficeHours: []
    },
    getters: {}

}

export default syllabusOfficeHours