<template>
  <div class="container">


    <div class="student-attestation mt-4 mb-4">
      <h4 class="text-center mb-4">Успеваемость за текущий семестр</h4>

      <div class="col-md-12 mt-4 mb-4">
        <div class="card">
          <a href="https://back.uib.kz/documents_uploads/instructionToResetPassword.pdf" style="text-decoration: none">
            <h2
                class="instruction m-3 d-block" style="font-size: 1.3rem; font-weight: bold;">Инструкция по смене пароля
              Front. Обязательно к прочтению!</h2></a>
        </div>
      </div>

      <div class="col-md-12 mt-4 mb-4">
        <div class="card">

          <div class="card-body">
            <div class="card-title mb-4">
              <div class="row">
                <div class="col-12 ml-3">
                  <h2 class="d-block"
                      style="font-size: 1.3rem; font-weight: bold;">Информация о студенте</h2>
                </div>
              </div>
            </div>

            <div>
              <p><strong>ФИО:</strong> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }}
                {{ studentInfos?.middle_name }}</p>


              <p><strong>Баркод:</strong> {{ studentInfos?.barcode }}</p>

              <p><strong>Специальность или ОП:</strong> {{ studentInfos?.educationProgram?.education_speciality_code }}
                {{ studentInfos?.educationProgram?.education_speciality_name != 'Smart medicine' ? studentInfos?.educationProgram?.education_speciality_name : 'General medicine'}}</p>

              <p><strong>Уровень обучения:</strong> {{ studentInfos?.studyLevel?.name }}</p>
              <p><strong>Форма обучения:</strong> {{ studentInfos?.studyForm?.name }}</p>
              <p><strong>Курс:</strong> {{ studentInfos?.study_course }}</p>
              <p><strong>Группа:</strong> {{ studentInfos?.studentGroups?.name }}</p>


            </div>


          </div>


        </div>
      </div>


      <div v-if="attestationInfos.length !== 0">
        <DataTable :value="attestationInfos"
                   showGridlines
                   stripedRows responsiveLayout="scroll">
          <Column field="course_name" header="Название курса">
            <template #body="{data}">
              <a :href="'https://moodle.uib.kz/course/view.php?id='+ data.mdl_course_id"
                 target="_blank">{{ data.course_name }}</a>
            </template>
          </Column>

          <Column header="Преподаватель">
            <template #body="{data}">

              <div v-if="data.pps_id_lecture.id === data.pps_id_seminar.id">
                <span>Лектор: {{ data.pps_data_lastname }} {{ data.pps_data_firstname }}</span>
                <br>
<!--                <router-link-->
<!--                    :to="'/student/teacher-evaluation?course_id=' + data.course_id + '&pps_id=' + data.pps_id_lecture.id"-->
<!--                    target="_blank">-->
<!--                  Анкетирование-->
<!--                </router-link>-->

              </div>
              <div v-else>
                <span>Лектор: {{ data.pps_data_lastname }} {{ data.pps_data_firstname }}</span>
                <br>

<!--                <router-link-->
<!--                    :to="'/student/teacher-evaluation?course_id=' + data.course_id + '&pps_id=' + data.pps_id_lecture.id"-->
<!--                    target="_blank">-->
<!--                  Анкетирование-->
<!--                </router-link>-->


                <br>
                <br>
                <span>Семинарист: {{ data.pps_data_lastname_seminar }} {{ data.pps_data_firstname_seminar }}</span>
                <br>

<!--                <router-link-->
<!--                    :to="'/student/teacher-evaluation?course_id=' + data.course_id + '&pps_id=' + data.pps_id_seminar.id"-->
<!--                    target="_blank">-->
<!--                  Анкетирование-->
<!--                </router-link>-->

              </div>

              <!--            <span>Семинар:{{data.seminar_pps_lastname}} {{data.seminar_pps_firstname}}</span>-->
            </template>
          </Column>

          <Column field="semester" header="Семестр"></Column>
          <Column field="credit" header="Кредит"></Column>
          <Column field="vsk1" header="ВСК1"></Column>
          <Column header="ВСК2">
            <template #body="{data}">
<!--              <div v-if="data.pps_id_lecture.evaluate_status == 1 && data.pps_id_seminar.evaluate_status == 1">-->
<!--                {{ data.vsk2 }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p>Пройдите анкетирование чтобы увидеть балл</p>-->
<!--              </div>-->
              <p>{{ data.vsk2 }}</p>
            </template>
          </Column>
          <Column header="Экзамен">
            <template #body="{data}">
<!--              <div v-if="data.pps_id_lecture.evaluate_status == 1 && data.pps_id_seminar.evaluate_status == 1">-->
<!--                {{ data.exam }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p><strong>0</strong></p>-->
<!--                <p>Пройдите анкетирование чтобы балл исправился</p>-->
<!--              </div>-->
              <p>{{ data.exam }}</p>
            </template>
          </Column>
          <Column field="total" header="Общий балл">
            <template #body="{data}">
<!--              <div v-if="data.pps_id_lecture.evaluate_status == 1 && data.pps_id_seminar.evaluate_status == 1">-->
<!--                {{ data.total }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p>Пройдите анкетирование чтобы увидеть общий балл</p>-->
<!--              </div>-->
              <p>{{ data.total }}</p>
            </template>
          </Column>
          <Column field="num_gpa" header="GPA">
            <template #body="{data}">
<!--              <div v-if="data.pps_id_lecture.evaluate_status == 1 && data.pps_id_seminar.evaluate_status == 1">-->
<!--                {{ data.num_gpa }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p>Пройдите анкетирование чтобы увидеть GPA</p>-->
<!--              </div>-->
              <p>{{ data.num_gpa }}</p>
            </template>
          </Column>
          <Column field="letter_equiv" header="Grade">
            <template #body="{data}">
<!--              <div v-if="data.pps_id_lecture.evaluate_status == 1 && data.pps_id_seminar.evaluate_status == 1">-->
<!--                {{ data.letter_equiv }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p>Пройдите анкетирование чтобы увидеть оценку</p>-->
<!--              </div>-->
              <p>{{ data.letter_equiv }}</p>
            </template>
          </Column>

          <Column header="Силлабус">
            <template #body="{data}">
              <a :href="`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${data.user_id}&education_discipline_id=${data.education_discipline_id}`"
                 target="_blank" class="btn btn-primary m-1" data-v-74b5cb6c="">
                <i class="pi pi-print"></i>
              </a>
            </template>
          </Column>

          <!--          <Column header="Анкетирование">-->
          <!--            <template #body="{data}">-->
          <!--              <router-link :to="'/student/teacher-evaluation?course_id=' + data.course_id + '&pps_id=' + data.pps_id" target="_blank">-->
          <!--                Перейти-->
          <!--              </router-link>-->
          <!--            </template>-->
          <!--          </Column>-->

        </DataTable>
      </div>
      <div v-else>
        <div class="col-md-12 mt-4 text-center">
          <h4>Курсов пока нет</h4>
        </div>
      </div>


      <!--        <div class="card">-->
      <!--          <div class="card-body row">-->

      <!--            <div class="row">-->
      <!--              <div class="col-4">-->
      <!--                <div class="col-12">-->

      <!--                  <div class="card-title mb-2">-->
      <!--                    <strong>-->
      <!--                      Современная история Казахстана-->
      <!--                    </strong>-->
      <!--                  </div>-->
      <!--                  <div class="mb-1">Преподаватель: <strong>Зайцева Ирина</strong></div>-->
      <!--                  <div class="mb-1">Кредит: <strong>5</strong></div>-->
      <!--                  <div class="mb-1">Семестр: <strong>1</strong></div>-->

      <!--                </div>-->

      <!--                <div class="col-12">-->
      <!--                  <div class="row">-->
      <!--                    <div class="border m-3 mb-4 rounded col-2 grade">-->
      <!--                      <span class="grade-text">95</span>-->
      <!--                      <span class="category-text mt-2">ВСК1</span>-->
      <!--                    </div>-->


      <!--                    <div class="border m-3 mb-4 rounded col-2 grade">-->
      <!--                      <span class="grade-text">96</span>-->
      <!--                      <span class="category-text mt-2">ВСК2</span>-->
      <!--                    </div>-->

      <!--                    <div class="border m-3 mb-4 rounded col-2 grade">-->
      <!--                      <span class="grade-text">100</span>-->
      <!--                      <span class="category-text mt-2">Экзамен</span>-->
      <!--                    </div>-->

      <!--                    <div class="border m-3 mb-4 rounded col-2 grade">-->
      <!--                      <span class="grade-text">92</span>-->
      <!--                      <span class="category-text mt-2">Общий</span>-->
      <!--                    </div>-->


      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              <div class="col-md-4">-->


      <!--                    <div class="border m-2 mb-4 rounded col-2 grade">-->
      <!--                      <span class="grade-text">A</span>-->
      <!--                      <span class="category-text mt-2">Оценка</span>-->
      <!--                    </div>-->


      <!--                    <div class="border m-2 mb-4 rounded col-2 grade">-->
      <!--                      <span class="grade-text">4</span>-->
      <!--                      <span class="category-text mt-2">GPA</span>-->
      <!--                    </div>-->

      <!--              </div>-->


      <!--              </div>-->
      <!--            </div>-->
      <!--        </div>-->

    </div>

  </div>
  <Dialog v-model:visible="visible" modal header="Пройдите анкетирование, иначе не сможете пользоваться front.uib.kz"
          :style="{ width: '50vw' }">
  </Dialog>

  <!--  </div>-->
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import httpClient from "../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";


export default {
  name: "StudentAttestation",
  data() {
    return {
      attestationInfos: [],
      urlBarcode: +this.$route.query.barcode || 0,
      studentInfos: [],
      visible: null
    }
  },
  computed: {},

  methods: {
    async GET_STUDENT_DATA_BY_BARCODE() {
      try {
        let url = 'student_data/student-data/get-info-by-barcode' + '?access-token=' + getCookie('ACCESS_TOKEN')
        if (this.urlBarcode === 0) {
          url = url + '&barcode=' + getCookie('USERNAME')
        } else {
          url = url + '&barcode=' + this.urlBarcode
        }

        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          // console.log(data.success, 'GET_STUDENT_DATA_BY_BARCODE')
          this.studentInfos = data.success

        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_STUDENT_ATTESTATION() {
      try {
        let url = 'student/attestation/get-current-attestation' + '?access-token=' + getCookie('ACCESS_TOKEN')
        if (this.urlBarcode === 0) {
          url = url + '&barcode=' + getCookie('USERNAME')
        } else {
          url = url + '&barcode=' + this.urlBarcode
        }

        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          // console.log(data.success, 'GET_STUDENT_ATTESTATION')
          this.attestationInfos = data.success
          console.log(this.attestationInfos, 'attestationInfos')
          //commit("SET_STUDENT_ATTESTATION", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    }
  },

  async mounted() {
    await this.GET_STUDENT_DATA_BY_BARCODE()
    await this.GET_STUDENT_ATTESTATION()
    // if (this.$route.query.notification) {
    //   this.$error({title: this.$route.query.notification})
    //   this.visible = true;
    // }
  }
}
</script>

<style scoped>
.grade {
  width: 65px;
  height: 65px;
}

.grade-text {
  text-align: center;
  vertical-align: middle;
  line-height: 65px;
}

.category-text {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.instruction {
  background: linear-gradient(to right, #ff0000, #ff8000, #ffff00, #00ff00, #0000ff, #8b00ff, #ff00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 3s linear infinite;
}

@keyframes rainbow {
  0% {
    background-size: 400% auto;
    background-position: 0% 50%;
  }
  50% {
    background-size: 400% auto;
    background-position: 100% 50%;
  }
  100% {
    background-size: 400% auto;
    background-position: 0% 50%;
  }
}
</style>
