import httpClient from "@/services/http.service"


const graduateWorkAttestationService = {
  getGraduateWorkUsersByGroup(group_id) {
    return httpClient.get(`graduate/graduate-work-users/index?group_id=${group_id}`)
  },
  postGraduateWorkUsers(data) {
    return httpClient.post(`graduate/graduate-work-users/create`, data)
  },
  putGraduateWorkUsers(data) {
    return httpClient.put(`graduate/graduate-work-users/update?id=${data.id}`, data)
  },
  getTeachers() {
    return httpClient.get(`graduate/graduate-work-users/get-teachers`)
  },
  getLastEducationProgramsByStudyLevel(study_level_id) {
    return httpClient.get(`education_program/education-program/get-last-education-program-by-study-level?study_level_id=${study_level_id}`)
  },
}

export default graduateWorkAttestationService