import deputyDeanResearchCupService from "@/services/student-research-cup/deputy-dean/deputyDeanResearchCup.service"

const deputyDeanResearchCup = {
    namespaced: true,
    actions: {



        async GET_REGISTERED_STUDENTS({commit}) {
            try {
                const {status, data} = await deputyDeanResearchCupService.getRegisteredStudents()
                if (status === 200) {
                    console.log('GET_REGISTERED_STUDENTS', data)
                    commit('SET_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async PUT_STUDENT_RESEARCH_CUP_REGISTRATION_STATUS({commit}, form) {
            console.log('form', form)
            try {
                await deputyDeanResearchCupService.putStudentResearchCupStatus(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },




    },
    mutations: {
        SET_STUDENTS(state, students) {
            state.deputyDeanResearchCup_form.students = students
        },
    },
    state: {
        deputyDeanResearchCup_form: {
            students: []
        }
    },
    getters: {}
}

export default deputyDeanResearchCup