import uploadGraduateWorkService from "@/services/graduate-work/uploadGraduateWork.service"
import axios from 'axios'
import {getCookie} from "@/utils/helpers/cookies.helpers";

const uploadGraduateWork = {
    namespaced: true,
    actions: {
        async GET_STUDENT_GRADUATE_WORK_THEME({commit}) {
            try {
                const {data} = await uploadGraduateWorkService.getStudentGraduateWorkTheme();
                if (data) {
                    console.log(data, 'GET_STUDENT_GRADUATE_WORK_THEME')
                    commit('SET_STUDENT_GRADUATE_WORK_THEME', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_CHECK_ACCESS({commit}) {
            try {
                const {data} = await uploadGraduateWorkService.getCheckAccess();
                if (data) {
                    console.log(data, 'GET_CHECK_ACCESS')
                    commit('SET_CHECK_ACCESS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_DIPLOMA_RESULTS({commit}) {
            try {
                const {data} = await uploadGraduateWorkService.getDiplomaResults();
                if (data) {
                    console.log(data, 'GET_DIPLOMA_RESULTS')
                    commit('SET_DIPLOMA_RESULTS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_GRADUATE_WORK_CHAPTER({commit}) {
            try {
                const {data} = await uploadGraduateWorkService.getGraduateWorkChapter();
                if (data) {
                    console.log(data, 'GET_GRADUATE_WORK_CHAPTER')
                    commit('SET_GRADUATE_WORK_CHAPTER', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES({commit}) {
            try {
                const {data} = await uploadGraduateWorkService.getStudentGraduateWorkChapterFiles();
                if (data) {
                    console.log(data, 'GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES')
                    commit('SET_STUDENT_GRADUATE_WORK_CHAPTER_FILES', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_GRADUATE_WORK_CHAPTER_FILE({state, commit}, parent_id) {

            let formData = new FormData();
            let graduateWorkChapterFileInfo = state.uploadGraduateWork_form.graduateWorkChapterFileInfo;


            formData.append("file_url", graduateWorkChapterFileInfo["file_url"]);
            formData.append("graduate_work_chapter_id", graduateWorkChapterFileInfo["graduate_work_chapter_id"]);


            if (graduateWorkChapterFileInfo["graduate_work_student"] != 0) {
                formData.append("graduate_work_student", graduateWorkChapterFileInfo["graduate_work_student"]);
            }
            else {
                formData.append("graduate_work_student", 0);
            }

            // let findArray = state.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.find(i=>i.graduate_work_chapter_id == graduateWorkChapterFileInfo["graduate_work_chapter_id"]) || []
            // console.log(findArray, 'findArray')
            // if (findArray.length > 0 && findArray.parent_id) {
            //     commit('SET_PARENT_ID', findArray.parent_id)
            // }
            // else {
            //     commit('SET_PARENT_ID', null)
            // }
            if (state.uploadGraduateWork_form.parentId) {
                formData.append("parent_id", state.uploadGraduateWork_form.parentId);
            }





            if (graduateWorkChapterFileInfo["comment"]) {
                formData.append("comment", graduateWorkChapterFileInfo["comment"]);
            }


            // if (state.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.length != 0) {
            //     formData.append("graduate_work_student", studentGraduateWorkChapterFilesInfo[0]["id"]);
            // }


            console.log(Object.fromEntries(formData), 'hello from formdata')

            try {
                const {data} = await uploadGraduateWorkService.postGraduateWorkChapterFile(formData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false

            // try {
            //     const {
            //         status,
            //         data
            //     } = await axios.post('https://back.uib.kz/kkk/user-syllabus/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData, {
            //         headers: {
            //             'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            //         }
            //     });
            //     if (status === 200) {
            //         console.log(data)
            //     }
            //     return {
            //         success: true
            //     }
            // } catch (e) {
            //     console.log(e.response);
            //     return {
            //         success: false,
            //         errors: e.response.data.errors
            //     }
            // }
        },



        async POST_FINAL_CHAPTER_FILE({state, commit}) {

            let formData = new FormData();
            let finalChapterFileInfo = state.uploadGraduateWork_form.finalChapterFileInfo;


            formData.append("file_url", finalChapterFileInfo["file_url"]);
            formData.append("graduate_work_chapter_id", finalChapterFileInfo["graduate_work_chapter_id"]);
            //formData.append("comment", finalChapterFileInfo["comment"]);

            if (state.uploadGraduateWork_form.finalChapterGraduateWorkStudent != 0) {
                formData.append("graduate_work_student", state.uploadGraduateWork_form.finalChapterGraduateWorkStudent);
            }
            else {
                formData.append("graduate_work_student", finalChapterFileInfo["graduate_work_student"]);
            }




            console.log(Object.fromEntries(formData), 'hello from formdata')

            try {
                const {data} = await uploadGraduateWorkService.postFinalChapterFile(formData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async POST_FINAL_FILE_TO_PLAGIAT({state, commit}, graduate_work_comment_id) {

            let finalFileToPlagiatInfo = {};
            finalFileToPlagiatInfo['id'] = graduate_work_comment_id

            console.log(finalFileToPlagiatInfo, 'post finalFileToPlagiatInfo')

            try {
                const {data} = await uploadGraduateWorkService.postFinalFileToPlagiat(finalFileToPlagiatInfo);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async PUT_GRADUATE_WORK_CHAPTER_FILE({state, commit}) {

            let updateFormData = new FormData();
            let updateGraduateWorkChapterFileInfo = state.uploadGraduateWork_form.updateGraduateWorkChapterFileInfo;
            let graduateWorkChapterId = state.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.find(i => i.id == state.uploadGraduateWork_form.updateGraduateWorkChapterFileId).graduate_work_chapter_id


            updateFormData.append("file_url", updateGraduateWorkChapterFileInfo["file_url"]);
            updateFormData.append("graduate_work_chapter_id", graduateWorkChapterId);


            console.log(Object.fromEntries(updateFormData), 'put updateFormData')

            try {
                const {data} = await uploadGraduateWorkService.putGraduateWorkChapterFile(updateFormData, state.uploadGraduateWork_form.updateGraduateWorkChapterFileId);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false

        },

    },
    mutations: {
        SET_STUDENT_INFO_BY_BARCODE(state, studentInfo) {
            state.uploadGraduateWork_form.studentInfo = studentInfo
        },
        SET_STUDENT_GRADUATE_WORK_THEME(state, studentGraduateWorkThemeInfo) {
            state.uploadGraduateWork_form.studentGraduateWorkThemeInfo = studentGraduateWorkThemeInfo
        },
        SET_CHECK_ACCESS(state, checkAccess) {
            state.uploadGraduateWork_form.checkAccess = checkAccess
        },
        SET_DIPLOMA_RESULTS(state, diplomaResults) {
            state.uploadGraduateWork_form.diplomaResults = diplomaResults
        },
        SET_GRADUATE_WORK_CHAPTER(state, graduateWorkChapterInfo) {
            state.uploadGraduateWork_form.graduateWorkChapterInfo = graduateWorkChapterInfo
        },
        SET_GRADUATE_WORK_CHAPTER_FILE(state, graduateWorkChapterFileData) {
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['file_url'] = graduateWorkChapterFileData.file
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_chapter_id'] = graduateWorkChapterFileData.graduate_work_chapter_id
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_student'] = 0
            console.log(state.uploadGraduateWork_form.graduateWorkChapterFileInfo, 'graduateWorkChapterFileInfo')
        },

        SET_FINAL_CHAPTER_FILE(state, finalChapterFileData) {
            state.uploadGraduateWork_form.finalChapterFileInfo['file_url'] = finalChapterFileData.final_chapter_file
            state.uploadGraduateWork_form.finalChapterFileInfo['graduate_work_chapter_id'] = finalChapterFileData.graduate_work_chapter_id
            state.uploadGraduateWork_form.finalChapterFileInfo['graduate_work_student'] = 0
            console.log(state.uploadGraduateWork_form.finalChapterFileInfo, 'finalChapterFileInfo')
        },

        SET_GRADUATE_WORK_NEW_CHAPTER_COMMENT_FILE(state, graduateWorkChapterFileData) {
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['file_url'] = graduateWorkChapterFileData.file
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_chapter_id'] = graduateWorkChapterFileData.graduate_work_chapter_id
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_student'] = graduateWorkChapterFileData.graduate_work_student
            console.log(state.uploadGraduateWork_form.graduateWorkChapterFileInfo, 'graduateWorkChapterFileInfo')
        },

        // SET_GRADUATE_WORK_CHAPTER_UPDATE_FILE(state, updateGraduateWorkChapterFileData) {
        //     state.uploadGraduateWork_form.updateGraduateWorkChapterFileInfo['file_url'] = updateGraduateWorkChapterFileData.file
        //     console.log(state.uploadGraduateWork_form.updateGraduateWorkChapterFileInfo, 'updateGraduateWorkChapterFileInfo')
        // },
        SET_STUDENT_GRADUATE_WORK_CHAPTER_FILES(state, studentGraduateWorkChapterFilesInfo) {
            state.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo = studentGraduateWorkChapterFilesInfo
            console.log(state.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo, 'STATE studentGraduateWorkChapterFilesInfo')
        },
        // SET_GRADUATE_WORK_CHAPTER_UPDATE_FILE_ID(state, updateGraduateWorkChapterFileId) {
        //     state.uploadGraduateWork_form.updateGraduateWorkChapterFileId = updateGraduateWorkChapterFileId
        //     console.log(state.uploadGraduateWork_form.updateGraduateWorkChapterFileId, 'STATE updateGraduateWorkChapterFileId')
        // },
        SET_GRADUATE_WORK_CHAPTER_COMMENT(state, graduateWorkChapterComment) {
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['comment'] = graduateWorkChapterComment.comment
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_chapter_id'] = graduateWorkChapterComment.graduate_work_chapter_id
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_student'] = graduateWorkChapterComment.graduate_work_student
            console.log(state.uploadGraduateWork_form.graduateWorkChapterFileInfo, 'STATE graduateWorkChapterFileInfo')
        },
        SET_GRADUATE_WORK_NEW_CHAPTER_COMMENT(state, graduateWorkChapterComment) {
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['comment'] = graduateWorkChapterComment.comment
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_chapter_id'] = graduateWorkChapterComment.graduate_work_chapter_id
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo['graduate_work_student'] = 0
            console.log(state.uploadGraduateWork_form.graduateWorkChapterFileInfo, 'STATE graduateWorkChapterFileInfo')
        },

        SET_FINAL_CHAPTER_COMMENT(state, finalChapterComment) {
            state.uploadGraduateWork_form.finalChapterFileInfo['comment'] = finalChapterComment.comment
            state.uploadGraduateWork_form.finalChapterFileInfo['graduate_work_chapter_id'] = finalChapterComment.graduate_work_chapter_id
            state.uploadGraduateWork_form.finalChapterFileInfo['graduate_work_student'] = 0
            console.log(state.uploadGraduateWork_form.finalChapterFileInfo, 'STATE finalChapterFileInfo')
        },
        SET_PARENT_ID(state, parentId) {
            state.uploadGraduateWork_form.parentId = parentId
            console.log(state.uploadGraduateWork_form.parentId, 'STATE parentId')
        },
        CLEAR_GRADUATE_WORK_CHAPTER_FILES(state) {
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo = {}
            console.log(state.uploadGraduateWork_form.graduateWorkChapterFileInfo, 'CLEAR_GRADUATE_WORK_CHAPTER_FILES')
        },
        CLEAR_STATE(state) {
            state.uploadGraduateWork_form.parentId = null
            state.uploadGraduateWork_form.graduateWorkChapterFileInfo = []
        },
        CLEAR_STATE_FINAL_CHAPTER(state) {
            state.uploadGraduateWork_form.finalChapterFileInfo = []
            state.uploadGraduateWork_form.finalChapterGraduateWorkStudent = 0
        },
        SET_FINAL_CHAPTER_GRADUATE_WORK_STUDENT(state, finalChapterGraduateWorkStudent) {
            state.uploadGraduateWork_form.finalChapterGraduateWorkStudent = finalChapterGraduateWorkStudent
            console.log(state.uploadGraduateWork_form.finalChapterGraduateWorkStudent, 'STATE finalChapterGraduateWorkStudent')
        }

    },
    state: {
        uploadGraduateWork_form: {
            studentGraduateWorkThemeInfo: {},
            graduateWorkChapterInfo: [],
            graduateWorkChapterFileInfo: [],
            //updateGraduateWorkChapterFileInfo: [],
            studentGraduateWorkChapterFilesInfo: [],
            //updateGraduateWorkChapterFileId: null,
            studentInfo: [],
            parentId: null,

            finalChapterFileInfo: [],
            checkAccess: {},
            diplomaResults: [],
            finalChapterGraduateWorkStudent: 0
        }
    },
    getters: {}
}

export default uploadGraduateWork