import httpClient from "@/services/http.service"

const commissionResearchCupService = {
    getResearchCupWinners() {
        return httpClient.get(`research_cup/research-cup-student-score/get-research-cup-winner`)
    },

    getResearchCupStepsProtocolCondition() {
        return httpClient.get(`research_cup/research-cup-score-print/get-protocol-condition`)
    },
    getResearchCupSteps() {
        return httpClient.get(`research_cup/research-cup-steps/get-research-cup-steps`)
    },
    getResearchCupStepsCriterions() {
        return httpClient.get(`research_cup/research-cup-steps-criterions/get-research-cup-steps-criterions`)
    },

    postResearchCupStudentScore(form) {
        return httpClient.post(`research_cup/research-cup-student-score/create`, form)
    },

}

export default commissionResearchCupService