import departmentPracticeService from "@/services/department/departmentPractice.service"

const departmentPractice = {
  namespaced: true,
  actions: {
    async GET_PPS({commit}) {
      try {
        const {data} = await departmentPracticeService.getPps()
        if (data) {
          commit('SET_PPS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_STUDY_LANGUAGES({commit}) {
      try {
        const {data} = await departmentPracticeService.getLanguages()
        if (data) {
          commit('SET_STUDY_LANGUAGES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_EDUCATION_PROGRAMS({commit}, {studyLevel, studyLanguage}) {
      try {
        const {status, data} = await departmentPracticeService.getEducationProgramsByLevelAndLanguage(studyLevel, studyLanguage)
        if (status === 200) {
          commit('SET_EDUCATION_PROGRAMS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_PRACTICES_BY_EDUCATION_PROGRAM({commit}, {studyLevel, studyLanguage, edu_prog_id}) {
      try {
        const {status, data} = await departmentPracticeService.getPracticesByLevelAndLanguage(studyLevel, studyLanguage, edu_prog_id)
        if (status === 200) {
          commit('SET_PRACTICES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_STUDENTS_PRACTICE_BY_EDUCATION_PROGRAM({commit}, {educationProgramId, formationId}) {
      try {
        const {status, data} = await departmentPracticeService.getStudentsPracticeByEducationProgram(educationProgramId, formationId)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_STUDENT_PRACTICES({commit}, form) {
      try {
        const {status, data} = await departmentPracticeService.postStudentPractices(form)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {
    SET_PPS(state, pps) {
      state.pps = pps.map(i => ({
        ...i,
        full_name: i.lastname + ' ' + i.firstname
      }))
    },
    SET_STUDY_LANGUAGES(state, studyLanguages) {
      state.studyLanguages = studyLanguages
    },
    SET_EDUCATION_PROGRAMS(state, educationPrograms) {
      state.educationPrograms = educationPrograms
    },
    SET_PRACTICES(state, practices) {
      state.practices = practices
    },
  },
  state: {
    pps: [],
    studyLanguages: [],
    educationPrograms: [],
    practices: [],
  },
}

export default departmentPractice