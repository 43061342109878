import luscherService from "@/services/luscher/luscher.service";

const luscher = {
  namespaced: true,
  actions: {
    async GET_LUSCHER_COLORS({commit}) {
      try {
        const {status, data} = await luscherService.getLuscherColors()
        if (status === 200) {
          commit('SET_LUSCHER_COLORS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_LUSCHER_ANSWERS({state, commit}, answers) {
      try {
        await luscherService.postLuscherAnswers(answers)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_LUSCHER_ANSWERS({commit}) {
      try {
        const {status, data} = await luscherService.getLuscherAnswers()
        if (status === 200) {
          console.log('SET_LUSCHER_ANSWERS', data)
          commit('SET_LUSCHER_ANSWERS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_LUSCHER_COLORS(state, data) {
      state.luscherColors = data
    },
    SET_LUSCHER_ANSWERS(state, data) {
      state.luscherResults = data
    },
  },
  state: {
    luscherColors: [],
    luscherResults: {}
  }
}

export default luscher
