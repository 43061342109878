<template>
    <!-- Syllabus Modal -->
    <div class="modal fade" id="syllabusModal" tabindex="-1"
         aria-labelledby="syllabusModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="syllabusModalLabel">Импорт</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="col-md-12 mt-4">

                        <div class="form-group row mt-4">
                            <label class="col-md-3 col-form-label">Силлабус</label>
                            <div class="col-md-9">
                                <select class="form-control form-select"
                                        @input="changeSyllabusId($event)">
                                    <option v-for="(item, index) in [{id: 0, educationDiscipline:{name: 'Выберите'}}, ...syllabus_form.syllabusInfos] "
                                            :value="item.id"
                                            :key="index">{{ item?.educationDiscipline?.name }} - {{
                                        item?.evaluationOption?.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mt-4" v-if="syllabus_form.checkSyllabusContentData == false">
                            <p style="color: red"><strong>Содержание дисциплины не заполнено!!!</strong></p>
                        </div>


                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            @click="importSyllabus()"
                            :disabled="syllabusId == null || syllabus_form.checkSyllabusContentData == false">
                        Импортировать
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- End Syllabus Modal -->
</template>


<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "SyllabusModal",

        data() {
            return {
                syllabusId: null
            }
        },
        computed: {
            ...mapState('syllabus', ['syllabus_form']),
        },

        methods: {
            ...mapActions('teacher', ['POST_IMPORT_SYLLABUS', 'PUT_EDUCATION_COURSES_SYLLABUS_STATUS', 'GET_EDUCATION_COURSES_DATA_BY_PPS_ID']),
            ...mapActions('syllabus', ['GET_SYLLABUS_DATA', 'GET_SYLLABUS_DATA_BY_DISCIPLINE_ID', 'GET_CHECK_SYLLABUS_CONTENT_DATA']),
            ...mapMutations('syllabus', ['']),

            changeSyllabusId(e, val = 'value') {
                const value = e.target[val]
                console.log(value, "value")
                this.syllabusId = value
                this.GET_CHECK_SYLLABUS_CONTENT_DATA(this.syllabusId)
            },

            async importSyllabus() {
                const res = await this.POST_IMPORT_SYLLABUS(this.syllabusId)
                if (res.success == true) {
                    await this.PUT_EDUCATION_COURSES_SYLLABUS_STATUS();
                    await this.GET_EDUCATION_COURSES_DATA_BY_PPS_ID()
                    this.$message({title: 'Импортирование', text: 'Силлабус успешно импортирован'});
                } else {
                    this.$error({title: 'Импортирование', text: 'Произошла ошибка'})
                }
                //await this.PUT_EDUCATION_COURSES_SYLLABUS_STATUS()
            }

        },
        async mounted() {
            await this.GET_SYLLABUS_DATA()
        }
    }
</script>

<style scoped>

</style>