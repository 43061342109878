import changePhotoService from "@/services/pps/changePhoto.service";

const changePhoto = {
  namespaced: true,
  actions: {
    async GET_PPS({commit}, departmentId) {
      try {
        const {status, data} = await changePhotoService.getPps(departmentId)
        if (status === 200) {
          commit('SET_PPS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_ADMINISTRATIONS({commit}) {
      try {
        const {status, data} = await changePhotoService.getAdministrations()
        if (status === 200) {
          commit('SET_ADMINISTRATIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_PHOTO({commit}, form) {
      try {
        await changePhotoService.postPhoto(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {
    SET_PPS(state, pps) {
      state.pps = pps
    },
    SET_ADMINISTRATIONS(state, administrations) {
      state.administrations = administrations
    },
  },
  state: {
    pps: [],
    administrations: []
  },
  getters: {}
}

export default changePhoto
