import httpClient from "@/services/http.service"

const analyticsService = {
    getAnalytics() {
        return httpClient.get(`analytics_data/analytics-data/index`)
    },
    createAnalytics(data) {
        return httpClient.post(`analytics_data/analytics-data/create`, data)
    },
    deleteAnalytics(id) {
        return httpClient.delete(`analytics_data/analytics-data/delete?id=` + id)
    },
}

export default analyticsService
