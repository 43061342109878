import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const educationfield = {
  namespaced: true,
  actions: {
    async DELETE_EDUCATION_FIELD_DATA({commit, state}, id) {
      //let id = state.educationfield_form.educationFieldInfos[index].id
      try {
        const {
          status,
          data
        } = await httpClient.delete('education_program/education-field/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          console.log('education field data DELETED')
          // commit("SET_EDUCATION_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async POST_EDUCATION_FIELD_DATA() {
      console.log(educationfield.state.educationfield_form.newEducationFieldInfos, "TESTTEST")
      let educationFieldForm = (educationfield.state.educationfield_form.newEducationFieldInfos)
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/education-field/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationFieldForm);
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async PUT_EDUCATION_FIELD_DATA({commit, state}, id) {
      console.log(id, "IDIDIDIDID")
      let educationFieldForm = (educationfield.state.educationfield_form.educationFieldInfos).filter(v => v.id == id)
      console.log(educationFieldForm, 'educationFieldForm')
      if (educationFieldForm.length > 0) {

        try {
          console.log(educationFieldForm)
          const {
            status,
            data
          } = await httpClient.put('education_program/education-field/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationFieldForm);
          if (status === 200) {
            console.log(data)
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            errors: e.response.data.errors
          }
        }
      } else {
        return 0
      }
    },
    async GET_EDUCATION_FIELD_DATA({commit}, page) {

      try {
        const urlGetParams = `&page=${page}`
        console.log(urlGetParams)
        const url = 'education_program/education-field/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data.page_count, 'PAGE COUNT')

          commit("SET_PAGE_COUNT", data.page_count)
          commit("SET_EDUCATION_FIELD_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
  },
  mutations: {
    SET_EDUCATION_FIELD_DATA(state, educationFieldInfos) {
      console.log(educationFieldInfos, "TESTEST")
      state.educationfield_form.educationFieldInfos = educationFieldInfos
    },
    SET_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount
    },
    ADD_ROW_EDUCATION_FIELD_INFO(state) {
      state.educationfield_form.newEducationFieldInfos.push({
        name: '',
        put_status: 0
      });
    },
    DELETE_ROW_EDUCATION_FIELD_INFO(state, index) {
      state.educationfield_form.newEducationFieldInfos.splice(index, 1);
    },
    UPDATE_EDUCATION_FIELD_DATA(state, {item, property, value}) {
      const index = state.educationfield_form.educationFieldInfos.indexOf(item)
      state.educationfield_form.educationFieldInfos[index][property] = value
      console.log(state.educationfield_form.educationFieldInfos, value)
    },
    UPDATE_NEW_EDUCATION_FIELD_DATA(state, {item, property, value}) {
      const index = state.educationfield_form.newEducationFieldInfos.indexOf(item)
      state.educationfield_form.newEducationFieldInfos[index][property] = value
      console.log(state.educationfield_form.newEducationFieldInfos, value)
    },
    CHANGE_EDIT_STATUS_EDUCATION_FIELD_INFO(state, index) {
      state.educationfield_form.educationFieldInfos[index].edit_status = 1
    },
  },
  state: {
    educationfield_form: {
      educationFieldInfos: [{
        name: '',
        put_status: 0
      }],
      newEducationFieldInfos: []
    },
    pageCount: 0
  },
  getters: {}

}

export default educationfield