import academicDebtService from "@/services/student/academicDebt.service"

const academicDebt = {
  namespaced: true,
  actions: {
    async GET_SUMMER_COURSE_REASONS({commit}) {
      try {
        const {data} = await academicDebtService.getSummerCourseReasons();
        if (data) {
          commit('SET_SUMMER_COURSE_REASONS', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_ACADEMIC_DEBTS({commit}) {
      try {
        const {data} = await academicDebtService.getAcademicDebts();
        if (data) {
          commit('SET_ACADEMIC_DEBTS', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_SUMMER_COURSE_REQUEST({commit}) {
      try {
        const {data} = await academicDebtService.getSummerCourseRequest();
        if (data) {
          commit('SET_SUMMER_COURSE_REQUEST', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_STUDENT_SUMMER_COURSE_REQUEST({state, commit}, postDisciplines) {
      let formData = new FormData();

      formData.append("file_url", state.academicDebt_form.statement["file_url"]);
      formData.append("reason_id", state.academicDebt_form.statement["reason_id"]);
      formData.append("reason_text", state.academicDebt_form.statement["reason_text"]);
      formData.append("disciplines", JSON.stringify(postDisciplines));


      // console.log(Object.fromEntries(formData), 'formData')

      try {
        const {data} = await academicDebtService.postStudentSummerCourseRequest(formData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false

    },

  },
  mutations: {
    SET_SUMMER_COURSE_REASONS(state, summerCourseReasons) {
      state.academicDebt_form.summerCourseReasons = summerCourseReasons
    },
    SET_ACADEMIC_DEBTS(state, academicDebts) {
      state.academicDebt_form.academicDebts = academicDebts
    },
    SET_SUMMER_COURSE_REQUEST(state, summerCourseRequest) {
      state.academicDebt_form.summerCourseRequest = summerCourseRequest
    },
    SET_STUDENT_SUMMER_COURSE_DISCIPLINES(state, student_ratings_id) {
      state.academicDebt_form.disciplines.push({
        student_ratings_id: student_ratings_id
      })
    },
    SET_STUDENT_SUMMER_COURSE_REASON(state, {reason_id, reason_text}) {
      state.academicDebt_form.statement['reason_id'] = reason_id
      state.academicDebt_form.statement['reason_text'] = reason_text
    },
    SET_STUDENT_SUMMER_COURSE_REASON_TEXT(state, reason_text) {
      state.academicDebt_form.statement['reason_text'] = reason_text
    },
    SET_STUDENT_SUMMER_COURSE_FILE(state, file) {
      state.academicDebt_form.statement['file_url'] = file
    },
  },
  state: {
    academicDebt_form: {
      summerCourseReasons: [],
      summerCourseRequest: [],
      academicDebts: [],
      disciplines: [],
      statement: []
    }
  },
  getters: {}
}

export default academicDebt