import httpClient from "@/services/http.service"


const teacherScheduleService = {

  getPpsFreetime(form) {
    return httpClient.get(`schedule/pps-free-time/index`, form)
  },
  postPpsFreetime(form) {
    return httpClient.post(`schedule/pps-free-time/create`, form)
  },
  deletePpsFreetime(deleteId) {
    return httpClient.delete(`schedule/pps-free-time/delete` + '?id=' + deleteId)
  },
  
}

export default teacherScheduleService