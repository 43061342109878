import axios from 'axios'
import studentOrdersService from "@/services/student-orders/studentOrders.service";

const studentOrders = {
    namespaced: true,
    actions: {
        async GET_DOC_ORDERS({commit}, date) {
            try {
                const {status, data} = await studentOrdersService.getDocOrders(date)
                if (status === 200) {
                    console.log('GET_DOC_ORDERS', data)
                    commit('SET_DOC_ORDERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_APPROVE_ORDERS({commit}, doc_order_id) {
            try {
                return await studentOrdersService.postApproveOrder(doc_order_id);
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_DOC_ORDERS(state, data) {
            state.docOrders = data
        }
    },
    state: {
        docOrders: null
    },
}

export default studentOrders
