import disciplinesDifferenceService from "@/services/disciplines-difference/disciplinesDifference.service"
import axios from 'axios'

const disciplinesDifference = {
    namespaced: true,
    actions: {

        async GET_DISCIPLINES_DIFFERENCE({commit}, {student_id, order_from, order_to}) {
            try {
                const {data} = await disciplinesDifferenceService.getDisciplinesDifference(student_id, order_from, order_to);
                if (data) {
                    console.log(data, 'GET_DISCIPLINES_DIFFERENCE')
                    commit('SET_DISCIPLINES_DIFFERENCE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_DIFFERENCE_STUDENT_RATINGS({commit, state}, formData) {




            console.log(formData, 'post difference student ratings')
            try {
                const {data} = await disciplinesDifferenceService.postDifferenceStudentRatings(formData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_DISCIPLINES_DIFFERENCE(state, disciplinesDifference) {
            state.disciplinesDifference_form.disciplinesDifference = disciplinesDifference
        }
    },
    state: {
        disciplinesDifference_form: {
            disciplinesDifference: []
        }
    },
    getters: {
    }
}

export default disciplinesDifference