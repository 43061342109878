import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const educationmodule = {
    namespaced: true,
    actions: {
        async DELETE_EDUCATION_MODULE_DATA({commit, state}, id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('education_program/education-modules/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('education module data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_EDUCATION_MODULE_DATA() {

            let educationModuleForm = (educationmodule.state.educationmodule_form.newEducationModuleInfos)
            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/education-modules/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationModuleForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async PUT_EDUCATION_MODULE_DATA({commit, state}, id) {

            let educationModuleForm = (educationmodule.state.educationmodule_form.educationModuleInfos).filter(v => v.id == id)

            if (educationModuleForm.length > 0) {

                try {

                    const {
                        status,
                        data
                    } = await httpClient.put('education_program/education-modules/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationModuleForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_EDUCATION_MODULE_DATA({commit}) {

            try {

                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-modules/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {

                    commit("SET_EDUCATION_MODULE_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_EDUCATION_MODULE_DATA(state, educationModuleInfos) {
            state.educationmodule_form.educationModuleInfos = educationModuleInfos
        },

        ADD_ROW_EDUCATION_MODULE_INFO(state) {
            state.educationmodule_form.newEducationModuleInfos.push({
                module: '',
                name: '',
                put_status: 0
            });
        },
        DELETE_ROW_EDUCATION_MODULE_INFO(state, index) {
            state.educationmodule_form.newEducationModuleInfos.splice(index, 1);
        },
        UPDATE_EDUCATION_MODULE_DATA(state, {item, property, value}) {
            const index = state.educationmodule_form.educationModuleInfos.indexOf(item)
            state.educationmodule_form.educationModuleInfos[index][property] = value
        },
        UPDATE_NEW_EDUCATION_MODULE_DATA(state, {item, property, value}) {
            const index = state.educationmodule_form.newEducationModuleInfos.indexOf(item)
            state.educationmodule_form.newEducationModuleInfos[index][property] = value
        },
        CHANGE_EDIT_STATUS_EDUCATION_MODULE_INFO(state, index) {
            state.educationmodule_form.educationModuleInfos[index].edit_status = 1;
        },
    },
    state: {
        educationmodule_form: {
            educationModuleInfos: [{
                module: '',
                name: '',
                put_status: 0
            }],
            newEducationModuleInfos: []
        },
    },
    getters: {

    }

}

export default educationmodule