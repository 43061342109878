import httpClient from "@/services/http.service"

const organizationService = {
  getTypes() {
    return httpClient.get(`events/organizations/get-organization-types`)
  },
  getProfiles() {
    return httpClient.get(`events/organizations/get-organization-profiles`)
  },
  getOrganizationMembersJoin() {
    return httpClient.get(`events/organization-members-join/index`)
  },
  getStatuses() {
    return httpClient.get(`events/organizations/get-organization-status`)
  },

  getOrganizations() {
    return httpClient.get(`events/organizations/get-organizations`)
  },
  post(form) {
    return httpClient.post(`events/organizations/create`, form)
  },

  postOrganizationMembersJoin(form) {
    return httpClient.post(`events/organization-members-join/create`, form)
  },

  getStudentByBarcode(barcode) {
    return httpClient.get(`student_data/student-data/get-gpa-past-semester?barcode=${barcode}`)
  },

  getOrganizationMembers(){
    return httpClient.get('events/organization-members-join/get-organization-members-by-leader')
  },

  putOrganizationMembersStatus(data){
    return httpClient.post('events/organization-members/members-confirm', data)
  },

  putOrganizationStatus(id, status_id){
    return httpClient.put(`events/organizations/update?id=${id}`, {status_id: status_id})
  },

  deleteOrganizationMembers(delete_id){
    return httpClient.delete('events/organization-members/delete?id=' + delete_id)
  },

  getOrganization() {
    return httpClient.get(`events/organizations/get-organization`)
  }
}

export default organizationService
