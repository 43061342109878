import httpClient from "@/services/http.service"

const teachersOrdersService = {
    getOrdersTypes() {
        return httpClient.get(`questionnaire/pps-data/get-orders-types`)
    },
    getOrders(order_id) {
        return httpClient.get(`questionnaire/pps-data/get-orders` + (order_id ? '?order_id=' + order_id : ''))
    },
    postOrder(data) {
        return httpClient.post(`questionnaire/pps-data/create-order`, data)
    },
    getPpsByName(name) {
        return httpClient.post(`questionnaire/pps-data/find-by-full-name?name=` + name)
    },
    postPpsOrder(data) {
        return httpClient.post(`questionnaire/pps-data/add-order-member`, data)
    },
    getPpsMembers(pps_order_id) {
        return httpClient.get(`questionnaire/pps-data/get-order-members?pps_order_id=` + pps_order_id)
    },
    deletePpsMember(pps_order_id, pps_user_id) {
        return httpClient.post(`questionnaire/pps-data/delete-order-member?pps_order_id=` + pps_order_id + '&pps_user_id=' + pps_user_id)
    },
    confirmPpsOrder(data) {
        return httpClient.post(`questionnaire/pps-data/confirm-pps-order`, data)
    },
}

export default teachersOrdersService