import httpClient from "@/services/http.service"
import router from "@/router"


const loadCalculation = {
    getLoadCalculation() {
        return httpClient.get(`load_calculation/load-calculation/get-load-calculation`)
    },
    getPpsByDepartmentId() {
        return httpClient.get(`graduate/selection-supervisors/get-pps-by-department-id`)
    },
    postSelectionSupervisors(formData){
        return httpClient.post(`graduate/selection-supervisors/create`, formData)
    },
    putSelectionSupervisors(formData){
        return httpClient.put(`graduate/selection-supervisors/update`, formData)
    },
    deleteSelectionSupervisors(id){
        return httpClient.delete(`graduate/selection-supervisors/delete?id=`+ id)
    }
}

export default loadCalculation