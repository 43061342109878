import httpClient from "@/services/http.service"

const creativeImaginationTestService = {
  getQuestions() {
    return httpClient.get(`sei/imagination-questions`)
  },
  postAnswers(form) {
    return httpClient.post(`sei/imagination-answers/create`, form)
  },
  getResult() {
    return httpClient.get(`sei/imagination-rate/get-imagination-result`)
  },
}

export default creativeImaginationTestService
