import httpClient from "@/services/http.service"
import router from "@/router"


const academicDebtService = {
    getSummerCourseReasons() {
        return httpClient.get(`retake_ratings/student-summer-course/get-reasons`)
    },
    getAcademicDebts() {
        return httpClient.get(`retake_ratings/student-summer-course/get-academic-debts`)
    },
    postStudentSummerCourseRequest(formData) {
        return httpClient.post(`retake_ratings/student-summer-course/create`, formData)
    },
    getSummerCourseRequest() {
        return httpClient.get(`retake_ratings/student-summer-course/index`)
    },
}

export default academicDebtService