import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const document = {
    namespaced: true,
    actions: {
        async getCitizenships({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/pps-data/citizenship' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateCitizenships', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async getDocTypes({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/pps-data/doctype' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateDocTypes', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        updateDocumentForm(state, userDocumentData) {
            state.document_form.doc_number = userDocumentData.doc_number
            state.document_form.doc_iin = userDocumentData.doc_iin
            state.document_form.doc_date_start = userDocumentData.doc_date_start
            state.document_form.doc_date_end = userDocumentData.doc_date_end
            state.document_form.citizenship = userDocumentData.citizenship
            state.document_form.doc_type = userDocumentData.doc_type
            state.document_form.doc_issued = userDocumentData.doc_issued
            console.log('*** USER DATA', state.document_form)
        },
        updateCitizenships(state, citizenships) {
            state.citizenships = citizenships
        },
        updateDocTypes(state, docTypes) {
            state.docTypes = docTypes
        },
        updateCitizenship(state, citizenship) {
            state.document_form.citizenship = citizenship
        },
        updateDocType(state, doc_type) {
            state.document_form.doc_type = doc_type
        },
        updateDocIssuede(state, doc_issued) {
            state.document_form.doc_issued = doc_issued
        },
        updateDocNumber(state, doc_number) {
            state.document_form.doc_number = doc_number
            console.log(state.document_form.doc_number)
        },
        updateDocIin(state, doc_iin) {
            state.document_form.doc_iin = doc_iin
            console.log(state.document_form.doc_iin)
        },
        updateDocDateStart(state, doc_date_start) {
            state.document_form.doc_date_start = parseInt((new Date(doc_date_start)).getTime() / 1000)
        },
        updateDocDateEnd(state, doc_date_end) {
            state.document_form.doc_date_end = parseInt((new Date(doc_date_end)).getTime() / 1000)
        },
        updateDocVisa(state, doc_visa) {
            state.document_form.doc_visa = parseInt((new Date(doc_visa)).getTime() / 1000)
        },
    },
    state: {
        document_form: {
            doc_number: '',
            doc_iin: '',
            doc_date_start: parseInt(new Date().getTime() / 1000),
            doc_date_end: parseInt(new Date().getTime() / 1000),
            doc_visa: parseInt(new Date().getTime() / 1000),
            citizenship: 1,
            doc_type: 1,
            doc_issued: ''
        },
        citizenships: [],
        docTypes: [],
    },
    getters: {
        allCitizenships(state) {
            return state.citizenships
        },
        allDocTypes(state) {
            return state.docTypes
        },
    }
}

export default document