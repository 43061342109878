import departmentDifferenceService from "@/services/department/departmentDifference.service"

const departmentDifference = {
  namespaced: true,
  actions: {
    async GET_STUDENTS({commit}) {
      try {
        const {status, data} = await departmentDifferenceService.getStudents()
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async PUT_DIFFERENCE_RATINGS_PPS({commit}, form) {
      try {
        const {status, data} = await departmentDifferenceService.putDifferenceRatingsPps(form)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_DIFFERENCE_DISCIPLINES({commit}) {
      try {
        const {status, data} = await departmentDifferenceService.getDifferenceDisciplines()
        if (status === 200) {
          commit('SET_DIFFERENCE_DISCIPLINES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async POST_MDL_COURSE_DIFFERENCE({commit}, form) {
      try {
        await departmentDifferenceService.postMdlCourseDifference(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


  },
  mutations: {
    SET_DIFFERENCE_DISCIPLINES(state, differenceDisciplines) {
      state.differenceDisciplines = differenceDisciplines
    }
  },
  state: {
    differenceDisciplines: []
  },
}

export default departmentDifference