import httpClient from "@/services/http.service"
import router from "@/router"


const studentResearchCupRegistrationService = {
  getStudentRegistration() {
    return httpClient.get(`research_cup/registration-research-cup/get-student-registration-info`)
  },
  registerToResearchCup(studentRegisterData) {
    return httpClient.post(`research_cup/registration-research-cup/create`, studentRegisterData)
  },
  getProjectThemeDirection(){
    return httpClient.get(`research_cup/project-theme-direction`)
  },
  postResearchCupVideo(formData){
    return httpClient.post(`research_cup/research-cup-students-files/create`, formData)
  },
  getResearchCupSteps(){
    return httpClient.get(`research_cup/research-cup-steps/get-research-cup-steps`)
  },
  getCupSchedule(){
    return httpClient.get(`research_cup/student-research-cup-schedule/index`)
  }
}

export default studentResearchCupRegistrationService
