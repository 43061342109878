import httpClient from "@/services/http.service"
import router from "@/router"


const formedLoadCalculation = {
    getSelectionSupervisors() {
        return httpClient.get(`graduate/selection-supervisors/index`)
    }
}

export default formedLoadCalculation