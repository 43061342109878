import differenceStudentService from "@/services/difference/differenceStudent.service"


const differenceStudent = {
  namespaced: true,
  actions: {
    async GET_DIFFERENCE_STUDENT_DISCIPLINES({commit}) {
      try {
        const {data} = await differenceStudentService.getDifferenceStudentDisciplines()
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_DIFFERENCE_STUDENT_APPLICATION({commit}) {
      try {
        const {data} = await differenceStudentService.getDifferenceStudentApplications()
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_DIFFERENCE_STUDENT({commit}, form) {
      try {
        await differenceStudentService.postDifferenceStudent(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {},
  state: {},
  getters: {}
}

export default differenceStudent