import holidayCalendarService from "@/services/calendar/holidayCalendar.service"
import axios from "axios";

const holidayCalendar = {
  namespaced: true,
  actions: {
    async GET({commit}) {
      try {
        const {status, data} = await holidayCalendarService.get()
        if (status === 200) {
          commit('SET_HOLIDAYS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST({commit}, form) {
      try {
        await holidayCalendarService.post(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT({state, commit}) {
      try {
        await holidayCalendarService.put(state.holidays)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async DELETE({commit}, deletedId) {
      try {
        const {
          status
        } =await holidayCalendarService.delete(deletedId);
        if (status === 200) {
          console.log('DELETED')
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
  },
  mutations: {
    SET_HOLIDAYS(state, holidays) {
      state.holidays = holidays
    },
    CHANGE_HOLIDAY(state, {start_end, index, value}) {
      if(start_end === 'start'){
        state.holidays[index].start_date = value
      }else {
        state.holidays[index].end_date = value
      }
    },
  },
  state: {
    holidays: []
  },
  getters: {}
}

export default holidayCalendar