import semesterSummerCalendarService from "@/services/academic-calendar/semesterSummerCalendar.service"

const semesterSummerCalendar = {
    namespaced: true,
    actions: {



        async GET_SEMESTER_SUMMER_CALENDAR({commit}) {
            try {
                const {status, data} = await semesterSummerCalendarService.getSemesterSummerCalendar()
                if (status === 200) {
                    console.log('GET_SEMESTER_SUMMER_CALENDAR', data)
                    commit('SET_SEMESTER_SUMMER_CALENDAR', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async POST_SEMESTER_SUMMER_CALENDAR({commit, state}) {
            let form = state.semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>!i.id)
            for (let i = 0; i< form.length; i++) {
                delete form[i]['study_level_id']
            }

            console.log(form, 'post form')
            try {
                await semesterSummerCalendarService.postSemesterSummerCalendar(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_SEMESTER_SUMMER_CALENDAR({commit, state}) {
            let form = state.semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>i.id)
            for (let i = 0; i< form.length; i++) {
                delete form[i]['study_level_id']
            }
            console.log(form, 'put form')
            try {
                await semesterSummerCalendarService.putSemesterSummerCalendar(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async DELETE_SEMESTER_SUMMER_CALENDAR({commit}, deleteId) {
            try {
                const {status, data} = await semesterSummerCalendarService.deleteSemesterSummerCalendar(deleteId)
                if (status === 200) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})

            }
            return false
        },


    },
    mutations: {
        SET_SEMESTER_SUMMER_CALENDAR(state, semesterSummerCalendar) {
            state.semesterSummerCalendar_form.semesterSummerCalendar = semesterSummerCalendar
        },
        ADD_ELEMENT(state, study_level_id) {
            state.semesterSummerCalendar_form.semesterSummerCalendar.push({
                education_calendar_id: null,
                study_level_id: study_level_id,
                start_date: null,
                end_date: null,
                start_registration_date: null,
                end_registration_date: null,
            })

            console.log(state.semesterSummerCalendar_form.semesterSummerCalendar, 'semesterSummerCalendar')
        },
        DELETE_ELEMENT(state, element) {
            let index = state.semesterSummerCalendar_form.semesterSummerCalendar.indexOf(element)
            state.semesterSummerCalendar_form.semesterSummerCalendar.splice(index, 1);
            console.log(state.semesterSummerCalendar_form.semesterSummerCalendar, 'semesterSummerCalendar')
        },
        SET_EDUCATION_CALENDAR_ID(state, {elementIndex, studyLevelId, value}) {
            let element = state.semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>i.study_level_id == studyLevelId)
            element[elementIndex].education_calendar_id = value
            console.log(element, 'semesterSummerCalendar')
        },
        SET_DATE(state, {elementIndex, studyLevelId, property, date}) {
            let element = state.semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>i.study_level_id == studyLevelId)
            element[elementIndex][property] = date
            console.log(element, 'semesterSummerCalendar')
        }
    },
    state: {
        semesterSummerCalendar_form: {
            semesterSummerCalendar: []

        }
    },
    getters: {}
}

export default semesterSummerCalendar