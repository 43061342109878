import httpClient from "@/services/http.service"

const kkkService = {
    getKkkResult(user_id){
        return httpClient.get('https://back.uib.kz/kkk/active-directory/profile-evaluation?user_id=' + user_id)
    },
    getTeachingPositions() {
        return httpClient.get(`kkk/teaching-position/index`)
    },
    getVacancyApplications() {
        return httpClient.get(`kkk/discipline-vacancy/get-new-applications-vacancy`)
    },
    postDHConfirm(form) {
        return httpClient.post(`kkk/user-syllabus/confirm`, form)
    },
    postDHReject(form) {
        return httpClient.post(`kkk/user-syllabus/reject`, form)
    },
    postDHConfirmFast(form) {
        return httpClient.post(`kkk/user-syllabus/without-kkk`, form)
    },
    postContract(form) {
        return httpClient.post(`labor_contract/teacher-labor-contract/create`, form)
    },
    postAddToActiveDirectory(form) {
        return httpClient.post(`kkk/active-directory/add-to-active-directory`, form)
    },
    postDeleteFromActiveDirectory(form) {
        return httpClient.post(`kkk/active-directory/delete-from-active-directory`, form)
    },
    putContract(form) {
        return httpClient.put(`labor_contract/teacher-labor-contract/update?id=${form.id}`, form)
    },

    postTeacherType(form) {
        return httpClient.post(`kkk/teacher-type/create`, form)
    },
    getTeachers(page = 1, teacherName = '') {
        return httpClient.get(`kkk/active-directory/get-new-kkk-users?page=${page}&teacher_name=${teacherName}`)
    },

    getTeachersNew(page, limit , teacher_name) {
        let url = 'kkk/active-directory/get-real-users?page=' + page;

        if (limit) {
            url += '&limit=' + limit;
        }
        if (teacher_name) {
            url += '&teacher_name=' + teacher_name;
        }

        return httpClient.get(url);
    },

    postPpsDataByUser(form) {
        return httpClient.post(`questionnaire/pps-data/update-by-user`, form)
    },

    postCreateContract(form) {
        return httpClient.post(`labor_contract/teacher-labor-contract/create-contract`, form)
    },
    postUpdateContract(form) {
        return httpClient.post(`labor_contract/teacher-labor-contract/update-contract?id=${form.id}`, form)
    },
    putUpdateContractHrHead(form) {
        return httpClient.put(`labor_contract/teacher-labor-contract/update?id=${form.id}`, form)
    },

    postInterviewTime(form) {
        return httpClient.post(`kkk/kkk-interview-time/create`, form)
    },
    putInterviewTime(form) {
        return httpClient.put(`kkk/kkk-interview-time/update`, form)
    },
}

export default kkkService
