import graduateWorkService from "@/services/graduate-work/graduateWork.service"
import graduateWorkTempService from "@/services/graduate-work/graduateWorkTemp.service"

const selectionSupervisors = {
    namespaced: true,
    actions: {
        async GET_PPS_BY_DEPARTMENT_ID({commit}) {
            try {
                const {status,data} = await graduateWorkService.getPpsByDepartmentId();
                if (status === 200) {
                    commit('SET_DEPARTMENT_PPS', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_STUDY_LEVELS({commit}) {
            try {
                const {data} = await graduateWorkTempService.getStudyLevels();
                if (data) {
                    commit('SET_STUDY_LEVELS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_SELECTION_SUPERVISORS({commit, state}) {

            console.log(state.department_pps.newSelectionSupervisors, 'newSelectionSupervisors')
            try {
                const {data} = await graduateWorkService.postSelectionSupervisors(state.department_pps.newSelectionSupervisors);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_DEPARTMENT_PPS(state, pps) {

            state.department_pps.pps = pps
        },
        SET_STUDY_LEVELS(state, studyLevels) {

            state.department_pps.studyLevels = studyLevels
        },
    },
    state: {
        department_pps: {
            pps: [],
            studyLevels: [],
            newSelectionSupervisors: {},
        }

    },
    getters: {

    }

}

export default selectionSupervisors