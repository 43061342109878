import httpClient from "@/services/http.service"
import {generateURL} from "@/utils/helpers/queryParams.helper";


const predictListService = {
    getPredict(params) {
        return httpClient.get(generateURL('analytics_data/student-predict/predict-api', params))
    },
    getEducationPrograms(params) {
        return httpClient.get(generateURL(`education_program/education-program/get-education-program-names`, params))
    },
}

export default predictListService
