import httpClient from "@/services/http.service"


const departmentMagistracyPrerequisiteService = {

  getEducationPrograms() {
    return httpClient.get(`education_program/education-program/get-magistracy-education-programs`)
  },
  postMagistracyPrerequisite(form) {
    return httpClient.post(`requisites/magistracy-prerequisites/create`, form)
  },
  getStudents() {
    return httpClient.get(`requisites/magistracy-prerequisites/get-masters-by-department`)
  },
  putPpsPrerequisitesRatings(form) {
    return httpClient.put(`student/prerequisites-ratings/update?id=${form.id}`, form)
  },
  deleteMagistracyPrerequisites(id) {
    return httpClient.delete(`requisites/magistracy-prerequisites/delete` + '?id='+ id)
  },


}

export default departmentMagistracyPrerequisiteService