import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const syllabusContentOld = {
    namespaced: true,
    actions: {
        async POST_SYLLABUS_CONTENT_DATA({state}, syllabus_id) {

            let syllabusContentForm = state.syllabusContent_form.newSyllabusContentInfos
            syllabusContentForm['syllabus_id'] = syllabus_id

            console.log(syllabusContentForm, 'syllabusContentForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('syllabus_data/syllabus-content/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), syllabusContentForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async PUT_SYLLABUS_CONTENT_DATA({state}, syllabus_id) {

            let syllabusContentForm = state.syllabusContent_form.syllabusContentInfos
            console.log(syllabusContentForm, 'syllabusContentForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.put('syllabus_data/syllabus-content/update'+ '?syllabus_id=' + syllabus_id + '&access-token=' + getCookie('ACCESS_TOKEN'), syllabusContentForm);
                if (status === 200) {
                    console.log(data, 'update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },
        async GET_SYLLABUS_CONTENT_DATA({commit}, syllabus_id) {
            try {
                const url = 'syllabus_data/syllabus-content/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&syllabus_id=' + syllabus_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_SYLLABUS_CONTENT_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_SYLLABUS_DATA_BY_ID({commit}, syllabus_id) {
            try {
                const url = 'syllabus_data/syllabus-content/get-syllabus-by-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + syllabus_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_SYLLABUS_DATA_EVALUATION_OPTION", data.data.evaluation_option_id)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_NEW_SYLLABUS_CONTENT_DATA(state, {property, value}) {
            state.syllabusContent_form.newSyllabusContentInfos[property] = value
            console.log(state.syllabusContent_form.newSyllabusContentInfos, 'STATE newSyllabusContentInfos')
        },
        SET_SYLLABUS_CONTENT_DATA(state, syllabusContentInfos) {
            state.syllabusContent_form.syllabusContentInfos = syllabusContentInfos
            console.log(state.syllabusContent_form.syllabusContentInfos, 'STATE syllabusContentInfos')
        },
        SET_URL_SYLLABUS_ID(state, urlSyllabusId) {
            state.syllabusContent_form.urlSyllabusId = urlSyllabusId
        },
        SET_SYLLABUS_DATA_EVALUATION_OPTION( state, syllabusEvaluationOptionId) {
            state.syllabusContent_form.syllabusEvaluationOptionId = syllabusEvaluationOptionId
        },
        UPDATE_SYLLABUS_CONTENT_DATA(state, {property, value}) {
            // var index = state.syllabusContent_form.syllabusContentInfos.indexOf(property);
            //
            // if (index > 0) {
            //     state.syllabusContent_form.syllabusContentInfos[index] = value;
            // }
            delete state.syllabusContent_form.syllabusContentInfos[property];
            state.syllabusContent_form.syllabusContentInfos[property] = value

            console.log(state.syllabusContent_form.syllabusContentInfos, 'STATE syllabusContentInfos')
        },
    },
    state: {
        syllabusContent_form: {
            syllabusEvaluationOptionId: null,
            newSyllabusContentInfos: {},
            syllabusContentInfos: {},
            urlSyllabusId: null,
        }
    },
    getters: {

    }

}

export default syllabusContentOld