import accreditationExperienceService from "@/services/pps/accreditationExperience.service";

const accreditationExperience = {
  namespaced: true,
  actions: {
    async GET_ACCREDITATION_TYPES({commit}) {
      try {
        const {status, data} = await accreditationExperienceService.getAccreditationTypes()
        if (status === 200) {
          commit('SET_ACCREDITATION_TYPES', data.success)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_ACCREDITATION_AGENCIES({commit}) {
      try {
        const {status, data} = await accreditationExperienceService.getAccreditationAgencies()
        if (status === 200) {
          commit('SET_ACCREDITATION_AGENCIES', data.success)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_ACCREDITATION_ROLES({commit}) {
      try {
        const {status, data} = await accreditationExperienceService.getAccreditationRoles()
        if (status === 200) {
          commit('SET_ACCREDITATION_ROLES', data.success)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_ACCREDITATION_EXPERIENCE({commit}, userId = null) {
      try {
        const {status, data} = await accreditationExperienceService.getAccreditationExperience(userId)
        if (status === 200) {
          commit('SET_ACCREDITATION_EXPERIENCE', data.success)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async DELETE_ACCREDITATION_EXPERIENCE({commit, state}, index) {
      try {
        const {status, data} = await accreditationExperienceService.deleteAccreditationExperience(state.accreditation_experience[index].id)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_ACCREDITATION_EXPERIENCE({commit, state}) {
      let newAccExperience = state.accreditation_experience.filter(i => i.id == null)
      try {
        await accreditationExperienceService.postAccreditationExperience(newAccExperience)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_ACCREDITATION_EXPERIENCE({commit, state}) {
      let newAccExperience = state.accreditation_experience.filter(i => i.id != null)
      try {
        await accreditationExperienceService.putAccreditationExperience(newAccExperience)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {
    SET_ACCREDITATION_TYPES(state, accreditation_types) {
      state.accreditation_types = accreditation_types
    },
    SET_ACCREDITATION_AGENCIES(state, accreditationAgencies) {
      state.accreditationAgencies = accreditationAgencies
    },
    SET_ACCREDITATION_ROLES(state, accreditation_roles) {
      state.accreditation_roles = accreditation_roles
    },
    SET_ACCREDITATION_EXPERIENCE(state, accreditation_experience) {
      state.accreditation_experience = accreditation_experience
    },
    CHANGE_ACCREDITATION(state, accreditation) {
      state.accreditation_experience[accreditation.index][accreditation.accreditation_data] = accreditation.value
    },
    DELETE_ROW_ACCREDITATION_INFO(state, index) {
      state.accreditation_experience.splice(index, 1);
    },
    ADD_ROW_ACCREDITATION_EXPERIENCE(state) {
      state.accreditation_experience.push({
        id: null,
        accreditation_type_id: null,
        accreditation_role_id: null,
        education_program_name: '',
      });
    },
  },
  state: {
    accreditation_types: [],
    accreditation_roles: [],
    accreditation_experience: [],
    accreditationAgencies: []
  },
  getters: {}
}

export default accreditationExperience
