import httpClient from "@/services/http.service"

const minorEducationProgramService = {
    postMinorEducationProgramCreate(data){
        return httpClient.post('education_program/minor-education-program/create', data)
    },
    postMinorEducationProgramConfirm(data){
        return httpClient.post('education_program/minor-education-program/confirm', data)
    },
    getMinorEducationProgram(){
        return httpClient.get('education_program/minor-education-program/index')
    },
    getMinorStudents(education_program_id, minor_education_program_id){
        return httpClient.get('education_program/minor-education-program/get-students-list-minor' + '?education_program='+education_program_id+'&minor_education_program_id='+minor_education_program_id)
    },
    getMinorEducationProgramType(){
        return httpClient.get('education_program/minor-education-program/get-minor-ep-type')
    },
    putMinorEducationProgram(id, data){
        return httpClient.put('education_program/minor-education-program/update?id=' + id, data)
    },
    deleteMinorEducationProgram(id){
        return httpClient.delete('education_program/minor-education-program/delete?id=' + id)
    },
    getChooseDiscipline(minor_education_program_id){
        return httpClient.get('education_program/minor-education-program/get-choose-discipline-data' + '?minor_id='+minor_education_program_id)
    },
}

export default minorEducationProgramService