import teacherGrantsService from "@/services/teacher_grants/teacherGrants.service"
import axios from 'axios'

const teacherGrants = {
  namespaced: true,
  actions: {
    async GET_TEACHER_GRANT_TYPE({commit}) {
      try {
        const {data} = await teacherGrantsService.getTeacherGrantType();
        if (data) {
          console.log(data, 'getTeacherGrantType')
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_FOREIGN_EDUCATIONAL_GRANTS({commit}, user_id = null) {
      try {
        const {data} = await teacherGrantsService.getForeignEducationalGrants(user_id)
        if (data) {
          console.log(data, 'getForeignEducationalGrants')
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_FOREIGN_EDUCATIONAL_GRANTS({commit}, postData) {
      try {
        const {data} = await teacherGrantsService.postForeignEducationalGrants(postData);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_FOREIGN_EDUCATIONAL_GRANTS({commit}, putData) {
      try {
        const {data} = await teacherGrantsService.putForeignEducationalGrants(putData);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async DELETE_FOREIGN_EDUCATIONAL_GRANTS({commit}, deleteId) {
      try {
        const {data} = await teacherGrantsService.deleteForeignEducationalGrants(deleteId);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async GET_FORMS_OF_PARTICIPATION({commit}) {
      try {
        const {data} = await teacherGrantsService.getFormsOfParticipation()
        if (data) {
          commit('SET_FORMS_OF_PARTICIPATION', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_FINANCING_SOURCE({commit}) {
      try {
        const {data} = await teacherGrantsService.getFinancingSource()
        if (data) {
          commit('SET_FINANCING_SOURCE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_RESEARCH_GRANTS({commit}, user_id = null) {
      try {
        const {data} = await teacherGrantsService.getResearchGrants(user_id)
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_RESEARCH_GRANT({commit}, form) {
      try {
        const {data} = await teacherGrantsService.postResearchGrants(form)
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_RESEARCH_GRANT({commit}, form) {
      try {
        const {data} = await teacherGrantsService.putResearchGrants(form)
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async DELETE_RESEARCH_GRANT({commit}, id) {
      try {
        const {data} = await teacherGrantsService.deleteResearchGrants(id)
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    }

  },
  mutations: {
    SET_FORMS_OF_PARTICIPATION(state, formsOfParticipation) {
      state.formsOfParticipation = formsOfParticipation
    },
    SET_FINANCING_SOURCE(state, financingSource) {
      state.financingSource = financingSource
    }
  },
  state: {
    formsOfParticipation: [],
    financingSource: []
  },
  getters: {}
}

export default teacherGrants