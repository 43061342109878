import httpClient from "@/services/http.service"

const dmpEventsService = {
  getEvents() {
    return httpClient.get(`events/events/get-events`)
  },
  putEventsCmpStatus(id, status){
    return httpClient.put(`events/events/update?id=${id}`, {status: status})
  },

}

export default dmpEventsService