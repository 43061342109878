import organizationService from "@/services/organization/organization.service";


const organization = {
  namespaced: true,
  actions: {
    async GET_ORGANIZATIONS({commit}) {
      try {
        const {status, data} = await organizationService.getOrganizations()
        if (status === 200) {
          console.log('GET_ORGANIZATIONS', data)
          commit('SET_ORGANIZATIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_TYPES({commit}) {
      try {
        const {status, data} = await organizationService.getTypes()
        if (status === 200) {
          commit('SET_TYPES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_PROFILES({commit}) {
      try {
        const {status, data} = await organizationService.getProfiles()
        if (status === 200) {
          commit('SET_PROFILES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_STATUSES({commit}) {
      try {
        const {status, data} = await organizationService.getStatuses()
        if (status === 200) {
          commit('SET_STATUSES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_ORGANIZATION_MEMBERS_JOIN({commit}) {
      try {
        const {status, data} = await organizationService.getOrganizationMembersJoin()
        if (status === 200) {
          commit('SET_ORGANIZATION_MEMBERS_JOIN', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async POST_ORGANIZATION({commit}, form) {
      try {
        await organizationService.post(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_ORGANIZATION_MEMBERS_JOIN({commit}, form) {
      try {
        await organizationService.postOrganizationMembersJoin(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async GET_STUDENT_BY_BARCODE({commit}, barcode) {
      try {
        const {status, data} = await organizationService.getStudentByBarcode(barcode)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return null
    },

    async GET_ORGANIZATION_MEMBERS({commit}){
      try {
        const {status, data} = await organizationService.getOrganizationMembers()
        if (status === 200) {
          commit('SET_MEMBERS', data.members)
          commit('SET_MEMBERS_JOIN', data.membersJoin)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async PUT_ORGANIZATION_MEMBERS_STATUS({commit}, data){
      try {
        await organizationService.putOrganizationMembersStatus(data)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async DELETE_ORGANIZATIONS_MEMBERS({commit}, delete_id){
      try {
        await organizationService.deleteOrganizationMembers(delete_id)
      }
      catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_ORGANIZATION_STATUS({state, commit}, id){
      try {
        let organization = state.organizations.find(i => i.id === id)
        await organizationService.putOrganizationStatus(organization.id, organization.status_id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_ORGANIZATION({commit}) {
      try {
        const {status, data} = await organizationService.getOrganization()
        if (status === 200) {
          console.log('GET_ORGANIZATION', data)
          commit('SET_ORGANIZATION', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },




  },
  mutations: {
    SET_TYPES(state, types) {
      state.types = types
    },
    SET_PROFILES(state, profiles) {
      state.profiles = profiles
    },
    SET_STATUSES(state, statuses) {
      state.statuses = statuses
    },
    SET_ORGANIZATION_MEMBERS_JOIN(state, organizationMembersJoin) {
      state.organizationMembersJoin = organizationMembersJoin
    },
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
    SET_MEMBERS(state, data) {
      state.members = data
    },
    SET_MEMBERS_JOIN(state, data) {
      state.membersJoin = data
    },
    SET_STATUS_ID(state, object) {
      state.organizations.find(i => i.id === object.id).status_id = object.status_id
    },
    SET_ORGANIZATION(state, organization) {
      state.organization = organization
    }
  },
  state: {
    organizations: [],
    organization: {},
    types: [],
    profiles: [],
    organizationMembersJoin: [],
    statuses: [],
    members: [],
    membersJoin: [],
  },
  getters: {}
}

export default organization
