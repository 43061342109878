import minorFormationEducationProgramService
    from "@/services/education-programme/minorFormationEducationProgram.service";
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const educationprogram = {
    namespaced: true,
    actions: {
        async DELETE_EDUCATION_PROGRAM_DATA({commit, state}, id) {
            //let id = state.educationprogram_form.educationProgramInfos[index].id
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('education_program/education-program/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('education program data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_EDUCATION_PROGRAM_DATA({state}, arr = []) {
            let educationProgramForm = []
            if(arr.length){
                educationProgramForm = arr
            }else {
                console.log(state.educationprogram_form.newEducationProgramInfos, "newEducationProgramInfos")
                educationProgramForm = state.educationprogram_form.newEducationProgramInfos
            }
            console.log(educationProgramForm, "educationProgramForm")

            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/education-program/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationProgramForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },
        async PUT_EDUCATION_PROGRAM_DATA({commit, state}, id) {
            console.log(id, "PROGRAM ID")
            let educationProgramForm = (educationprogram.state.educationprogram_form.educationProgramInfos).filter(v => v.id == id)
            console.log(educationProgramForm, 'educationProgramForm')
            if (educationProgramForm.length > 0) {

                try {
                    console.log(educationProgramForm)
                    const {
                        status,
                        data
                    } = await httpClient.put('education_program/education-program/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationProgramForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_EDUCATION_PROGRAM_DATA({commit}, page = 1) {
            try {
                const urlGetParams = `&page=${page}`
                console.log(urlGetParams)
                const url = 'education_program/education-program/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(url, ' URL')
                    console.log(data.page_count, 'PAGE COUNT')

                    commit("SET_PAGE_COUNT", data.page_count)
                    commit("SET_EDUCATION_PROGRAM_DATA", data.success)

                    // data.success.forEach(d=>{
                    //     console.log(d, d.id);
                    // })
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_FIELD_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-field/full-data' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_EDUCATION_FIELD_NAMES', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_DIRECTION_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-program/get-direction' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_EDUCATION_DIRECTION_NAMES', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_EDUCATION_PROGRAM_SEMESTER_TYPE({commit}) {
            try {
                const {status,  data  } = await httpClient.get('education_program/education-program/get-education-program-semester-type')
                if (status === 200) {
                    //return data
                    commit('SET_EDUCATION_PROGRAM_SEMESTER_TYPE', data)
                }
            } catch (e) {
                console.log(e.response)
            }
            return []
        },

        async GET_EP_DEVELOPERS({commit}, education_program_id) {
            try {
                const {status,  data  } = await httpClient.get('education_program/education-program-creator/get-info?education_program_id='+education_program_id)
                if (status === 200) {
                    return data
                }
            } catch (e) {
                console.log(e.response)
            }
            return []
        },
        async POST_EP_DEVELOPERS({commit}, form) {
            try {
                await httpClient.post('education_program/education-program-creator/create', form)
                return true
            } catch (e) {
                console.log(e.response)
            }
            return false
        },
        async PUT_EP_DEVELOPERS({commit}, form) {
            try {
                await httpClient.put('education_program/education-program-creator/update', form)
                return true
            } catch (e) {
                console.log(e.response)
            }
            return false
        },

        async DELETE_EP_DEVELOPERS({commit}, id) {
            try {
                await httpClient.delete('education_program/education-program-creator/delete?id='+id)
                return true
            } catch (e) {
                console.log(e.response)
            }
            return false
        },

        async DELETE_EDUCATION_PROGRAM_MINORS({commit}, id) {
            try {
                await httpClient.delete('education_program/education-program-minors/delete?id='+id)
                return true
            } catch (e) {
                console.log(e.response)
            }
            return false
        },


        async GET_MINOR_EDUCATION_PROGRAMS_BY_NAME({commit}, {name,educationProgramId}) {
            try {
                const {status, data} = await httpClient.get('education_program/education-program-minors/search'+ '?minor_name='+name+ '&education_program_id='+educationProgramId)
                if (status === 200) {
                    console.log('GET_MINOR_EDUCATION_PROGRAMS_BY_NAME', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async POST_EDUCATION_PROGRAM_MINORS({commit}, form) {
            try {
                await httpClient.post('education_program/education-program-minors/create', form)
                return true
            } catch (e) {
                console.log(e.response)
            }
            return false
        },

        async GET_EDUCATION_GROUPS_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-groups/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('GET_EDUCATION_GROUPS_NAMES', data)
                    commit('SET_EDUCATION_GROUPS_NAMES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_STUDY_FORM_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-program/get-study-form' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_STUDY_FORM_NAMES', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_STUDY_LEVEL_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/study-level/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_STUDY_LEVEL_NAMES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_DEGREE_TYPES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('graduate/degree-type/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_DEGREE_TYPES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_EDUCATION_PROGRAM_SEMESTER_TYPE(state, educationProgramSemesterType) {
          state.educationprogram_form.educationProgramSemesterType = educationProgramSemesterType
        },
        SET_EDUCATION_PROGRAM_DATA(state, educationProgramInfos) {
            console.log(educationProgramInfos, "TESTEST")
            state.educationprogram_form.educationProgramInfos = educationProgramInfos
        },
        SET_PAGE_COUNT(state, pageCount) {
            state.pageCount = pageCount
        },
        // ADD_ROW_EDUCATION_PROGRAM_INFO(state) {
        //     state.educationprogram_form.newEducationProgramInfos.push({
        //         education_field_id: 0,
        //         education_direction_id: 0,
        //         education_speciality_code: '',
        //         education_speciality_name: '',
        //         ep_aim: '',
        //         partner_university_sop: '',
        //         partner_university_ddop: '',
        //         study_form_id: 0,
        //         credit_volume: 0,
        //         academic_degree: '',
        //         application_availability: '',
        //         study_level_id: 0,
        //         language_id: 0,
        //     });
        // },
        // DELETE_ROW_EDUCATION_PROGRAM_INFO(state, index) {
        //     state.educationprogram_form.newEducationProgramInfos.splice(index, 1);
        // },
        UPDATE_EDUCATION_PROGRAM_DATA(state, {item, property, value}) {
            const index = state.educationprogram_form.educationProgramInfos.indexOf(item)
            state.educationprogram_form.educationProgramInfos[index][property] = value
            console.log(state.educationprogram_form.educationProgramInfos, value)
        },
        UPDATE_NEW_EDUCATION_PROGRAM_DATA(state, {item, property, value}) {
            const index = state.educationprogram_form.newEducationProgramInfos.indexOf(item)
            state.educationprogram_form.newEducationProgramInfos[index][property] = value
            state.educationprogram_form.newEducationProgramInfos[index]['department_id'] = getCookie('DEPARTMENT_ID')
            console.log(state.educationprogram_form.newEducationProgramInfos, value)
        },
        SET_EDUCATION_FIELD_NAMES(state, educationField) {
            state.educationprogram_form.educationField = educationField
        },
        SET_EDUCATION_DIRECTION_NAMES(state, educationDirection) {
            state.educationprogram_form.educationDirection = educationDirection
        },
        SET_EDUCATION_GROUPS_NAMES(state, educationGroups) {
            state.educationprogram_form.educationGroups = educationGroups
        },
        SET_STUDY_FORM_NAMES(state, studyForm) {
            state.educationprogram_form.studyForm = studyForm
        },
        SET_STUDY_LEVEL_NAMES(state, studyLevel) {
            state.educationprogram_form.studyLevel = studyLevel
        },
        SET_DEGREE_TYPES(state, degreeTypes) {
            state.educationprogram_form.degreeTypes = degreeTypes
        },
        SET_UPDATE_ID(state, UpdateId) {
            state.educationprogram_form.updateId = UpdateId
        },
        SET_DELETE_EDUCATION_PROGRAM_ID(state, deleteEducationProgramId) {
            state.educationprogram_form.deleteEducationProgramId = deleteEducationProgramId
        },
        SET_DUPLICATE_EDUCATION_PROGRAM_ID(state, duplicateId) {
            state.educationprogram_form.duplicateId = duplicateId
        },
        SET_EP_DEVELOPERS(state, epDevelopers) {
            state.epDevelopers = epDevelopers
        },
        SET_DEFAULT_DATA(state, decreeGosoTemplates_form){
            const decree_goso_template_id = decreeGosoTemplates_form.decreeGosoTemplatesInfos.length ? decreeGosoTemplates_form.decreeGosoTemplatesInfos[0].id : null
            const study_form_id = state.educationprogram_form.studyForm.length ? state.educationprogram_form.studyForm[0].id : null
            const study_level_id = state.educationprogram_form.studyLevel.length ? state.educationprogram_form.studyLevel[0].id : null
            const language_id = state.educationprogram_form.languages.length ? state.educationprogram_form.languages[0].id : null


            let newEducationProgramInfos = state.educationprogram_form.newEducationProgramInfos[0]
            newEducationProgramInfos = {...newEducationProgramInfos,
                decree_goso_template_id,
                study_form_id,
                study_level_id,
                language_id
            }
            state.educationprogram_form.newEducationProgramInfos[0] = newEducationProgramInfos
            console.log(state.educationprogram_form.newEducationProgramInfos, 'SET_DEFAULT_DATA')

        }
    },
    state: {
        educationprogram_form: {
            educationProgramInfos: [{
                education_field_id: 0,
                education_direction_id: 0,
                education_speciality_code: '',
                education_speciality_name: '',
                ep_aim: '',
                partner_university_sop: '',
                partner_university_ddop: '',
                study_form_id: 0,
                credit_volume: 0,
                academic_degree: '',
                application_availability: '',
                study_level_id: 0,
                language_id: 0,
            }],
            newEducationProgramInfos: [{
                education_field_id: 5,
                education_direction_id: 17,
                education_speciality_code: '',
                education_speciality_name: '',
                ep_aim: '',
                partner_university_sop: '',
                partner_university_ddop: '',
                study_form_id: 0,
                credit_volume: 0,
                academic_degree: '',
                application_availability: '',
                study_level_id: 0,
                language_id: 0,
                decree_goso_template_id: 0,
                education_program_semester_type_id: 0
            }],
            educationField: [],
            educationDirection: [],
            educationGroups: [],
            studyForm: [],
            studyLevel: [],
            degreeTypes: [],
            educationProgramSemesterType: [],
            languages: [
                { id: 39, name: 'English' },
                { id: 82, name: 'Қазақ тілі ' },
                { id: 137, name: 'Русский язык ' }
            ],
            updateId: 0,
            deleteEducationProgramId: null,
            duplicateId: 0
        },
        pageCount: 0,
        epDevelopers: []
    },
    getters: {
        UPDATE_ITEM(state) {
            return state.educationprogram_form.educationProgramInfos.find(i => i.id == state.educationprogram_form.updateId)

        }
    }

}

export default educationprogram
