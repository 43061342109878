import httpClient from "@/services/http.service"
import router from "@/router"


const curriculumOptionalComponentService = {
  getOptionalComponent(code, admission_year, study_course, language) {
    return httpClient.get(`education_program/curriculum-optional-component/get-optional-component?code=${code}&admission_year=${admission_year}&study_course=${study_course}&language=${language}`)
  },
  postOptionalDisciplines(putData) {
    return httpClient.post(`education_program/education-curriculum/create`, putData)
  },
  getGroupedEducationPrograms(study_level_id, study_course, type, language) {
    return httpClient.get(`education_program/minor-education-program/get-types-of-ep?study_level_id=${study_level_id}&study_course=${study_course}&type=${type}&language=${language}`)
  },
  getCourses() {
    return httpClient.get(`education_program/curriculum-optional-component/get-courses`)
  },
  getModuleTypes() {
    return httpClient.get(`education_program/education-program-type/index`)
  },
  getMinorTypes() {
    return httpClient.get(`education_program/minor-education-program/get-minor-ep-type`)
  },
  postUpdateStudentsDisciplineChoose(delete_formation_id, change_formation_id) {
    return httpClient.post(`/education_program/education-discipline-choose/update-discipline`, {delete_formation_id: delete_formation_id, change_formation_id: change_formation_id})
  },
  putDisciplineConfirmStatus(formation_id, confirm_status) {
    let queryParams = `?formation_id=${formation_id}`
    if (confirm_status === 0){
      queryParams = queryParams.concat(`&status=${confirm_status}`)
    }
    return httpClient.put(`education_program/education-discipline-choose/confirm-status${queryParams}`)
  },
  postCreateStudentsDisciplineChoose(students, formation_id, semester) {
    return httpClient.post(`/education_program/education-discipline-choose/create-dis-choose`, {students: students, formation_education_program_id: formation_id, semester: semester})
  },
  postMoveElectabilityStudents(minor_f_group_id) {
    console.log(minor_f_group_id)
    return httpClient.post(`education_program/education-discipline-choose/update-component`, {minor_f_group_id: minor_f_group_id})
  },
}

export default curriculumOptionalComponentService