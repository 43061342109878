import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const professionalCertification = {
    namespaced: true,
    actions: {
        async DELETE_PROFESSIONAL_CERTIFICATION_DATA({commit, state}, index) {
            let id = state.professionalCertification_form.professionalCertificationInfos[index].id
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/professional-certification/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('professional certification data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_PROFESSIONAL_CERTIFICATION_DATA({state}) {
            let professionalCertificationForm = state.professionalCertification_form.professionalCertificationInfos.filter(i => !i.id)
            if (professionalCertificationForm.length > 0) {


                let formData = new FormData();

                console.log(professionalCertificationForm, 'post professionalCertificationForm')

                for (let i = 0; i < professionalCertificationForm.length; i++) {


                    let file = professionalCertificationForm[i].file;
                    let name = "ProfessionalCertification[" + i + "][file]";

                    let certification_center_id = professionalCertificationForm[i].certification_center_id;
                    let certification_center_id_name = "ProfessionalCertification[" + i + "][certification_center_id]";

                    let education_groups_id = professionalCertificationForm[i].education_groups_id;
                    let education_groups_id_name = "ProfessionalCertification[" + i + "][education_groups_id]";

                    let expiry_date = professionalCertificationForm[i].expiry_date;
                    let expiry_date_name = "ProfessionalCertification[" + i + "][expiry_date]";

                    let other_certification_center_name = professionalCertificationForm[i].other_certification_center_name;
                    let other_certification_center_name_name = "ProfessionalCertification[" + i + "][other_certification_center_name]";

                    let is_perpetual = professionalCertificationForm[i].is_perpetual;
                    let is_perpetual_name = "ProfessionalCertification[" + i + "][is_perpetual]";


                    formData.append(name, file);

                    formData.append(certification_center_id_name, certification_center_id);
                    formData.append(education_groups_id_name, education_groups_id);


                    if (is_perpetual == 0) {
                        formData.append(expiry_date_name, expiry_date);
                    }
                    else {
                        formData.append(expiry_date_name, 0);
                    }

                    if (other_certification_center_name != null) {
                        formData.append(other_certification_center_name_name, other_certification_center_name);
                    }
                    formData.append(is_perpetual_name, is_perpetual);


                }
                console.log(Object.fromEntries(formData), 'hello from formdata')
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/professional-certification/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        data: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        data: false,
                        errors: e.response.data
                    }
                }
            } else {
                return 0
            }
        },
        async PUT_PROFESSIONAL_CERTIFICATION_DATA({state}) {
            let professionalCertificationForm = state.professionalCertification_form.professionalCertificationInfos.filter(i => i.id)
            if (professionalCertificationForm.length > 0) {
                let formData = new FormData();
                console.log(professionalCertificationForm, 'put professionalCertificationForm')
                for (let i = 0; i < professionalCertificationForm.length; i++) {

                    let file = professionalCertificationForm[i].file;
                    let name = "ProfessionalCertification[" + i + "][file]";

                    let certification_center_id = professionalCertificationForm[i].certification_center_id;
                    let certification_center_id_name = "ProfessionalCertification[" + i + "][certification_center_id]";

                    let education_groups_id = professionalCertificationForm[i].education_groups_id;
                    let education_groups_id_name = "ProfessionalCertification[" + i + "][education_groups_id]";

                    let expiry_date = professionalCertificationForm[i].expiry_date;
                    let expiry_date_name = "ProfessionalCertification[" + i + "][expiry_date]";

                    let other_certification_center_name = professionalCertificationForm[i].other_certification_center_name;
                    let other_certification_center_name_name = "ProfessionalCertification[" + i + "][other_certification_center_name]";

                    let is_perpetual = professionalCertificationForm[i].is_perpetual;
                    let is_perpetual_name = "ProfessionalCertification[" + i + "][is_perpetual]";

                    formData.append(name, file);

                    formData.append(certification_center_id_name, certification_center_id);
                    formData.append(education_groups_id_name, education_groups_id);
                    if (is_perpetual == 0) {
                        formData.append(expiry_date_name, expiry_date);
                    }
                    else {
                        formData.append(expiry_date_name, 0);
                    }

                    if (other_certification_center_name != null) {
                        formData.append(other_certification_center_name_name, other_certification_center_name);
                    }

                    formData.append(is_perpetual_name, is_perpetual);


                }
                console.log(Object.fromEntries(formData), 'hello from formdata')
                try {
                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/professional-certification/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        data: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        data: false,
                        errors: e.response.data
                    }
                }
            } else {
                return 0
            }
        },
        // async PUT_QUALIFICATION_DATA() {
        //     let qualificationForm = (qualification.state.qualification_form.qualificationInfos).filter(v => v.put_status == 1)
        //     if (qualificationForm.length > 0) {
        //
        //
        //         let formData = new FormData();
        //         // console.log(languageskills.state.languageskills_form.languageInfos, 'hello from begin');
        //         console.log(qualificationForm, 'put qualificationForm')
        //
        //         for (let i = 0; i < qualificationForm.length; i++) {
        //
        //             let file = qualificationForm[i].file;
        //             let name = "UserQualifications[" + i + "][file]";
        //
        //             let sp_university_id = qualificationForm[i].sp_university_id;
        //             let sp_university_id_name = "UserQualifications[" + i + "][sp_university_id]";
        //
        //             let specialization = qualificationForm[i].specialization;
        //             let specialization_name = "UserQualifications[" + i + "][specialization]";
        //
        //             let organization = qualificationForm[i].organization;
        //             let organization_name = "UserQualifications[" + i + "][organization]";
        //
        //             let course_name = qualificationForm[i].course_name;
        //             let course_name_name = "UserQualifications[" + i + "][course_name]";
        //
        //             let hours_count = qualificationForm[i].hours_count;
        //             let hours_count_name = "UserQualifications[" + i + "][hours_count]";
        //
        //             let end_year = qualificationForm[i].end_year;
        //             let end_year_name = "UserQualifications[" + i + "][end_year]";
        //
        //
        //
        //
        //             formData.append(name, file);
        //             //formData.append(lang_id_name, lang_id);
        //             formData.append(sp_university_id_name, sp_university_id);
        //             formData.append(specialization_name, specialization);
        //             formData.append(organization_name, organization);
        //             formData.append(course_name_name, course_name);
        //             formData.append(hours_count_name, hours_count);
        //             formData.append(end_year_name, end_year);
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //         }
        //         console.log(Object.fromEntries(formData), 'hello from formdata')
        //
        //         try {
        //
        //             const {
        //                 status,
        //                 data
        //             } = await httpClient.put('questionnaire/user-qualifications/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
        //             if (status === 200) {
        //                 console.log(data)
        //             }
        //             return {
        //                 success: true
        //             }
        //         } catch (e) {
        //             console.log(e.response);
        //             return {
        //                 success: false,
        //                 errors: e.response.data.errors
        //             }
        //         }
        //     } else {
        //         return 0
        //     }
        // },
        async GET_PROFESSIONAL_CERTIFICATION_DATA({commit}, userId = null) {
            try {

                const userQuery = userId ? '&user_id='+userId: ''

                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/professional-certification/index' + '?access-token=' + getCookie('ACCESS_TOKEN')+userQuery);
                if (status === 200) {
                    console.log('GET_PROFESSIONAL_CERTIFICATION_DATA', data)
                    commit("SET_PROFESSIONAL_CERTIFICATION_DATA", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_EDUCATION_GROUPS({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-groups/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_EDUCATION_GROUPS", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_CERTIFICATION_CENTER({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/professional-certification/get-certification-center' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_CERTIFICATION_CENTER", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_PROFESSIONAL_CERTIFICATION_DATA(state, professionalCertificationInfos) {
            state.professionalCertification_form.professionalCertificationInfos = professionalCertificationInfos
        },
        SET_EDUCATION_GROUPS(state, educationGroups) {
            state.professionalCertification_form.educationGroups = educationGroups
        },
        SET_CERTIFICATION_CENTER(state, certificationCenter) {
            state.professionalCertification_form.certificationCenter = certificationCenter
        },
        ADD_ROW_PROFESSIONAL_CERTIFICATION_INFO(state) {
            state.professionalCertification_form.professionalCertificationInfos.push({
                certification_center_id: null,
                education_groups_id: null,
                expiry_date: null,
                file: null,
                other_certification_center_name: null,
                is_perpetual: 0,
            });
        },
        DELETE_ROW_PROFESSIONAL_CERTIFICATION_INFO(state, index) {
            state.professionalCertification_form.professionalCertificationInfos.splice(index, 1);
        },

        UPDATE_PROFESSIONAL_CERTIFICATION_DATA(state, payload) {
            state.professionalCertification_form.professionalCertificationInfos[payload.index][payload.property] = payload.value
            console.log(state.professionalCertification_form.professionalCertificationInfos, 'professionalCertificationInfos')
        },
        // UPDATE_EXPIRY_DATE(state, payload) {
        //     state.professionalCertification_form.professionalCertificationInfos[payload.index].expiry_date = payload.value
        //     console.log(state.professionalCertification_form.professionalCertificationInfos, 'professionalCertificationInfos')
        // },
        // UPDATE_QUALIFICATION_UNIVERSITY(state, payload) {
        //     state.qualification_form.qualificationInfos[payload.index].sp_university_id = payload.value
        // },

    },
    state: {
        professionalCertification_form: {
            professionalCertificationInfos: [],
            certificationCenter: [],
            educationGroups: [],
        },
    },
    getters: {}
}

export default professionalCertification
