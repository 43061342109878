import httpClient from "@/services/http.service"


const studentService = {
  getStudentInfoById(student_id) {
    return httpClient.get(`student_data/student-data/get-info-by-barcode?student_id=${student_id}`)
  },
  getStudentInfoByBarcode(barcode) {
    return httpClient.get(`student_data/student-data/get-info-by-barcode?barcode=${barcode}`)
  },
}

export default studentService