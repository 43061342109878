import httpClient from "../../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const graduateWorkTheme = {
    namespaced: true,
    actions: {
        async GET_STUDY_LEVEL_NAMES({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/study-level/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_STUDY_LEVEL_NAMES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_PROGRAMS_BY_NAME_GRADUATE_WORK({commit, state}, {name = '', study_level_id}) {
            console.log(study_level_id, 'study_level_id')
            const url = 'education_program/education-program/get-programs-by-name-graduate-work' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&name=' + name + '&study_level_id=' + study_level_id
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_EDUCATION_PROGRAMS_BY_NAME_GRADUATE_WORK')
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_GRADUATE_WORK_THEME_DATA({state}) {
            let graduateWorkThemeForm = state.graduateWorkTheme_form.newGraduateWorkThemeInfos

            console.log(graduateWorkThemeForm, "POST graduateWorkThemeForm")
            try {
                const {
                    status,
                    data
                } = await httpClient.post('graduate/graduate-work-theme/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), graduateWorkThemeForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_GRADUATE_WORK_THEME_DATA({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('graduate/graduate-work-theme/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_GRADUATE_WORK_THEME_DATA', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_STUDY_LEVEL_NAMES(state, studyLevel) {

            state.graduateWorkTheme_form.studyLevel = studyLevel
        },
        SET_GRADUATE_WORK_THEME_DATA(state, graduateWorkThemeInfos) {

            state.graduateWorkTheme_form.graduateWorkThemeInfos = graduateWorkThemeInfos
        },
        SET_NEW_GRADUATE_WORK_THEME_DATA(state, {property, value}) {

            state.graduateWorkTheme_form.newGraduateWorkThemeInfos[property] = value

            console.log(state.graduateWorkTheme_form.newGraduateWorkThemeInfos, "STATE")
        },

        SET_SELECTED_EDUCATION_PROGRAM_SPECIALITY_CODE(state, selectedEducationProgramSpecialityCode) {
            //state.educationCurriculum_form.selectedEducationProgramId = id
            state.graduateWorkTheme_form.newGraduateWorkThemeInfos['education_program_code'] = selectedEducationProgramSpecialityCode
            console.log(state.graduateWorkTheme_form.newGraduateWorkThemeInfos, "STATE")
        },
        SET_NEW_GRADUATE_WORK_THEME_STUDY_LEVEL_ID(state, selectedStudyLevelId) {
            state.graduateWorkTheme_form.newGraduateWorkThemeInfos['study_level_id'] = selectedStudyLevelId
            console.log(state.graduateWorkTheme_form.newGraduateWorkThemeInfos, "STATE")
        }
    },
    state: {
        graduateWorkTheme_form: {
            studyLevel: [],
            newGraduateWorkThemeInfos: {},
            graduateWorkThemeInfos: []
        }

    },
    getters: {

    }

}

export default graduateWorkTheme