import httpClient from "@/services/http.service"


const departmentPracticeService = {
  getPps() {
    return httpClient.get(`pps/pps-data/get-pps-by-department-id`)
  },
  getLanguages() {
    return httpClient.get(`api/language/get-main-languages`)
  },
  getEducationProgramsByLevelAndLanguage(studyLevel, studyLanguage) {
    return httpClient.get(`education_program/education-program/get-edu-prog-by-study-lev-and-lang?study_level=${studyLevel}&lang=${studyLanguage}`)
  },
  getPracticesByLevelAndLanguage(studyLevel, studyLanguage, edu_prog_id) {
    return httpClient.get(`education_program/education-discipline/get-practices-by-study-lev-and-lang?study_level=${studyLevel}&lang=${studyLanguage}&edu_prog_id=${edu_prog_id}`)
  },
  getStudentsPracticeByEducationProgram(educationProgramId, formationId) {
    return httpClient.get(`student_data/student-data/get-by-edu-prog-for-practice?education_program_id=${educationProgramId}&formation_id=${formationId}`)
  },
  postStudentPractices(form) {
    return httpClient.post(`student_data/student-practices/create`, form)
  },
}

export default departmentPracticeService