import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const attendanceReport = {
    namespaced: true,
    actions: {


        async GET_EDUCATION_COURSE_DATA_BY_ID({commit}, education_courses_id) {
            try {
                const url = 'education_program/education-courses/get-course-by-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + education_courses_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data.success, 'GET_EDUCATION_COURSE_DATA_BY_ID')
                    commit("SET_EDUCATION_COURSE_DATA_BY_ID", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        // async GET_STUDENT_GROUPS_BY_MDL_COURSE_ID({commit}, mdl_course_id) {
        //     try {
        //         const url = 'attendance/student-attendance/get-students-by-mdl-course-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&mdl_course_id=' + mdl_course_id
        //         const {
        //             status,
        //             data
        //         } = await httpClient.get(url);
        //         if (status === 200) {
        //             console.log(data, 'GET_STUDENT_GROUPS_BY_MDL_COURSE_ID')
        //             commit("SET_STUDENT_GROUPS_BY_MDL_COURSE_ID", data)
        //         }
        //     } catch (e) {
        //         console.log(e.response);
        //         return {
        //             success: false,
        //             error: e.response.data.errors
        //         }
        //     }
        // },

        async GET_STUDENTS_BY_MDL_COURSE_ID({commit}, mdl_course_id) {
            try {
                const url = 'student/student-ratings/get-students-by-mdl-course-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&mdl_course_id=' + mdl_course_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_STUDENTS_BY_COURSE_ID')
                    commit("SET_STUDENTS", data.students)
                    commit("SET_STUDENTS_GROUPS", data.groups)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_ATTENDANCE_TYPE({commit}) {
            try {
                const url = 'attendance/student-attendance/get-attendance-type' + '?access-token=' + getCookie('ACCESS_TOKEN')
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_ATTENDANCE_TYPE')
                    commit("SET_ATTENDANCE_TYPE", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_ATTENDANCE_REPORT({state}) {

            let attendanceReportForm = state.attendanceReport_form.attendanceReport
            try {
                const {
                    status,
                    data
                } = await httpClient.post('attendance/student-attendance/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), attendanceReportForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async GET_ATTENDANCE_REPORT_DATA({state, commit}, {mdl_course_id, day}) {
            day=day||0
            console.log(day, 'GET_ATTENDANCE_REPORT_DATA')
            try {
                const url = 'attendance/student-attendance/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&mdl_course_id=' + mdl_course_id + '&day=' + day
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_ATTENDANCE_REPORT_DATA')
                    commit("SET_ATTENDANCE_REPORT_DATA", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async PUT_ATTENDANCE_REPORT({commit, state}) {

            let updateAttendanceReportForm = state.attendanceReport_form.updateAttendanceReport
            console.log(updateAttendanceReportForm, 'updateAttendanceReportForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.put('attendance/student-attendance/update' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + state.attendanceReport_form.students_attendance_id, updateAttendanceReportForm);
                if (status === 200) {
                    console.log(data, 'update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }

        },




    },
    mutations: {

        SET_EDUCATION_COURSE_DATA_BY_ID(state, courseInfos) {
            state.attendanceReport_form.courseInfos = courseInfos
            console.log(state.attendanceReport_form.courseInfos, "courseInfos")
        },
        SET_STUDENTS_GROUPS(state, studentGroups) {
            state.attendanceReport_form.studentGroups = studentGroups
            console.log(state.attendanceReport_form.studentGroups, "studentGroups")
        },
        SET_STUDENTS(state, students) {
            state.attendanceReport_form.students = students
            console.log(state.attendanceReport_form.students, "students")
        },
        SET_ATTENDANCE_TYPE(state, attendanceType) {
            state.attendanceReport_form.attendanceType = attendanceType
            console.log(state.attendanceReport_form.attendanceType, "attendanceType")
        },
        SET_ATTENDANCE_REPORT(state, studentDatas) {
            let attendanceReport = []
            for (let i = 0; i < studentDatas.length; i++){
                // attendanceReport['student_id'] = studentDatas[i].id
                // attendanceReport['attendance_type_id'] = 1
                attendanceReport.push({
                    student_id: studentDatas[i].id,
                    attendance_type_id: 1,
                    mdl_course_id: state.attendanceReport_form.mdl_course_id
                })
            }
            state.attendanceReport_form.attendanceReport = attendanceReport
            console.log(state.attendanceReport_form.attendanceReport, "attendanceReport")
        },
        UPDATE_ATTENDANCE_REPORT(state, value){
            let student_id = value.student_id
            let attendance_type_id = value.attendance_type_id

            let updateAttendanceReport = state.attendanceReport_form.attendanceReport.find(i => i.student_id == student_id)
            updateAttendanceReport['attendance_type_id'] = attendance_type_id

            console.log(state.attendanceReport_form.updateAttendanceReport, "updateAttendanceReport")

        },
        SET_MDL_COURSE_ID(state, mdl_course_id) {
            state.attendanceReport_form.mdl_course_id = mdl_course_id
        },
        SET_ATTENDANCE_REPORT_DATA(state, attendanceReportData) {
            state.attendanceReport_form.attendanceReportData = attendanceReportData
        },
        UPDATE_ATTENDANCE_TYPE(state, value) {
            let students_attendance_id = value.students_attendance_id
            let attendance_type_id = value.attendance_type_id

            state.attendanceReport_form.students_attendance_id = students_attendance_id
            state.attendanceReport_form.updateAttendanceReport['attendance_type_id'] = attendance_type_id
            // state.attendanceReport_form.updateAttendanceReport.push({
            //     attendance_type_id: attendance_type_id
            // })
        },
        SET_ATTENDANCE_REPORT_DAY(state, day) {
            state.attendanceReport_form.attendanceReportDay = parseInt((new Date(day).getTime() / 1000).toFixed(0))
            console.log(state.attendanceReport_form.attendanceReportDay, "attendanceReportDay")
        }

    },
    state: {
        attendanceReport_form: {
            courseInfos: [],
            studentGroups: [],
            students: [],
            attendanceType: {},
            attendanceReport: [],
            mdl_course_id: null,
            attendanceReportData: [],
            updateAttendanceReport: {},
            attendanceReportDay: null
        }

    },
    getters: {}

}

export default attendanceReport