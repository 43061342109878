import academicDebtServiceApi from "@/services/academic-debt/academicDebtService.service"

const academicDebtService = {
  namespaced: true,
  actions: {
    async GET_PPS({commit}, all = 0) {
      try {
        const {data} = await academicDebtServiceApi.getPpsByDepartmentId(all)
        if (data) {
          commit('SET_PPS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_APPLICATIONS_ACADEMIC_DEBT({commit}) {
      try {
        const {data} = await academicDebtServiceApi.getApplicationsAcademicDebt();
        if (data) {
          commit('SET_APPLICATIONS_ACADEMIC_DEBT', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async PUT_APPLICATION_STATUS({state, commit}, formData) {
      try {
        const {data} = await academicDebtServiceApi.putApplicationStatus(formData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_CONFIRMED_APPLICATIONS({commit}) {
      try {
        const {data} = await academicDebtServiceApi.getConfirmedApplications();
        if (data) {
          commit('SET_CONFIRMED_APPLICATIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_STUDENTS_BY_DESCIPLINE_ID({commit}, discipline_id) {
      try {
        const {data} = await academicDebtServiceApi.getStudentsByDisciplineId(discipline_id);
        if (data) {
          commit('SET_STUDENTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_SUMMER_COURSE({state, commit}, postData) {
      try {
        const {data} = await academicDebtServiceApi.postSummerCourse(postData);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_SUMMER_COURSES({commit}, queryParam) {
      try {
        const {data} = await academicDebtServiceApi.getSummerCourses(queryParam);
        if (data) {
          commit('SET_SUMMER_COURSES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_MDL_SUMMER_COURSE({state, commit}, postData) {
      try {
        const {data} = await academicDebtServiceApi.postMdlSummerCourse(postData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async UNITE_COURSES({state, commit}, postData) {
      try {
        const {status} = await academicDebtServiceApi.uniteCourses(postData)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_PPS(state, pps) {
      state.academicDebtService.pps = pps
    },
    CHANGE_PPS(state, {discipline_id, pps_id}) {
      const index = this.state.confirmedApplications.findIndex(i => i.education_discipline_id == discipline_id)
      this.state.confirmedApplications[index]['pps_id'] = pps_id
      console.log(this.state.confirmedApplications)
    },
    SET_APPLICATIONS_ACADEMIC_DEBT(state, applications) {
      state.academicDebtService.applications = applications
    },
    SET_CONFIRMED_APPLICATIONS(state, confirmedApplications) {
      state.academicDebtService.confirmedApplications = confirmedApplications
    },
    SET_STUDENTS(state, students) {
      state.academicDebtService.students = students
    },
    SET_SUMMER_COURSES(state, courses) {
      state.academicDebtService.courses = courses
    }
  },
  state: {
    academicDebtService: {
      pps: [],
      applications: [],
      confirmedApplications: [],
      students: [],
      courses: []
    }
  },
  getters: {}
}

export default academicDebtService