import httpClient from "@/services/http.service"
import router from "@/router"


const docOrdersStudentsService = {
  getStudyLevels() {
    return httpClient.get(`education_program/study-level/index`)
  },
  getForeignUniversity() {
    return httpClient.get(`academic_mobility/academic-mobility/get-foreign-university`)
  },
  getEducationPrograms(study_level_id, study_course) {
    return httpClient.get(`education_program/education-program/get-education-program-names` + '?study_level_id=' + study_level_id + '&study_course=' + study_course)
  },
  getStudentsByEducationProgramId(education_program_id) {
    return httpClient.get(`student_data/student-data/get-students-by-education-program-id?education_program_id=${education_program_id}`)
  },
  getStudentGroupsByName(name) {
    return httpClient.get(`student_groups/student-groups/get-student-groups-by-name?name=${name}`)
  },
  getEducationProgramsByName(name) {
    return httpClient.get(`education_program/education-program/get-programs-by-name?name=${name}`)
  },
  postDocOrderStudents(form) {
    return httpClient.post(`doc_orders/doc-order-students/create`, form)
  },
  getSectionStudents(sections_id){
    return httpClient.get(`doc_orders/doc-order-students/get-section-students?sections_id=` + sections_id)
  },
  createStipend(data){
    return httpClient.post(`doc_orders/doc-order-students/create-stipend`, data)
  },
  createAcademicMobility(data){
    return httpClient.post(`doc_orders/doc-order-students/create-foreign`, data)
  },
  createTransfer(data){
    return httpClient.post(`doc_orders/doc-order-students/create-transfer`, data)
  },
}

export default docOrdersStudentsService