import teacherEvaluationService from "@/services/student/teacherEvaluation.service"
import axios from 'axios'

const teacherEvaluation = {
    namespaced: true,
    actions: {


        async GET_EDUCATION_COURSE_DATA_BY_ID({commit}, education_courses_id) {
            try {
                const {data} = await teacherEvaluationService.getEducationCourseDataById(education_courses_id);
                if (data) {
                    console.log(data.success, 'GET_EDUCATION_COURSE_DATA_BY_ID')
                    commit('SET_EDUCATION_COURSE_DATA_BY_ID', data.success)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_STUDENT_ATTESTATION({commit}) {
            try {
                const {data} = await teacherEvaluationService.getStudentAttestation();
                if (data) {
                    console.log(data, 'GET_STUDENT_ATTESTATION')
                    commit('SET_STUDENT_ATTESTATION', data.success)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_TEACHER_EVALUATION_QUESTIONS({commit}) {
            try {
                const {data} = await teacherEvaluationService.getTeacherEvaluationQuestions();
                if (data) {
                    console.log(data, 'GET_TEACHER_EVALUATION_QUESTIONS')
                    commit('SET_TEACHER_EVALUATION_QUESTIONS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_TEACHER_EVALUATE_STUDENT_ANSWERS({commit}, {pps_id, course_id}) {
            try {
                const {data} = await teacherEvaluationService.getTeacherEvaluateStudentAnswers(pps_id, course_id);
                if (data) {
                    console.log(data, 'GET_TEACHER_EVALUATE_STUDENT_ANSWERS')
                    commit('SET_TEACHER_EVALUATE_STUDENT_ANSWERS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_TEACHER_EVALUATE_STUDENT_ANSWERS({state, commit}, teacherEvaluationData) {
            let questionsCount = 0;
            for (const key in state.teacherEvaluation_form.teacherEvaluationQuestions) {
                questionsCount += state.teacherEvaluation_form.teacherEvaluationQuestions[key].length;
            }
            if (teacherEvaluationData.length == questionsCount) {

                let formData = new FormData();
                console.log(teacherEvaluationData, 'POST teacherEvaluationData')


                // formData.append("file_url", teacherEvaluationData["file"]);
                // formData.append("answer_id", teacherEvaluationData["answer_id"]);
                // formData.append("pps_id", teacherEvaluationData["pps_id"]);
                // formData.append("education_course_id", teacherEvaluationData["education_course_id"]);

                console.log(teacherEvaluationData.length, 'post teacherEvaluationData length')

                for (let i = 0; i < teacherEvaluationData.length; i++) {

                    // let file = null
                    // let name = ''
                    // if (state.teacherEvaluation_form.teacherEvaluationFileInfo[i].answer_id==50) {
                    //     file = state.teacherEvaluation_form.teacherEvaluationFileInfo.find(i=>i.answer_id == 50).file
                    //     name = "TeacherEvaluateStudentAnswers[" + i + "][file]";
                    //     formData.append(name, file);
                    // }
                    //
                    // if (state.teacherEvaluation_form.teacherEvaluationFileInfo[i].answer_id==53) {
                    //     file = state.teacherEvaluation_form.teacherEvaluationFileInfo.find(i=>i.answer_id == 53).file
                    //     name = "TeacherEvaluateStudentAnswers[" + i + "][file]";
                    //     formData.append(name, file);
                    // }


                    let file = teacherEvaluationData[i].file;
                    let name = "TeacherEvaluateStudentAnswers[" + i + "][file]";


                    let answer_id = teacherEvaluationData[i].answer_id;
                    let answer_id_name = "TeacherEvaluateStudentAnswers[" + i + "][answer_id]";
                    let pps_id = teacherEvaluationData[i].pps_id;
                    let pps_id_name = "TeacherEvaluateStudentAnswers[" + i + "][pps_id]";
                    let education_course_id = teacherEvaluationData[i].education_course_id;
                    let education_course_id_name = "TeacherEvaluateStudentAnswers[" + i + "][education_course_id]";

                    if (file && name) {
                        formData.append(name, file);
                    }

                    formData.append(answer_id_name, answer_id);
                    formData.append(pps_id_name, pps_id);
                    formData.append(education_course_id_name, education_course_id);




                }





                console.log(Object.fromEntries(formData), 'hello from formdata')

                try {
                    const {data} = await teacherEvaluationService.postTeacherEvaluateStudentAnswers(formData);
                    if (data) {
                        return true
                    }
                } catch (e) {
                    commit('SET_ERROR', e, {root: true})
                }
                return false
            }
            else {
                let error = {
                    response: {
                        data: {
                            errors: 'Ответьте на все вопросы!'
                        }
                    },
                    message: ''
                }
                commit('SET_ERROR', error, {root: true})
            }




        },

    },
    mutations: {
        SET_EDUCATION_COURSE_DATA_BY_ID(state, courseInfos) {
            state.teacherEvaluation_form.courseInfos = courseInfos
            console.log(state.teacherEvaluation_form.courseInfos, "courseInfos")
        },
        SET_STUDENT_ATTESTATION(state, attestation) {
            state.teacherEvaluation_form.attestation = attestation
        },
        SET_TEACHER_EVALUATION_QUESTIONS(state, teacherEvaluationQuestions) {
            state.teacherEvaluation_form.teacherEvaluationQuestions = teacherEvaluationQuestions
        },
        SET_TEACHER_EVALUATE_STUDENT_ANSWERS(state, teacherEvaluateStudentAnswers) {
            state.teacherEvaluation_form.teacherEvaluateStudentAnswers = teacherEvaluateStudentAnswers
        },
        SET_TEACHER_EVALUATION_FILE(state, teacherEvaluationFileData) {

            if (state.teacherEvaluation_form.teacherEvaluationFileInfo.find(i=>i.answer_id == teacherEvaluationFileData.answer_id)) {
                state.teacherEvaluation_form.teacherEvaluationFileInfo['file'] = teacherEvaluationFileData.file
                state.teacherEvaluation_form.teacherEvaluationFileInfo['answer_id'] = teacherEvaluationFileData.answer_id
            }
            else {
                state.teacherEvaluation_form.teacherEvaluationFileInfo.push({
                    file: teacherEvaluationFileData.file,
                    answer_id: teacherEvaluationFileData.answer_id,
                })
            }

            console.log(state.teacherEvaluation_form.teacherEvaluationFileInfo, 'teacherEvaluationFileInfo')
        }

    },
    state: {
        teacherEvaluation_form: {
            courseInfos: [],
            attestation: [],
            teacherEvaluationQuestions: [],
            teacherEvaluateStudentAnswers: [],
            teacherEvaluationFileInfo: []
        }
    },
    getters: {

    }
}

export default teacherEvaluation