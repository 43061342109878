import minorFormationEducationProgramService from "@/services/education-programme/minorFormationEducationProgram.service"

const minorFormationEducationProgram = {
    namespaced: true,
    actions: {

        async GET_EDUCATION_PROGRAM_MINORS({commit}, minorEducationProgramId) {
            try {
                const {status, data} = await minorFormationEducationProgramService.getEducationProgramMinors(minorEducationProgramId)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_MINORS', data)
                    commit('SET_EDUCATION_PROGRAM_MINORS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_MINOR_FORMATION_EDUCATION_PROGRAM({commit}, minorEducationProgramId) {
            try {
                const {status, data} = await minorFormationEducationProgramService.getMinorFormationEducationProgram(minorEducationProgramId)
                if (status === 200) {
                    console.log('GET_MINOR_FORMATION_EDUCATION_PROGRAM', data)
                    commit('SET_MINOR_FORMATION_EDUCATION_PROGRAM', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_PROGRAMS_FOR_MINOR({commit}, minorEducationProgramId) {
            try {
                const {status, data} = await minorFormationEducationProgramService.getEducationProgramsForMinor(minorEducationProgramId)
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAMS_FOR_MINOR', data)
                    commit('SET_EDUCATION_PROGRAMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_VOK_DISCIPLINES({commit}, educationProgramId) {
            try {
                const {status, data} = await minorFormationEducationProgramService.getVokDisciplines(educationProgramId)
                if (status === 200) {
                    console.log('GET_VOK_DISCIPLINES', data)
                    commit('SET_DISCIPLINES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async POST_MINOR_FORMATION_EDUCATION_PROGRAM({commit, state}, form) {
            //let form = state.educationCalendar_form.educationCalendar.filter(i=>!i.id)
            console.log(form, 'post form')
            try {
                await minorFormationEducationProgramService.postMinorFormationEducationProgram(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async DELETE_MINOR_FORMATION({commit}, deleteId) {
            try {
                const {status, data} = await minorFormationEducationProgramService.deleteMinorFormation(deleteId)
                if (status === 200) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})

            }
            return false
        },



    },
    mutations: {
        SET_EDUCATION_PROGRAM_MINORS(state, educationProgramMinors) {
            state.minorFormationEducationProgram_form.educationProgramMinors = educationProgramMinors
        },
        SET_MINOR_FORMATION_EDUCATION_PROGRAM(state, minorFormationEducationProgram) {
            state.minorFormationEducationProgram_form.minorFormationEducationProgram = minorFormationEducationProgram
        },
        SET_DISCIPLINES(state, disciplines) {
            state.minorFormationEducationProgram_form.disciplines = disciplines
        },
        SET_EDUCATION_PROGRAMS(state, educationPrograms) {
            state.minorFormationEducationProgram_form.educationPrograms = educationPrograms
        },
        CLEAR_DISCIPLINES(state) {
            state.minorFormationEducationProgram_form.disciplines = []
        }
    },
    state: {
        minorFormationEducationProgram_form: {
            disciplines: [],
            educationPrograms: [],
            minorFormationEducationProgram: [],
            educationProgramMinors: []

        }
    },
    getters: {}
}

export default minorFormationEducationProgram