import httpClient from "@/services/http.service"

const bigFiveService = {
  getBigFiveQuestions() {
    return httpClient.get(`sei/big-five-questions/index`)
  },
  getBigFiveScore(){
    return httpClient.get('sei/big-five-score/index')
  },
  postBigFiveAnswers(answers){
    return httpClient.post('sei/big-five-answers/create', answers)
  },
  getBigFiveResults(){
    return httpClient.get(`sei/big-five-answers/get-big-five-result`)
  },
}

export default bigFiveService