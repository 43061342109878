import officeRegistrationDifferenceStudentRequestService
    from "@/services/office-registration/officeRegistrationDifferenceStudentRequest.service"


const officeRegistrationDifferenceStudentRequest = {
    namespaced: true,
    actions: {
        async GET_STUDENT_DIFFERENCE_REQUEST({commit}, studentId) {
            try {
                const {data} = await officeRegistrationDifferenceStudentRequestService.getStudentDifferenceRequest()
                console.log('GET_STUDENT_DIFFERENCE_REQUEST', data)
                commit('SET_REQUESTS', data)
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async PUT_STUDENT_DIFFERENCE_REQUEST_STATUS({commit}, form) {
            try {
                await officeRegistrationDifferenceStudentRequestService.putStudentDifferenceRequestStatus(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_REQUESTS(state, requests) {
            state.officeRegistrationDifferenceStudentRequest_form.requests = requests
        },
    },
    state: {
        officeRegistrationDifferenceStudentRequest_form: {
            requests: []
        }
    },
    getters: {}
}

export default officeRegistrationDifferenceStudentRequest