import httpClient from "@/services/http.service"
import router from "@/router"
import {getCookie} from "@/utils/helpers/cookies.helpers";


const teacherEvaluationService = {
    getEducationCourseDataById(education_courses_id) {
        return httpClient.get(`education_program/education-courses/get-course-by-id?id=` + education_courses_id)
    },
    getStudentAttestation() {
        return httpClient.get(`student/attestation/get-current-attestation` + '?barcode=' + getCookie('USERNAME'))
    },
    getTeacherEvaluationQuestions() {
        return httpClient.get(`teacher_evaluation/teacher-evaluation-questions/index`)
    },
    postTeacherEvaluateStudentAnswers(formData) {
        return httpClient.post(`teacher_evaluation/teacher-evaluation-student-questions/create`, formData)
    },
    getTeacherEvaluateStudentAnswers(pps_id, course_id) {
        return httpClient.get(`teacher_evaluation/teacher-evaluation-student-questions/index` + '?pps_id=' + pps_id + '&course_id='+course_id)
    },
}

export default teacherEvaluationService