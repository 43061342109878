import httpClient from "@/services/http.service"
import router from "@/router"


const graduateWorkCalendarService = {
    getStudyLevels() {
        return httpClient.get(`education_program/study-level/index`)
    },
    getGraduateWorkCalendar(study_level_id) {
        return httpClient.get(`graduate/graduate-work-calendar/get-graduate-work-calendar-by-study-level-id` + '?study_level_id=' + study_level_id)
    },
    putGraduateWorkCalendar(study_level_id, form) {
        return httpClient.put(`graduate/graduate-work-calendar/update?study_level_id=${study_level_id}`, form)
    },
}

export default graduateWorkCalendarService
