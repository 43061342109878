import httpClient from "@/services/http.service"

const educationCalendarService = {
    getEducationCalendar() {
        return httpClient.get(`academic_calendar/education-calendar/get-education-calendar`)
    },
    postEducationCalendar(form) {
        return httpClient.post(`academic_calendar/education-calendar/create`, form)
    },
    putEducationCalendar(form) {
        return httpClient.put(`academic_calendar/education-calendar/update`, form)
    },
    deleteEducationCalendar(deleteId) {
        return httpClient.delete(`academic_calendar/education-calendar/delete` + '?id='+ deleteId)
    },

}

export default educationCalendarService