import httpClient from "@/services/http.service"
import router from "@/router"


const graduateWorkTempService = {
    getStudyLevels() {
        return httpClient.get(`education_program/study-level/index`)
    },
    getGraduateWorkThemes(id) {
        return httpClient.get(`graduate/graduate-work-theme/index?is_comlex_exam=` + id)
    },
    getSelectionSupervisorsPpsByDepartmentId() {
        return httpClient.get(`graduate/selection-supervisors/get-selection-supervisors-pps-by-department-id`)
    },
    getStudentsByEducationProgramCode(education_speciality_code) {
        return httpClient.get(`student_data/student-data/get-students-by-education-program-code` + '?education_speciality_code=' + education_speciality_code)
    },
    getStudentsWithStudyCourse(education_speciality_code) {
        return httpClient.get(`graduate/graduate-work-theme/get-students-by-education-program-id` + '?education_program_id=' + education_speciality_code)
    },
    postGraduateWorkThemes(formData) {
        return httpClient.post(`graduate/graduate-work-theme/create`, formData)
    },
    postSelectionTheme(formData) {
        return httpClient.post(`graduate/graduate-work-theme/select-theme`, formData)
    },
    postSelectionThemeExam(formData) {
        return httpClient.post(`graduate/graduate-work-theme/select-theme-exam`, formData)
    },
    putSelectionTheme(formData, id) {
        return httpClient.put(`graduate/selection-theme/update?id=` + id, formData)
    },
    deleteSelectionTheme(id) {
        return httpClient.delete(`graduate/selection-theme/delete?id=` + id)
    },
    deleteGraduateWorkTheme(id) {
        return httpClient.delete(`graduate/graduate-work-theme/delete?id=` + id)
    },
    getEducationProgramsByNameGraduateWork(name, study_level_id) {
        return httpClient.get(`education_program/education-program/get-programs-by-name-graduate-work` + '?name=' + name + '&study_level_id=' + study_level_id)
    },
}

export default graduateWorkTempService
