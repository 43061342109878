import chooseLanguageLevelService from "@/services/language-center/chooseLanguageLevel.service"

const chooseLanguageLevel = {
    namespaced: true,
    actions: {



        async GET_ADMISSION_TEST_STUDENTS({commit}) {
            try {
                const {status, data} = await chooseLanguageLevelService.getAdmissionTestStudents()
                if (status === 200) {
                    console.log('GET_ADMISSION_TEST_STUDENTS', data)
                    commit('SET_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async POST_ADMISSION_TEST_RESULT({commit}, form) {
            console.log('post form', form)
            try {
                await chooseLanguageLevelService.postAdmissionTestResult(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_UPDATE_ADMISSION_TEST_RESULT({commit}, form) {
            console.log('put form', form)
            try {
                await chooseLanguageLevelService.postUpdateAdmissionTestResult(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },




    },
    mutations: {
        SET_STUDENTS(state, students) {
            state.chooseLanguageLevel_form.students = students
        },
    },
    state: {
        chooseLanguageLevel_form: {
            students: []
        }
    },
    getters: {}
}

export default chooseLanguageLevel