import departmentShowSyllabusService from "@/services/department/departmentShowSyllabus.service";

const departmentShowSyllabus = {
    namespaced: true,
    actions: {
        async GET_DEPARTMENT_DISCIPLINES({commit}) {
            try {
                const {status, data} = await departmentShowSyllabusService.getDepartmentDisciplines()
                if (status == 200) {
                    console.log('GET_DEPARTMENT_DISCIPLINES', data)
                    commit('SET_DEPARTMENT_DISCIPLINES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_DEPARTMENT_DISCIPLINES(state, data) {
            state.data.disciplines = data
        },
    },
    state: {
        data:{
            disciplines: []
        }
    },
}

export default departmentShowSyllabus