import graduateWorkService from "@/services/graduate-work/graduateWork.service"

const graduateWork = {
  namespaced: true,
  actions: {
    async GET_GRADUATE_WORK_TOPIC({commit}) {
      try {
        const {data} = await graduateWorkService.getGraduateWorkTopic();
        if (data) {
          commit('SET_GRADUATE_WORK_TOPIC', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_GRADUATE_WORK_TOPICS({commit}) {
      try {
        const {data} = await graduateWorkService.getGraduateWorkTopics();
        commit('SET_GRADUATE_WORK_TOPICS', data)
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_PPS({commit}) {
      try {
        const {data} = await graduateWorkService.getPpsByDepartmentId();
        commit('SET_PPS', data)
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_GRADUATE_WORK_TOPIC({commit}, formData) {
      try {
        const {data} = await graduateWorkService.postGraduateWorkTopic(formData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_GRADUATE_WORK_TOPIC(state, topic) {
      state.graduateWork.topic = topic
    },
    SET_GRADUATE_WORK_TOPICS(state, topics) {
      state.graduateWork.topics = topics
    },
    SET_PPS(state, pps) {
      state.graduateWork.pps = pps
    }
  },
  state: {
    graduateWork: {
      topic: null,
      topics: [],
      pps: []
    }
  },
  getters: {}
}

export default graduateWork