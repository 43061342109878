import httpClient from "@/services/http.service"
import router from "@/router"
import {generateURL} from "@/utils/helpers/queryParams.helper";


const docOrdersService = {
    getSpAcademicYear() {
        return httpClient.get(`education_program/education-curriculum/get-academic-year`)
    },
    getSpDocOrderType() {
        return httpClient.get(`doc_orders/doc-orders/get-doc-order-types`)
    },
    getSpDocOrderSubType() {
        return httpClient.get(`doc_orders/doc-orders/get-doc-order-sub-types`)
    },
    getDocOrders(doc_order_id) {
        return httpClient.get(`doc_orders/doc-orders/index` + '?id='+ doc_order_id)
    },
    deleteDocOrders(id) {
        return httpClient.delete(`doc_orders/doc-orders/delete` + '?id='+ id)
    },
    postDocOrders(data) {
        return httpClient.post(`doc_orders/doc-orders/create`, data)
    },
    postDocOrdersStudents(queryParams) {
        return httpClient.post(`doc_orders/doc-orders/create-doc-order-students/`, queryParams)
    },
    putDocOrders(data, id) {
        console.log(data, 'put data', id, 'put id')
        return httpClient.put(`doc_orders/doc-orders/update?id=`+id, data)
    },
    getAcademicLeaveById(doc_order_id){
        return httpClient.get(`doc_orders/doc-order-students/get-academic-leave?order_id=` + doc_order_id)
    },
    createAcademicLeave(academic){
        return httpClient.post(`doc_orders/doc-order-students/create-academic`, academic)
    },
    createAcademicExtend(academic){
        return httpClient.post(`doc_orders/doc-order-students/create-academic-extend`, academic)
    },
    getDocOrdersInfo(doc_order_id){
        return httpClient.post(`doc_orders/doc-orders/get-doc-order-students?order_id=` + doc_order_id)
    },
    getSections(doc_order_id){
        return httpClient.get(`doc_orders/sections/index?order_id=` + doc_order_id)
    },
    createSection(data){
        return httpClient.post(`doc_orders/sections/create`, data)
    },
}

export default docOrdersService