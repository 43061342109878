import httpClient from "@/services/http.service"


const adviserService = {
  getStudentsByAdviser() {
    return httpClient.get(`adviser_choose/adviser-choose/index`)
  },
  getDisciplineSelectionStatistics(group_id) {
    return httpClient.get(`education_program/education-program/get-disciplines-for-advisers?group_id=${group_id}`)
  },
  getStudentsByEducationPrograms(educationPrograms) {
    return httpClient.post(`student_data/student-data/get-students-by-education-programs`, {ids: educationPrograms})
  },
  postAdviserStudent(form) {
    return httpClient.post(`adviser_choose/adviser-student/create`, form)
  },
  getAdviserStudent() {
    return httpClient.get(`adviser_choose/adviser-student/get-students-by-pps-id`)
  }
}

export default adviserService