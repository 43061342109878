import 'bootstrap/dist/css/bootstrap.min.css';
// import 'vue3-select/dist/vue-select.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';

import {createApp} from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import PrimeIcons from 'primevue/api';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Menubar from 'primevue/menubar';
import Calendar from 'primevue/calendar';
import PanelMenu from 'primevue/panelmenu';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea';
import AutoComplete from 'primevue/autocomplete';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import Column from 'primevue/column';
import Row from 'primevue/row';
import Dropdown from 'primevue/dropdown';
import ColumnGroup from 'primevue/columngroup'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import MultiSelect from 'primevue/multiselect';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import RadioButton from 'primevue/radiobutton';
import ConfirmPopup from 'primevue/confirmpopup';


import FileUpload from 'primevue/fileupload';
import Rating from 'primevue/rating';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';
import Tooltip from 'primevue/tooltip';
import Paginator from 'primevue/paginator';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import Knob from 'primevue/knob';
import Slider from 'primevue/slider';
import OverlayPanel from 'primevue/overlaypanel';


import FlashMessage from '@smartweb/vue-flash-message'
import VueCountdown from '@chenfengyuan/vue-countdown';
import messagePlugin from './utils/message.plugin'

import Preloader from '@/components/common/Preloader.vue'
import PrimePreloader from '@/components/common/PrimePreloader.vue'


// import vSelect from "vue3-select";

import YouTube from 'vue3-youtube'

import AvatarCropper from 'vue-avatar-cropper'

// or

// const app = createApp({ /* your root component */ })

// Install the store instance as a plugin
//app.use(router)
//app.use(store)
//app.mount('#app')
// createApp(App).use(router, store).mount('#app')

const app = createApp(App)
app.use(ConfirmationService);
app.use(ToastService);
app.component('Menubar', Menubar)
app.component('Textarea', Textarea)
app.component('Calendar', Calendar)
app.component('PanelMenu', PanelMenu)
app.component('Sidebar', Sidebar)
app.component('DataTable', DataTable)
app.component('InputText', InputText)
app.component('AutoComplete', AutoComplete)
app.component('Dialog', Dialog)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Toast', Toast)
app.component('Button', Button)
app.component('Card', Card)
app.component('Column', Column)
app.component('Row', Row)
app.component('ColumnGroup', ColumnGroup)
app.component('Dropdown', Dropdown)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('MultiSelect', MultiSelect)
app.component('Preloader', Preloader)
// app.component('v-select', vSelect)
app.component('InputNumber', InputNumber)
app.component('Checkbox', Checkbox)
app.component('SelectButton', SelectButton)
app.component('RadioButton', RadioButton)
app.component('ConfirmPopup', ConfirmPopup)

app.component('FileUpload', FileUpload)
app.component('Rating', Rating)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Chart', Chart)
app.component('Paginator', Paginator)
app.component('Knob', Knob)
app.component('Slider', Slider)
app.component('OverlayPanel', OverlayPanel)

app.component('ProgressBar', ProgressBar)


app.component('ProgressSpinner', ProgressSpinner)
app.component('PrimePreloader', PrimePreloader)
app.component('AvatarCropper', AvatarCropper)


app.directive('tooltip', Tooltip);


app.use(PrimeVue, {
  locale: {
    startsWith: 'Начинается с',
    contains: 'Содержит',
    notContains: 'Не содержит',
    endsWith: 'Заканчивается на',
    equals: 'Равно',
    notEquals: 'Не равно',
    noFilter: 'Нет фильтра',
    lt: 'Меньше чем',
    lte: 'Меньше чем или равно',
    gt: 'Больше чем',
    gte: 'Больше чем или равно',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Очистить',
    apply: 'Выбрать',
    matchAll: 'Соответствует всему',
    matchAny: 'Соответствует любому',
    addRule: 'Добавить правило',
    removeRule: 'Убрать правило',
    accept: 'Да',
    reject: 'Нет',
    choose: 'Выбрать',
    upload: 'Загрузить',
    cancel: 'Отклонить',
    dayNames: ["Суббота", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Воскресенье"],
    dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthNamesShort: ["янв.", "февр.", "март", "апр.", "май", "июнь", "июль", "авг.", "сент.", "окт.", "нояб.", "дек."],
    today: 'Сегодня',
    weekHeader: 'Неделя',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
    weak: 'Слабый',
    medium: 'Средний',
    strong: 'Сильный',
    passwordPrompt: 'Введите пароль',
    emptyFilterMessage: 'Не найдено результатов',
    emptyMessage: 'No available options'
  }
});


app.use(PrimeIcons);
app.use(FlashMessage)
app.use(messagePlugin)
app.component(VueCountdown.name, VueCountdown);
app.component('YouTube', YouTube)
app.use(router)
app.use(store)


app.mount('#app')
