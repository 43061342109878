import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";
const useraddress = {
    namespaced: true,
    actions: {
        async POST_USER_ADDRESS_DATA() {
            let userAddressForm = useraddress.state.form
            if (useraddress.state.form.put_status == 0) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/user-address/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), userAddressForm);
                    if (status === 200) {
                        console.log('addressSended')
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            }
            else {
                return 0
            }
        },
        async PUT_USER_ADDRESS_DATA({commit}) {
            let userAddressForm = useraddress.state.form
            if (useraddress.state.form.put_status == 1) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/user-address/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), userAddressForm);
                    if (status === 200) {
                        console.log('good')
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        error: e.response.data.errors
                    }
                }
            }
            else {
                return 0
            }
        },
        async getCity({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/user-address/city'+'?access-token='+getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateCity', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async getRegion({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/user-address/region'+'?access-token='+getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateRegion', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async getCountry({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/user-address/country'+'?access-token='+getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateCountry', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        }
    },
    mutations: {
        updateAddressForm(state, userAddressData) {
            state.form.residence_city = userAddressData.residence_city
            state.form.residence_street = userAddressData.residence_street
            state.form.residence_apartment = userAddressData.residence_apartment
            state.form.residence_home = userAddressData.residence_home
            state.form.inhabitation_street = userAddressData.inhabitation_street
            state.form.inhabitation_city = userAddressData.inhabitation_city
            state.form.inhabitation_apartment = userAddressData.inhabitation_apartment
            state.form.inhabitation_home = userAddressData.inhabitation_home
            state.form.put_status = userAddressData.put_status
        },
        updateCity(state, cities) {
            state.cities = cities
        },
        updateEqualCheckbox(state, equalcheckbox) {
            state.equalcheckbox = equalcheckbox
                if(state.equalcheckbox===true){
                    state.form.inhabitation_street = state.form.residence_street
                    state.form.inhabitation_city = state.form.residence_city
                    state.form.inhabitation_apartment = state.form.residence_apartment
                    state.form.inhabitation_home=state.form.residence_home
                }

        },
        updateRegion(state, regions) {
            state.regions = regions
        },
        updateCountry(state, countries) {
            state.countries = countries
        },
        updateResidenceCity(state, residence_city) {
            state.form.residence_city = residence_city
            if(state.equalcheckbox===true){
                state.form.inhabitation_city = state.form.residence_city
            }
        },
        updateResidenceStreet(state, residence_street) {
            state.form.residence_street = residence_street
            if(state.equalcheckbox===true){
                state.form.inhabitation_street = state.form.residence_street
            }
        },
        updateResidenceApartment(state, residence_apartment) {
            state.form.residence_apartment = residence_apartment
            if(state.equalcheckbox===true){
                state.form.inhabitation_apartment = state.form.residence_apartment
            }
        },
        updateResidenceHome(state, residence_home) {
            state.form.residence_home = residence_home
            if(state.equalcheckbox===true){
                state.form.inhabitation_home = state.form.residence_home
            }
        },
        updateInhabitionCity(state, inhabitation_city) {
            state.form.inhabitation_city = inhabitation_city
        },
        updateInhabitionStreet(state, inhabitation_street) {
            state.form.inhabitation_street = inhabitation_street
        },
        updateInhabitionApartment(state, inhabitation_apartment) {
            state.form.inhabitation_apartment = inhabitation_apartment
        },
        updateInhabitionHome(state, inhabitation_home) {
            state.form.inhabitation_home = inhabitation_home
        },
    },
    state: {
        form: {
            residence_city:1,
            residence_street: '',
            residence_apartment:'',
            residence_home:'',
            inhabitation_city:1,
            inhabitation_street: '',
            inhabitation_apartment:'',
            inhabitation_home:'',
            put_status: 0
        },
        cities: [],
        regions: [],
        countries: [],
        equalcheckbox:false,
    },
    getters: {
        allCities(state) {
            return state.cities
        },
        allRegions(state) {
            return state.regions
        },
        allCountries(state) {
            return state.countries
        }
    }
}

export default useraddress