import httpClient from "@/services/http.service"

const minorFormationEducationProgramService = {

    getEducationProgramMinors(minorEducationProgramId) {
        return httpClient.get(`education_program/education-program-minors/get-education-program-minors?minor_education_program_id=${minorEducationProgramId}`)
    },
    getMinorFormationEducationProgram(minorEducationProgramId) {
        return httpClient.get(`education_program/minor-formation-education-program/get-minor-formation?minor_education_program_id=${minorEducationProgramId}`)
    },
    getEducationProgramsForMinor(minorEducationProgramId) {
        return httpClient.get(`education_program/minor-education-program/get-education-programs?minor_education_program_id=${minorEducationProgramId}`)
    },
    getVokDisciplines(educationProgramId) {
        return httpClient.get(`education_program/minor-formation-education-program/get-vok-disciplines-from-formation` + '?education_program_id='+educationProgramId)
    },
    postMinorFormationEducationProgram(form) {
        return httpClient.post(`education_program/minor-formation-education-program/create`, form)
    },

    deleteMinorFormation(deleteId) {
        return httpClient.delete(`education_program/minor-formation-education-program/delete` + '?id='+ deleteId)
    },


}

export default minorFormationEducationProgramService