import teacherMagistracyPrerequisiteService from "@/services/teacher/teacherMagistracyPrerequisite.service"

const teacherMagistracyPrerequisite = {
    namespaced: true,
    actions: {
        async GET_MAGISTRACY_PREREQUISITE_DISCIPLINES({commit}) {
            try {
                const {data} = await teacherMagistracyPrerequisiteService.getMagistracyPrerequisiteDisciplines()
                if (data) {
                    commit('SET_DISCIPLINES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

        async POST_UPDATE_MAGISTRACY_PREREQUISITE_RATINGS({commit}, form) {
            console.log('post update prerequisite grade form', form)
            try {
                const {data} = await teacherMagistracyPrerequisiteService.postUpdateMagistracyPrerequisiteRatings(form)
                return data
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_DISCIPLINES(state, disciplines) {
            state.teacherMagistracyPrerequisite_form.disciplines = disciplines
        },

    },
    state: {
        teacherMagistracyPrerequisite_form: {
            disciplines: [],
        }
    },
    getters: {}
}

export default teacherMagistracyPrerequisite