import attendanceJournalService from "@/services/attendance-journal/attendanceJournal.service";

const attendanceJournal = {
  namespaced: true,
  actions: {
    async GET_TEACHER_GROUPS({commit}, pps_id) {
      try {
        const {status, data} = await attendanceJournalService.getTeacherGroups(pps_id)
        if (status === 200) {
          console.log('GET_TEACHER_GROUPS', data)
          commit('SET_TEACHER_GROUPS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_TEACHER_GROUPS(state, data) {
      state.teacher_groups = data
    },
  },
  state: {
    teacher_groups: [],
  }
}

export default attendanceJournal
