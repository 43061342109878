import diplomaReferenceService from "@/services/diploma-reference/diplomaReference.service";
import axios from 'axios'

const diplomaReference = {
    namespaced: true,
    actions: {
        async GET_GRADUATE_STUDENT_LIST({commit}) {
            try {
                const {data} = await diplomaReferenceService.getGraduateStudentList();
                if (data) {
                    console.log(data, 'GET_GRADUATE_STUDENT_LIST')
                    commit('SET_GRADUATE_STUDENT_LIST', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_REFERENCE_LOGS({commit}){
            try {
                const {data} = await diplomaReferenceService.getReferenceLogs();
                if (data) {
                    console.log(data, 'GET_REFERENCE_LOGS')
                    commit('SET_REFERENCE_LOGS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        }
    },
    mutations: {
        SET_GRADUATE_STUDENT_LIST(state, data) {
            state.graduateStudentList = data
        },
        SET_REFERENCE_LOGS(state, data){
            state.referenceLogs = data
        }
    },
    state: {
        graduateStudentList: [],
        referenceLogs: []
    },
}

export default diplomaReference
