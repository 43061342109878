import chooseDisciplineCalendarService from "@/services/calendar/chooseDisciplineCalendar.service"
import axios from "axios";

const chooseDisciplineCalendar = {
    namespaced: true,
    actions: {
        async GET_CHOOSE_DISCIPLINE_CALENDAR({commit}) {
            try {
                const {status, data} = await chooseDisciplineCalendarService.get()
                if (status === 200) {
                    commit('SET_CHOOSE_DISCIPLINE_CALENDAR', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_EDUCATION_CALENDAR({commit}) {
            try {
                const {status, data} = await chooseDisciplineCalendarService.getEducationCalendar()
                if (status === 200) {
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async POST_CHOOSE_DISCIPLINE_CALENDAR({state, commit}) {
            let form = state.chooseDisciplineCalendar_form.chooseDisciplineCalendar.filter(i => i.id === null)
            try {
                await chooseDisciplineCalendarService.post(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT({state, commit}) {
            let form = state.chooseDisciplineCalendar_form.chooseDisciplineCalendar.filter(i => i.id)
            console.log(form)
            try {
                await chooseDisciplineCalendarService.put(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async DELETE({commit}, deletedId) {
            try {
                const {
                    status
                } = await chooseDisciplineCalendarService.delete(deletedId);
                if (status === 200) {
                    console.log('DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_CHOOSE_DISCIPLINE_CALENDAR(state, chooseDisciplineCalendar) {
            state.chooseDisciplineCalendar_form.chooseDisciplineCalendar = chooseDisciplineCalendar
        },

        CHANGE_SELECTED(state, {value, sIndex}) {
            let element = state.chooseDisciplineCalendar_form.chooseDisciplineCalendar
            element[sIndex]['education_calendar_id'] = value
        },

        ADD_ELEMENT(state) {
            state.chooseDisciplineCalendar_form.chooseDisciplineCalendar.push({
                id: null,
                education_calendar_id: null,
                start_date: null,
                end_date: null,
            })
            console.log(state.chooseDisciplineCalendar_form.chooseDisciplineCalendar, 'chooseDisciplineCalendar')
        },

        DELETE_ELEMENT(state, s) {
            let index = state.chooseDisciplineCalendar_form.chooseDisciplineCalendar.indexOf(s)
            state.chooseDisciplineCalendar_form.chooseDisciplineCalendar.splice(index, 1);
            console.log(state.chooseDisciplineCalendar_form.chooseDisciplineCalendar, 'chooseDisciplineCalendar')
        },

        SET_DATE(state, {sIndex, property, date}) {
            let element = state.chooseDisciplineCalendar_form.chooseDisciplineCalendar
            element[sIndex][property] = date
            console.log(element, 'chooseDisciplineCalendar')
        }

    },
    state: {
        chooseDisciplineCalendar_form: {
            chooseDisciplineCalendar: []
        }
    },
    getters: {}
}

export default chooseDisciplineCalendar