import axios from 'axios'
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const departments = {
    namespaced: true,
    actions: {
        async DELETE_EDUCATION_MODULE_DATA({commit, state}, id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('admin/departments/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('departments data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_EDUCATION_MODULE_DATA() {

            let departmentsForm = (departments.state.departments_form.newDepartmentsInfos)
            try {
                const {
                    status,
                    data
                } = await httpClient.post('admin/departments/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), departmentsForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async PUT_EDUCATION_MODULE_DATA({commit, state}, id) {

            let departmentsForm = (departments.state.departments_form.departmentsInfos).filter(v => v.id == id)

            if (departmentsForm.length > 0) {

                try {

                    const {
                        status,
                        data
                    } = await httpClient.put('admin/departments/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), departmentsForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_DEPARTMENTS_DATA({commit}) {

            try {

                const {
                    status,
                    data
                } = await httpClient.get('admin/departments/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {

                    commit("SET_DEPARTMENTS_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_DEPARTMENTS_DATA(state, departmentsInfos) {
            state.departments_form.departmentsInfos = departmentsInfos
        },

        ADD_ROW_DEPARTMENTS_INFO(state) {
            state.departments_form.newDepartmentsInfos.push({
                name: '',
                put_status: 0
            });
        },
        DELETE_ROW_DEPARTMENTS_INFO(state, index) {
            state.departments_form.newDepartmentsInfos.splice(index, 1);
        },
        UPDATE_DEPARTMENTS_DATA(state, {item, property, value}) {
            const index = state.departments_form.departmentsInfos.indexOf(item)
            state.departments_form.departmentsInfos[index][property] = value
        },
        UPDATE_NEW_DEPARTMENTS_DATA(state, {item, property, value}) {
            const index = state.departments_form.newDepartmentsInfos.indexOf(item)
            state.departments_form.newDepartmentsInfos[index][property] = value
        },
        CHANGE_EDIT_STATUS_DEPARTMENTS_INFO(state, index) {
            state.departments_form.departmentsInfos[index].edit_status = 1;
        },
    },
    state: {
        departments_form: {
            departmentsInfos: [{
                name: '',
                put_status: 0
            }],
            newDepartmentsInfos: []
        },
    },
    getters: {

    }

}

export default departments