import httpClient from "@/services/http.service"
import router from "@/router"
import {generateURL} from "@/utils/helpers/queryParams.helper";


const statementPrintService = {
    getCoursesByName(name) {
        return httpClient.get(`education_program/education-courses/get-courses-by-name` + '?name=' + name)
    },
    getCoursesByPpsLastName(lastname) {
        return httpClient.get(`education_program/education-courses/get-courses-by-pps-last-name` + '?lastname=' + lastname)
    },
    getSummerCourses(queryData) {
        return httpClient.get(generateURL(`education_program/education-courses/get-summer-courses-by-pps-last-name`, queryData))
    }
}

export default statementPrintService