import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const decreeGosoTemplates = {
    namespaced: true,
    actions: {
        async DELETE_EDUCATION_MODULE_DATA({commit, state}, id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('education_program/education-modules/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('education module data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async DELETE_DECREE_GOSO_TEMPLATES_DATA({state}) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('education_program/decree-goso-templates/delete' + '?id=' + state.deleteDecreeGosoTemplateId + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('education module data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_DECREE_GOSO_TEMPLATES_DATA() {

            let decreeGosoTemplatesForm = (decreeGosoTemplates.state.decreeGosoTemplates_form.newDecreeGosoTemplatesInfos)
            console.log(decreeGosoTemplatesForm, 'decreeGosoTemplatesForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/decree-goso-templates/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), decreeGosoTemplatesForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async PUT_DECREE_GOSO_TEMPLATES_DATA({state}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.put(`education_program/decree-goso-templates/update?access-token=${getCookie('ACCESS_TOKEN')}&id=${state.decreeGosoTemplate.id}`, state.decreeGosoTemplate);
                if (status === 200) {
                    console.log(data, 'update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        // async PUT_EDUCATION_MODULE_DATA({commit, state}, id) {
        //
        //     let educationModuleForm = (educationmodule.state.educationmodule_form.educationModuleInfos).filter(v => v.id == id)
        //
        //     if (educationModuleForm.length > 0) {
        //
        //         try {
        //
        //             const {
        //                 status,
        //                 data
        //             } = await httpClient.put('education_program/education-modules/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationModuleForm);
        //             if (status === 200) {
        //                 console.log(data)
        //             }
        //             return {
        //                 success: true
        //             }
        //         } catch (e) {
        //             console.log(e.response);
        //             return {
        //                 success: false,
        //                 errors: e.response.data.errors
        //             }
        //         }
        //     } else {
        //         return 0
        //     }
        // },
        async GET_DECREE_GOSO_TEMPLATES_DATA({commit}) {

            try {

                const {
                    status,
                    data
                } = await httpClient.get('education_program/decree-goso-templates/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {

                    commit("SET_DECREE_GOSO_TEMPLATES_DATA", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_DECREE_GOSO_TEMPLATES_DATA(state, decreeGosoTemplatesInfos) {
            state.decreeGosoTemplates_form.decreeGosoTemplatesInfos = decreeGosoTemplatesInfos
        },

        ADD_ROW_DECREE_GOSO_TEMPLATES_INFO(state) {
            state.decreeGosoTemplates_form.newDecreeGosoTemplatesInfos.push({
                name: '',
                date: '',
                put_status: 0
            });
        },
        DELETE_ROW_DECREE_GOSO_TEMPLATES_INFO(state, index) {
            state.decreeGosoTemplates_form.newDecreeGosoTemplatesInfos.splice(index, 1);
        },
        UPDATE_EDUCATION_MODULE_DATA(state, {item, property, value}) {
            const index = state.educationmodule_form.educationModuleInfos.indexOf(item)
            state.educationmodule_form.educationModuleInfos[index][property] = value
        },
        UPDATE_NEW_DECREE_GOSO_TEMPLATES_NAME(state, {value, property}) {
            state.decreeGosoTemplates_form.newDecreeGosoTemplatesInfos[property] = value
        },
        CHANGE_EDIT_STATUS_EDUCATION_MODULE_INFO(state, index) {
            state.educationmodule_form.educationModuleInfos[index].edit_status = 1;
        },
        UPDATE_NEW_DECREE_GOSO_TEMPLATES_DATE(state, {value, property}) {

            state.decreeGosoTemplates_form.newDecreeGosoTemplatesInfos[property] = parseInt((new Date(value)).getTime() / 1000)
            console.log(state.decreeGosoTemplates_form.newDecreeGosoTemplatesInfos[property], 'property')

        },
        CHOOSE_DECREE_GOSO_TEMPLATE(state, decreeGosoTemplate) {
            state.decreeGosoTemplate = {...decreeGosoTemplate};
            console.log(state.decreeGosoTemplate, 'CHOOSE_DECREE_GOSO_TEMPLATE decreeGosoTemplate')
        },
        CHANGE_DECREE_GOSO_TEMPLATE(state, {item, property, value}) {
            state.decreeGosoTemplate[property] = value
            console.log(state.decreeGosoTemplate, 'CHANGE_DECREE_GOSO_TEMPLATE decreeGosoTemplate')
        },
        CHOOSE_DELETE_DECREE_GOSO_TEMPLATE(state,id){
            state.deleteDecreeGosoTemplateId = id;
        }
    },
    state: {
        decreeGosoTemplates_form: {
            decreeGosoTemplatesInfos: [],
            newDecreeGosoTemplatesInfos: {}
        },
        decreeGosoTemplate: {},
        deleteDecreeGosoTemplateId :0
    },
    getters: {
        getDecreeGosoTemplateById(state) {
            return state.decreeGosoTemplates_form.decreeGosoTemplatesInfos.find(decreeGosoTemplate => decreeGosoTemplate.id == state.decreeGosoTemplatesId)
        }
    }

}

export default decreeGosoTemplates