import httpClient from "@/services/http.service"


const educationProgrammeSupervisorService = {
    getSupervisorEducationPrograms() {
        return httpClient.get(`education_program/education-program/get-supervisor-education-programs`)
    },

}

export default educationProgrammeSupervisorService