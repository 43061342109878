import httpClient from "@/services/http.service"

const officeRegistrationPrerequisitesMagistracyService = {
  getMastersPrerequisites() {
    return httpClient.get(`student_data/student-data/get-prerequisite-masters`)
  },
  postPrerequisitesRatings(form) {
    return httpClient.post(`student/prerequisites-ratings/create`, form)
  },
}

export default officeRegistrationPrerequisitesMagistracyService