import httpClient from "@/services/http.service"

const semesterCalendarService = {
  getSemester() {
    return httpClient.get(`academic_calendar/semester-type/get-semester-type`)
  },
  getEducationCalendar() {
    return httpClient.get(`academic_calendar/semester-calendar/get-education-calendar`)
  },
  get() {
    return httpClient.get(`academic_calendar/semester-calendar/get-semester-calendar-info`)
  },
  post(form) {
    return httpClient.post(`academic_calendar/semester-calendar/create`, form)
  },
}

export default semesterCalendarService