import httpClient from "@/services/http.service"
import router from "@/router"

const conferenceCoordinatorService = {

    getConferenceArticle() {
        return httpClient.get(`conference/conference-article/get-articles-for-coordinator`)
    },
    getSectionModerators() {
        return httpClient.get(`conference/conference-section-moderator/get-section-moderator`)
    },
    putSectionModerator() {
        return httpClient.put(`conference/conference-section-moderator/update`)
    },
}

export default conferenceCoordinatorService