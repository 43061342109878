import educationProgrammeSupervisorService from "@/services/education-programme/supervisor/educationProgrammeSupervisor.service"

const educationProgrammeSupervisor = {
    namespaced: true,
    actions: {
        async GET_SUPERVISOR_EDUCATION_PROGRAMS({commit}) {
            try {
                const {status, data} = await educationProgrammeSupervisorService.getSupervisorEducationPrograms()
                if (status === 200) {
                    //return data
                    console.log('GET_SUPERVISOR_EDUCATION_PROGRAMS', data)
                    commit('SET_EDUCATION_PROGRAMS', data);
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },
        // async GET_EDUCATION_CALENDAR({commit}) {
        //     try {
        //         const {status, data} = await semesterCalendarService.getEducationCalendar()
        //         if (status === 200) {
        //             return data
        //         }
        //     } catch (e) {
        //         commit('SET_ERROR', e, {root: true})
        //     }
        //     return []
        // },
        // async GET_SEMESTER_CALENDAR({commit}) {
        //     try {
        //         const {status, data} = await semesterCalendarService.get()
        //         if (status === 200) {
        //             return data
        //         }
        //     } catch (e) {
        //         commit('SET_ERROR', e, {root: true})
        //     }
        //     return []
        // },
        // async POST_SEMESTER_CALENDAR({commit}, form) {
        //     try {
        //         await semesterCalendarService.post(form)
        //         return true
        //     } catch (e) {
        //         commit('SET_ERROR', e, {root: true})
        //     }
        //     return false
        // },
    },
    mutations: {
        SET_EDUCATION_PROGRAMS(state, educationPrograms) {
            state.educationProgrammeSupervisor_form.educationPrograms = educationPrograms
        }
    },
    state: {
        educationProgrammeSupervisor_form: {
            educationPrograms: []
        }
    },
    getters: {}
}

export default educationProgrammeSupervisor