import kpiCommissionService from "@/services/kpi/kpiCommission.service";


const kpiCommission = {
    namespaced: true,
    actions: {
        async GET_KPI_TEACHERS({commit}) {
            try {
                const {status, data} = await kpiCommissionService.getKpiTeachers()
                if (status === 200) {
                    console.log('GET_KPI_TEACHERS', data)
                    commit('SET_KPI_TEACHERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_KPI_TEACHER_SCORE({commit}, userId) {
            try {
                const {status, data} = await kpiCommissionService.getKpiTeacherScore(userId)
                if (status === 200) {
                    console.log('GET_KPI_TEACHER_SCORE', data)
                    commit('SET_KPI_TEACHER_SCORE', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_USER_KPI_COMMISSION_DECISION({commit}, form) {
            try {
                await kpiCommissionService.postUserKpiCommissionDecision(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


    },
    mutations: {
        SET_KPI_TEACHERS(state, teachers) {
            state.kpiCommission_form.teachers = teachers
        },
        SET_KPI_TEACHER_SCORE(state, teacherScore) {
            state.kpiCommission_form.teacherScore = teacherScore
        },
    },
    state: {
        kpiCommission_form: {
            // teachers: [{
            //     pps_id: 1,
            //     lastname: 'Иванов',
            //     firstname: 'Иван',
            //     total_score: 1000,
            //     goal_achievement_index: 2,
            //     verified_score: 150,
            //     decision: 'Принято',
            // }],
            teachers: [],
            teacherScore: []
            // teacherScore: [
            //     {
            //         kpi_sub_type_id: 1,
            //         planned_value: 250,
            //         actual_value: 150,
            //         score: 500,
            //     },
            //     {
            //         kpi_sub_type_id: 2,
            //         planned_value: 350,
            //         actual_value: 450,
            //         score: 600,
            //     },
            // ]
        }
    },
    getters: {}
}

export default kpiCommission
