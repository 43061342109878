import httpClient from "@/services/http.service"

const officeRegistrationDifferenceService = {
  getDifference(studentId) {
    return httpClient.get(`requisites/difference-ratings/get-students-difference-ratings?student_id=${studentId}`)
  },
  postDifference(form) {
    return httpClient.post(`requisites/difference-ratings/create`, form)
  },
  putDifference(form) {
    return httpClient.put(`requisites/difference-ratings/update`, form)
  },
  deleteDifference(id) {
    return httpClient.delete(`requisites/difference-ratings/delete?id=${id}`)
  },
}

export default officeRegistrationDifferenceService