import httpClient from "@/services/http.service"
import router from "@/router"


const ppsLoadService = {
  // getStudyLevels() {
  //     return httpClient.get(`education_program/education-curriculum/get-academic-year`)
  // },
  getFormedPpsTeachingLoad() {
    return httpClient.get(`load_calculation/pps-teaching-load/get-pps-teaching-load-by-department-id`)
  },
  getPpsTeachingLoadByPpsId(pps_id) {
    return httpClient.get(`load_calculation/pps-teaching-load/get-pps-teaching-load-by-pps-id` + '?pps_id=' + pps_id)
  },
  getTeacherLoadCalculation() {
    return httpClient.get(`load_calculation/pps-teaching-load/get-teacher-load-calculation`)
  },
  getDisciplines(languageId, studyLevelId) {
    return httpClient.get(`education_program/education-program/get-discipline-with-lecture-time` + '?language=' + languageId + '&study_level=' + studyLevelId)
  },
  getFormedDisciplines(languageId, studyLevelId) {
    return httpClient.get(`education_program/education-program/get-discipline-with-lecture-time` + '?language=' + languageId + '&study_level=' + studyLevelId + '&status=' + 1)
  },
  getTeachers(education_discipline_id) {
    return httpClient.get(`pps/pps-data/get-pps-by-contract-type` + '?discipline_id=' + education_discipline_id)
  },
  getEducationType() {
    return httpClient.get(`teaching_load/education-type/index`)
  },
  postPpsTeachingLoad(data) {
    console.log(data, 'postPpsTeachingLoad')
    return httpClient.post(`load_calculation/pps-teaching-load/create`, data)
  },
  postDisciplineVacancy(data) {
    return httpClient.post(`kkk/discipline-vacancy/create`, data)
  },
  putPpsTeachingLoad(data) {
    return httpClient.put(`load_calculation/pps-teaching-load/update`, data)
  },
  putLanguagePpsTeachingLoad(data) {
    return httpClient.put(`load_calculation/pps-teaching-load/update`, data)
  },

  getDisciplinesNew(languageId, studyLevelId) {
    return httpClient.get(`education_program/education-program/get-new-discipline-with-lecture-time?language=${languageId}&study_level=${studyLevelId}&status=1`)
  },

  getLanguageDisciplines(languageId, studyLevelId) {
    return httpClient.get(`education_program/education-program/get-language-disciplines-load-calculation?language_id=${languageId}&study_level_id=${studyLevelId}`)
  },
  deletePpsTeachingLoad(deleteId) {
    return httpClient.delete(`load_calculation/pps-teaching-load/delete` + '?id='+ deleteId)
  },


  postConfirmPpsLoad() {
    return httpClient.post(`load_calculation/pps-teaching-load/is-pps-confirm`)
  },


  getStudentsByEducationProgramsForKazakhLanguage(educationPrograms) {
    return httpClient.post(`student_data/student-data/get-students-by-education-program-ids`, {ids: educationPrograms})
  },
}

export default ppsLoadService