import {createStore} from 'vuex'
import {getCookie} from "@/utils/helpers/cookies.helpers";

import personal from './modules/user-personal/personal'
import document from './modules/user-personal/document'
import useraddress from './modules/user-personal/useraddress'
import education from './modules/user-personal/education'
import job from './modules/user-personal/job'
import languageskills from './modules/user-personal/languageskills'
import qualification from './modules/user-personal/qualification'

import researchwork from './modules/user-personal/researchwork'
import children from './modules/user-personal/children'
import educationfield from './modules/education-programme/educationfield'
import educationdirection from './modules/education-programme/educationdirection'
import educationprogram from './modules/education-programme/educationprogram'
import gosoTemplates from './modules/education-programme/gosoTemplates'
import educationmodule from './modules/education-programme/educationmodule'
import educationdiscipline from './modules/education-programme/educationdiscipline'

import foreignDiscipline from './modules/education-programme/foreignDiscipline'

import departments from './modules/admin/departments'
import formationEducationProgram from './modules/education-programme/formationEducationProgram'
import decreeGosoTemplates from './modules/education-programme/decreeGosoTemplates'
import creditTimeNormTemplate from './modules/education-programme/creditTimeNormTemplate'
import vacancy from './modules/vacancy/vacancy'
import userinfo from './modules/vacancy/userinfo'
import commission from './modules/vacancy/commission'
import activedirectory from './modules/vacancy/activedirectory'
import syllabus from './modules/syllabus/syllabus'
import syllabusContent from './modules/syllabus/syllabusContent'
import syllabusLiterature from './modules/syllabus/syllabusLiterature'
import educationCurriculum from './modules/education-programme/educationCurriculum'
import orders from './modules/abiturient/orders'
import item from './modules/abiturient/item'
import abiturient from './modules/abiturient/abiturient'
import studentsList from './modules/admin/studentsList'
import teachingLoad from './modules/education-programme/teachingLoad'
import teacher from './modules/teacher/teacher'
import journalRating from './modules/teacher/journalRating'
import selectableFlows from './modules/education-programme/selectable-course/selectableFlows'
import selectableGroups from './modules/education-programme/selectable-course/selectableGroups'
import selectableDisciplines from './modules/education-programme/selectable-course/selectableDisciplines'
import bindSelectableGroups from './modules/education-programme/bindSelectableGroups'
import consolidatedStatement from './modules/admin/consolidatedStatement'
import attendanceReport from './modules/teacher/attendanceReport'
import writtenExamCoursesList from './modules/admin/writtenExamCoursesList'
import complaintForm from './modules/complaint/complaintForm'
import graduateWorkCalendar from './modules/graduate-work/dean/graduateWorkCalendar'
import graduateWorkTheme from './modules/graduate-work/teacher/graduateWorkTheme'
import graduateWorkTemp from './modules/graduate-work/departmentHead/graduateWorkTemp'
import graduateWorkAttestation from './modules/graduate-work/departmentHead/graduateWorkAttestation'
import graduateWork from './modules/graduate-work/student/graduateWork'
import selectionSupervisors from './modules/graduate-work/departmentHead/selectionSupervisors'
import loadCalculation from './modules/load-calculation/loadCalculation'
import formedLoadCalculation from './modules/load-calculation/formedLoadCalculation'
import uploadGraduateWork from './modules/graduate-work/student/uploadGraduateWork'
import graduateWorkStudents from './modules/graduate-work/teacher/graduateWorkStudents'
import finalExam from './modules/teacher/finalExam'
import totalGrade from './modules/teacher/totalGrade'
import docOrders from './modules/doc-orders/docOrders'
import docOrdersStudents from './modules/doc-orders/docOrdersStudents'
import transferCourses from './modules/admin/transferCourses'
import academicDebt from './modules/student/academicDebt'
import academicDebtService from './modules/academic-debt/academicDebtService'
import oldSummerRatings from './modules/old-summer-ratings/oldSummerRatings'
import conferenceAuthor from './modules/conference/author/conferenceAuthor'
import conferenceModerator from './modules/conference/moderator/conferenceModerator'
import conferenceReviewer from './modules/conference/reviewer/conferenceReviewer'
import teacherSummer from './modules/teacher/teacherSummer'
import conferenceCoordinator from './modules/conference/coordinator/conferenceCoordinator'
import statementPrint from './modules/admin/statementPrint'
import ppsLoad from './modules/load-calculation/ppsLoad'
import chooseDisciplines from './modules/student/chooseDisciplines'
import adviserChoose from './modules/adviser-choose/adviserChoose'
import adviser from './modules/adviser/adviser'
import educationProgramResult from './modules/education-programme/educationProgramResult'
import curriculumOptionalComponent from './modules/curriculum-optional-component/curriculumOptionalComponent'
import teacherEvaluation from './modules/student/teacherEvaluation'
import graduateWorkScore from './modules/graduate-work/graduateWorkScore'
import disciplinesDifference from './modules/disciplines-difference/disciplinesDifference'
import departmentPractice from './modules/department/departmentPractice'
import teacherPractice from './modules/teacher/teacherPractice'
import commissionDecision from './modules/kkk/commission/commissionDecision'
import kkk from './modules/kkk/kkk'
import commissionHeadDecision from './modules/kkk/commission/commissionHeadDecision'
import teacherGrants from './modules/teacher_grants/teacherGrants'
import teachingExperiences from './modules/teacher_data/teachingExperiences'
import commissionScore from './modules/kkk/commission/commissionScore'
import managerSchedule from './modules/schedule/managerSchedule'
import teacherSchedule from './modules/schedule/teacherSchedule'
import studentSchedule from './modules/schedule/studentSchedule'
import curriculumDisciplines from './modules/curriculum/curriculumDisciplines'
import studentAgreement from './modules/lawyer/studentAgreement'
import combinedTeachingLoad from './modules/combined-teaching-load/combinedTeachingLoad'
import departmentMagistracyPrerequisite from './modules/department/departmentMagistracyPrerequisite'
import officeRegistrationPrerequisitesMagistracy
  from './modules/office-registration/officeRegistrationPrerequisitesMagistracy'
import chooseLanguageLevel from './modules/language-center/chooseLanguageLevel'
import teacherMagistracyPrerequisite from './modules/teacher/teacherMagistracyPrerequisite'

import axios from 'axios'
import officeRegistrationDifference from "./modules/office-registration/officeRegistrationDifference";
import departmentDifference from "./modules/department/departmentDifference";
import differenceStudent from "./modules/difference/differenceStudent";
import officeRegistrationDifferenceStudentRequest from "./modules/office-registration/officeRegistrationDifferenceStudentRequest";
import teacherDifference from './modules/teacher/teacherDifference'
import studentResearchCup from "./modules/student-research-cup/studentResearchCup";
import deputyDeanResearchCup from "./modules/student-research-cup/deputy-dean/deputyDeanResearchCup";
import studentResearchCupRegistration from "./modules/student-research-cup/student/studentResearchCupRegistration";
import commissionResearchCup from "./modules/student-research-cup/commission/commissionResearchCup";

import educationCalendar from "./modules/academic-calendar/educationCalendar";
import semesterCalendar from "@/store/modules/calendar/semesterCalendar";
import holidayCalendar from "@/store/modules/calendar/holidayCalendar";

import semesterSummerCalendar from "@/store/modules/academic-calendar/semesterSummerCalendar";

import chooseDisciplineCalendar from "@/store/modules/calendar/chooseDisciplineCalendar";
import changePhoto from "@/store/modules/pps/changePhoto";
import accreditationExperience from "@/store/modules/pps/accreditationExperience";

import professionalCertification from './modules/user-personal/professionalCertification'
import scientificActivity from "@/store/modules/user-personal/scientificActivity";
import kpiTeachersDepartmentHead from "@/store/modules/kpi/kpiTeachersDepartmentHead";
import kpiTeacher from "@/store/modules/kpi/kpiTeacher";
import kpiCommission from "@/store/modules/kpi/kpiCommission";

import events from "@/store/modules/events/events";
import studentEventRegistration from "@/store/modules/events/studentEventRegistration";
import dispatcherEvents from "@/store/modules/events/dispatcherEvents";
import securityEvents from "@/store/modules/events/securityEvents";
import dmpEvents from "@/store/modules/events/dmpEvents"

import organization from "@/store/modules/organization/organization";
import survey from "@/store/modules/sei/survey";
import bigFive from "@/store/modules/big-five/big-five";
import creativeImaginationTest from "@/store/modules/sei/creativeImaginationTest";
import eqiShort from "@/store/modules/eqi-short/eqi-short";
import luscher from "@/store/modules/luscher/luscher";
import totalPercent from "@/store/modules/social-gpa/totalPercent";
import checkAccess from "./modules/check-access/check-access";

import minorEducationProgram from "@/store/modules/education-programme/minorEducationProgram";
import educationProgrammeSupervisor from "@/store/modules/education-programme/supervisor/educationProgrammeSupervisor";

import minorFormationEducationProgram from "@/store/modules/education-programme/minorFormationEducationProgram";
import studentOrders from "@/store/modules/student-orders/studentOrders";
import diplomaReference from "@/store/modules/diploma-reference/diplomaReference";

import teachersContracts from "@/store/modules/kkk/teachersContracts";
import teachersOrders from "@/store/modules/kkk/teachersOrders";

import analytics from "@/store/modules/analytics/analytics";

import httpClient from "../services/http.service";
import attendanceJournal from "@/store/modules/attendance-journal/attendance-journal";
import officeRegistrationDisciplineOpening
  from "@/store/modules/office-registration/officeRegistrationDisciplineOpening";
import officeRegistrationEmailChange from "@/store/modules/office-registration/officeRegistrationEmailChange";
import departmentShowSyllabus from "@/store/modules/department/departmentShowSyllabus";
import departmentPpsTeachingTrainingDirection from "@/store/modules/department/departmentPpsTeachingTrainingDirection";
import predictList from "@/store/modules/predict/predict-list";
import syllabusOfficeHours from "@/store/modules/syllabus/syllabusOfficeHours";
import syllabusMainTasks from "@/store/modules/syllabus/syllabusMainTasks";

import documentSignature from "./modules/document-signature/documentSignature";
import syllabusLiteratureOld from "@/store/modules/syllabus/syllabusLiteratureOld";
import syllabusContentOld from "@/store/modules/syllabus/syllabusContentOld";
import signingDocument from "@/store/modules/document/signing-document/signingDocument";
import transcriptOrders from "@/store/modules/office-registration/transcript-orders/transcriptOrders";

const store = createStore({
  modules: {
    personal,
    document,
    useraddress,
    education,
    job,
    languageskills,
    qualification,
    researchwork,
    children,
    educationfield,
    educationdirection,
    educationprogram,
    gosoTemplates,
    educationmodule,
    educationdiscipline,
    departments,
    formationEducationProgram,
    vacancy,
    userinfo,
    commission,
    activedirectory,
    decreeGosoTemplates,
    syllabus,
    syllabusContent,
    syllabusLiterature,
    creditTimeNormTemplate,
    educationCurriculum,
    orders,
    item,
    abiturient,
    studentsList,
    teachingLoad,
    teacher,
    journalRating,
    selectableFlows,
    selectableGroups,
    selectableDisciplines,
    bindSelectableGroups,
    consolidatedStatement,
    attendanceReport,
    writtenExamCoursesList,
    complaintForm,
    graduateWorkCalendar,
    graduateWorkTheme,
    graduateWorkTemp,
    graduateWorkAttestation,
    graduateWork,
    selectionSupervisors,
    loadCalculation,
    formedLoadCalculation,
    uploadGraduateWork,
    graduateWorkStudents,
    finalExam,
    totalGrade,
    docOrders,
    docOrdersStudents,
    transferCourses,
    academicDebt,
    academicDebtService,
    oldSummerRatings,
    conferenceAuthor,
    conferenceModerator,
    conferenceReviewer,
    teacherSummer,
    conferenceCoordinator,
    statementPrint,
    ppsLoad,
    chooseDisciplines,
    adviserChoose,
    adviser,
    educationProgramResult,
    curriculumOptionalComponent,
    teacherEvaluation,
    foreignDiscipline,
    graduateWorkScore,
    disciplinesDifference,
    departmentPractice,
    teacherPractice,
    commissionDecision,
    kkk,
    commissionHeadDecision,
    teacherGrants,
    commissionScore,
    teachingExperiences,
    managerSchedule,
    teacherSchedule,
    studentSchedule,
    curriculumDisciplines,
    studentAgreement,
    combinedTeachingLoad,
    departmentMagistracyPrerequisite,
    officeRegistrationPrerequisitesMagistracy,
    chooseLanguageLevel,
    teacherMagistracyPrerequisite,
    officeRegistrationDifference,
    departmentDifference,
    differenceStudent,
    officeRegistrationDifferenceStudentRequest,
    teacherDifference,
    studentResearchCup,
    deputyDeanResearchCup,
    studentResearchCupRegistration,
    commissionResearchCup,
    educationCalendar,
    semesterCalendar,
    holidayCalendar,
    semesterSummerCalendar,
    chooseDisciplineCalendar,
    changePhoto,
    professionalCertification,
    scientificActivity,
    accreditationExperience,
    kpiTeachersDepartmentHead,
    kpiTeacher,
    kpiCommission,
    events,
    organization,
    studentEventRegistration,
    survey,
    bigFive,
    studentOrders,
    diplomaReference,
    creativeImaginationTest,
    eqiShort,
    luscher,
    dispatcherEvents,
    totalPercent,
    securityEvents,
    dmpEvents,
    checkAccess,
    educationProgrammeSupervisor,
    minorEducationProgram,
    minorFormationEducationProgram,
    teachersContracts,
    teachersOrders,
    analytics,
    attendanceJournal,
    officeRegistrationDisciplineOpening,
    officeRegistrationEmailChange,
    departmentShowSyllabus,
    departmentPpsTeachingTrainingDirection,
    predictList,
    syllabusOfficeHours,
    syllabusMainTasks,
    documentSignature,
    syllabusContentOld,
    syllabusLiteratureOld,
    signingDocument,
    transcriptOrders,
  },
  actions: {

    async POST_PPS_DATA() {
      let formData = Object.assign(personal.state.form, document.state.document_form)
      if (personal.state.form.status == 0) {
        try {
          const {
            status,
            data
          } = await httpClient.post(`questionnaire/pps-data/create?access-token=${getCookie('ACCESS_TOKEN')}`, formData);
          if (status === 200) {
            console.log('PPS DATA POST DONE')
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            errors: e.response.data.errors
          }
        }
      } else {
        return 0
      }

    },
    async PUT_PPS_DATA({commit}) {
      let formData = Object.assign(personal.state.form, document.state.document_form)
      if (personal.state.form.status == 1) {
        try {
          const {
            status,
            data
          } = await httpClient.put(`questionnaire/pps-data/update?access-token=${getCookie('ACCESS_TOKEN')}`, formData);
          if (status === 200) {
            console.log('PPS DATA PUT DONE')
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            error: e.response.data.errors
          }
        }
      } else {
        return 0
      }
    },
    async GET_PPS_DATA({commit}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('questionnaire/pps-data' + '?access-token=' + getCookie('ACCESS_TOKEN'));

        console.log('* PPS DATA')
        console.log(data)
        if (status === 200) {
          commit("personal/updatePersonalForm", data.success)
          commit("document/updateDocumentForm", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response
        }
      }
    },
    async GET_USER_ADDRESS_DATA({commit}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('questionnaire/user-address/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit("useraddress/updateAddressForm", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response
        }
      }
    },
    async GET_HEADHUNTER_RESUMES({commit}) {
      httpClient.get('questionnaire/head-hunter/get-resume?code=' + new URL(location.href).searchParams.get('code') + '&access-token=' + getCookie('ACCESS_TOKEN'), {}, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then((response) => {
            console.log(response, 'headHunterBack');
            commit("SET_HH_DATA", response.data.items)
            // commit("personal/updatePersonalHeadHunterData", response.data)
            // commit("education/updateEducationHeadHunterData", response.data)
          })
          .catch((error) => {
            console.log(error, 'headHunterBackError');
          });
    },
    async SELECT_HH_DATA({commit, dispatch}) {
      let hhData = this.state.hh.hhData
      let index = this.state.hh.resumeIndex
      console.log(hhData, index, hhData[index])

      await dispatch('education/GET_EDUCATION_DATA')
      await dispatch('job/GET_JOB_DATA')

      commit("personal/SET_HEADHUNTER_TO_PERSONAL_DATA", hhData[index])
      commit("education/SET_HEADHUNTER_TO_EDUCATION_DATA", hhData[index].education)
      commit("job/SET_HEADHUNTER_TO_JOB_DATA", hhData[index].experience)
    },


    async GET_USERS({commit}, search_username) {
      try {
        const {
          status,
          data
        } = await httpClient.get('api/admin/get-users' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&username='+ search_username);

        if (status === 200) {
          return data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response
        }
      }
    },

  },
  mutations: {
    SET_HH_DATA(state, hhData) {
      state.hh.hhData = hhData
      // console.log('index.js state hhData', hhData)
    },
    SET_HH_RESUME(state, index) {
      state.hh.resumeIndex = index
      // console.log('index.js state resumeIndex', state.hh.resumeIndex)
    },
    SET_ERROR(state, error) {
      state.error = {
        message: error.message,
        errors: error?.response?.data?.errors
      }
    }

  },
  state: {
    hh: {
      hhData: [],
      resumeIndex: 0
    },
    error: null
  },
  getters: {
    error: (s) => s.error
  },
})
export default store



