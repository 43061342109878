import httpClient from "@/services/http.service"

const differenceStudentService = {
  getDifferenceStudentDisciplines() {
    return httpClient.get(`requisites/difference-ratings/get-difference-ratings-discipline`)
  },
  getDifferenceStudentApplications() {
    return httpClient.get(`requisites/student-difference-request/index`)
  },
  postDifferenceStudent(form) {
    return httpClient.post(`requisites/student-difference-request/create`, form)
  },
}

export default differenceStudentService