import httpClient from "@/services/http.service"


const syllabusOfficeHoursService = {
    getSyllabusOfficeHours(syllabus_id) {
        return httpClient.get(`syllabus_data/syllabus-schedule-office-hour/index?syllabus_id=${syllabus_id}`)
    },
    postSyllabusOfficeHours(form) {
        return httpClient.post(`syllabus_data/syllabus-schedule-office-hour/create`, form)
    },
    putSyllabusOfficeHours(id, form) {
        return httpClient.put(`syllabus_data/syllabus-schedule-office-hour/update?id=${id}`, form)
    },
    deleteSyllabusOfficeHours(id) {
        return httpClient.delete(`syllabus_data/syllabus-schedule-office-hour/delete?id=${id}`)
    },
}

export default syllabusOfficeHoursService