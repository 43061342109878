import totalGradeService from "@/services/teacher/totalGrade.service"
import finalExamService from "@/services/teacher/finalExam.service"

const totalGrade = {
    namespaced: true,
    actions: {

        async GET_EDUCATION_COURSE_DATA_BY_ID({commit}, education_courses_id) {
            try {
                const {data} = await finalExamService.getEducationCourseDataById(education_courses_id);
                if (data) {
                    console.log(data.success, 'GET_EDUCATION_COURSE_DATA_BY_ID')
                    commit('SET_EDUCATION_COURSE_DATA_BY_ID', data.success)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_STUDENTS_BY_MDL_COURSE_ID({commit}, mdl_course_id) {
            try {
                const {data} = await finalExamService.getStudentsByMdlCourseId(mdl_course_id);
                if (data) {
                    console.log(data, 'GET_STUDENTS_BY_COURSE_ID')
                    commit("SET_STUDENTS", data.students)
                    commit("SET_STUDENTS_GROUPS", data.groups)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },



        async POST_TOTAL_GRADE_REPORT({commit, state}) {
            let totalGradeReportForm = state.totalGrade_form.totalGradeReport.filter(i=>i.close_status == 0)
            //console.log(state.graduateWorkTemp.newGraduateWorkThemes, 'newGraduateWorkThemes')
            try {
                const {data} = await totalGradeService.postTotalGradeReport(totalGradeReportForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


    },
    mutations: {
        SET_EDUCATION_COURSE_DATA_BY_ID(state, courseInfos) {
            state.totalGrade_form.courseInfos = courseInfos
            console.log(state.totalGrade_form.courseInfos, "courseInfos")
        },
        SET_STUDENTS_GROUPS(state, studentGroups) {
            state.totalGrade_form.studentGroups = studentGroups
            console.log(state.totalGrade_form.studentGroups, "studentGroups")
        },
        SET_STUDENTS(state, students) {
            state.totalGrade_form.students = students
            console.log(state.totalGrade_form.students, "students")
        },
        // SET_TOTAL_GRADE_REPORT(state, studentDatas) {
        //     let totalGradeReport = []
        //     for (let i = 0; i < studentDatas.length; i++){
        //         // attendanceReport['student_id'] = studentDatas[i].id
        //         // attendanceReport['attendance_type_id'] = 1
        //         totalGradeReport.push({
        //             //student_id: studentDatas[i].id,
        //             total: 0,
        //             student_ratings_id: studentDatas[i].student_ratings_id
        //         })
        //     }
        //     state.totalGrade_form.totalGradeReport = totalGradeReport
        //     console.log(state.totalGrade_form.totalGradeReport, "totalGradeReport")
        // },
        SET_TOTAL_GRADE_REPORT(state, filteredStudents) {
                for (let i = 0; i < filteredStudents.length; i++){

                    state.totalGrade_form.totalGradeReport.push({
                        total: filteredStudents[i].student_ratings_total,
                        student_ratings_id: filteredStudents[i].student_ratings_id,
                        close_status: filteredStudents[i].student_ratings_close_status
                    })
                }
            console.log(state.totalGrade_form.totalGradeReport, "totalGradeReport")
        },
        // SET_EDUCATION_COURSES_ID(state, education_courses_id) {
        //     state.totalGrade_form.education_courses_id = education_courses_id
        // },
        UPDATE_TOTAL_GRADE_REPORT(state, value){
            let student_ratings_id = value.student_ratings_id
            let total = value.total


            let updateTotalGradeReport = state.totalGrade_form.totalGradeReport.find(i => i.student_ratings_id == student_ratings_id)
            updateTotalGradeReport['total'] = total

            console.log(state.totalGrade_form.totalGradeReport, "updateTotalGradeReport")

        },

    },
    state: {
        totalGrade_form: {
            courseInfos: [],
            studentGroups: [],
            students: [],
            totalGradeReport: []
        }
    },
    getters: {}
}

export default totalGrade