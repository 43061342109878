import securityEventsService from "@/services/events/securityEvents.service";

const securityEvents = {
  namespaced: true,
  actions: {
    async GET_EVENTS_FOR_SECURITY({commit}) {
      try {
        const {status, data} = await securityEventsService.getEventsForSecurity()
        if (status === 200) {
          console.log('GET_EVENTS_FOR_SECURITY', data)
          commit('SET_EVENTS_FOR_SECURITY', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_EVENTS_FOR_SECURITY(state, events) {
      state.events = events
    }
  },
  state: {
    events: []
  },
  getters: {}
}

export default securityEvents
