import httpClient from "@/services/http.service"
import router from "@/router"


const teacherPracticeService = {
    getPracticeStudents() {
        return httpClient.get(`student_data/student-practices/get-practice-students`)
    },
    postPracticeRatings(postData) {
        return httpClient.post(`student/student-practice-ratings/create`, postData)
    },

}

export default teacherPracticeService