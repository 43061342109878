import httpClient from "@/services/http.service"

const officeRegistrationEmailChangeService = {
    getSearchUser(name) {
        return httpClient.get(`student_data/student-data/get-user-data?name=${name}`)
    },
    putUserEmail(user_id, email) {
        return httpClient.get(`student_data/student-data/change-emails?user_id=${user_id}&email=${email}`)
    },
    postActiveAccount(user_id, active_directory) {
        return httpClient.get(`student_data/student-data/change-active-directory-status?user_id=${user_id}&active_directory=${active_directory}`)
    },
}

export default officeRegistrationEmailChangeService

