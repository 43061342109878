import officeRegistrationPrerequisitesMagistracyService
  from "@/services/office-registration/officeRegistrationPrerequisitesMagistracy.service"

const officeRegistrationPrerequisitesMagistracy = {
  namespaced: true,
  actions: {
    async GET_MASTERS_PREREQUISITES({commit}) {
      try {
        const {data} = await officeRegistrationPrerequisitesMagistracyService.getMastersPrerequisites()
        commit('SET_MASTERS_PREREQUISITES', data)

      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_PREREQUISITES_RATINGS({commit}, form) {
      try {
        await officeRegistrationPrerequisitesMagistracyService.postPrerequisitesRatings(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    }
  },
  mutations: {
    SET_MASTERS_PREREQUISITES(state, mastersPrerequisites) {
      state.mastersPrerequisites = mastersPrerequisites
    },
  },
  state: {
    mastersPrerequisites: []
  },
  getters: {}
}

export default officeRegistrationPrerequisitesMagistracy