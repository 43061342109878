import httpClient from "@/services/http.service"
const surveyService = {
  getSurveyStudents() {
    return httpClient.get(`sei/survey-students/get-survey-students`)
  },
  getSurveyQuestions() {
    return httpClient.get(`sei/survey-questions/index`)
  },
  getSurveyScore(){
    return httpClient.get('sei/survey-score/index')
  },
  postSurveyAnswer(answers){
    return httpClient.post('sei/survey-answers/create', answers)
  },
  getSurveySendData(objectData){
    return httpClient.post(`sei/survey-answers/get-survey-answers?student_id=${objectData.student_id}&semester=${objectData.semester}`)
  },
  getShortStudentInfo(student_id){
    return httpClient.get(`student_data/student-data/get-short-student-data-by-id?student_id=${student_id}`)
  }
}

export default surveyService