import httpClient from "@/services/http.service"
import router from "@/router"


const oldMoodleService = {
    getOldMoodleSummerCourse() {
        return httpClient.get(`old_ratings/old-student-ratings/get-old-moodle-summer-course`)
    },
}

export default oldMoodleService