import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

function getFIO(user) {
  return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
}

const commission = {
  namespaced: true,
  actions: {
    async GET_KKK_USERS({commit}, {page, teacher_name}) {
      page = page || 1
      teacher_name = teacher_name || 'none'
      try {
        const url = `kkk/active-directory/get-kkk-users?access-token=${getCookie('ACCESS_TOKEN')}&page=${page}&teacher_name=${teacher_name}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(Object.values(data.success), 'GET_KKK_USERS')
          commit('SET_KKK_USERS', Object.values(data.success))
          commit('SET_PAGE_COUNT', data.page_count)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async POST_ADD_TO_ACTIVE_DIRECTORY({state}, user_id) {
      console.log(state)
      try {
        const {
          status,
          data
        } = await httpClient.post('kkk/active-directory/add-to-active-directory?access-token=' + getCookie('ACCESS_TOKEN'), user_id);
        if (status === 200) {
          console.log(data, 'score created')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async POST_CHECKED_DISCIPLINE({state}, data) {
      console.log(state)
      try {
        const {
          status,
          data
        } = await httpClient.post('teaching_load/teacher-courses/create?access-token=' + getCookie('ACCESS_TOKEN'), data);
        if (status === 200) {
          console.log(data, 'teacher-courses created')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },


    async POST_CONTRACT({state}) {
      const stateContractData = state.contractData

      let additional_contract_status = stateContractData.contract_type == 1 ? stateContractData.additional_contract_status : 0
      let additional_contract_status2 = stateContractData.contract_type == 2 ? stateContractData.additional_contract_status2 : 0

      const contractData = {
        additional_contract_status,
        additional_contract_status2,
        user_id: stateContractData.user_id,
        contract_type: stateContractData.contract_type,
        work_start_date: new Date(stateContractData.work_start_date).getTime() / 1000,
        contract_start_date: new Date(stateContractData.contract_start_date).getTime() / 1000,
        contract_end_date: new Date(stateContractData.contract_end_date).getTime() / 1000
      }

      console.log(contractData, 'contractData POST')

      try {
        const {
          status,
          data
        } = await httpClient.post(`labor_contract/teacher-labor-contract/create-contract?access-token=${getCookie('ACCESS_TOKEN')}`, contractData);
        if (status === 200) {
          console.log(data, 'contractData created')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data
        }
      }
    },


  },
  mutations: {
    SET_KKK_USERS(state, kkkUsers) {
      state.kkkUsers = kkkUsers
    },
    SET_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount
    },
    SET_TEACHER_COURCES(state, teacherCources) {
      state.teacherCources = teacherCources
    },
    SET_CONTRACT_DATA(state, {property, value}) {
      if (property == 'contract_term') {
        const end_year = new Date(state.contractData['contract_start_date'])?.getFullYear() + +value;
        if (state.contractData['contract_type'] == 1) {
          state.contractData['contract_end_date'] = `${end_year}-08-31`
        }
      }
      if (property == 'contract_type') {
        state.contractData['contract_term'] = null
        if (value == 4) {
          state.contractData['contract_end_date'] = `2021-12-31`
        }
        if (value == 2) {
          state.contractData['contract_end_date'] = `2022-05-31`
          state.contractData['additional_contract_status2'] = 1
        }
        if (value != 1) {
          state.contractData['additional_contract_status'] = 0
        }
        if (value != 2) {
          state.contractData['additional_contract_status2'] = 0
        }
      }
      state.contractData[property] = value
    },
    CLEAR_CONTRACT_DATA(state, contractData) {
      state.contractData = {
        ...contractData,
        work_start_date: `${new Date().getFullYear()}-01-24`,
        contract_start_date: `${new Date().getFullYear()}-01-24`,
        contract_end_date: `${new Date().getFullYear() + 1}-08-31`,
      }
    }
  },
  state: {
    kkkUsers: [],
    teacherCources: [],
    pageCount: 0,
    contractData: {},
  },
  getters: {}
}


export default commission