import axios from 'axios'
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const writtenExamCoursesList = {
    namespaced: true,
    actions: {


        async GET_EDUCATION_COURSES_WRITTEN_EXAM({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-courses/get-written-exam-courses' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('GET_EDUCATION_COURSES_WRITTEN_EXAM', data.success)
                    commit('SET_EDUCATION_COURSES_WRITTEN_EXAM', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_EXAM_QUESTIONS_SEND_TO_PRINT(url) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('certificate/certificate/send-to-print?url='+ "%22"+ url+ "%22" + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    return data
                }

            } catch (e) {
                return null;
            }

        },
    },
    mutations: {
        SET_EDUCATION_COURSES_WRITTEN_EXAM(state, writtenExamCoursesInfos) {
            state.writtenExamCoursesList_form.writtenExamCoursesInfos = writtenExamCoursesInfos
        }

    },
    state: {
        writtenExamCoursesList_form: {
            writtenExamCoursesInfos: []
        },

    },
    getters: {

    }

}

export default writtenExamCoursesList