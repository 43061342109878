<template>
  <div class="container">
    <div class="my-courses mt-4 mb-4">
<!--      <h5>Полезные ссылки:</h5>-->
<!--      <ul>-->
<!--        <li>-->
<!--          <a href="https://www.youtube.com/watch?v=JqWeF83tbKk" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=JqWeF83tbKk')">Как создать тестовый экзамен?</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://www.youtube.com/watch?v=QohuIeys4dI" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=QohuIeys4dI')">Как создать письменный экзамен?</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://uibkz-my.sharepoint.com/:f:/g/personal/cit_adm2_uib_kz/EuJq&#45;&#45;PjBBFNqTn4knMqO40Bck2BmD7lUwml_KopSyaj7g?e=fJB0Ke" target="_blank"-->
<!--             @click="saveFaqLog('https://uibkz-my.sharepoint.com/:f:/g/personal/cit_adm2_uib_kz/EuJq&#45;&#45;PjBBFNqTn4knMqO40Bck2BmD7lUwml_KopSyaj7g?e=fJB0Ke')">-->
<!--            Добавление вопросов в Moodle используя программу Word 2003-->
<!--          </a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://www.youtube.com/watch?v=ime42OjjIVE" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=ime42OjjIVE')">Как импортировать вопросы новой системы moodle?</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://www.youtube.com/watch?v=K6_rEX5No6E" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=K6_rEX5No6E')">Как импортировать банк вопросов из oldmoodle?</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://www.youtube.com/watch?v=5xsNQ2lUicQ" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=5xsNQ2lUicQ')">Как выставлять оценки за экзамен (устно, take home, проект)?</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://youtu.be/-ksKPXvSidI?si=DJ-SoZefdiguSlrt" target="_blank" @click="saveFaqLog('https://youtu.be/-ksKPXvSidI?si=DJ-SoZefdiguSlrt')">Работа с front.uib.kz</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://youtu.be/ZwXNBwJPSfA?si=0l0DU2Duo0HBL3jc" target="_blank" @click="saveFaqLog('https://youtu.be/ZwXNBwJPSfA?si=0l0DU2Duo0HBL3jc')">Работа с moodle.uib.kz</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://youtu.be/V7sohHHQhwQ" target="_blank" @click="saveFaqLog('https://youtu.be/V7sohHHQhwQ')">Критерии и варианты оценивания</a>-->
<!--        </li>-->
<!--      </ul>-->
      <h4 class="text-center">Мои курсы</h4>
      <SyllabusModal/>

      <DataTable class="mt-4" :value="teacher_form.teacherCoursesInfos" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 showGridlines stripedRows responsiveLayout="scroll">
        <Column header="№">
          <template #body="{index}">
            {{index + 1}}
          </template>
        </Column>

        <Column field="course_name" header="Название курса">
          <template #body="{data}">
            <a :href="'https://moodle.uib.kz/course/view.php?id='+ data.mdl_course_id" target="_blank">{{data.course_name}}</a>
          </template>
        </Column>
<!--        <Column field="studentGroups.name" header="Группа"></Column>-->
        <Column field="educationCurriculum.spAcademicYear.name" header="Год обучения"></Column>

        <Column header="Силлабус">
          <template #body="{data}">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                    v-if="data.syllabus_status == 0"
                    data-bs-target="#syllabusModal"
                    @click="openSyllabusModal(data.mdl_course_id, data.id)" :disabled="data.lecture_pps_id != getUser_id && data.seminar_pps_id == getUser_id">
              Импортировать
            </button>
            <div v-else>
              <p style="color: green">Силлабус уже импортирован</p>
            </div>
          </template>
        </Column>

        <Column header="Тип экзамена">
          <template #body="{data}" >
            <div class="mb-2" >
              <!--              <button :class="['btn btn-sm', data.exam_type_id != null ? 'btn-light' : 'btn-primary']"-->
              <!--                      @click="chooseExamType(data.mdl_course_id)" :disabled="data.lecture_pps_id != getUser_id && data.seminar_pps_id == getUser_id || (data.created_at < 1704045600)">-->
              <!--                {{ data.exam_type_id != null ? data.spExamType.name : 'Выбрать тип экзамена' }}-->
              <!--              </button>-->
              <button :class="['btn btn-sm', data.exam_type_id != null ? 'btn-light' : 'btn-primary']"
                      :disabled="true">
                {{ data.exam_type_id != null ? data.spExamType.name : 'Выбрать тип экзамена' }}
              </button>

            </div>

            <div v-if="!data.mdlCategory&&data?.spExamType?.id==2" >
              <a href="#" @click="chooseQuestionBankCategory(data.mdl_course_id)" class="btn btn-primary btn-sm">
                Выбрать категорию из банка вопросов
              </a>
            </div>
            <div v-else-if="data.mdlCategory">
              Категория выбрана
            </div>

<!--            <div v-else-if="getRole=='admin'&&data.mdlCategory">-->
<!--              <router-link-->
<!--                  :to="`/questions?parent_category_id=${data.mdlCategory?.mdl_category_id}`"-->
<!--                  target="_blank">-->
<!--                Список вопросов-->
<!--              </router-link>-->
<!--            </div>-->

          </template>
        </Column>


        <Column header="Действие">

          <template #body="{data}">

            <ul>
              <li>
                <router-link :to="'/journal-rating?mdl_course_id=' + data.mdl_course_id + '&type=vsk1'" target="_blank">
                  ВСК 1
                </router-link>
              </li>
              <li>
                <router-link :to="'/journal-rating?mdl_course_id=' + data.mdl_course_id + '&type=vsk2'" target="_blank">
                  ВСК 2
                </router-link>
              </li>
              <li>
                <router-link :to="'/final-exam?mdl_course_id=' + data.mdl_course_id + '&education_courses_id=' + data.id" target="_blank">
                  Экзамен
                </router-link>
              </li>
              <li>
                <router-link :to="'/total-grade?mdl_course_id=' + data.mdl_course_id + '&education_courses_id=' + data.id" target="_blank">
                  Итоговый
                </router-link>
              </li>
<!--              <li>-->
<!--                <router-link-->
<!--                    :to="'/attendance-report?mdl_course_id=' + data.mdl_course_id + '&education_courses_id=' + data.id"-->
<!--                    target="_blank">-->
<!--                  Посещаемость-->
<!--                </router-link>-->
<!--              </li>-->


            </ul>


          </template>


        </Column>

      </DataTable>
    </div>





    <Dialog header="Выбор типа экзамена" v-model:visible="displayChooseExamType" :modal="true" :style="{width: '50vw'}">

      <div>
        <div class="row mt-4">
          <div class="col-md-3">
            <p>Тип экзамена</p>
          </div>
          <div class="col-md-9">
            <select class="form-control form-select"
                    @input="changeEducationCourseExamType('exam_type_id', $event)">
              <option v-for="(examTypes, index) in [{id: 0, name: 'Выберите тип экзамена'}, ...teacher_form.examTypes]"
                      :value="examTypes.id"
                      :key="index">{{ examTypes.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <template #footer>
        <Button label="Отмена" icon="pi pi-times" class="p-button-text" @click="closeChooseExamType"/>
        <Button label="Сохранить" icon="pi pi-check" @click="putEducationCourseExamType"/>
      </template>

    </Dialog>

    <Dialog header="Выбор категории из банка вопросов для экзамена" v-model:visible="displayChooseQuestionBankCategory" :modal="true"
            @update:visible="closeChooseQuestionBankCategory">
      <div>
        <div v-for="(category, categoryIndex) in teacher_form.questionCategories" :key="categoryIndex" class="form-check">
          <input class="form-check-input" type="radio" :id="'categoryIndex'+categoryIndex" :value="category.id" v-model="categoryId">
          <label class="form-check-label" :for="'categoryIndex'+categoryIndex">{{category.name}}</label>
        </div>
      </div>

      <template #footer>
        <Button label="Отмена" icon="pi pi-times" class="p-button-text" @click="closeChooseQuestionBankCategory"/>
        <Button label="Сохранить" icon="pi pi-check" @click="saveQuizCategory" :disabled="!categoryId"/>
      </template>

    </Dialog>

  </div>
</template>

<script>
  import axios from 'axios';
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import SyllabusModal from '@/components/teacher/SyllabusModal.vue'
  import httpClient from "@/services/http.service";
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: "MyCourses",
    components: {
      SyllabusModal
    },

    data() {
      return {
        importToMoodleBtnDisabled: false,
        displayGroupOfStudentsModal: false,
        openGroupData: null,
        displayChooseExamType: false,
        displayChooseQuestionBankCategory: false,
        categoryId: null,
        mdlCourseId: null
      }
    },
    computed: {
      ...mapState('teacher', ['teacher_form']),
      getUser_id () {
        return getCookie('USER_ID');
      }
    },

    methods: {
      ...mapActions('teacher', ['GET_EXAM_TYPES', 'GET_EDUCATION_COURSES_DATA_BY_PPS_ID', 'GET_SELECTABLE_COURSES_DATA_BY_PPS_ID', 'PUT_EDUCATION_COURSE_EXAM_TYPE',
        'GET_QUESTION_BANK_PARENT_CATEGORIES_BY_MDL_COURSE_ID',
      'POST_QUIZ_CATEGORY'
      ]),
      ...mapMutations('teacher', ['SET_EDUCATION_COURSES_ID', 'SET_MDL_COURSE_ID', 'SET_EDUCATION_COURSE_EXAM_TYPE']),
      importToMoodle() {
        return this.importToMoodleBtnDisabled
      },

      saveFaqLog(videoUrl) {
        console.log(videoUrl, 'videoUrl')
        this.POST_FAQ_LOG(videoUrl)
      },

      async POST_FAQ_LOG(videoUrl) {
        try {
          let url = 'api/faq-log/create' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&url='+ videoUrl

          const {
            status,
            data
          } = await httpClient.post(url);
          if (status === 200) {
            console.log(data, 'saved')
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            error: e.response.data.errors
          }
        }
      },

      openSyllabusModal(mdl_course_id, education_courses_id) {
        console.log(mdl_course_id, 'mdl_course_id')
        console.log(education_courses_id, 'education_courses_id')
        this.SET_EDUCATION_COURSES_ID(education_courses_id)
        this.SET_MDL_COURSE_ID(mdl_course_id)
      },
      chooseExamType(mdl_course_id) {
        this.teacher_form.educationCourseExamInfos = {}
        this.displayChooseExamType = true
        this.SET_MDL_COURSE_ID(mdl_course_id)
      },
      closeChooseExamType() {
        this.displayChooseExamType = false
      },
      async chooseQuestionBankCategory(mdl_course_id) {
        await this.GET_QUESTION_BANK_PARENT_CATEGORIES_BY_MDL_COURSE_ID(mdl_course_id)
        this.mdlCourseId = mdl_course_id
        this.displayChooseQuestionBankCategory = true
      },
      closeChooseQuestionBankCategory() {
        this.displayChooseQuestionBankCategory = false
        this.categoryId = null
      },
      async saveQuizCategory(){
        console.log(this.categoryId, this.mdlCourseId)
        await this.POST_QUIZ_CATEGORY({mdl_course_id: this.mdlCourseId, mdl_category_id: this.categoryId})
        await this.GET_EDUCATION_COURSES_DATA_BY_PPS_ID()
        this.closeChooseQuestionBankCategory()
      },
      changeEducationCourseExamType(property, e, val = 'value') {
        const value = e.target[val]
        console.log(value, "value")
        this.SET_EDUCATION_COURSE_EXAM_TYPE({property, value})
      },
      async putEducationCourseExamType() {

        await this.PUT_EDUCATION_COURSE_EXAM_TYPE()
        this.displayChooseExamType = false
        await this.GET_EDUCATION_COURSES_DATA_BY_PPS_ID()

      }

    },
    async mounted() {
      await this.GET_EXAM_TYPES()
      await this.GET_EDUCATION_COURSES_DATA_BY_PPS_ID()
      await this.GET_SELECTABLE_COURSES_DATA_BY_PPS_ID()
    }

  }
</script>

<style scoped>

</style>
