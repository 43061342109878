import httpClient from "@/services/http.service"

const kpiTeachersDepartmentHeadService = {
  getKpiTeachersByDepartmentId() {
    return httpClient.get(`kpi/user-kpi/get-users-kpi-by-department-id`)
  },

  putKpiTeacherStatus(id, status, comment) {
    console.log(id, status)
    return httpClient.put(`kpi/user-kpi/update?id=${id}`, {status: status, comment: comment})
  },

}

export default kpiTeachersDepartmentHeadService