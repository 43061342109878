import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const studentsList = {
  namespaced: true,
  actions: {

    async GET_STUDY_LEVEL_NAMES({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/study-level/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_STUDY_LEVEL_NAMES', data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async GET_EDUCATION_PROGRAMS_BY_NAME({commit, state}, name = '') {
      const url = 'education_program/education-program/get-programs-by-name' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&name=' + name
      try {
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data.success, 'GET_EDUCATION_PROGRAMS_BY_NAME')
          return data.success
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_STUDENT_GROUPS_BY_NAME({commit, state}, name = '') {
      const url = 'admin/student-groups/get-student-groups-by-name' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&name=' + name
      try {
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data.success, 'GET_STUDENT_GROUPS_BY_NAME')
          return data.success
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_STUDENTS_LIST({commit}, studentObjectData) {
      try {
        const {
          status,
          data
        } = await httpClient.get('student_data/student-data/index' + '?course_id=' + studentObjectData.course_id + '&name=' + studentObjectData.name + '&page=' + studentObjectData.page);
        if (status === 200) {
          commit("SET_STUDENTS_LIST", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async PUT_STUDENT_DATA({commit, state}) {

      //let studentDataForm = state.studentsList_form.studentsListInfos.filter(v => v.id == state.studentsList_form.editStudentId)
      let studentDataForm = state.studentsList_form.newStudentsListInfos
      console.log(studentDataForm, 'studentDataForm')

      try {

        const {
          status,
          data
        } = await httpClient.put('student_data/student-data/update' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + state.studentsList_form.editStudentId, studentDataForm);
        if (status === 200) {
          console.log(data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async PUT_STUDENT_DEBT({commit, state}) {
      let studentBarcode = state.studentsList_form.barcode
      try {

        const {
          status,
          data
        } = await httpClient.put('exam/exam-access/give-access' + '?access-token=' + getCookie('ACCESS_TOKEN'), {barcode: studentBarcode});
        if (status === 200) {
          console.log(data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async POST_STUDENT_PREVIOUS_EDUCATION_DOCUMENT({commit, state}) {
      let studentPreviousEducationDocumentForm = state.studentsList_form.studentPreviousEducationDocumentInfos
      studentPreviousEducationDocumentForm['student_id'] = state.studentsList_form.previousEducationDocumentStudentId
      console.log(studentPreviousEducationDocumentForm)
      try {

        const {
          status,
          data
        } = await httpClient.post('student_data/student-previous-education-documents/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), studentPreviousEducationDocumentForm);
        if (status === 200) {
          console.log(data)
        }
        return {
          data: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async UPDATE_STUDENT_PREVIOUS_EDUCATION_DOCUMENT({commit, state}) {
      let studentPreviousEducationDocumentForm = state.studentsList_form.studentPreviousEducationDocumentInfos
      studentPreviousEducationDocumentForm['student_id'] = state.studentsList_form.previousEducationDocumentStudentId
      console.log('klasdlasd', studentPreviousEducationDocumentForm)
      try {
        const {status, data} = await httpClient.put('student_data/student-previous-education-documents/update' +
            '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + studentPreviousEducationDocumentForm['student_id'], studentPreviousEducationDocumentForm);
        if (status === 200) {
          console.log(data)
        }
        return {
          data: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async POST_STUDENT_ENTERED_UNIVERSITY({commit, state}) {
      let studentEnteredUniversityForm = state.studentsList_form.studentEnteredUniversityData
      studentEnteredUniversityForm['student_id'] = state.studentsList_form.enteredUniversityStudentId
      console.log(studentEnteredUniversityForm)
      try {
        const {status, data} = await httpClient.post('student_data/student-entered-university-data/create'
            + '?access-token=' + getCookie('ACCESS_TOKEN'), studentEnteredUniversityForm);
        if (status === 200) {
          console.log(data)
        }
        return {
          data: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async UPDATE_STUDENT_ENTERED_UNIVERSITY({commit, state}) {
      let studentEnteredUniversityForm = state.studentsList_form.studentEnteredUniversityData
      studentEnteredUniversityForm['student_id'] = state.studentsList_form.enteredUniversityStudentId
      console.log('1231231231')
      console.log('klasdlasd', studentEnteredUniversityForm)
      try {
        const {status, data} = await httpClient.put('student_data/student-entered-university-data/update' +
            '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + studentEnteredUniversityForm['student_id'],
            studentEnteredUniversityForm);
        if (status === 200) {
          console.log(data)
        }
        return {
          data: data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async PUT_EXAM_GRADE({commit, state}) {
      let examGradeForm = state.studentsList_form.updateExamGradeInfo
      try {
        const {
          status,
          data
        } = await httpClient.post('student/student-ratings/update-exam-grades' + '?access-token=' + getCookie('ACCESS_TOKEN'), examGradeForm);
        if (status === 200) {
          console.log(data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async POST_UPDATE_STUDENT_DATA({commit}, form) {
      try {
        await httpClient.put('student_data/student-data/update'+ '?id='+ form.student_id + '&access-token=' + getCookie('ACCESS_TOKEN'), form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_UPDATE_STUDENT_ADDITIONAL_DATA({commit}, form) {
      try {
        await httpClient.put('student_data/student-data-additional/update'+ '?id='+ form.student_data_id + '&access-token=' + getCookie('ACCESS_TOKEN'), form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_OBJECT_EDUCATION_DOCUMENT_NULL(state){
      state.studentsList_form.studentPreviousEducationDocumentInfos = {};
    },
    SET_OBJECT_UNIVERSITY_DOCUMENT_NULL(state) {
      state.studentsList_form.studentEnteredUniversityData = {};
    },

    SET_STUDY_LEVEL_NAMES(state, studyLevel) {
      state.studentsList_form.studyLevel = studyLevel
    },
    SET_STUDENTS_LIST(state, studentsListInfos) {
      console.log('123123123', studentsListInfos)
      state.studentsList_form.studentsListInfos = studentsListInfos.query
      state.studentsList_form.pages = studentsListInfos.pages
    },

    SET_SELECTED_EDUCATION_PROGRAM_ID(state, education_program_id) {
      //let editStudent = state.studentsList_form.studentsListInfos.find(v => v.id == state.studentsList_form.editStudentId)
      //editStudent['education_program_id'] = education_program_id
      state.studentsList_form.newStudentsListInfos['education_program_id'] = education_program_id
      console.log(state.studentsList_form.newStudentsListInfos, "newStudentsListInfos")
    },
    SET_SELECTED_STUDENT_GROUP_ID(state, study_group_id) {
      //let editStudent = state.studentsList_form.studentsListInfos.find(v => v.id == state.studentsList_form.editStudentId)
      //editStudent['education_program_id'] = education_program_id
      state.studentsList_form.newStudentsListInfos['study_group_id'] = study_group_id
      console.log(state.studentsList_form.newStudentsListInfos, "newStudentsListInfos")
    },
    SET_SELECTED_STUDENT_COURSE(state, study_course) {
      //let editStudent = state.studentsList_form.studentsListInfos.find(v => v.id == state.studentsList_form.editStudentId)
      //editStudent['education_program_id'] = education_program_id
      state.studentsList_form.newStudentsListInfos['study_course'] = study_course
      console.log(state.studentsList_form.newStudentsListInfos, "newStudentsListInfos")
    },
    SET_SELECTED_STUDENT_STUDY_LEVEL_ID(state, study_level_id) {
      //let editStudent = state.studentsList_form.studentsListInfos.find(v => v.id == state.studentsList_form.editStudentId)
      //editStudent['education_program_id'] = education_program_id
      state.studentsList_form.newStudentsListInfos['study_level_id'] = study_level_id
      console.log(state.studentsList_form.newStudentsListInfos, "newStudentsListInfos")
    },
    SET_SELECTED_STUDENT_STUDY_LANGUAGE_ID(state, study_language_id) {
      //let editStudent = state.studentsList_form.studentsListInfos.find(v => v.id == state.studentsList_form.editStudentId)
      //editStudent['education_program_id'] = education_program_id
      state.studentsList_form.newStudentsListInfos['study_language_id'] = study_language_id
      console.log(state.studentsList_form.newStudentsListInfos, "newStudentsListInfos")
    },
    SET_EDIT_STUDENT_ID(state, editStudentId) {
      state.studentsList_form.editStudentId = editStudentId
      console.log(state.studentsList_form.editStudentId, "editStudentId")
    },
    SET_BARCODE(state, barcode) {
      state.studentsList_form.barcode = barcode
      console.log(state.studentsList_form.barcode = barcode, "barcode")
    },
    UPDATE_EXAM_GRADE_RATING_ID(state, rating_id) {
      state.studentsList_form.updateExamGradeInfo.push({
        student_ratings_id: rating_id,
        exam: 0
      })
      console.log(state.studentsList_form.updateExamGradeInfo, "updateExamGradeInfo")
    },
    UPDATE_EXAM_GRADE(state, exam) {
      state.studentsList_form.updateExamGradeInfo[0].exam = exam
      console.log(state.studentsList_form.updateExamGradeInfo, "updateExamGradeInfo")
    },
    CLEAR_EXAM_GRADE_REPORT(state) {
      state.studentsList_form.updateExamGradeInfo = []
    },

    SET_STUDENT_PREVIOUS_EDUCATION_DOCUMENT_DATA(state, {property, value}) {

      state.studentsList_form.studentPreviousEducationDocumentInfos[property] = value

      console.log(state.studentsList_form.studentPreviousEducationDocumentInfos, 'studentPreviousEducationDocumentInfos')
    },
    SET_PREVIOUS_EDUCATION_DOCUMENT_STUDENT_ID(state, student_id) {
      state.studentsList_form.previousEducationDocumentStudentId = student_id

      console.log(state.studentsList_form.previousEducationDocumentStudentId, 'STATE previousEducationDocumentStudentId')
    },

    SET_STUDENT_ENTERED_UNIVERSITY_DATA(state, {property, value}) {

      state.studentsList_form.studentEnteredUniversityData[property] = value
      console.log(state.studentsList_form.studentEnteredUniversityData, 'studentEnteredUniversityData')
    },
    SET_ENTERED_UNIVERSITY_STUDENT_ID(state, student_id) {
      state.studentsList_form.enteredUniversityStudentId = student_id

      console.log(state.studentsList_form.enteredUniversityStudentId, 'STATE enteredUniversityStudentId')
    },

  },
  state: {
    studentsList_form: {
      studentsListInfos: [],
      newStudentsListInfos: {},
      editStudentId: null,
      barcode: null,
      studyLevel: [],
      pages: null,
      languages: [
        {id: 39, name: 'English'},
        {id: 82, name: 'Қазақ тілі '},
        {id: 137, name: 'Русский язык '}
      ],
      updateExamGradeInfo: [],
      studentPreviousEducationDocumentInfos: {},
      previousEducationDocumentStudentId: null,
      studentEnteredUniversityData: {},
      enteredUniversityStudentId: null
    },

  },
  getters: {}

}

export default studentsList
