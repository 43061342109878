import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";
import axios from "axios";

const syllabusContent = {
    namespaced: true,
    actions: {
        async POST_SYLLABUS_CONTENT_DATA({commit, state}) {
            try {
                const {status} = await httpClient.post('syllabus_data/syllabus-content/create-questions', state.syllabusContent_form.newSyllabusContentInfos);
                return status == 200;
            } catch (e) {
                console.log(e.response);
                commit('SET_ERROR', e, {root: true})
                return false;
            }
        },

        async DELETE_SYLLABUS_CONTENT_QUESTION({commit}, id) {
            try {
                const {status} = await httpClient.delete(`syllabus_data/syllabus-content/delete?id=${id}`);
                return status == 200;
            } catch (e) {
                console.log(e.response);
                commit('SET_ERROR', e, {root: true})
                return false;
            }
        },

        async POST_SYLLABUS_CONTENT_FORM_SYLLABUS({commit}, syllabus_id) {
            try {
                const {status} = await httpClient.post('syllabus_data/syllabus/save-syllabus', {syllabus_id: syllabus_id});
                return status == 200;
            } catch (e) {
                console.log(e.response);
                commit('SET_ERROR', e, {root: true})
                return false;
            }
        },

        async PUT_SYLLABUS_CONTENT_DATA({commit, state}, syllabus_id) {

            let syllabusContentForm = state.syllabusContent_form.syllabusContentInfos
            console.log(syllabusContentForm, 'syllabusContentForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.put('syllabus_data/syllabus-content/update-questions'+ '?syllabus_id=' + syllabus_id + '&access-token=' + getCookie('ACCESS_TOKEN'), syllabusContentForm);
                if (status === 200) {
                    console.log(data, 'update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },
        async GET_SYLLABUS_CONTENT_DATA({commit}, syllabus_id) {
            try {
                const url = 'syllabus_data/syllabus-content/index-questions' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&syllabus_id=' + syllabus_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'data')
                    if (Object.keys(data.weeks).length === 0){
                        console.log(data, 'GET_SYLLABUS_CONTENT_DATA1')
                        commit("SET_SYLLABUS_CONTENT_DATA", {})
                    } else {
                        console.log(data, 'GET_SYLLABUS_CONTENT_DATA2')
                        commit("SET_SYLLABUS_CONTENT_DATA", data)
                    }
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_SYLLABUS_DATA_BY_ID({commit}, syllabus_id) {
            try {
                const url = 'syllabus_data/syllabus-content/get-syllabus-by-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + syllabus_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log('GET_SYLLABUS_DATA_BY_ID', data.data)
                    commit("SET_SYLLABUS_DATA_EVALUATION_OPTION", data.data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_NEW_SYLLABUS_CONTENT_DATA(state, {key, property, value, question_index}) {
            console.log('key', key, 'property', property, 'value', value)
            if (question_index !== null){
                state.syllabusContent_form.newSyllabusContentInfos['weeks'][key][property][question_index].text = value;
            } else {
                state.syllabusContent_form.newSyllabusContentInfos['weeks'][key][property] = value;
            }
            console.log(state.syllabusContent_form.newSyllabusContentInfos, 'STATE newSyllabusContentInfos')
        },
        SET_SYLLABUS_CONTENT_DATA(state, syllabusContentInfos) {
            state.syllabusContent_form.syllabusContentInfos = syllabusContentInfos
            console.log(state.syllabusContent_form.syllabusContentInfos, 'STATE syllabusContentInfos')
        },
        SET_URL_SYLLABUS_ID(state, urlSyllabusId) {
            state.syllabusContent_form.urlSyllabusId = urlSyllabusId
        },
        SET_SYLLABUS_DATA_EVALUATION_OPTION( state, data) {
            state.syllabusContent_form.syllabusData = data
        },
        UPDATE_SYLLABUS_CONTENT_DATA(state, {property, value}) {
            // var index = state.syllabusContent_form.syllabusContentInfos.indexOf(property);
            //
            // if (index > 0) {
            //     state.syllabusContent_form.syllabusContentInfos[index] = value;
            // }
            state.syllabusContent_form.syllabusContentInfos.weeks[property] = value

            console.log(state.syllabusContent_form.syllabusContentInfos, 'STATE syllabusContentInfos')
        },
        UPDATE_NEW_SYLLABUS_CONTENT_DATA(state, {key, property, value, question_index}) {
            if (question_index !== null){
                state.syllabusContent_form.syllabusContentInfos['weeks'][key][property][question_index].text = value;
            } else {
                state.syllabusContent_form.syllabusContentInfos['weeks'][key][property] = value;
            }
            console.log(state.syllabusContent_form.syllabusContentInfos, 'STATE syllabusContentInfos')
        },
    },
    state: {
        syllabusContent_form: {
            syllabusData: {},
            newSyllabusContentInfos: {},
            syllabusContentInfos: {},
            urlSyllabusId: null,
        }
    },
    getters: {

    }

}

export default syllabusContent