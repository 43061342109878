import httpClient from "@/services/http.service"

const luscherService = {
  getLuscherColors() {
    return httpClient.get(`sei/lusher-colors/index`)
  },
  postLuscherAnswers(answers) {
    return httpClient.post(`sei/lusher-answers/create`, answers)
  },
  getLuscherAnswers() {
    return httpClient.get(`sei/lusher-answers/get-lusher-answer`)
  },
}

export default luscherService