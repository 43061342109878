import commissionScoreService from "@/services/kkk/commission/commissionScore.service"
import axios from 'axios'

const commissionScore = {
  namespaced: true,
  actions: {
    async GET_MEMBER_STATUS({commit}) {
      try {
        const {data} = await commissionScoreService.getMemberStatus()
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return null
    },
    async GET_COMMISSION_MEMBERS({commit}) {
      try {
        const {data} = await commissionScoreService.getCommissionMembers()
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_MEMBER_STATUS({commit}, form) {
      try {
        await commissionScoreService.postMemberStatus(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_MEMBER_STATUS({commit}, form) {
      try {
        await commissionScoreService.putMemberStatus(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async GET_CANDIDATE_LIST({commit}) {
      try {
        const {data} = await commissionScoreService.getCandidateList();
        if (data) {
          console.log(data, 'GET_CANDIDATE_LIST')
          commit('SET_CANDIDATE_LIST', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_DISCIPLINE_TEACHING_SCORE_CRITERION({commit}) {
      try {
        const {data} = await commissionScoreService.getDisciplineTeachingScoreCriterion();
        if (data) {
          console.log(data, 'GET_DISCIPLINE_TEACHING_SCORE_CRITERION')
          commit('SET_DISCIPLINE_TEACHING_SCORE_CRITERION', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_TEACHER_PROFILE_SCORE_CRITERION({commit}) {
      try {
        const {data} = await commissionScoreService.getTeacherProfileScoreCriterion();
        if (data) {
          console.log(data, 'GET_TEACHER_PROFILE_SCORE_CRITERION')
          commit('SET_TEACHER_PROFILE_SCORE_CRITERION', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_TEACHER_PROFILE_SCORE({commit, state}, form) {

      console.log(form, 'post form')
      try {
        const {data} = await commissionScoreService.postTeacherProfileScore(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_DISCIPLINE_TEACHING_SCORE({commit, state}, form) {

      console.log(form, 'post form')
      try {
        const {data} = await commissionScoreService.postDisciplineTeachingScore(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_ANONYMOUS_VOTE({commit, state}, form) {

      console.log(form, 'post form')
      try {
        const {data} = await commissionScoreService.postAnonymousVote(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_DISCIPLINE_ANONYMOUS_VOTE({commit, state}, form) {

      console.log(form, 'post form')
      try {
        const {data} = await commissionScoreService.postDisciplineAnonymousVote(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


  },
  mutations: {
    SET_CANDIDATE_LIST(state, candidateList) {
      state.commissionScore_form.candidateList = candidateList
    },
    SET_DISCIPLINE_TEACHING_SCORE_CRITERION(state, disciplineTeachingScoreCriterion) {
      state.commissionScore_form.disciplineTeachingScoreCriterion = disciplineTeachingScoreCriterion
    },
    SET_TEACHER_PROFILE_SCORE_CRITERION(state, teacherProfileScoreCriterion) {
      state.commissionScore_form.teacherProfileScoreCriterion = teacherProfileScoreCriterion
    },
  },
  state: {
    commissionScore_form: {
      candidateList: [],
      disciplineTeachingScoreCriterion: [],
      teacherProfileScoreCriterion: []
    }
  },
  getters: {}
}

export default commissionScore