import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

function getFIO(user) {
    return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
}

const commission = {
    namespaced: true,
    actions: {
        async GET_SCORE_BY_USERID({commit}, kkk_interview_id) {
            try {
                const url = `kkk/kkk-user-criterion-score/get-resume-scores-by-user-id?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_id=${kkk_interview_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_SCORE_BY_USERID')
                    commit('SET_SCORE_INFO', data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_SCORE_USERS({state, dispatch, commit}, usersId) {
            try {
                let users = []
                let stateScoreUser = 0

                for (const userId of usersId) {
                    // stateScoreUser = state.scoreUsers[userId.userId]

                    // if (false) {
                    // users.push({name: stateScoreUser.name, scoreComment: stateScoreUser.scoreComment})
                    // } else {
                    const userRes = await dispatch("GET_USER_INFO", userId.userId)

                    const userFIO = userRes.data.success ? getFIO(userRes.data.success) : userId.userId
                    users.push({name: userFIO, scoreComment: userId.scoreComment})

                    // console.log('userRes: ', userRes)
                    // console.log('score user: ', {id: userId.userId, name: userFIO})

                    // commit('SET_SCORE_USER', {id: userId.userId, name: userFIO, scoreComment: userId.scoreComment})
                    // }
                }
                // console.log('state users', state.scoreUsers)
                // console.log('users', users)
                commit('SET_SCORE_USERS_NAME', users)
            } catch (e) {
                console.error('GET_SCORE_USERS ERROR', e)
            }
        },
        async GET_CANDIDATE_LIST({commit, dispatch, state}, all = 0) {
            try {

                const url = 'kkk/kkk-interview-time/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&all=' + all
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'KKK INTERVIEW TIME DB')

                    for (let v of data.success) {
                        // v.count = await dispatch("GET_RESUME_SCORE", v.id)
                        // v.vote = await dispatch("GET_VOTE_SCORE", v.id)
                        // v.score_sum = await dispatch("GET_RESUME_SCORE_SUM", v.id)
                        // v.permission = await dispatch("GET_ANOMYMOUS_VOTE_PERMISSION", v.id)
                        let yes = 0
                        let no = 0
                        // let votes = await dispatch("GET_VOTE_ALL", v.id)
                        // let scores = await dispatch("GET_RESUME_SCORES", v.id)

                        let votes = v.votes
                        let scores = v.scores
                        console.log('GET_RESUME_SCORES', scores)

                        let usersId = []
                        for (const vote of votes) {
                            // usersId.push(vote.commission_user)

                            if (vote.vote == 1) {
                                yes++
                            } else {
                                no++
                            }
                        }

                        v.votesum = {yes, no};
                        v.usersId = scores.map(item => ({
                            userId: item.commission_user_id,
                            scoreComment: item.score_comment
                        }))
                    }
                    console.log(data.success, 'helolo asdasda')
                    commit("SET_CANDIDATE_LIST", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_CRITERION_DATA({commit}) {
            try {
                const url = `kkk/kkk-criterion/index?access-token=${getCookie('ACCESS_TOKEN')}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'SET_CRITERION_DATA')
                    commit('SET_CRITERION_DATA', data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_KKK_USER_CRITERION_SCORE({state}) {
            console.log(state.candidateCriterionScoreInfos)
            try {
                const {
                    status,
                    data
                } = await httpClient.post('kkk/kkk-user-criterion-score/create?access-token=' + getCookie('ACCESS_TOKEN'), state.candidateCriterionScoreInfos);
                if (status === 200) {
                    console.log(data, 'score created')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_RESUME_SCORE({commit}, kkk_interview_id) {
            try {
                const url = `kkk/kkk-user-criterion-score/get-resume-score?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_id=${kkk_interview_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_RESUME_SCORES({commit}, kkk_interview_id) {
            try {
                const url = `kkk/kkk-user-criterion-score/get-resume-scores?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_id=${kkk_interview_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_VOTE_SCORE({commit}, kkk_interview_time_id) {
            try {
                const url = `kkk/kkk-anonymous-vote/get-vote?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_time_id=${kkk_interview_time_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_VOTE_SCORE')
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_RESUME_SCORE_SUM({commit}, kkk_interview_time_id) {
            try {
                const url = `kkk/kkk-user-criterion-score/get-average-sum?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_id=${kkk_interview_time_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_KKK_USER_VOTE({state}, {kkk_interview_time_id, vote}) {
            let params = [{kkk_interview_time_id, vote}]
            try {
                const {
                    status,
                    data
                } = await httpClient.post('kkk/kkk-anonymous-vote/create?access-token=' + getCookie('ACCESS_TOKEN'), params);
                if (status === 200) {
                    console.log(data, 'score created')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_VOTE_ALL({commit}, kkk_interview_time_id) {
            try {
                const url = `kkk/kkk-anonymous-vote/get-all-votes?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_time_id=${kkk_interview_time_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_USER_INFO({commit}, userId) {
            try {
                const url = `questionnaire/pps-data/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    return {
                        data
                    }
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_ANOMYMOUS_VOTE_PERMISSION({state, commit}, kkk_interview_time_id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.post('kkk/anonymous-permission-vote/create?access-token=' + getCookie('ACCESS_TOKEN'),
                    {'kkk_interview_time_id': kkk_interview_time_id, 'permission': 1});
                if (status === 200) {
                    console.log(data, 'Nurkhan')
                    commit('SET_ANONYMOUS_PERMISSION', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_ANOMYMOUS_VOTE_PERMISSION({commit}, kkk_interview_time_id) {
            try {
                const url = `kkk/anonymous-permission-vote/get-permission?access-token=${getCookie('ACCESS_TOKEN')}&kkk_interview_time_id=${kkk_interview_time_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

    },
    mutations: {
        SET_CANDIDATE_LIST(state, candidateListData) {
            state.candidateListData = candidateListData
        },
        SET_CRITERION_DATA(state, criterionData) {
            state.criterionData = criterionData
            console.log(state.criterionData, 'state criterion data')
        },
        UPDATE_CANDIDATE_CRITERION_SCORE_DATA(state, {value, property}) {
            state.candidateCriterionScoreInfos[0][property] = value
            console.log(state.candidateCriterionScoreInfos, "STATE")
        },
        SET_KKK_INTERVIEW_ID(state, kkkInterviewId) {
            state.candidateCriterionScoreInfos[0] = {
                kkk_interview_id: kkkInterviewId,
                score_comment: ''
            }
            console.log(state.candidateCriterionScoreInfos, "STATE")
        },
        SET_SCORE_USER(state, user) {
            state.scoreUsers[user.id] = user
        },
        SET_SCORE_USERS_ID(state, usersId) {
            state.scoreUsersId = usersId
        },
        SET_SCORE_USERS_NAME(state, usersName) {
            state.scoreUsersName = usersName
        },
        SET_ANONYMOUS_PERMISSION(state, anonymous_permission) {
            state.anonymous_permission = anonymous_permission
        },
        SET_SCORE_INFO(state, scoreInfo) {
            state.scoreInfo = scoreInfo
        },
        SET_CANDIDATELISTDATAID(state, candidateListDataId) {
            state.candidateListDataId = candidateListDataId
        }
    },
    state: {
        scoreUsers: {},
        scoreUsersId: [],
        scoreUsersName: [],
        candidateListData: [],
        candidateListDataId: 0,
        criterionData: [],
        candidateCriterionScoreInfos: [
            // kkk_interview_id: null,
            // //commision_user_id: null,
            // k1: null,
            // k2: null,
            // k3: null,
            // k4: null,
            // k5: null,
            // k6: null,
            // k7: null,
            // k8: null,
            // k9: null,
            // k10: null,
        ],
        scoreInfo: {}
    },
    getters: {}
}


export default commission