import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const researchwork = {
    namespaced: true,
    actions: {
        async DELETE_DB_AUTHOR_ARTICLES({commit, state}, id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/user-articles/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('db author articles DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_DB_AUTHOR_ARTICLES({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/user-articles/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('author articles db data', data.success)
                    commit("SET_DB_AUTHOR_ARTICLES", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_AUTHOR_ARTICLES() {
            let articles = researchwork.state.scopusData.authorArticles
                .filter(item => item.isCheck)
                .filter(item => item['prism:doi'])
                .map(item => {
                    let year = new Date(item['prism:coverDate']).getFullYear()
                    let research_work_id;
                    switch (item['subtypeDescription']) {
                        case 'Article':
                            research_work_id = 2
                            break;
                        case 'Article-in-Press (AiP)':
                            research_work_id = 2
                            break;
                        case 'Book':
                            research_work_id = 1
                            break;
                        case 'Chapter':
                            research_work_id = 2
                            break;
                        case 'Conference Paper':
                            research_work_id = 3
                            break;
                        case 'Letter':
                            research_work_id = 4
                            break;
                        case 'Note':
                            research_work_id = 4
                            break;
                        default:
                            research_work_id = 5
                    }
                    return {
                        year,
                        language: 39,
                        book_name: item['dc:title'],
                        publishing_house: item['prism:publicationName'],
                        page_number: item['prism:pageRange'],
                        issn: item['prism:issn'],
                        doi: item['prism:doi'],
                        link: item['link'][2]['@href'],
                        eid: item['eid'],
                        country: '',
                        co_autors: item['authors'],
                        research_work_id,
                        file: null,
                        sub_research_work: 26 //id 26 - scopus
                    }
                })


            console.log(articles, 'post articles')

            try {
                const {
                    status,
                    data
                } = await httpClient.post('questionnaire/user-articles/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), articles);
                if (status === 200) {
                    console.log(data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e);
                return {
                    success: false,
                    errors: e.response.data
                }
            }


        },
        async POST_DB_AUTHOR_ARTICLES() {
            let postDbData = researchwork.state.postDbResearchWork

            if (!postDbData.research_work_id) {
                postDbData.research_work_id = researchwork.state.researchWorkType[0].id
            }

            if (!postDbData.sub_research_work) {
                postDbData.sub_research_work = researchwork.state.subResearchWorkType.filter(i => i.parent_id == postDbData.research_work_id)[0].id
            }

            console.log(researchwork.state.postDbResearchWork, "researchwork.state.postDbResearchWork")


            let formData = new FormData();

            console.log(postDbData, 'post postDbData')


            formData.append("language", postDbData["language"]);
            formData.append("book_name", postDbData["book_name"]);
            formData.append("research_work_id", postDbData["research_work_id"]);
            formData.append("sub_research_work", postDbData["sub_research_work"]);
            formData.append("publishing_house", postDbData["publishing_house"]);
            formData.append("year", postDbData["year"]);



            if (postDbData["file"] != null) {
                formData.append("file", postDbData["file"]);
            }

            if (postDbData["chapter"] != null) {
                formData.append("chapter", postDbData["chapter"]);
            }

            if (postDbData["co_autors"] != null) {
                formData.append("co_autors", postDbData["co_autors"]);
            }

            if (postDbData["country"] != null) {
                formData.append("country", postDbData["country"]);
            }

            if (postDbData["journal_number"] != null) {
                formData.append("journal_number", postDbData["journal_number"]);
            }

            if (postDbData["page_number"] != null) {
                formData.append("page_number", postDbData["page_number"]);
            }

            if (postDbData["info_factor"] != null) {
                formData.append("info_factor", postDbData["info_factor"]);
            }

            if (postDbData["issn"] != null) {
                formData.append("issn", postDbData["issn"]);
            }

            if (postDbData["isbn"] != null) {
                formData.append("isbn", postDbData["isbn"]);
            }

            if (postDbData["doi"] != null) {
                formData.append("doi", postDbData["doi"]);
            }

            if (postDbData["link"] != null) {
                formData.append("link", postDbData["link"]);
            }

            if (postDbData["note"] != null) {
                formData.append("note", postDbData["note"]);
            }

            if (postDbData["eid"] != null) {
                formData.append("eid", postDbData["eid"]);
            }

            if (postDbData["conference_name"] != null) {
                formData.append("conference_name", postDbData["conference_name"]);
            }

            if (postDbData["journal_quartile"] != null) {
                formData.append("journal_quartile", postDbData["journal_quartile"]);
            }

            if (postDbData["journal_name"] != null) {
                formData.append("journal_name", postDbData["journal_name"]);
            }

            if (postDbData["monographs_type_id"] != null) {
                formData.append("monographs_type_id", postDbData["monographs_type_id"]);
            }
            //formData.append("co_autors", postDbData["co_autors"] ? postDbData["co_autors"] : '');
            //formData.append("country", postDbData["country"] ? postDbData["country"] : '');
            //formData.append("journal_number", postDbData["journal_number"] ? postDbData["journal_number"] : '');
            //formData.append("page_number", postDbData["page_number"] ? postDbData["page_number"] : '');
            //formData.append("info_factor", postDbData["info_factor"] ? postDbData["info_factor"] : '');
            //formData.append("issn", postDbData["issn"] ? postDbData["issn"] : '');
            //formData.append("isbn", postDbData["isbn"] ? postDbData["isbn"] : '');
            //formData.append("doi", postDbData["doi"] ? postDbData["doi"] : '');
            //formData.append("link", postDbData["link"] ? postDbData["link"] : '');
            //formData.append("note", postDbData["note"] ? postDbData["note"] : '');
            //formData.append("eid", postDbData["eid"] ? postDbData["eid"] : '');
            //formData.append("conference_name", postDbData["conference_name"] ? postDbData["conference_name"] : '');
            //formData.append("journal_quartile", postDbData["journal_quartile"] ? postDbData["journal_quartile"] : '');
            //formData.append("journal_name", postDbData["journal_name"] ? postDbData["journal_name"] : '');
            //formData.append("monographs_type_id", postDbData["monographs_type_id"] ? postDbData["monographs_type_id"] : '');






            console.log(Object.fromEntries(formData), 'hello from formdata')


            try {
                const {
                    status,
                    data
                } = await httpClient.post('questionnaire/user-articles/create-files' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
                if (status === 200) {
                    console.log(data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e);
                return {
                    success: false,
                    errors: e.response.data
                }
            }


        },
        async PUT_AUTHOR_ARTICLES({commit}, id = null) {

            let article = researchwork.state.scopusData.dbAuthorArticles
                .filter(item => item.id == id)[0]

            let subResearchWork = article.sub_research_work
            if (!subResearchWork) {
                article.sub_research_work = researchwork.state.subResearchWorkType.filter(i => i.parent_id == article.research_work_id)[0].id
            }


            console.log('id', id, article, 'article')

            try {
                const {status, data} = await httpClient.put('questionnaire/user-articles/update' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + id, article);
                if (status === 200) {
                    console.log(data, 'user-articles/update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e);
                return {
                    success: false,
                    errors: e.response.data
                }
            }

        },
        async GET_SCOPUS({commit, state, dispatch}) {
            commit('SET_LOADING', true);

            try {
                const {
                    status,
                    data
                } = await httpClient.get('https://api.elsevier.com/content/search/scopus?query=AU-ID(' + state.scopusData.author_id + ')&apiKey=ec064aedd187cccec9d06950716f54f5');
                if (status === 200) {
                    console.log('loading')
                    console.log(this.loading)
                    console.log('scopus data')
                    console.log(data, data['search-results'], data['search-results']['entry'])
                    let authorArticles = data['search-results']['entry']
                    console.log('DOI DATA')
                    for (let item of authorArticles) {
                        let doi = item['prism:doi']
                        if (doi) {
                            let doiData = await dispatch('GET_SCOPUS_DOI', doi)
                            let authorsString = ''
                            let authors = doiData['abstracts-retrieval-response'].coredata['dc:creator'].author
                            console.log(authors)

                            for (let author of doiData['abstracts-retrieval-response'].coredata['dc:creator'].author) {
                                authorsString += author['ce:indexed-name'] + ', '
                            }
                            item.authors = authorsString.substring(0, authorsString.length - 2)
                        }

                    }
                    //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', authorArticles)
                    commit('SET_AUTHOR_ARTICLES', authorArticles)
                }
                return {
                    success: true,
                    data: data
                }
            } catch (e) {
                console.log(e.response);
                console.log('err scopus data')

                return {
                    success: false,
                    error: e.response
                }
            } finally {
                commit('SET_LOADING', false);
                console.log(this.loading, 'loading end')
            }
        },
        async GET_SCOPUS_AUTHOR({state, commit}, res = null) {
            let scopusUrl = '';
            if (res == null) {
                scopusUrl = 'questionnaire/scopus-info/author' + '?lastname=' + getCookie('LAST_NAME') + '&firstname=' + getCookie('FIRST_NAME') + '&access-token=' + getCookie('ACCESS_TOKEN')
                //scopusUrl = 'https://api.elsevier.com/content/search/author?count=100&query=authlast(' + getCookie('LAST_NAME') + ')%20and%20authfirst(' + getCookie('FIRST_NAME') + ')&apiKey=ec064aedd187cccec9d06950716f54f5';
            } else if (res.isAuthorId) {
                scopusUrl = 'questionnaire/scopus-info/article' + 'authorId' + state.scopusData.author_id + '&access-token=' + getCookie('ACCESS_TOKEN')
                //scopusUrl = 'https://api.elsevier.com/content/search/author?query=au-id(' + researchwork.state.scopusData.author_id + ')&apiKey=ec064aedd187cccec9d06950716f54f5';
            } else {
                scopusUrl = 'questionnaire/scopus-info/author' + '?lastname=' + res.lastname + '&firstname=' + res.firstname + '&access-token=' + getCookie('ACCESS_TOKEN')
                //scopusUrl = 'https://api.elsevier.com/content/search/author?count=100&query=authlast(' + res.lastname + ')%20and%20authfirst(' + res.firstname + ')&apiKey=ec064aedd187cccec9d06950716f54f5';
            }


            try {

                const {
                    status,
                    data
                } = await httpClient.get(scopusUrl);
                if (status === 200) {
                    console.log('scopus author data')
                    console.log(data['search-results'].entry)
                    let authorData = []
                    for (let item of data['search-results'].entry) {
                        let affilationCurrent = item['affiliation-current'] ? item['affiliation-current'] : {
                            'affiliation-city': '',
                            'affiliation-name': '',
                            'affiliation-country': ''
                        };
                        authorData.push({
                            author_id: item['dc:identifier'].substr(10),
                            affilationData: affilationCurrent
                        })

                    }
                    if (data['search-results'].entry.length == 1) {
                        commit("SET_IS_ONE_AUTHOR", true)
                        console.log('authorId', data['search-results'].entry, data['search-results'].entry[0]['dc:identifier'].substr(10))
                        commit("SET_AUTHOR_ID", data['search-results'].entry[0]['dc:identifier'].substr(10))

                    }

                    console.log('authordata', authorData)
                    commit("SET_SCOPUS_AUTHOR", authorData)
                }
                return {
                    success: true,
                    data: data
                }
            } catch (e) {
                console.log(e, 'err scopus author data');
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_SCOPUS_DOI({commit}, res) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('https://api.elsevier.com/content/abstract/doi/' + res + '?apiKey=ec064aedd187cccec9d06950716f54f5');
                if (status === 200) {
                    console.log(data, 'doi data')
                    return data
                }
            } catch (e) {
                console.log(e.response);
                console.log('err doi data')

                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_RESEARCH_WORK_TYPE({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/user-articles/get-research-name/' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('ALL_UPDATE_RESEARCH_WORK_TYPE', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_SUB_RESEARCH_WORK_TYPE({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/user-articles/get-sub-research-name/' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('ALL_UPDATE_SUB_RESEARCH_WORK_TYPE', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_QUARTILE({commit}) {
            try {
                const { status, data } = await httpClient.get('questionnaire/user-articles/get-quartile')
                if (status === 200) {
                    commit('SET_QUARTILE', data)
                }
            } catch (e) {
                console.log(e.response)
            }
        },
        async GET_JOURNALS({commit}) {
            try {
                const { status, data } = await httpClient.get('kpi/sp-kokson-mon/get-info')
                if (status === 200) {
                    commit('SET_JOURNALS', data)
                }
            } catch (e) {
                console.log(e.response)
            }
        },
        async GET_MONOGRAPHS_TYPE({commit}) {
            try {
                const { status, data } = await httpClient.get('questionnaire/user-articles/get-monographs-type')
                if (status === 200) {
                    commit('SET_MONOGRAPHS_TYPE', data)
                }
            } catch (e) {
                console.log(e.response)
            }
        }
    },
    mutations: {
        SET_SCOPUS_AUTHOR(state, authorData) {
            state.scopusData.isMoreTwentyAuthors = authorData.length > 20 && true
            state.scopusData.authorData = authorData

            console.log('authorData length - ', authorData.length,)
            console.log('MIRAS')
            console.log(state.scopusData.authorData)
        },
        UPDATE_QUALIFICATION_INSTITUTION(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].institution = payload.value
        },
        SET_AUTHOR_ID(state, author_id) {
            state.scopusData.author_id = author_id
            console.log(state.scopusData.author_id, 'state author id');
        },
        SET_AUTHOR_ARTICLES(state, author_articles) {
            let authorArticles = author_articles.map(item => {
                item.isCheck = false;
                return item;
            })

            state.scopusData.authorArticles = authorArticles
            console.log(state.scopusData.authorArticles, 'state authorArticles');
        },
        SET_IS_ONE_AUTHOR(state, isOneAuthor) {
            state.scopusData.idOneAuthor = isOneAuthor

        },
        CHECKED_ALL(state, isChecked) {
            state.scopusData.isCheckedAll = isChecked
            state.scopusData.authorArticles.forEach(item => {
                item.isCheck = isChecked
            })
            console.log(state.scopusData.authorArticles, isChecked, 'check')


        },
        SET_DB_AUTHOR_ARTICLES(state, articles) {
            state.scopusData.dbAuthorArticles = articles
            console.log('state dbAuthorArticles', state.scopusData.dbAuthorArticles)
        },
        UPDATE_CHECK_ARTICLE(state, payload) {

            console.log(payload.source_id, 'UPDATE_CHECK_ARTICLE source_id')
            let index = state.scopusData.authorArticles.findIndex(i=>i['source-id'] == payload.source_id)
            console.log(index, 'UPDATE_CHECK_ARTICLE index')
            //let index = discipline.pps_teaching_load.indexOf(ppsLoad)
            state.scopusData.authorArticles[index].isCheck = payload.value

            console.log(state.scopusData.authorArticles, 'UPDATE_CHECK_ARTICLE')
        },
        SET_LOADING(state, load) {
            state.loading = load
        },
        ALL_UPDATE_RESEARCH_WORK_TYPE(state, researchWorkType) {
            state.researchWorkType = researchWorkType
        },
        ALL_UPDATE_SUB_RESEARCH_WORK_TYPE(state, subResearchWorkType) {
            state.subResearchWorkType = subResearchWorkType
        },
        UPDATE_RESEARCH_WORK_TYPE(state, {index, value}) {
            state.scopusData.dbAuthorArticles[index].research_work_id = value
            console.log(state.scopusData.dbAuthorArticles[index].research_work_id, 'state work type')
        },
        UPDATE_SUB_RESEARCH_WORK_TYPE(state, {index, value}) {
            state.scopusData.dbAuthorArticles[index].sub_research_work = value
        },
        UPDATE_RESEARCH_WORK_DATA(state, {index, value, property}) {
            state.scopusData.dbAuthorArticles[index][property] = value
        },
        SET_RESEARCH_WORK_EDIT_INDEX(state, researchWorkEditIndex) {
            state.researchWorkEditIndex = researchWorkEditIndex
        },
        SET_RESEARCH_WORK_DELETE_ID(state, researchWorkDeleteId) {
            state.researchWorkDeleteId = researchWorkDeleteId
            console.log(state.researchWorkDeleteId, 'researchWorkDeleteId')
        },
        SET_RESEARCH_WORK_DB_DATA(state, {value, property}) {
            state.postDbResearchWork[property] = value
        },
        SET_QUARTILE(state, quartile) {
            state.quartile = quartile
        },
        SET_JOURNALS(state, journals) {
            state.journals = journals
        },
        SET_MONOGRAPHS_TYPE(state, monographsType) {
            state.monographsType = monographsType
        }
    },
    state: {
        scopusData: {
            author_id: '',
            authorData: [{
                author_id: '',
                affilationData: {
                    name: '',
                    city: '',
                    country: ''
                }
            }],
            authorArticles: [],
            dbAuthorArticles: [{
                year: '2000',
                language: 39,
                book_name: '',
                publishing_house: '',
                page_number: '',
                issn: '',
                doi: '',
                link: '',
                eid: '',
                country: '',
                co_authors: ''
            }],
            idOneAuthor: false,
            isMoreTwentyAuthors: false,
            isCheckedAll: false
        },
        loading: false,
        researchWorkType: [],
        selectedResearchWorkType: [],
        subResearchWorkType: [],
        postDbResearchWork: {},
        researchWorkEditIndex: 0,
        researchWorkDeleteId: null,
        name: {
            firstName: getCookie('FIRST_NAME'),
            lastName: getCookie('LAST_NAME')
        },
        quartile: [],
        journals: [],
        monographsType: []

    },
    getters: {
        getFirstName() {
            return getCookie('FIRST_NAME')
        },
        getLastName() {
            return getCookie('LAST_NAME')
        },
        getFullName() {
            return getCookie('LAST_NAME') + ' ' + getCookie('FIRST_NAME')
        }
    }
}

export default researchwork
