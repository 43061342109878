import httpClient from "@/services/http.service"
import router from "@/router"


const academicDebtServiceApi = {
  getApplicationsAcademicDebt() {
    return httpClient.get(`retake_ratings/student-summer-course/get-student-summer-request`)
  },
  putApplicationStatus(status) {
    return httpClient.post(`retake_ratings/student-summer-course/change-status?id=${status.id}`, status)
  },
  getConfirmedApplications() {
    return httpClient.get(`retake_ratings/student-summer-course/get-student-summer-request-by-department-id`)
  },
  getStudentsByDisciplineId(discipline_id) {
    return httpClient.get(`retake_ratings/student-summer-course/get-students-by-discipline-id?discipline_id=${discipline_id}`)
  },
  getPpsByDepartmentId(all = 0) {
    return httpClient.get(`pps/pps-data/get-pps-by-department-id?all=${all}`)
  },
  postSummerCourse(postData) {
    return httpClient.post(`retake_ratings/summer-course/create`, postData)
  },
  getSummerCourses(queryParam) {
    return httpClient.get(`retake_ratings/summer-course/index?${Object.keys(queryParam).map(q => `${q}=${queryParam[q]}`).join('&')}`)
  },
  postMdlSummerCourse(postData) {
    return httpClient.post(`retake_ratings/summer-course/create-moodle-course`, postData)
  },
  uniteCourses(data) {
    return httpClient.post(`retake_ratings/summer-course/unite-courses`, {ids: data})
  },
}

export default academicDebtServiceApi