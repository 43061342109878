import httpClient from "../../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const selectableGroups = {
    namespaced: true,
    actions: {

        async GET_LANGUAGES_LEVEL({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/user-lang-level/lang-level' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_LANGUAGES_LEVEL', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_SELECTABLE_FLOW_BY_ID({commit}, id) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('selectable_course/selectable-flow/get-selectable-flow-by-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + id);
                if (status === 200) {
                    commit('SET_SELECTABLE_FLOW', data)                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM({state, commit}) {
            const formation_education_program_id = state.selectableGroups_form?.selectableFlow?.formation_education_program_id
            try {
                const {
                    status,
                    data
                } = await httpClient.get('teaching_load/teacher-courses/get-teachers-by-formation-education-program' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&formation_education_program_id=' + formation_education_program_id);
                if (status === 200) {
                    commit('SET_TEACHERS', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async POST_SELECTABLE_GROUPS_DATA({state}) {

            let selectableGroupsForm = state.selectableGroups_form.newSelectableGroupsInfos


            selectableGroupsForm['selectable_flow_id'] = state.selectableGroups_form.selectable_flow_id

            console.log(selectableGroupsForm, 'selectableGroupsForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('selectable_course/selectable-groups/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), selectableGroupsForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async PUT_SELECTABLE_GROUPS_DATA({state}) {
            const editData = state.selectableGroups_form.editSelectableGroups
            try {
                const {
                    status,
                    data
                } = await httpClient.put('selectable_course/selectable-groups/update' + '?id=' + editData.id + '&access-token=' + getCookie('ACCESS_TOKEN'), editData);
                if (status === 200) {
                    console.log(data, 'update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },


        async GET_SELECTABLE_GROUPS_DATA({commit, state}) {
            try {
                const url = 'selectable_course/selectable-groups/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
                    + '&selectable_flow_id=' + state.selectableGroups_form.selectable_flow_id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_SELECTABLE_GROUPS_DATA", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async DELETE_SELECTABLE_GROUPS_DATA({state}, deleteSelectableGroupsId) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('selectable_course/selectable-groups/delete' + '?id=' + deleteSelectableGroupsId + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('selectable flow DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_NEW_SELECTABLE_GROUPS_DATA(state, {property, value}) {
            state.selectableGroups_form.newSelectableGroupsInfos[property] = value
            console.log(state.selectableGroups_form.newSelectableGroupsInfos, 'STATE')
        },

        SET_SELECTABLE_GROUPS_DATA(state, selectableGroupsInfos) {
            state.selectableGroups_form.selectableGroupsInfos = selectableGroupsInfos
            console.log(state.selectableGroups_form.selectableGroupsInfos, "selectableGroupsInfos")
        },
        SET_SELECTABLE_FLOW_ID(state, selectable_flow_id) {
            state.selectableGroups_form.selectable_flow_id = selectable_flow_id
        },
        SET_LANGUAGES_LEVEL(state, languagesLevel) {
            state.selectableGroups_form.languagesLevel = languagesLevel
        },
        SET_SELECTABLE_FLOW(state, selectableFlow) {
            state.selectableGroups_form.selectableFlow = selectableFlow
            console.log(state.selectableGroups_form.selectableFlow, 'selectableFlow')

        },
        SET_TEACHERS(state, teachers) {
            state.selectableGroups_form.teachers = teachers
            console.log(state.selectableGroups_form.teachers, 'teachers')
        },
        SET_EDIT_SELECTABLE_GROUPS(state, editSelectableGroups) {
            state.selectableGroups_form.editSelectableGroups = editSelectableGroups
        },
        CHANGE_SELECTABLE_GROUPS_DATA(state, {property, value}) {
            state.selectableGroups_form.editSelectableGroups[property] = value
        },

    },
    state: {
        selectableGroups_form: {
            newSelectableGroupsInfos: {},
            editSelectableGroups: {},
            selectableGroupsInfos: [],
            selectable_flow_id: null,
            languagesLevel: [],
            teachers: [],
            selectableFlow: {}
        }

    },
    getters: {}

}

export default selectableGroups