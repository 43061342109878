import httpClient from "@/services/http.service"

const holidayCalendarService = {
  get() {
    return httpClient.get(`/academic_calendar/holiday-calendar/index`)
  },
  put(holidays) {
    console.log(holidays)
    return httpClient.put(`/academic_calendar/holiday-calendar/update`, holidays)
  },
  delete(deletedId) {
    return httpClient.delete(`/academic_calendar/holiday-calendar/delete?id=${deletedId}`)
  },
}

export default holidayCalendarService