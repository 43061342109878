import dmpEventsService from "@/services/events/dmpEvents.service";

const dmpEvents = {
  namespaced: true,
  actions: {
    async GET_EVENTS({commit}) {
      try {
        const {status, data} = await dmpEventsService.getEvents()
        if (status === 200) {
          console.log('GET_EVENTS', data)
          commit('SET_EVENTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async PUT_EVENTS_STATUS({state, commit}, id){
      try {
        let event = state.events.find(i => i.events_id === id)
        console.log(event)
        await dmpEventsService.putEventsCmpStatus(event.events_id, event.status)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_EVENT_STATUS_ID(state, object) {
      state.events.find(i => i.events_id === object.id).status = object.status_id
    }
  },
  state: {
    events: []
  },
  getters: {}
}

export default dmpEvents
