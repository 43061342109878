import formedLoadCalculationService from "@/services/load-calculation/formedLoadCalculation.service"

const formedLoadCalculation = {
    namespaced: true,
    actions: {
        async GET_SELECTION_SUPERVISORS({commit, state}) {
            try {
                const {data} = await formedLoadCalculationService.getSelectionSupervisors();
                if (data) {
                    console.log(data, 'GET_SELECTION_SUPERVISORS')
                    commit('SET_SELECTION_SUPERVISORS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


    },
    mutations: {
        SET_SELECTION_SUPERVISORS(state, selectionSupervisors) {
            state.formedLoadCalculation_form.selectionSupervisors = selectionSupervisors
        },


    },
    state: {
        formedLoadCalculation_form: {

            selectionSupervisors: []
        }
    },
    getters: {}
}

export default formedLoadCalculation