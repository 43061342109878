import graduateWorkService from "@/services/graduate-work/graduateWork.service"
import uploadGraduateWorkService from "@/services/graduate-work/uploadGraduateWork.service"

const graduateWorkStudents = {
  namespaced: true,
  actions: {
    async GET_STUDENTS_BY_SUPERVISOR_ID({commit}) {
      try {
        const {data} = await graduateWorkService.getStudentsBySupervisorId();
        if (data) {
          commit('SET_STUDENTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_STUDENT_GRADUATE_WORK_THEME_BY_ID({commit}, theme_id) {
      try {
        const {data} = await graduateWorkService.getStudentGraduateWorkThemeById(theme_id);
        commit('SET_THEME', data)
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_GRADUATE_WORK_CHAPTER_BY_THEME_ID({commit}, theme_id) {
      try {
        const {data} = await uploadGraduateWorkService.getGraduateWorkChapter(theme_id);
        if (data) {
          commit('SET_CHAPTER_INFO', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_STUDENT_CHAPTERS_BY_STUDENT_ID({commit}, student_id) {
      try {
        const {data} = await graduateWorkService.getStudentGraduateWorkChapterFilesByStudentId(student_id)
        if (data) {
          commit('SET_CHAPTERS', data.files)
          commit('SET_PLAGIAT_RESULT', data.plagiat_result)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async PUT_COMMENT_STATUS({commit}, formData) {
      try {
        const {data} = await graduateWorkService.putCommentStatus(formData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_GRADUATE_WORK_COMMENT({state, commit}, commentData) {
      let formData = new FormData();

      formData.append("file_url", commentData.file_url)
      formData.append("graduate_work_chapter_id", commentData.graduate_work_chapter_id)
      formData.append("graduate_work_student", commentData.graduate_work_student || 0)
      formData.append("parent_id", commentData.parent_id)
      formData.append("comment", commentData.comment || null)

      console.log(Object.fromEntries(formData), 'comment formdata')

      try {
        const {data} = await uploadGraduateWorkService.postGraduateWorkChapterFile(formData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

  },
  mutations: {
    SET_STUDENTS(state, students) {
      state.graduateWorkStudents.students = students
    },
    SET_CHAPTERS(state, chapters) {
      state.graduateWorkStudents.chapters = chapters
      console.log(state.graduateWorkStudents.chapters, 'SET_CHAPTERS')
    },
    SET_PLAGIAT_RESULT(state, plagiatResult) {
      state.graduateWorkStudents.plagiatResult = plagiatResult
      console.log(state.graduateWorkStudents.plagiatResult, 'SET_PLAGIAT_RESULT')
    },
    SET_THEME(state, theme) {
      state.graduateWorkStudents.theme = theme
    },
    SET_CHAPTER_INFO(state, chapterInfo) {
      state.graduateWorkStudents.chapterInfo = chapterInfo
    },
  },
  state: {
    graduateWorkStudents: {
      students: [],
      chapters: [],
      theme: {},
      chapterInfo: [],
      plagiatResult: []
    }
  },
  getters: {}
}

export default graduateWorkStudents