import eventsService from "@/services/events/events.service";


const events = {
    namespaced: true,
    actions: {
        async DELETE_EVENTS({commit}, id) {
            try {
                const data = await eventsService.deleteEvents(id);
                if (data) {
                    console.log(data, 'events deleted')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async UPDATE_EVENTS({commit}, dataObject) {
            try {
                const data = await eventsService.updateEvents(dataObject.id, dataObject.events_modal);
                if (data) {
                    console.log(data, 'events updated')
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_EVENTS({commit}) {
            try {
                const {status, data} = await eventsService.getEvents()
                if (status === 200) {
                    console.log('GET_EVENTS', data)
                    commit('SET_EVENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_EVENT_TYPE({commit}) {
            try {
                const {status, data} = await eventsService.getEventType()
                if (status === 200) {
                    console.log('GET_EVENT_TYPE', data)
                    commit('SET_EVENT_TYPE', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_COMPETENCIES({commit}) {
            try {
                const {status, data} = await eventsService.getCompetencies()
                if (status === 200) {
                    console.log('GET_COMPETENCIES', data)
                    commit('SET_COMPETENCIES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_EVENTS({commit}, form) {
            try {
                await eventsService.postEvents(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_FREE_ROOMS_FOR_EVENTS({commit}, {start_date, end_date}) {
            try {
                const {status, data} = await eventsService.postFreeRoomsForEvents(start_date, end_date)
                if (status === 200) {
                    console.log('GET_FREE_ROOMS_FOR_EVENTS', data)
                    commit('SET_FREE_ROOMS_FOR_EVENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },


    },
    mutations: {
        SET_EVENTS(state, events) {
            state.events_form.events = events
        },
        SET_COMPETENCIES(state, competencies) {
            state.events_form.competencies = competencies
        },
        SET_EVENT_TYPE(state, eventType) {
            state.events_form.eventType = eventType
        },
        SET_FREE_ROOMS_FOR_EVENTS(state, rooms) {
            state.free_rooms_for_events = rooms
        }
    },
    state: {
        events_form: {},
    },
    getters: {},
    free_rooms_for_events: []
}

export default events
