import adviserChooseService from "@/services/adviser-choose/adviserChoose.service"


const adviserChoose = {
  namespaced: true,
  actions: {

    async GET_STUDY_LEVELS({commit}) {
      try {
        const {data} = await adviserChooseService.getStudyLevels();
        if (data) {
          commit('SET_STUDY_LEVELS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_EDUCATION_PROGRAMS({state, commit}, {studyLevelId, studyCourse}) {
      try {
        const {data} = await adviserChooseService.getEducationPrograms(studyLevelId, studyCourse)
        if (data) {
          commit('SET_EDUCATION_PROGRAMS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID({state, commit}, education_program_id) {
      try {
        const {data} = await adviserChooseService.getStudentGroupsByEducationProgramId(education_program_id);
        if (data) {
          console.log(data, 'GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID')
          //commit("SET_STUDENTS", data.students)
          commit("SET_STUDENTS_GROUPS", data.groups)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_PPS({commit}) {
      try {
        const {data} = await adviserChooseService.getPpsByDepartmentId();
        console.log('GET_PPS'.data)
        commit('SET_PPS', data)
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },


    async POST_ADVISER_CHOOSE({commit}, form) {
      try {
        const {data} = await adviserChooseService.postAdviserChoose(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_ADVISER_CHOOSE({commit}, form) {
      try {
        const {data} = await adviserChooseService.putAdviserChoose(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    // async GET_ADVISER_CHOOSE({commit}) {
    //     try {
    //         const {data} = await adviserChooseService.getAdviserChoose();
    //         console.log('GET_ADVISER_CHOOSE'. data)
    //         commit('SET_ADVISER_CHOOSE', data)
    //     } catch (e) {
    //         commit('SET_ERROR', e, {root: true})
    //     }
    // },

  },
  mutations: {
    SET_STUDY_LEVELS(state, studyLevels) {
      state.adviserChoose_form.studyLevels = studyLevels
    },
    SET_EDUCATION_PROGRAMS(state, educationPrograms) {
      state.adviserChoose_form.educationPrograms = educationPrograms
    },
    SET_STUDENTS_GROUPS(state, studentGroups) {
      state.adviserChoose_form.studentGroups = studentGroups
      console.log(state.adviserChoose_form.studentGroups, "studentGroups")
    },
    SET_PPS(state, pps) {
      state.adviserChoose_form.pps = pps
    },
    // SET_ADVISER_CHOOSE(state, adviserChoose) {
    //     state.adviserChoose_form.adviserChoose = adviserChoose
    // },
    CLEAR_STATE(state) {
      //state.adviserChoose_form.studyLevels = []
      state.adviserChoose_form.educationPrograms = []
      state.adviserChoose_form.studentGroups = []
      //state.adviserChoose_form.pps = []
    }
  },
  state: {
    adviserChoose_form: {
      studyLevels: [],
      educationPrograms: [],
      studentGroups: [],
      pps: [],
      //adviserChoose: []

    }
  },
  getters: {}
}

export default adviserChoose