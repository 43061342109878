import managerScheduleService from "@/services/schedule/managerSchedule.service"

const managerSchedule = {
  namespaced: true,
  actions: {
    async GET_DAYS({commit}) {
      try {
        const {status, data} = await managerScheduleService.getDays()
        if (status === 200) {
          commit('SET_DAYS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_SCHEDULE_INTERVAL_TIMES({commit}) {
      try {
        const {status, data} = await managerScheduleService.getScheduleIntervalTimes()
        if (status === 200) {
          commit('SET_SCHEDULE_INTERVAL_TIMES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_DISCIPLINE_SCHEDULE({commit}, {study_level_id, language_id, study_course}) {
      try {
        const {status, data} = await managerScheduleService.getDisciplineSchedule(study_level_id, language_id, study_course)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_PPS_TEACHING_LOAD_BY_DISCIPLINE_ID({commit}, queryParam) {
      try {
        const {status, data} = await managerScheduleService.getPpsTeachingLoadByDisciplineId(queryParam)
        if (status === 200) {
          return data.map(i => ({
            id: i.id,
            name: `${i.lastname || 'Вакансия'} (${i.education_type_name}, ${i.students_count}) ${i?.lecture || ''} ${i?.lecture_seminar_count || ''} ${i?.union_course_id || ''}`,
            students_count: i.students_count,
            count: i.count,
          }))
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_FREE_ROOMS({commit}, {count_of_student, day_id, schedule_interval_time_id, need_computer}) {
      try {
        const {status, data} = await managerScheduleService.getFreeRooms(count_of_student, day_id, schedule_interval_time_id, need_computer)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },


    async GET_SCHEDULE({commit}, queryParam = {}) {
      try {
        const {status, data} = await managerScheduleService.getSchedule(queryParam)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_SCHEDULE({commit}, form) {
      try {
        await managerScheduleService.postSchedule(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_SCHEDULE({commit}, form) {
      try {
        await managerScheduleService.putSchedule(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async DELETE_SCHEDULE({commit}, id) {
      try {
        await managerScheduleService.deleteSchedule(id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_PPS_FREE_TIME({commit}, discipline_id) {
      try {
        const {status, data} = await managerScheduleService.getPpsFreeTime(discipline_id)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_TEACHER_LATE({commit}, user_id) {
      try {
        const {status, data} = await managerScheduleService.getTeacherLate(user_id)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_TEACHER_LATE({commit}, form) {
      try {
        await managerScheduleService.postTeacherLate(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_ROOMS({commit}) {
      try {
        const {status, data} = await managerScheduleService.getRooms()
        if (status === 200) {
          commit('SET_ROOMS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

  },
  mutations: {
    SET_DAYS(state, days) {
      state.days = days
    },
    SET_SCHEDULE_INTERVAL_TIMES(state, scheduleIntervalTimes) {
      state.scheduleIntervalTimes = scheduleIntervalTimes
    },
    SET_ROOMS(state, rooms) {
      state.rooms = rooms
    },
  },
  state: {
    days: [],
    scheduleIntervalTimes: [],
    rooms: []
  },
  getters: {}
}

export default managerSchedule