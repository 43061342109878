import analyticsService from "@/services/analytics/analytics.service";

const analytics = {
    namespaced: true,
    actions: {
        async GET_ANALYTICS({commit}) {
            try {
                const {data} = await analyticsService.getAnalytics();
                if (data) {
                    console.log(data)
                    commit('SET_ANALYTICS_DATA', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async CREATE_ANALYTICS({commit}, data) {
            try {
                const {status} = await analyticsService.createAnalytics(data);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async DELETE_ANALYTICS({commit}, id) {
            try {
                const {status} = await analyticsService.deleteAnalytics(id);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_ANALYTICS_DATA(state, data){
            state.AnalyticsData = data
        }
    },
    state: {
        AnalyticsData: []
    },
}

export default analytics
