import officeRegistrationDisciplineOpeningService
    from "@/services/office-registration/officeRegistrationDisciplineOpeningService.service"

const officeRegistrationDisciplineOpening = {
    namespaced: true,
    actions: {
        async GET_TEACHES({commit}, fio) {
            try {
                const {status, data} = await officeRegistrationDisciplineOpeningService.getTeachers(fio)
                if (status == 200){
                    commit('SET_TEACHES', data)
                    console.log('GET_TEACHES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_TEACHER_COURSES({commit}, teacher_id) {
            try {
                const {status, data} = await officeRegistrationDisciplineOpeningService.getTeacherCourses(teacher_id)
                if (status == 200){
                    commit('SET_TEACHER_COURSES', data)
                    console.log('GET_TEACHER_COURSES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_SUMMER_RATINGS({commit}, mdl_course_id) {
            try {
                const {status, data} = await officeRegistrationDisciplineOpeningService.getSummerRatings(mdl_course_id)
                if (status == 200){
                    commit('SET_SUMMER_RATINGS', data)
                    console.log('GET_SUMMER_RATINGS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_OPEN_VSK({commit}, params) {
            try {
                const {status} = await officeRegistrationDisciplineOpeningService.openVSK(params.mdl_course_id, params.vsk)
                return status == 200
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_CLOSE_VSK({commit}, params) {
            try {
                const {status} = await officeRegistrationDisciplineOpeningService.closeVSK(params.mdl_course_id, params.vsk)
                return status == 200
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_IMPORT_SUMMER_RATINGS({commit}, mdl_course_id) {
            try {
                const {status} = await officeRegistrationDisciplineOpeningService.importSummerRatings(mdl_course_id)
                return status == 200
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        }
    },
    mutations: {
        SET_TEACHES(state, data) {
            state.teachers = data
        },
        SET_TEACHER_COURSES(state, data) {
            state.teacher_courses.ordinary = data.success
            state.teacher_courses.summer = data.summer
        },
        SET_SUMMER_RATINGS(state, data) {
            state.summer_ratings = data
        },
    },
    state: {
        teachers: [],
        teacher_courses: {
            ordinary: [],
            summer: []
        },
        summer_ratings: []
    },
    getters: {}
}

export default officeRegistrationDisciplineOpening