import httpClient from "@/services/http.service"
import router from "@/router"


const commissionScoreService = {
  getMemberStatus() {
    return httpClient.get(`kkk/kkk-commission-members/get-member-status`)
  },
  getCommissionMembers() {
    return httpClient.get(`kkk/kkk-commission-members/index`)
  },
  postMemberStatus(form) {
    return httpClient.post(`kkk/kkk-commission-members/create`, form)
  },
  putMemberStatus(form) {
    return httpClient.put(`kkk/kkk-commission-members/update?id=${form.id}`, form)
  },

  getCandidateList() {
    return httpClient.get(`kkk/kkk-interview-time/index-interview-time`)
  },
  getDisciplineTeachingScoreCriterion() {
    return httpClient.get(`kkk/kkk-discipline-teaching-score/get-sp-discipline-teaching-score-criterion`)
  },
  getTeacherProfileScoreCriterion() {
    return httpClient.get(`kkk/kkk-teacher-profile-score/get-sp-teacher-profile-score-criterion`)
  },
  postTeacherProfileScore(form) {
    return httpClient.post(`kkk/kkk-teacher-profile-score/create`, form)
  },
  postDisciplineTeachingScore(form) {
    return httpClient.post(`kkk/kkk-discipline-teaching-score/create`, form)
  },
  postAnonymousVote(form) {
    return httpClient.post(`kkk/kkk-profile-anonymous-vote/create`, form)
  },
  postDisciplineAnonymousVote(form) {
    return httpClient.post(`kkk/kkk-anonymous-vote/create`, form)
  },

}

export default commissionScoreService