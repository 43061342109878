import httpClient from "@/services/http.service"
import router from "@/router"


const studentResearchCupService = {
  getRoles() {
    return httpClient.get(`research_cup/research-cup-user/get-members-of-commission`)
  },
  getCommissionMembers() {
    return httpClient.get(`research_cup/research-cup-user/get-users-by-role`)
  },
  postCommissionMembers(form) {
    return httpClient.post(`research_cup/research-cup-user/create`, form)
  },
}

export default studentResearchCupService