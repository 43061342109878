import teachersOrdersService from "@/services/kkk/teachers.orders.service";
import httpClient from "@/services/http.service";

const teachersOrders = {
    namespaced: true,
    actions: {
        async GET_ORDERS_TYPES({commit}) {
            try {
                const {status, data} = await teachersOrdersService.getOrdersTypes();
                if (status === 200) {
                    commit('SET_ORDERS_TYPES', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_ORDERS({commit}, order_id = null) {
            try {
                const {status, data} = await teachersOrdersService.getOrders(order_id);
                if (status === 200) {
                    commit('SET_ORDERS_LIST', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async CONFIRM_PPS_ORDER({commit}, {order_id, status_order}) {
            try {
                const data = {pps_order_id: order_id, status: status_order};

                const {status} = await teachersOrdersService.confirmPpsOrder(data);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_ORDER({commit}, data) {
            try {
                const {status} = await teachersOrdersService.postOrder(data);
                if (status === 200) {
                    return status;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_PPS_BY_NAME({commit}, name){
            try {
                const {status, data} = await teachersOrdersService.getPpsByName(name);
                if (status === 200) {
                    commit('SET_PPS_BY_NAME', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_PPS_MEMBER({commit}, data){
            try {
                const {status} = await teachersOrdersService.postPpsOrder(data);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_PPS_MEMBERS({commit}, pps_order_id){
            try {
                const {status, data} = await teachersOrdersService.getPpsMembers(pps_order_id);
                if (status === 200) {
                    commit('SET_PPS_MEMBERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async DELETE_PPS_MEMBER({commit}, {pps_order_id, pps_user_id}){
            try {
                const {status, data} = await teachersOrdersService.deletePpsMember(pps_order_id, pps_user_id);
                if (status === 200) {
                    commit('SET_PPS_MEMBERS', data)
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        }
    },
    mutations: {
        SET_ORDERS_TYPES(state, data) {
            state.ordersTypes = data
        },
        SET_ORDERS_LIST(state, data) {
            state.ordersList = data
        },
        SET_PPS_BY_NAME(state, data){
            state.ppsSearchList = data
        },
        SET_PPS_MEMBERS(state, data){
            state.ppsMembers = data
        }
    },
    state: {
        ordersList: [],
        ordersTypes: [],
        ppsSearchList: [],
        ppsMembers: []
    },
}

export default teachersOrders
