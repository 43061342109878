import totalPercentService from "@/services/social-gpa/totalPercent.service";

const totalPercent = {
    namespaced: true,
    actions: {
        async GET_TOTAL_PERCENT({commit}) {
            try {
                const {status, data} = await totalPercentService.getTotalPercent()
                if (status === 200) {
                    console.log('SET_TOTAL_PERCENT', data)
                    commit('SET_TOTAL_PERCENT', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        }
    },
    mutations: {
        SET_TOTAL_PERCENT(state, data) {
            state.totalPercent = data
        },
    },
    state: {
        totalPercent: []
    }
}

export default totalPercent
