import studentEventRegistrationService from "@/services/events/studentEventRegistration.service";


const studentEventRegistration = {
  namespaced: true,
  actions: {
    async GET_STUDENT_EVENTS({commit}) {
        try {
            const {status, data} = await studentEventRegistrationService.getStudentEvents()
            if (status === 200) {
                commit('SET_STUDENT_EVENTS', data)
            }
        } catch (e) {
            commit('SET_ERROR', e, {root: true})
        }
    },
    async REGISTER_TO_EVENT({commit}, id) {
      try {
        await studentEventRegistrationService.postEventRegistration(id)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {
    SET_STUDENT_EVENTS(state, studentEvents) {
      state.studentEvents = studentEvents
    },
  },
  state: {
    studentEvents: []
  },
  getters: {}
}

export default studentEventRegistration
