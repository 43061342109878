<template>

  <!-- Create Discipline Modal -->
  <div class="modal fade" id="disciplineVacancyModal" tabindex="-1"
       aria-labelledby="disciplineVacancyModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Информация о дисциплине
            <b>{{ disciplineDescriptions.name }}</b>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div v-if="checkPpsData === true">
            <div class="row" v-if="disciplineDescriptions">
              <div class="col-md-12">
                <div class="row text-center">
                  <div class="col-md-12">
                    <h4>Знания</h4>
                  </div>
                  <div class="col-md-12">
                    {{ disciplineDescriptions.knowledge }}
                  </div>
                  <hr>
                  <div class="col-md-12">
                    <h4>Навыки</h4>
                  </div>
                  <div class="col-md-12">
                    {{ disciplineDescriptions.skills }}
                  </div>
                  <hr>
                  <div class="col-md-12">
                    <h4>Умения</h4>
                  </div>
                  <div class="col-md-12">
                    {{ disciplineDescriptions.abilities }}
                  </div>
                  <hr>
                  <div class="col-md-12">
                    <h4>Рекомендации</h4>
                  </div>
                  <div class="col-md-12">
                    {{ disciplineDescriptions.recommendation }}
                  </div>
                  <hr>
                </div>

                <div class="row text-center">
                  <!--                  <div class="col-md-6">-->
                  <!--                    <input type="file" class="form-control" id="file" @change="handleFileUpload">-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-6">-->
                  <!--                    <a href="https://back.uib.kz/syllabus/syllabus.docx">-->
                  <!--                      Шаблон силлабуса для заполнения-->
                  <!--                    </a>-->
                  <!--                  </div>-->
                  <div class="row mt-4">
                    <label class="col-md-2 col-form-label">Силлабус</label>
                    <div class="col-md-6">
                      <select class="form-control form-select"
                              @input="changeSyllabusId($event)">
                        <option
                            v-for="(item, index) in [{id: 0,  educationDiscipline:{name: 'Выберите силлабус'}}, ...syllabus_form.syllabusInfos] "
                            :value="item.id"
                            :key="index">
                          {{ item?.educationDiscipline?.name }} - {{ item?.evaluationOption?.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <router-link to="/syllabus-template" target="_blank">Заполнить силлабус</router-link>
                    </div>
                  </div>
                  <div class="form-group row mt-4" v-if="syllabus_form.checkSyllabusContentData == false">
                    <p class="text-danger"><strong>Содержание дисциплины не заполнено!!!</strong></p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div v-else>
            <p>
              <b style="font-size: 24px">Заполните
                <router-link to="/main" target="_blank">персональные данные</router-link>
                , чтобы подать заявку на вакансию!</b>
            </p>
          </div>

          <div class="ms-3 mt-4" v-if="(checkPpsData === true)">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="agreeCheckbox" v-model="agreeCheckbox">
              <label class="form-check-label" for="agreeCheckbox">
                Согласен с Положением об организации и проведении конкурса на замещение вакантных должностей
                профессорско-преподавательского состава </label>
            </div>

<!--            <a class="ms-4" href="https://back.uib.kz/documents_template/test.pdf" target="_blank">-->
<!--              Заявление о согласии на обработку персональных данных-->
<!--            </a>-->
          </div>

        </div>
        <div class="modal-footer">
          <button v-if="agreeCheckbox && ( syllabusId && (syllabus_form.checkSyllabusContentData == true))"
                  type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="sendUserSyllabus">
            Подать заявку
          </button>
          <!--          :disabled="!agreeCheckbox || (checkPpsData === false) || !syllabusId || (syllabus_form.checkSyllabusContentData == false)"-->
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Create Program Modal -->


  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else>
      <div class="row mt-2">
        <div class="col-md-3">
          <label for="filterFilterDepartments">Фильтрация по кафедрам</label>
          <select class="form-control form-select" id="filterFilterDepartments"
                  @change="changeFilterDepartments">
            <option v-for="(item, index) in [{id: 0, name_ru: 'Выберите'},{id: 0, name_ru: 'Все'}, ...departments]"
                    :value="item.id"
                    :key="index"
                    :selected="item.id==departmentID">{{ item.name_ru }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3 mt-4">
            <input type="text" class="form-control" placeholder="Название дисциплины" v-model="inputDisciplineName">
            <button class="btn btn-outline-primary" type="button" @click="searchDisciplineByName">
              Найти
            </button>
          </div>
        </div>
      </div>

      <div class="mt-3" v-if="vacancy_form.educationDisciplineVacancyInfos">
        <h4 class="text-center">Список вакансий</h4>

        <div class="row">
          <div
              v-for="(item,index) in vacancy_form.educationDisciplineVacancyInfos.filter(educationDisciplineVacancyInfo => educationDisciplineVacancyInfo.hr_status == 1)"
              :key="index" class="col-md-6 mb-2 p-3 border rounded-3">
            <h5>{{ item.discipline.name }}</h5>
            <div class="my-2">
              <div>{{ item.discipline.code }}</div>
              <div>{{ item.discipline.department.name_ru }}</div>
            </div>
            <div>
              <span class="me-1 px-1 rounded bg-light-green">{{ item.discipline.studyLevel.name }}</span>
              <span class="px-1 rounded bg-light-yellow">{{ item.discipline.language.native_name }}</span>
            </div>
            <div>
              <div class="mt-1" v-for="(credit, creditKey) in item.credit" :key="creditKey">
              <span class="me-1 px-1 rounded bg-light-blue">
                <b>Кред.: {{ creditKey }}</b>
                <span class="ms-2">Лек. часы: ×{{ credit?.lecture_group }} ({{ credit?.lecture_hours }})</span>
                <span class="ms-1">Сем. часы: ×{{ credit?.seminar_group }} ({{ credit?.seminar_hours }})</span>
              </span>
              </div>
            </div>


            <div class="mt-3">
              <button class="btn btn-primary" type="button" data-bs-toggle="modal"
                      data-bs-target="#disciplineVacancyModal"
                      :disabled="getSyllabusRequest(item.userSyllabus)"
                      @click="openCandidateModal(item.discipline_id, item.id)">
                Подать
              </button>
            </div>

          </div>
        </div>

      </div>

      <!--      <div class="hr-vacancy-form mt-4">-->
      <!--        <h4 class="text-center">Список вакансий</h4>-->

      <!--        &lt;!&ndash; table start &ndash;&gt;-->
      <!--        <div class="table-responsive-md mt-4">-->
      <!--          <table class="table table-striped table-bordered">-->
      <!--            <thead>-->
      <!--            <tr>-->
      <!--              <th scope="col">№</th>-->
      <!--              <th scope="col">Код дисциплины</th>-->
      <!--              <th scope="col">Название дисциплины</th>-->
      <!--              <th scope="col">Кафедра</th>-->
      <!--              <th scope="col">Язык обучения</th>-->
      <!--              <th scope="col">Уровень обучения</th>-->
      <!--              <th scope="col">Лекционные часы</th>-->
      <!--              <th scope="col">Подать резюме</th>-->
      <!--            </tr>-->
      <!--            </thead>-->
      <!--            <tbody>-->

      <!--            <tr v-for="(item,index) in vacancy_form.educationDisciplineVacancyInfos.filter(educationDisciplineVacancyInfo => educationDisciplineVacancyInfo.hr_status == 1)"-->
      <!--                :key="index">-->
      <!--              <th scope="row">{{ index + 1 }}</th>-->
      <!--              <td>{{ item.discipline.code }}</td>-->
      <!--              <td>{{ item.discipline.name }}</td>-->
      <!--              <td>{{ item.discipline.department.name_ru }}</td>-->
      <!--              <td>{{ item.discipline.language.native_name }}</td>-->
      <!--              <td>{{ item.discipline.studyLevel.name }}</td>-->
      <!--              <td>{{ item?.lecture_hours }}</td>-->
      <!--              <td>-->
      <!--                <button class="btn btn-success" type="button"-->
      <!--                        data-bs-toggle="modal"-->
      <!--                        data-bs-target="#disciplineVacancyModal"-->
      <!--                        :disabled="getSyllabusRequest(item.userSyllabus)"-->
      <!--                        @click="openCandidateModal(item.discipline_id, item.id)">-->
      <!--                  Подать-->
      <!--                </button>-->
      <!--              </td>-->

      <!--            </tr>-->
      <!--            </tbody>-->
      <!--          </table>-->
      <!--        </div>-->
      <!--        &lt;!&ndash; table end &ndash;&gt;-->


      <!--      </div>-->

    </div>

    <nav v-if="pageCount" class="mt-3 d-flex justify-content-center" aria-label="Page navigation example">
      <Paginate
          v-model="page"
          :page-count="pageCount"
          :click-handler="paginateHandler"
          :prev-text="'Предыдущий'"
          :next-text="'Следующий'"
          :container-class="'pagination'"
          :page-class="'page-item'">
      </Paginate>
    </nav>


  </div>
</template>

<script>
// @ is an alias to /src
import Paginate from '@/components/common/Paginate.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex"
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: 'Home',
  components: {
    Paginate
  },

  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      discipline_id: null,
      discipline_vacancy_id: null,
      departmentID: 0,
      inputDisciplineName: '',
      loading: true,
      syllabusId: null,
      agreeCheckbox: true,
    }
  },
  computed: {
    ...mapState('vacancy', ['vacancy_form', 'departments', 'checkPpsData', 'pageCount']),
    ...mapState('syllabus', ['syllabus_form']),
    ...mapGetters('vacancy', ['ALL_EDUCATION_DISCIPLINE_VACANCY']),
    disciplineDescriptions() {
      if (this.vacancy_form.educationDisciplineVacancyInfos.length && this.discipline_vacancy_id) {
        return this.vacancy_form.educationDisciplineVacancyInfos.find(v => v.id == this.discipline_vacancy_id).discipline
      }
      return {}
    }
  },

  methods: {
    ...mapActions('vacancy', ['GET_DEPARTMENTS', 'GET_EDUCATION_DISCIPLINE_VACANCY', 'POST_USER_SYLLABUS', 'GET_CHECK_PPS_DATA']),
    ...mapActions('syllabus', ['GET_SYLLABUS_DATA', 'GET_SYLLABUS_DATA_BY_DISCIPLINE_ID', 'GET_CHECK_SYLLABUS_CONTENT_DATA']),
    ...mapMutations('vacancy', ['SET_USER_SYLLABUS', 'SET_NEW_USER_SYLLABUS', 'SET_USER_SYLLABUS_AGREE_STATUS']),
    async searchDisciplineByName() {
      console.log(this.inputDisciplineName)
      this.loading = true
      await this.GET_EDUCATION_DISCIPLINE_VACANCY({
        page: this.page,
        department_id: this.departmentID,
        count: false,
        discipline_name: this.inputDisciplineName,
        hr_status: 1
      })
      this.loading = false
    },

    async paginateHandler(page) {
      this.page = page
      this.page = page
      await this.changePage()
    },
    openCandidateModal(discipline_id, discipline_vacancy_id) {
      this.agreeCheckbox = true
      this.discipline_id = discipline_id
      this.discipline_vacancy_id = discipline_vacancy_id
    },
    handleFileUpload(e) {
      let file = e.target.files[0];
      this.SET_USER_SYLLABUS({'file': file, 'discipline_vacancy_id': this.discipline_vacancy_id})
      console.log({'file': file, 'discipline_vacancy_id': this.discipline_vacancy_id}, 'set user syllabus')
    },
    sendUserSyllabus() {
      if (!this.agreeCheckbox) {
        return this.$error({title: 'Для подачи резюме необходимо согласие на обработку персональных данных'})
      }

      this.SET_USER_SYLLABUS_AGREE_STATUS(this.agreeCheckbox)

      this.POST_USER_SYLLABUS().then(res => {
        if (res.success == true) {
          this.GET_EDUCATION_DISCIPLINE_VACANCY({
            page: this.page,
            department_id: 0,
            count: false,
            hr_status: 1
          });
          this.$message({title: 'Резюме', text: 'Ваше резюме успешно отправилось'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Резюме', text: errorText})
        }
      });
    },

    async changeFilterDepartments(e) {
      this.departmentID = e.target.value
      await this.changePage()
    },

    async changePage() {
      this.$router.push(`${this.$route.path}?page=${this.page}&department_id=${this.departmentID}`)
      this.loading = true

      await this.GET_EDUCATION_DISCIPLINE_VACANCY({
        page: this.page,
        department_id: this.departmentID,
        count: false,
        discipline_name: this.inputDisciplineName,
        hr_status: 1
      })
      this.loading = false
    },
    getSyllabusRequest(userSyllabuses) {
      console.log(userSyllabuses.some(userSyllabus => userSyllabus.user == getCookie('USER_ID')))
      return userSyllabuses.some(userSyllabus => userSyllabus.user == getCookie('USER_ID'))
    },

    changeSyllabusId(e, val = 'value') {
      this.syllabusId = e.target[val]

      this.SET_NEW_USER_SYLLABUS({
        'syllabus_id': this.syllabusId,
        'discipline_vacancy_id': this.discipline_vacancy_id
      })

      this.GET_CHECK_SYLLABUS_CONTENT_DATA(this.syllabusId)
    },

  },
  async mounted() {
    await this.GET_EDUCATION_DISCIPLINE_VACANCY({
      page: this.page,
      department_id: this.departmentID,
      count: false,
      discipline_name: this.inputDisciplineName,
      hr_status: 1
    })

    await this.GET_DEPARTMENTS()
    await this.GET_CHECK_PPS_DATA()
    await this.GET_SYLLABUS_DATA()
    this.loading = false
  }
}
</script>

<style scoped>
.text-blue {
  color: #3c9df2;
}

.bg-light-green {
  background-color: #ebfaeb;
}

.bg-light-yellow {
  background-color: #fef8e5

}

.bg-light-blue {
  background-color: #ebeffa
}

</style>
