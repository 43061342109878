import httpClient from "@/services/http.service"


const departmentDifferenceService = {
  getStudents() {
    return httpClient.get(`requisites/student-difference-request/get-difference-request`)
  },
  putDifferenceRatingsPps(form) {
    return httpClient.put(`requisites/difference-ratings/choose-pps?id=${form.id}`, form)
  },
  getDifferenceDisciplines() {
    return httpClient.get(`requisites/difference-ratings/get-difference-disciplines`)
  },
  postMdlCourseDifference(form) {
    return httpClient.put(`requisites/difference-ratings/set-mdl-course-id`, form)
  },


}

export default departmentDifferenceService