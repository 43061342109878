import teachingExperiencesService from "@/services/teacher_data/teachingExperiences.service"
import axios from 'axios'

const teachingExperiences = {
  namespaced: true,
  actions: {
    async GET_TEACHING_EXPERIENCES_FRONT({commit}, user_id = null) {
      try {
        const {data} = await teachingExperiencesService.getTeachingExperiencesFront(user_id)
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async GET_TEACHING_EXPERIENCES({commit}, user_id = null) {
      try {
        const {data} = await teachingExperiencesService.getTeachingExperiences(user_id)
        if (data) {
          console.log(data, 'getTeachingExperiences')
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_TEACHING_EXPERIENCES({commit}, postData) {
      try {
        const {data} = await teachingExperiencesService.postTeachingExperiences(postData);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_TEACHING_EXPERIENCES({commit}, putData) {
      try {
        const {data} = await teachingExperiencesService.putTeachingExperiences(putData);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async DELETE_TEACHING_EXPERIENCES({commit}, deleteId) {
      try {
        const {data} = await teachingExperiencesService.deleteTeachingExperiences(deleteId);
        if (data != 'error') {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
  },
  mutations: {},
  state: {},
  getters: {}
}

export default teachingExperiences