import httpClient from "@/services/http.service"
import router from "@/router"


const teacherSummerService = {
  getSummerCourses() {
    return httpClient.get(`retake_ratings/summer-course/get-summer-courses-by-teacher`)
  },
  getSummerGradesBySummerCourse(summerCourseId) {
    return httpClient.get(`student/student-ratings/get-summer-grades-by-summer-course?summer_course_id=${summerCourseId}`)
  },
  postImportSyllabus(postData) {
    return httpClient.post(`syllabus_data/syllabus/add-topics-and-other-categories-to-moodle`, postData)
  },
  putSummerCourseSyllabusStatus(putData) {
    return httpClient.put(`retake_ratings/summer-course/update`, putData)
  },
  postTotalGrade(bodyData) {
    return httpClient.put(`student/student-ratings/update-practice-summer`, bodyData)
  }
}

export default teacherSummerService