import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const vacancy = {
  namespaced: true,
  actions: {
    /*
            async GET_DISCIPLINE_VACANCY_BY_DEPARTMENT_ID({commit}, page = 1) {
                try {
                    const urlGetParams = `&page=${page}&department_id=${getCookie('DEPARTMENT_ID')}`
                    console.log(urlGetParams)
                    const url = 'kkk/discipline-vacancy/get-deparment-discipline' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams
                    const {
                        status,
                        data
                    } = await httpClient.get(url);
                    if (status === 200) {
                        console.log(url, ' URL')
                        console.log(data.page_count, 'PAGE COUNT')

                        commit("SET_PAGE_COUNT", data.page_count)
                        commit("SET_EDUCATION_DISCIPLINE_VACANCY", data.success)

                        console.log(data.success)
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        error: e.response.data.errors
                    }
                }
            },
            */

    async PUT_USER_SYLLABUS_CONFIRM_STATUS({state, getters}, form) {
      try {
        const {status, data} = await httpClient.put(`kkk/user-syllabus/confirm`, {
          ...form,
          userSyllabusId: state.confirmCandidateId,
          user_id: getters.getUserIdByUserSyllabusId,
        })

        if (status === 200) {
          console.log(data, 'confirm status updated')
        }

        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async POST_TEACHER_COURSES({state}, user_syllabus_id = 0, is_fast = 0) {
      let urlParam = user_syllabus_id ? `&user_syllabus_id=${user_syllabus_id}` : ``
      urlParam = is_fast ? `${urlParam}&is_fast=${is_fast}` : urlParam

      console.log(urlParam)
      try {
        const {
          status,
          data
        } = await httpClient.post(`teaching_load/teacher-courses/create?access-token=${getCookie('ACCESS_TOKEN')}${urlParam}`, [{
          pps_id: state.user_id,
          discipline_id: state.discipline_id
        }]);
        if (status === 200) {
          console.log(data, 'teacher courses created')
        }
        return {
          success: true,
          data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async POST_NEW_TEACHER_COURSES({state}, {user_syllabus_id, is_fast, form}) {
      let urlParam = user_syllabus_id ? `&user_syllabus_id=${user_syllabus_id}` : ``
      urlParam = is_fast ? `${urlParam}&is_fast=${is_fast}` : urlParam

      try {
        const {status, data} = await httpClient.post(`teaching_load/teacher-courses/create?access-token=${getCookie('ACCESS_TOKEN')}${urlParam}`, [{
          ...form,
          user_id: state.user_id,
          pps_id: state.user_id,
          discipline_id: state.discipline_id
        }]);
        if (status === 200) {
          console.log(data, 'teacher courses created')
        }
        return {
          success: true,
          data
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async PUT_USER_SYLLABUS_REJECT_STATUS({state}, rejectCandidateComment) {
      console.log(rejectCandidateComment, 'rejectCandidateComment')
      try {
        const {
          status,
          data
        } = await httpClient.put('kkk/user-syllabus/reject' + '?access-token=' + getCookie('ACCESS_TOKEN'), {
          userSyllabusId: state.rejectCandidateId,
          comment: rejectCandidateComment
        });
        if (status === 200) {
          console.log(data, 'reject status updated')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },


    async GET_CHECK_PPS_DATA({commit}) {
      try {

        const url = 'questionnaire/pps-data/check-data' + '?access-token=' + getCookie('ACCESS_TOKEN')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data, "CHECK PPS DATA")
          commit("SET_CHECK_PPS_DATA", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async GET_DEPARTMENTS({commit}) {
      try {

        const url = 'admin/departments/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit("SET_DEPARTMENTS", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_EDUCATION_DISCIPLINE_VACANCY({commit, dispatch}, {page, department_id, count, hr_status, discipline_name, sort_by_confirm}) {
      try {

        // const urlGetParams = `&page=${page}&department_id=${getCookie('DEPARTMENT_ID')}`
        hr_status = hr_status || 2
        discipline_name = discipline_name || ''

        const urlGetParams = `&s_page=${page}&department_id=${department_id}&hr_status=${hr_status}&discipline_name=${discipline_name}`
        console.log(urlGetParams)
        const url = 'kkk/discipline-vacancy/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams + '&sort_by_confirm=' + sort_by_confirm
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(url, ' URL')
          console.log(data.page_count, 'PAGE COUNT')

          commit("SET_PAGE_COUNT", data.page_count)

          if (count) {
            for (let v of data.success) {
              v.count = await dispatch("GET_USER_SYLLABUS_COUNT", v.id)
            }
          }

          console.log(data.success, 'educationDisciplineVacancy')
          commit("SET_EDUCATION_DISCIPLINE_VACANCY", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async GET_APPLICATIONS_VACANCY({commit, dispatch},) {
      try {
        const url = 'kkk/discipline-vacancy/get-applications-vacancy' + '?access-token=' + getCookie('ACCESS_TOKEN')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {

          console.log(data, 'applicationsVacancy')
          commit("SET_APPLICATIONS_VACANCY", data)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },


    async GET_USER_SYLLABUS({commit, dispatch}, {page, discipline_vacancy_id}) {
      try {
        const urlGetParams = `&page=${page}&discipline_vacancy_id=${discipline_vacancy_id}`
        const url = 'kkk/user-syllabus/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(url, ' URL')
          console.log(data.page_count, 'PAGE COUNT')
          console.log(data.success)

          // commit("SET_PAGE_COUNT", data.page_count)
          commit("SET_USER_SYLLABUS_DATA", data.success)
          commit("CLEAR_INTERVIEW_USER_INFOS")

        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    // async GET_USER_SYLLABUS_COUNT({commit}, id) {
    //   try {
    //     const url = 'kkk/user-syllabus/count' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&discipline_vacancy_id=' + id
    //     const {
    //       status,
    //       data
    //     } = await httpClient.get(url);
    //     if (status === 200) {
    //       commit("SET_USER_SYLLABUS_COUNT", data.success)
    //       return data.success
    //     }
    //   } catch (e) {
    //     console.log(e.response);
    //     return 0
    //   }
    // },

    async POST_USER_SYLLABUS({state, commit}) {
      try {
        const {status} = await httpClient.post(`kkk/user-syllabus/create`, state.vacancy_form.userSyllabusInfos)

        return {success: true}
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }

      return {success: false}
    },

    async POST_DISCIPLINE_VACANCY_DEPARTMENT_STATUS({state}, discipline_id) {
      console.log(discipline_id, "UPDATE ID")
      console.log(state, "state")
      try {
        const {
          status,
          data
        } = await httpClient.post('kkk/discipline-vacancy/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), [{discipline_id}]);
        if (status === 200) {
          console.log(data, 'status updated')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async PUT_DISCIPLINE_VACANCY_HR_STATUS({state}, id) {

      try {
        const {
          status,
          data
        } = await httpClient.put('kkk/discipline-vacancy/hr-confirm' + '?access-token=' + getCookie('ACCESS_TOKEN'), [{id}]);
        if (status === 200) {
          console.log(data, 'hr status updated')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async UPDATE_REJECT_DISCIPLINE_VACANSY({state}, discipline_id) {
      console.log(discipline_id, "REJECT_DISCIPLINE_VACANSY")
      try {
        const {
          status,
          data
        } = await httpClient.put('kkk/discipline-vacancy/change-statuses' + '?access-token=' + getCookie('ACCESS_TOKEN'), {discipline_id});
        if (status === 200) {
          console.log(data, 'change statuses object')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async POST_KKK_INTERVIEW_TIME({state, commit}) {
      let interviewUserInfos = (state.interviewUserInfos).filter(v => v.put_status == 0)
      commit('SET_LOADING', true);
      console.log(interviewUserInfos, "POST_KKK_INTERVIEW_TIME")
      if (interviewUserInfos.length) {
        try {
          const {
            status,
            data
          } = await httpClient.post('kkk/kkk-interview-time/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), interviewUserInfos);
          if (status === 200) {
            console.log(data, 'created')
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            errors: e.response.data.errors
          }
        } finally {
          commit('SET_LOADING', false);
          console.log(this.loading, 'loading end')
        }
      }
      commit('SET_LOADING', false);

      return {
        success: true
      }
    },
    async PUT_KKK_INTERVIEW_TIME({state}) {
      console.log(state.newKkkInterviewTimeInfos, 'newKkkInterviewTimeInfos')
      let newKkkInterviewTimeInfos = (state.newKkkInterviewTimeInfos).filter(v => v.put_status == 1)
      if (newKkkInterviewTimeInfos.length) {
        try {
          const {
            status,
            data
          } = await httpClient.put('kkk/kkk-interview-time/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), newKkkInterviewTimeInfos);
          if (status === 200) {
            console.log(data, 'updated')
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            errors: e.response.data.errors
          }
        }
      }
    },

    async GET_KKK_INTERVIEW_TIME({commit},) {
      try {
        const url = 'kkk/kkk-interview-time/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(url, ' URL')

          console.log(data.success, 'ne nax')
          // commit("SET_PAGE_COUNT", data.page_count)
          commit("SET_KKK_INTERVIEW_TIME_DATA", data.success)

        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
  },
  mutations: {
    UPDATE_FILTER_STATUS(state, filterStatus) {
      if (filterStatus == 'confirmedStatus') {
        state.userSyllabus = state.userSyllabus.filter(i => i.confirm_status == 1);
        console.log(state.userSyllabus, "CONFIRMED STATE")
      } else if (filterStatus == 'rejectedStatus') {
        state.userSyllabus = state.userSyllabus.filter(i => i.reject_status == 1);
        console.log(state.userSyllabus, "REJECTED STATE")
      } else if (filterStatus == 'all') {
        state.userSyllabus = state.userSyllabus.filter(i => i.reject_status == 0 && i.confirm_status == 0);
        console.log(state.userSyllabus, "ALL STATE")
      }

    },
    SET_CONFIRM_CANDIDATE_MODAL_USER_SYLLABUS_ID(state, confirmCandidateId) {
      state.confirmCandidateId = confirmCandidateId
    },
    SET_CONFIRM_WITHOUT_INTERVIEW_USER_ID(state, user_id) {
      state.user_id = user_id
    },
    SET_CONFIRM_WITHOUT_INTERVIEW_DISCIPLINE_ID(state, discipline_id) {
      state.discipline_id = discipline_id
    },

    SET_REJECT_CANDIDATE_MODAL_USER_SYLLABUS_ID(state, rejectCandidateId) {
      state.rejectCandidateId = rejectCandidateId
    },
    SET_CHECK_PPS_DATA(state, checkPpsData) {
      state.checkPpsData = checkPpsData
    },
    SET_RESUME_INFO_MODAL_USER_ID(state, resumeInfoModalUserId) {
      state.resumeInfoModalUserId = resumeInfoModalUserId
    },
    SET_USER_SYLLABUS_DATA(state, userSyllabus) {
      state.userSyllabus = userSyllabus
    },
    SET_USER_SYLLABUS_COUNT(state, userSyllabusCount) {
      state.userSyllabusCount = userSyllabusCount
    },
    SET_EDUCATION_DISCIPLINE_VACANCY(state, educationDisciplineVacancyInfos) {
      state.vacancy_form.educationDisciplineVacancyInfos = educationDisciplineVacancyInfos
    },
    SET_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount
    },
    SET_USER_SYLLABUS(state, userSyllabusData) {
      state.vacancy_form.userSyllabusInfos[0].file_url = userSyllabusData.file
      state.vacancy_form.userSyllabusInfos[0].discipline_vacancy_id = userSyllabusData.discipline_vacancy_id
      console.log(state.vacancy_form.userSyllabusInfos, 'userSyllabusInfos')
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    },
    SET_LOADING(state, load) {
      state.loading = load
    },

    UPDATE_USER_KKK_INTERVIEW_TIME(state, payload) {
      let date = parseInt((new Date(payload.value)).getTime() / 1000)
      let interviewUserInfosIndex = state.interviewUserInfos.findIndex(item => item.user_syllabus_id == state.selectUserSyllabusId)

      if (interviewUserInfosIndex > -1) {
        state.interviewUserInfos.splice(interviewUserInfosIndex, 1)
      }

      state.interviewUserInfos.push({
        user_syllabus_id: state.selectUserSyllabusId,
        date: date,
        put_status: 0
      })

      console.log(interviewUserInfosIndex, 'interviewUserInfosIndex')
      console.log(state.interviewUserInfos, "interviewUserInfos new")
    },
    CLEAR_INTERVIEW_USER_INFOS(state) {
      state.interviewUserInfos = []
    },
    UPDATE_NEW_USER_KKK_INTERVIEW_TIME(state, payload) {
      state.newKkkInterviewTimeInfos[0].date = parseInt((new Date(payload.value)).getTime() / 1000)
    },

    SET_USER_SYLLABUS_ID(state, user_syllabus_id) {
      state.selectUserSyllabusId = user_syllabus_id
      console.log(state.selectUserSyllabusId, "selectUserSyllabusId")
    },
    SET_KKK_INTERVIEW_TIME_DATA(state, kkkInterviewTimeInfos) {
      state.kkkInterviewTimeInfos = kkkInterviewTimeInfos
    },
    SET_EDIT_KKK_INTERVIEW_TIME_DATA(state, kkkInterviewTimeData) {
      state.newKkkInterviewTimeInfos[0] = kkkInterviewTimeData
      console.log(kkkInterviewTimeData, "kkkInterviewTimeData")
    },
    SET_NEW_USER_SYLLABUS(state, userSyllabusData) {
      state.vacancy_form.userSyllabusInfos['syllabus_id'] = userSyllabusData.syllabus_id
      state.vacancy_form.userSyllabusInfos['discipline_vacancy_id'] = userSyllabusData.discipline_vacancy_id
    },
    SET_USER_SYLLABUS_AGREE_STATUS(state, status) {
      state.vacancy_form.userSyllabusInfos.agree_status = status ? 1 : 0
    },
    SET_APPLICATIONS_VACANCY(state, applicationsVacancy) {
      state.vacancy_form.applicationsVacancy = applicationsVacancy
    },
  },
  state: {
    vacancy_form: {
      educationDisciplineVacancyInfos: [],
      pageCount: 0,
      userSyllabusInfos: {},
      applicationsVacancy: []
    },
    departments: [],
    userSyllabus: [],
    resumeInfoModalUserId: null,
    userSyllabusCount: null,
    interviewUserInfos: [],
    kkkInterviewTimeInfos: [],
    newKkkInterviewTimeInfos: [],
    selectUserSyllabusId: null,
    editKkkInterviewTimeId: null,
    checkPpsData: null,
    confirmCandidateId: null,
    rejectCandidateId: null,
    loading: false,
    user_id: null,
    discipline_id: null
  },
  getters: {
    ALL_EDUCATION_DISCIPLINE_VACANCY(state) {
      var vacancies = state.vacancy_form.educationDisciplineVacancyInfos;
      var departments = vacancies.map(function (vacancy) {
        return vacancy.discipline.department;
      });

      return departments
    },
    getUserIdByUserSyllabusId(state) {
      return state.userSyllabus.find(i => i.id == state.confirmCandidateId)?.user || 0
    }

  }
}


export default vacancy