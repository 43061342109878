
//user-lang-level

import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const userinfo = {
  namespaced: true,
  actions: {
    async GET_USER_PERSONAL_DATA({commit}, userId) {
      try {
        const url = `questionnaire/pps-data/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit('SET_PERSONAL_DATA', data.success)
          return {
            success: true
          }
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_ADDRESS_DATA({commit}, userId) {
      try {
        const url = `questionnaire/user-address/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit('SET_ADDRESS_DATA', data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_CHILDREN_DATA({commit}, userId) {
      try {
        const url = `questionnaire/user-children/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`

        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit("SET_CHILDREN_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_EDUCATION_DATA({commit}, userId) {
      try {
        const url = `questionnaire/pps-data-education/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit('SET_EDUCATION_DATA', data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_QUALIFICATION_DATA({commit}, userId) {
      try {
        const url = `questionnaire/user-qualifications/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit('SET_QUALIFICATION_DATA', data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_EDUCATION_FILES({commit}, userId) {
      try {
        const url = `questionnaire/pps-data-files/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit("SET_EDUCATION_FILES", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_JOB_DATA({commit}, userId) {
      try {
        const url = `questionnaire/pps-data-job/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit('SET_JOB_DATA', data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_LANG_DATA({commit}, userId) {
      try {
        const url = `questionnaire/user-lang-level/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit('SET_LANG_DATA', data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_USER_ARTICLES({commit}, userId) {
      try {
        const url = `questionnaire/user-articles/index?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          commit("SET_ARTICLES", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_SCIENCE_DEGREE_BY_USER({commit}, userId) {
      try {
        const url = `pps/pps-data/get-science-degree-by-user?access-token=${getCookie('ACCESS_TOKEN')}&user_id=${userId}`
        const {status, data} = await httpClient.get(url);
        if (data) {
          commit("SET_SCIENCE_DEGREE", data)
        }
      } catch (e) {
        console.log(e.response);
      }
    },

  },
  mutations: {
    SET_PERSONAL_DATA(state, personalData) {
      state.personalData = personalData
    },
    SET_ADDRESS_DATA(state, addressData) {
      state.addressData = addressData
    },
    SET_CHILDREN_DATA(state, childrenData) {
      state.childrenData = childrenData
    },
    SET_EDUCATION_DATA(state, educationData) {
      state.educationData = educationData
    },
    SET_QUALIFICATION_DATA(state, qualificationData) {
      state.qualificationData = qualificationData
    },
    SET_EDUCATION_FILES(state, educationFileLinks) {
      state.educationFileLinks = educationFileLinks
    },
    SET_JOB_DATA(state, jobData) {
      state.jobData = jobData
    },
    SET_LANG_DATA(state, langData) {
      state.langData = langData
    },
    SET_ARTICLES(state, articles) {
      state.articles = articles
    },
    SET_KKK_INTERVIEW_ID(state, kkkInterviewId) {
      state.kkkUserCriterionScoreInfos.push({
        kkk_interview_id: kkkInterviewId
      })
      console.log(state.kkkUserCriterionScoreInfos, "STATE")
    },
    SET_USER_ID(state, userId) {
      state.userId = userId
    },
    SET_SCIENCE_DEGREE(state, scienceDegree) {
      state.scienceDegree = scienceDegree
    }
  },
  state: {
    personalData: {},
    addressData: {},
    childrenData: [],
    educationData: [],
    qualificationData: [],
    educationFileLinks: [],
    jobData: [],
    langData: [],
    articles: [],
    scienceDegree: {},
    userId: 0
  },
  getters: {}
}


export default userinfo