import languageScillsService from "@/services/languageScills.service"
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const languageskills = {
  namespaced: true,
  actions: {
    async GET_LANGUAGE_SKILLS_DATA({commit}) {
      try {
        const {data, status} = await languageScillsService.index()
        commit("SET_LANGUAGE_SKILLS_DATA", data.success)

        return {success: true, status}
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async DELETE_LANGUAGE_SKILLS_DATA({commit, state}, index) {
      let id = state.languageskills_form.languageInfos[index].id
      try {
        const {status} = await languageScillsService.delete(id)
        console.log('Language Skills data DELETED', status)
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async POST_LANGUAGE_SKILLS_DATA() {
      let languageskillsForm = (languageskills.state.languageskills_form.languageInfos).filter(v => v.put_status == 0)
      if (languageskillsForm.length > 0) {
        let formData = new FormData();
        // console.log(languageskills.state.languageskills_form.languageInfos, 'hello from begin');
        console.log(languageskillsForm.length, 'post lang skill')

        for (let i = 0; i < languageskillsForm.length; i++) {
          if (languageskillsForm[i].put_status == 0) {
            let file = languageskillsForm[i].file;
            let name = "UserLangLevel[" + i + "][file]";
            let lang_id = languageskillsForm[i].lang_id;
            let lang_id_name = "UserLangLevel[" + i + "][lang_id]";
            let lang_level_id = languageskillsForm[i].lang_level_id;
            let lang_level_id_name = "UserLangLevel[" + i + "][lang_level_id]";
            let certificate = languageskillsForm[i].certificate;
            let certificate_name = "UserLangLevel[" + i + "][certificate]";
            formData.append(name, file);
            formData.append(lang_id_name, lang_id);
            formData.append(lang_level_id_name, lang_level_id);
            formData.append(certificate_name, certificate);


            if(+languageskillsForm[i].certificate){
              let language_certification_type_id = languageskillsForm[i].language_certification_type_id;
              let language_certification_type_id_name = "UserLangLevel[" + i + "][language_certification_type_id]";

              let score = languageskillsForm[i].score;
              let score_name = "UserLangLevel[" + i + "][score]";

              let year = languageskillsForm[i].date_receive;
              let year_name = "UserLangLevel[" + i + "][date_receive]";

              formData.append(language_certification_type_id_name, language_certification_type_id);
              formData.append(score_name, score);
              formData.append(year_name, year);
            }


          }
        }
        console.log(Object.fromEntries(formData), 'hello from formdata')

        try {
          const {
            status,
            data
          } = await httpClient.post('questionnaire/user-lang-level/create?access-token=' + getCookie('ACCESS_TOKEN'), formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
          });
          if (status === 200) {
            console.log(data)
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            errors: e.response.data.errors
          }
        }
      }
      return 0


    },
    async PUT_LANGUAGE_SKILLS_DATA() {
      console.log(languageskills.state.languageskills_form.languageInfos, 'asdasdadadas')
      let languageskillsForm = (languageskills.state.languageskills_form.languageInfos).filter(v => v.put_status == 1)

      console.log('test lang skill')
      if (languageskillsForm.length) {
        let formData = new FormData();
        // console.log(languageskills.state.languageskills_form.languageInfos, 'hello from begin');
        console.log(languageskillsForm.length)

        for (let i = 0; i < languageskillsForm.length; i++) {
          let file = languageskillsForm[i].file;
          let name = "UserLangLevel[" + i + "][file]";
          let lang_id = languageskillsForm[i].lang_id;
          let lang_id_name = "UserLangLevel[" + i + "][lang_id]";
          let lang_level_id = languageskillsForm[i].lang_level_id;
          let lang_level_id_name = "UserLangLevel[" + i + "][lang_level_id]";
          let certificate = languageskillsForm[i].certificate;
          let certificate_name = "UserLangLevel[" + i + "][certificate]";
          formData.append(name, file);
          formData.append(lang_id_name, lang_id);
          formData.append(lang_level_id_name, lang_level_id);
          formData.append(certificate_name, certificate);

          if(+languageskillsForm[i].certificate){
            let language_certification_type_id = languageskillsForm[i].language_certification_type_id;
            let language_certification_type_id_name = "UserLangLevel[" + i + "][language_certification_type_id]";

            let score = languageskillsForm[i].score;
            let score_name = "UserLangLevel[" + i + "][score]";

            let year = languageskillsForm[i].date_receive;
            let year_name = "UserLangLevel[" + i + "][date_receive]";

            formData.append(language_certification_type_id_name, language_certification_type_id);
            formData.append(score_name, score);
            formData.append(year_name, year);
          }
        }

        try {
          console.log(languageskillsForm)
          console.log(Object.fromEntries(formData), 'put lang skill')
          const {
            status,
            data
          } = await httpClient.put('questionnaire/user-lang-level/update?access-token=' + getCookie('ACCESS_TOKEN'), formData,
            {
              headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
              }
            });
          if (status === 200) {
            console.log(data)
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e, 'lang skill put');
          return {
            success: false,
            errors: e.response.data.errors
          }
        }
      }
      return 0

    },
    async GET_LANGUAGES({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('questionnaire/pps-data-education/language?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('updateLanguages', data.success)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_LANGUAGE_CERTIFICATION_TYPES({commit}) {
      try {
        const {status, data} = await httpClient('questionnaire/language-certification-type/index')
        if (status === 200) {
          commit('updateLanguageCertificationTypes', data)
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    async GET_LANGUAGES_LEVEL({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('questionnaire/user-lang-level/lang-level?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('updateLanguagesLevel', data.success)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
  },
  mutations: {
    SET_LANGUAGE_SKILLS_DATA(state, languageInfos) {
      state.languageskills_form.languageInfos = languageInfos
    },
    ADD_ROW_LANGUAGE_INFO(state) {
      state.languageskills_form.languageInfos.push({
        lang_id: 82,
        lang_level_id: 6,
        certificate: 0,
        put_status: 0,
        file: '',
      });
      console.log(state.languageskills_form.languageInfos)
    },
    DELETE_ROW_LANGUAGE_INFO(state, index) {
      state.languageskills_form.languageInfos.splice(index, 1);
    },
    updateLanguageSkills(state, payload) {
      state.languageskills_form.languageInfos[payload.index].lang_id = payload.value
    },
    updateLanguageSkillsLevel(state, payload) {
      state.languageskills_form.languageInfos[payload.index].lang_level_id = payload.value
    },
    updateCertificateStatus(state, payload) {
      state.languageskills_form.languageInfos[payload.index].certificate = payload.value === true ? 1 : 0
    },
    updateLanguages(state, languages) {
      state.languages = languages
    },
    updateLanguageCertificationTypes(state, languageCertificationTypes) {
      state.languageCertificationTypes = languageCertificationTypes
    },
    updateLanguagesLevel(state, languagesLevel) {
      state.languagesLevel = languagesLevel
    },
    UPDATE_LANGUAGE_SKILLS_DATA(state, {item, property, value}) {
      const index = state.languageskills_form.languageInfos.indexOf(item)
      state.languageskills_form.languageInfos[index][property] = value
      console.log(state.languageskills_form.languageInfos, value)
    },
  },
  state: {
    languageskills_form: {
      languageInfos: [{
        lang_id: 82,
        lang_level_id: 6,
        certificate: 0,
        put_status: 0,
        file: '',

      }],
    },
    languages: [],
    languagesLevel: [],
    languageCertificationTypes: []
  },
  getters: {
    ALL_LANGUAGES(state) {
      // let arr = state.languages;
      // let kaz = arr.find(i=>i.id == 82);
      // let en = arr.find(i=>i.id == 39);
      // let ru = arr.find(i=>i.id == 137);
      //  arr.unshift(kaz, en, ru)
      // arr.splice(0, 0, arr.splice(indexKaz, 1)[0]);
      // arr.splice(0, 0, arr.splice(indexEn, 1)[1]);
      // arr.splice(0, 0, arr.splice(indexRu, 1)[2]);
      // return arr

      return state.languages
    },
    ALL_LANGUAGES_LEVEL(state) {
      return state.languagesLevel
    },
  }
}

export default languageskills
