import httpClient from "@/services/http.service"

const officeRegistrationDisciplineOpeningService = {
    getTeachers(fio) {
        return httpClient.get(`pps/pps-data/get-pps-by-fio?fio=${fio}`)
    },
    getTeacherCourses(teacher_id) {
        return httpClient.get(`education_program/education-courses/get-courses-by-pps-id-vsk?pps_id=${teacher_id}`)
    },
    getSummerRatings(mdl_course_id) {
        return httpClient.get(`pps/pps-data/get-summer-ratings?mdl_course_id=${mdl_course_id}`)
    },
    openVSK(mdl_course_id, vsk) {
        return httpClient.post(`pps/pps-data/open-grades?mdl_course_id=${mdl_course_id}&vsk=${vsk}`)
    },
    closeVSK(mdl_course_id, vsk) {
        return httpClient.post(`pps/pps-data/close-grades?mdl_course_id=${mdl_course_id}&vsk=${vsk}`)
    },
    importSummerRatings(mdl_course_id) {
        return httpClient.post(`pps/pps-data/import-summer-grades?mdl_course_id=${mdl_course_id}`)
    },
}

export default officeRegistrationDisciplineOpeningService