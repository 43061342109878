import curriculumDisciplinesService from "@/services/curriculum/curriculumDisciplines.service"
import axios from 'axios'

const curriculumDisciplines = {
    namespaced: true,
    actions: {

        async GET_CURRICULUM_DISCIPLINES({commit}, departmentID) {
            try {
                const {data} = await curriculumDisciplinesService.getCurriculumDisciplines(departmentID);
                if (data) {
                    console.log(data, 'GET_CURRICULUM_DISCIPLINES')
                    commit('SET_CURRICULUM_DISCIPLINES', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CURRICULUM_DISCIPLINES_APPROVED({commit}, departmentID) {
            try {
                const {data} = await curriculumDisciplinesService.getCurriculumDisciplinesNew(departmentID);
                if (data) {
                    console.log(data, 'GET_CURRICULUM_DISCIPLINES')
                    commit('SET_CURRICULUM_DISCIPLINES_APPROVED', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_CURRICULUM_DISCIPLINES(state, disciplines) {
            state.curriculumDisciplines_form.disciplines = disciplines
        },
        SET_CURRICULUM_DISCIPLINES_APPROVED(state, disciplinesApproved) {
            state.curriculumDisciplines_form.disciplinesApproved = disciplinesApproved
        },
    },
    state: {
        curriculumDisciplines_form: {
            disciplines: [],
            disciplinesApproved: []
        }
    },
    getters: {

    }
}

export default curriculumDisciplines