import httpClient from "@/services/http.service"
import router from "@/router"


const graduateWorkService = {
  getGraduateWorkTopic() {
    return httpClient.get(`graduate/graduate-work-theme/get-chosen-theme`)
  },
  getGraduateWorkTopics() {
    return httpClient.get(`graduate/graduate-work-theme/get-themes`)
  },
  postGraduateWorkTopic(formData) {
    return httpClient.post(`graduate/graduate-work-theme/select-theme`, formData)
  },
  getPpsByDepartmentId() {
    return httpClient.get(`graduate/selection-supervisors/get-pps-by-department-id`)
  },
  postSelectionSupervisors(formData) {
    return httpClient.post(`graduate/selection-supervisors/create`, formData)
  },
  getStudentsBySupervisorId() {
    return httpClient.get(`graduate/selection-theme/get-students-by-supervisor-id`)
  },
  getStudentChapterByThemeId(theme_id) {
    return httpClient.get(`graduate/graduate-work-theme/get-student-chapter-by-theme-id?theme_id=${theme_id}`)
  },
  postConfirmChapter(formData) {
    return httpClient.post(`graduate/graduate-work-teacher/create`, formData)
  },
  getStudentGraduateWorkThemeById(theme_id) {
    return httpClient.get(`graduate/selection-theme/get-student-graduate-work-theme-by-id?theme_id=${theme_id}`)
  },
  getStudentGraduateWorkChapterFilesByStudentId($student_id) {
    return httpClient.get(`graduate/graduate-work-chapter/get-student-graduate-work-chapter-files-by-student-id?student_id=${$student_id}`)
  },
  putCommentStatus(formData) {
    return httpClient.put(`graduate/graduate-work-comment/update?id=${formData.id}`, formData)
  },

}

export default graduateWorkService