import httpClient from "@/services/http.service"
import router from "@/router"

const teacherDifferenceService = {
  getDifferenceCoursesByTeacher() {
    return httpClient.get(`requisites/difference-ratings/get-difference-courses-by-teacher`)
  },
  getDifferenceGradesByDifferenceCourse(mdl_course_id) {
    return httpClient.get(`requisites/difference-ratings/get-difference-grades?mdl_course_id=${mdl_course_id}`)
  },
  postImportSyllabus(postData) {
    return httpClient.post(`syllabus_data/syllabus/add-topics-and-other-categories-to-moodle`, postData)
  },
  putDifferenceCourseSyllabusStatus(putData) {
    return httpClient.put(`requisites/difference-ratings/update-syllabus-status`, putData)
  },
  postTotalGrade(bodyData) {
    return httpClient.put(`requisites/difference-ratings/update`, bodyData)
  }

}

export default teacherDifferenceService
