import educationProgramResultService from "@/services/education-programme/educationProgramResult.service"
import axios from 'axios'

const educationProgramResult = {
    namespaced: true,
    actions: {



        async GET_EDUCATION_PROGRAM_RESULT({commit}, education_program_id) {
            try {
                const {data} = await educationProgramResultService.getEducationProgramResult(education_program_id);
                console.log('GET_EDUCATION_PROGRAM_RESULT', data)
                commit('SET_EDUCATION_PROGRAM_RESULT', data)
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },


        async POST_EDUCATION_PROGRAM_RESULT({commit}, educationProgramResultForm) {
            console.log(educationProgramResultForm, 'post data')
            try {
                const {data} = await educationProgramResultService.postEducationProgramResult(educationProgramResultForm)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_EDUCATION_PROGRAM_RESULT({commit, state}) {
            let updateForm = state.educationProgramResult_form.educationProgramResult
            try {
                const {data} = await educationProgramResultService.putEducationProgramResult(updateForm)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },




    },
    mutations: {

        SET_EDUCATION_PROGRAM_RESULT(state, educationProgramResult) {
            state.educationProgramResult_form.educationProgramResult = educationProgramResult
        },

        SET_UPDATE_EDUCATION_PROGRAM_RESULT(state, {number, property, value}) {
            state.educationProgramResult_form.educationProgramResult.find(i=>i.number == number)[property] = value
            console.log(state.educationProgramResult_form.educationProgramResult, 'STATE educationProgramResult')
        }

    },
    state: {
        educationProgramResult_form: {
            educationProgramResult: [],

        }
    },
    getters: {
    }
}

export default educationProgramResult