import CryptoJS from 'crypto-js'

const secretKey = 'DMrrxzdRTsNrsFaDjghnVb7fanShyBsJrj6lgri87cWyDTASq9YShB8Dnx2zu7VYS5'

export function encryptText(text) {
  return CryptoJS.AES.encrypt(text, secretKey).toString()
}

export function decryptText(encryptedText) {
  return CryptoJS.AES.decrypt(encryptedText, secretKey).toString(CryptoJS.enc.Utf8)
}
