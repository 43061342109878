import loadCalculationService from "@/services/load-calculation/loadCalculation.service"

const loadCalculation = {
    namespaced: true,
    actions: {
        async GET_LOAD_CALCULATION({commit, state}) {
            try {
                const {data} = await loadCalculationService.getLoadCalculation();
                if (data) {
                    console.log(data, 'GET_LOAD_CALCULATION')
                    commit('SET_LOAD_CALCULATION', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_PPS({commit}) {
            try {
                const {data} = await loadCalculationService.getPpsByDepartmentId();
                console.log('GET_PPS'. data)
                commit('SET_PPS', data)
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_SELECTION_SUPERVISORS({commit, state}, selectionSupervisorsForm) {

            //let selectionSupervisorsForm = state.loadCalculation.selectionSupervisorsInfos.filter(i => i.id == null)
            console.log(selectionSupervisorsForm, 'selectionSupervisorsForm')
            try {
                const {data} = await loadCalculationService.postSelectionSupervisors(selectionSupervisorsForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_SELECTION_SUPERVISORS({commit, state}, selectionSupervisorsForm) {

            //let selectionSupervisorsForm = state.loadCalculation.selectionSupervisorsInfos.filter(i => i.id != null)
            console.log(selectionSupervisorsForm, 'selectionSupervisorsForm')
            try {
                const {data} = await loadCalculationService.putSelectionSupervisors(selectionSupervisorsForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async DELETE_SELECTION_SUPERVISORS({commit, state}, id) {

            //let selectionSupervisorsForm = state.loadCalculation.selectionSupervisorsInfos.filter(i => i.id != null)
            console.log(id, 'delete id')
            try {
                const {data} = await loadCalculationService.deleteSelectionSupervisors(id);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_LOAD_CALCULATION(state, loadCalculation) {
            state.loadCalculation.loadCalculation = loadCalculation
        },
        SET_PPS(state, pps) {
            state.loadCalculation.pps = pps
        },
        ADD_ROW_SELECTION_SUPERVISORS_INFO(state) {
            state.loadCalculation.selectionSupervisorsInfos.push({
                pps_id: '',
                student_counts: 0,
                study_level_id: 1,
            });
            console.log(state.loadCalculation.selectionSupervisorsInfos)
        },

        DELETE_ROW_SELECTION_SUPERVISORS_INFO(state, index) {
            state.loadCalculation.selectionSupervisorsInfos.splice(index, 1);
        },

        SET_SELECTION_SUPERVISORS_DATA(state, {item, property, value}) {
            const index = state.loadCalculation.selectionSupervisorsInfos.indexOf(item)
            state.loadCalculation.selectionSupervisorsInfos[index][property] = value
            console.log(state.loadCalculation.selectionSupervisorsInfos, "STATE")
        },
        SET_SELECTION_SUPERVISORS_STUDY_LEVEL_ID(state, {item, studyLevelId}) {
            const index = state.loadCalculation.selectionSupervisorsInfos.indexOf(item)
            state.loadCalculation.selectionSupervisorsInfos[index]['study_level_id'] = studyLevelId
        },
        SET_SELECTION_SUPERVISORS_EDUCATION_PROGRAM_ID(state, {item, educationProgramId}) {
            const index = state.loadCalculation.selectionSupervisorsInfos.indexOf(item)
            state.loadCalculation.selectionSupervisorsInfos[index]['education_program_id'] = educationProgramId
        },

        SET_SELECTION_SUPERVISORS_DB(state, loadCalculationOne) {
            console.log(loadCalculationOne, 'loadCalculationOne MUTATION')


            // for (key in loadCalculationOne) {
            //     console.log(key, 'key')
            // }

                for (let i=0; i < loadCalculationOne?.selection_supervisors?.length; i++){



                    state.loadCalculation.selectionSupervisorsInfos.push({
                        id: loadCalculationOne.selection_supervisors[i].selection_supervisors_id,
                        pps_id: loadCalculationOne.selection_supervisors[i].user_id,
                        student_counts: loadCalculationOne.selection_supervisors[i].student_counts,
                        study_level_id: loadCalculationOne.selection_supervisors[i].selection_supervisors_study_level_id,
                        academic_year_id: loadCalculationOne.selection_supervisors[i].academic_year_id,
                        department_id: loadCalculationOne.selection_supervisors[i].selection_supervisors_department_id,
                        education_program_id: loadCalculationOne.selection_supervisors[i].selection_supervisors_education_program_id,
                    });


                }

            console.log(state.loadCalculation.selectionSupervisorsInfos, 'state.loadCalculation.selectionSupervisorsInfos')
        }

    },
    state: {
        loadCalculation: {
            loadCalculation: [],
            pps: [],
            selectionSupervisorsInfos: []
        }
    },
    getters: {}
}

export default loadCalculation