import documentSignatureService from "@/services/document/documentSignature.service"

const documentSignature = {
    namespaced: true,
    actions: {

        async GET_BASE64_DOCUMENT({commit}, {category, file_id}) {
            try {
                const {status, data} = await documentSignatureService.getBase64Document(category, file_id)
                if (status === 200) {
                    console.log('GET_BASE64_DOCUMENT', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async GET_CHECK_SIGNATURE({commit}, {file_id, sign_id}) {
            try {
                const {status, data} = await documentSignatureService.getCheckSignature(file_id, sign_id)
                if (status === 200) {
                    console.log('GET_CHECK_SIGNATURE', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },

        async POST_SIGNED_DOCUMENT({commit, state}, form) {
            //let form = state.educationCalendar_form.educationCalendar.filter(i=>!i.id)
            console.log(form, 'post form')
            try {
                await documentSignatureService.postSignedDocument(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },



    },
    mutations: {

    },
    state: {
        documentSignature_form: {

        }
    },
    getters: {}
}

export default documentSignature