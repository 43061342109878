import httpClient from "@/services/http.service"

const deputyDeanResearchCupService = {
    getRegisteredStudents() {
        return httpClient.get(`research_cup/registration-research-cup/get-registered-students`)
    },

    putStudentResearchCupStatus(form) {
        return httpClient.put(`research_cup/registration-research-cup/update-status?id=${form.id}`, form)
    },

}

export default deputyDeanResearchCupService