import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const qualification = {
    namespaced: true,
    actions: {
        async DELETE_QUALIFICATION_DATA({commit, state}, index) {
            let id = state.qualification_form.qualificationInfos[index].id
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/user-qualifications/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('qualification data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_QUALIFICATION_DATA() {
            let qualificationForm = (qualification.state.qualification_form.qualificationInfos).filter(v => v.put_status == 0)
            if (qualificationForm.length > 0) {
                
                let formData = new FormData();
                // console.log(languageskills.state.languageskills_form.languageInfos, 'hello from begin');
                console.log(qualificationForm, 'post qualificationForm')

                for (let i = 0; i < qualificationForm.length; i++) {
                    if (qualificationForm[i].put_status == 0) {
                        let file = qualificationForm[i].file;
                        let name = "UserQualifications[" + i + "][file]";

                        let sp_university_id = qualificationForm[i].sp_university_id;
                        let sp_university_id_name = "UserQualifications[" + i + "][sp_university_id]";

                        let specialization = qualificationForm[i].specialization;
                        let specialization_name = "UserQualifications[" + i + "][specialization]";

                        let organization = qualificationForm[i].organization;
                        let organization_name = "UserQualifications[" + i + "][organization]";

                        let course_name = qualificationForm[i].course_name;
                        let course_name_name = "UserQualifications[" + i + "][course_name]";

                        let hours_count = qualificationForm[i].hours_count;
                        let hours_count_name = "UserQualifications[" + i + "][hours_count]";

                        let end_year = qualificationForm[i].end_year;
                        let end_year_name = "UserQualifications[" + i + "][end_year]";

                        let certificate_series = qualificationForm[i].certificate_series;
                        let certificate_series_name = "UserQualifications[" + i + "][certificate_series]";

                        let certificate_number = qualificationForm[i].certificate_number;
                        let certificate_number_name = "UserQualifications[" + i + "][certificate_number]";




                        formData.append(name, file);
                        //formData.append(lang_id_name, lang_id);
                        formData.append(sp_university_id_name, sp_university_id);
                        formData.append(specialization_name, specialization);
                        formData.append(organization_name, organization);
                        formData.append(course_name_name, course_name);
                        formData.append(hours_count_name, hours_count);
                        formData.append(end_year_name, end_year);
                        formData.append(certificate_series_name, certificate_series);
                        formData.append(certificate_number_name, certificate_number);








                    }
                }
                console.log(Object.fromEntries(formData), 'hello from formdata')
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/user-qualifications/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
                    if (status === 200) {
                        console.log(data, 'create')
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data
                    }
                }
            } else {
                return 0
            }
        },
        async PUT_QUALIFICATION_DATA() {
            let qualificationForm = (qualification.state.qualification_form.qualificationInfos).filter(v => v.put_status == 1)
            if (qualificationForm.length > 0) {


                let formData = new FormData();
                // console.log(languageskills.state.languageskills_form.languageInfos, 'hello from begin');
                console.log(qualificationForm, 'put qualificationForm')

                for (let i = 0; i < qualificationForm.length; i++) {

                        let file = qualificationForm[i].file;
                        let name = "UserQualifications[" + i + "][file]";

                        let sp_university_id = qualificationForm[i].sp_university_id;
                        let sp_university_id_name = "UserQualifications[" + i + "][sp_university_id]";

                        let specialization = qualificationForm[i].specialization;
                        let specialization_name = "UserQualifications[" + i + "][specialization]";

                        let organization = qualificationForm[i].organization;
                        let organization_name = "UserQualifications[" + i + "][organization]";

                        let course_name = qualificationForm[i].course_name;
                        let course_name_name = "UserQualifications[" + i + "][course_name]";

                        let hours_count = qualificationForm[i].hours_count;
                        let hours_count_name = "UserQualifications[" + i + "][hours_count]";

                        let end_year = qualificationForm[i].end_year;
                        let end_year_name = "UserQualifications[" + i + "][end_year]";




                        formData.append(name, file);
                        //formData.append(lang_id_name, lang_id);
                        formData.append(sp_university_id_name, sp_university_id);
                        formData.append(specialization_name, specialization);
                        formData.append(organization_name, organization);
                        formData.append(course_name_name, course_name);
                        formData.append(hours_count_name, hours_count);
                        formData.append(end_year_name, end_year);









                }
                console.log(Object.fromEntries(formData), 'hello from formdata')

                try {

                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/user-qualifications/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_QUALIFICATION_DATA({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/user-qualifications/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_QUALIFICATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_QUALIFICATION_DATA(state, qualificationInfos) {
            state.qualification_form.qualificationInfos = qualificationInfos
        },
        ADD_ROW_QUALIFICATION_INFO(state) {
            state.qualification_form.qualificationInfos.push({
                institution: '',
                sp_university_id: 0,
                organization: '',
                specialization: '',
                course_name: '',
                hours_count: '',
                end_year: '',
                certificate_series: '',
                certificate_number: '',
                certificate_file: '',
                put_status: 0
            });
        },
        DELETE_ROW_QUALIFICATION_INFO(state, index) {
            state.qualification_form.qualificationInfos.splice(index, 1);
        },
        UPDATE_QUALIFICATION_UNIVERSITY(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].sp_university_id = payload.value
        },
        UPDATE_QUALIFICATION_CERTIFICATE_SERIES(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].certificate_series = payload.value
        },
        UPDATE_QUALIFICATION_CERTIFICATE_NUMBER(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].certificate_number = payload.value
        },
        UPDATE_QUALIFICATION_INSTITUTION(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].institution = payload.value
        },
        UPDATE_QUALIFICATION_ORGANIZATION(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].organization = payload.value
        },
        UPDATE_QUALIFICATION_SPECIALIZATION(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].specialization = payload.value
        },
        UPDATE_QUALIFICATION_END_YEAR(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].end_year = payload.value
        },
        UPDATE_QUALIFICATION_COURSE_NAME(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].course_name = payload.value
        },
        UPDATE_QUALIFICATION_HOURS_COUNT(state, payload) {
            state.qualification_form.qualificationInfos[payload.index].hours_count = payload.value
        },
    },
    state: {
        qualification_form: {
            qualificationInfos: [{
                institution: '',
                sp_university_id: 0,
                organization: '',
                course_name: '',
                hours_count: '',
                specialization: '',
                end_year: '',
                certificate_series: '',
                certificate_number: '',
                put_status: 0
            }],
        },
    },
    getters: {}
}

export default qualification