import foreignDisciplineService from "@/services/education-programme/foreignDiscipline.service"
import studentService from "@/services/student/student.service"

const foreignDiscipline = {
  namespaced: true,
  actions: {
    async GET_FOREIGN_DISCIPLINES({commit}, {page}) {
      try {
        const {data} = await foreignDisciplineService.get(page)
        if (data) {
          commit('SET_DISCIPLINES', data.success)
          commit("SET_PAGE_COUNT", data.page_count)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_FOREIGN_DISCIPLINE({commit}, postData) {
      try {
        const {status, data} = await foreignDisciplineService.post(postData)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async PUT_FOREIGN_DISCIPLINE({commit}, putData) {
      try {
        const {status, data} = await foreignDisciplineService.put(putData)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async DELETE_FOREIGN_DISCIPLINE({commit}, id) {
      try {
        const {status, data} = await foreignDisciplineService.delete(id)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async GET_FOREIGN_UNIVERSITIES({commit}) {
      try {
        const {data} = await foreignDisciplineService.getForeignUniversity()
        if (data) {
          commit('SET_FOREIGN_UNIVERSITIES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_FRONT_AND_FOREIGN_DISCIPLINES({commit}, doc_order_id) {
      try {
        const {data} = await foreignDisciplineService.getFrontAndForeignDisciplines(doc_order_id)
        if (data) {
          commit('SET_STUDENTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_FOREIGN_FRONT_DISCIPLINE({commit}, postData) {
      try {
        const {status, data} = await foreignDisciplineService.postForeignAndFrontDisciplines(postData)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async DELETE_DISCIPLINE_GROUP({commit}, grouped_disciplines_id) {
      try {
        const {status, data} = await foreignDisciplineService.deleteDisciplineGroup(grouped_disciplines_id)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async GET_STUDENT_INFO({commit}, student_id) {
      try {
        const {data} = await studentService.getStudentInfoById(student_id)
        if (data) {
          return data.success
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },
    async GET_GRADES_OF_FOREIGN_DISCIPLINES({commit}, student_id) {
      try {
        const {data} = await foreignDisciplineService.getGradesOfForeignDisciplines(student_id)
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async PUT_GRADES_OF_FOREIGN_DISCIPLINES({commit}, putData) {
      try {
        const {status, data} = await foreignDisciplineService.putGradesOfForeignDisciplines(putData)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
  },
  mutations: {
    SET_DISCIPLINES(state, disciplines) {
      state.foreignDiscipline.disciplines = disciplines
    },
    SET_PAGE_COUNT(state, pageCount) {
      state.foreignDiscipline.pageCount = pageCount
    },
    SET_FOREIGN_UNIVERSITIES(state, universities) {
      state.foreignDiscipline.universities = universities
    },
    SET_STUDENTS(state, students) {
      state.foreignDiscipline.students = students
    },
  },
  state: {
    foreignDiscipline: {
      disciplines: [],
      pageCount: 0,
      universities: [],
      students: []
    },
  },
  getters: {}
}

export default foreignDiscipline