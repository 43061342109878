import teacherDifferenceService from "@/services/teacher/teacherDifference.service"
import teacherSummerService from "@/services/teacher/teacherSummer.service";

const teacherDifference = {
  namespaced: true,
  actions: {
    async GET_DIFFERENCE_COURSES_BY_TEACHER({commit}) {
      try {
        const {data} = await teacherDifferenceService.getDifferenceCoursesByTeacher()
        if (data) {
          commit('SET_COURSES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_DIFFERENCE_GRADES_BY_DIFFERENCE_COURSE({commit}, mdl_course_id) {
      try {
        const {data} = await teacherDifferenceService.getDifferenceGradesByDifferenceCourse(mdl_course_id)
        if (data) {
          commit('SET_DIFFERENCE_GRADES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_IMPORT_SYLLABUS({commit}, postData) {
      try {
        const {data} = await teacherDifferenceService.postImportSyllabus(postData)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_DIFFERENCE_COURSE_SYLLABUS_STATUS({commit}, putData) {
      try {
        const {data} = await teacherDifferenceService.putDifferenceCourseSyllabusStatus(putData)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_TOTAL_GRADE({commit}, bodyData) {
      try {
        const {data} = await teacherDifferenceService.postTotalGrade(bodyData)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return null
    },


  },
  mutations: {
    SET_COURSES(state, courses) {
      state.teacherDifference_form.courses = courses
    },
    SET_DIFFERENCE_GRADES(state, grades) {
      state.teacherDifference_form.grades = grades
    },


  },
  state: {
    teacherDifference_form: {
      courses: [],
      grades: []
    },
  },
  getters: {}
}

export default teacherDifference
