import httpClient from "@/services/http.service"
import router from "@/router"

const studentOrdersService = {
    getDocOrders(date) {
        return httpClient.get(`doc_orders/doc-orders/index?date=` + date)
    },
    postApproveOrder(doc_order_id){
        return httpClient.post(`doc_orders/doc-orders/check-status?id=` + doc_order_id)
    }
}

export default studentOrdersService
