import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const syllabus = {
    namespaced: true,
    actions: {
        async GET_CHECK_SYLLABUS_CONTENT_DATA({commit}, syllabusId) {
            try {

                const url = 'syllabus_data/syllabus-content/check-syllabus-content' + '?access-token=' + getCookie('ACCESS_TOKEN')
                    + '&syllabus_id=' + syllabusId
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, "CHECK SYLLABUS CONTENT DATA")
                    commit("SET_CHECK_SYLLABUS_CONTENT_DATA", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async DELETE_SYLLABUS_DATA({commit, state}) {
            //let id = state.educationprogram_form.educationProgramInfos[index].id
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('syllabus_data/syllabus/delete' + '?id=' + state.syllabus_form.deleteSyllabusTemplateId + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('syllabus data DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async SEARCH_DISCIPLINE({commit}, word) {
            const url = 'education_program/education-discipline/search' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&word=' + word
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    // commit("SET_STUDY_LEVEL_DATA", data)
                    console.log(data)
                    return data
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_DISCIPLINE_BY_ID({commit}, id) {
            try {
                const url = 'education_program/education-discipline/get-discipline-by-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&id=' + id
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_EDUCATION_DISCIPLINE_BY_ID", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_SYLLABUS_DATA({state}) {

            let syllabusForm = state.syllabus_form.newSyllabusInfos

            syllabusForm['lecture_hour'] = state.syllabus_form.educationDisciplineInfos.discipline.creditSum[1]
            syllabusForm['seminar_hour'] = state.syllabus_form.educationDisciplineInfos.discipline.creditSum[2]
            syllabusForm['srsp_hour'] = state.syllabus_form.educationDisciplineInfos.discipline.creditSum[5]
            syllabusForm['srs_hour'] = state.syllabus_form.educationDisciplineInfos.discipline.creditSum[6]
            console.log(syllabusForm, 'syllabusForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('syllabus_data/syllabus/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), syllabusForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async PUT_SYLLABUS_DATA({state}) {

            //let syllabusForm = state.syllabus_form.syllabusInfos
            let syllabusForm = state.syllabus_form.syllabusInfos.filter(v => v.id == state.syllabus_form.updateSyllabusTemplateId)[0]

            console.log(syllabusForm, 'syllabusForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.put('syllabus_data/syllabus/update' + '?access-token=' + getCookie('ACCESS_TOKEN')+ '&id=' + state.syllabus_form.updateSyllabusTemplateId, syllabusForm);
                if (status === 200) {
                    console.log(data, 'update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async GET_SYLLABUS_DATA({commit}) {
            try {
                const url = 'syllabus_data/syllabus/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&user_id=' + getCookie('USER_ID')
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_SYLLABUS_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_EVALUATION_OPTIONS_DATA({commit}) {
            try {
                const url = 'syllabus_data/evaluation-options/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data.success, 'GET_EVALUATION_OPTIONS_DATA')
                    commit("SET_EVALUATION_OPTIONS_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },


        async POST_SYLLABUS_REQUISITES({state}, form) {

            console.log(form, 'POST_SYLLABUS_REQUISITES form')


            try {
                const {
                    status,
                    data
                } = await httpClient.post('syllabus_data/syllabus-requisites/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), form);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async DELETE_REQUISITE_DISCIPLINE({state}, syllabus_requisites_id) {
            console.log(syllabus_requisites_id, 'DELETE_REQUISITE_DISCIPLINE syllabus_requisites_id')
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('syllabus_data/syllabus-requisites/delete' + '?access-token=' + getCookie('ACCESS_TOKEN')+'&id='+syllabus_requisites_id);
                if (status === 200) {
                    console.log('discipline deleted')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },
    },
    mutations: {
        SET_EDUCATION_DISCIPLINE_BY_ID(state, educationDisciplineInfos) {
            console.log(educationDisciplineInfos, 'educationDisciplineInfos')
            state.syllabus_form.educationDisciplineInfos = educationDisciplineInfos
            state.syllabus_form.newSyllabusInfos['description'] = educationDisciplineInfos.discipline.description
            state.syllabus_form.newSyllabusInfos['knowledge'] = educationDisciplineInfos.discipline.knowledge
            state.syllabus_form.newSyllabusInfos['skills'] = educationDisciplineInfos.discipline.skills
            state.syllabus_form.newSyllabusInfos['abilities'] = educationDisciplineInfos.discipline.abilities
            console.log(state.syllabus_form.newSyllabusInfos, "newSyllabusInfos")
            console.log(state.syllabus_form.educationDisciplineInfos, "STATE")
        },
        SET_NEW_SYLLABUS_DATA(state, {property, value}) {
            state.syllabus_form.newSyllabusInfos[property] = value
            console.log(state.syllabus_form.newSyllabusInfos, 'STATE')
        },
        SET_MBA_EVALUATION_OPTION(state) {
            state.syllabus_form.newSyllabusInfos['evaluation_option_id'] = 6
            console.log(state.syllabus_form.newSyllabusInfos, 'STATE')
        },
        SET_NEW_SYLLABUS_EDUCATION_DISCIPLINE_ID(state, education_discipline_id) {
            state.syllabus_form.newSyllabusInfos['education_discipline_id'] = education_discipline_id
            console.log(state.syllabus_form.newSyllabusInfos, 'STATE')
        },
        SET_SYLLABUS_DATA(state, syllabusInfos) {
            state.syllabus_form.syllabusInfos = syllabusInfos
            console.log(state.syllabus_form.syllabusInfos, "syllabusInfos")
        },
        SET_EVALUATION_OPTIONS_DATA(state, evaluationOptions) {
            state.syllabus_form.evaluationOptions = evaluationOptions
            console.log(state.syllabus_form.evaluationOptions, "evaluationOptions")
        },
        SET_UPDATE_SYLLABUS_TEMPLATE_ID(state, updateSyllabusTemplateId) {
            state.syllabus_form.updateSyllabusTemplateId = updateSyllabusTemplateId
        },
        SET_DELETE_SYLLABUS_TEMPLATE_ID(state, deleteSyllabusTemplateId) {
            state.syllabus_form.deleteSyllabusTemplateId = deleteSyllabusTemplateId
        },
        UPDATE_SYLLABUS_DATA(state, {item, property, value}) {
            const index = state.syllabus_form.syllabusInfos.indexOf(item)
            state.syllabus_form.syllabusInfos[index][property] = value
            console.log(state.syllabus_form.syllabusInfos, 'STATE')
        },
        SET_CHECK_SYLLABUS_CONTENT_DATA(state, checkSyllabusContentData) {
            state.syllabus_form.checkSyllabusContentData = checkSyllabusContentData
            console.log(state.syllabus_form.checkSyllabusContentData, 'checkSyllabusContentData')
        },
    },
    state: {
        syllabus_form: {
            newSyllabusInfos: {},
            educationDisciplineInfos: {},
            syllabusInfos: [],
            evaluationOptions: [],
            updateSyllabusTemplateId: null,
            deleteSyllabusTemplateId: null,
            checkSyllabusContentData: null
        }

    },
    getters: {

    }

}

export default syllabus