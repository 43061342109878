import httpClient from "@/services/http.service"


const changePhotoService = {
  getPps(departmentId) {
    return httpClient.get(`pps/pps-data/get-pps-by-department-id?department_id=${departmentId}`)
  },
  getAdministrations() {
    return httpClient.get(`questionnaire/pps-data/get-administrations`)
  },
  postPhoto(form) {
    let formData = new FormData()
    formData.append("file", form["file"]);
    return httpClient.post(`questionnaire/pps-data/update-photo-by-user-id?user_id=${form.user_id}`, formData)
  },
}

export default changePhotoService
