import httpClient from "@/services/http.service"

const attendanceJournalService = {
  getTeacherGroups(pps_id) {
    return httpClient.get(`/student_data/student-visiting-journal/pps-courses?pps_id=${pps_id}`)
  },
  getGroupAttendance(group_id) {
    return httpClient.get(``)
  },
  getStudentAttendance(barcode) {
    return httpClient.get(``)
  },
}

export default attendanceJournalService