import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const teachingLoad = {
    namespaced: true,
    actions: {

        async GET_TEACHING_LOAD_BY_DEPARTMENT({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('teaching_load/teacher-courses/get-teaching-load-by-department' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&department_id=' + getCookie('DEPARTMENT_ID'));
                if (status === 200) {
                    console.log(data, 'GET_TEACHING_LOAD_BY_DEPARTMENT')
                    commit("SET_TEACHING_LOAD_BY_DEPARTMENT", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_CURRICULUM_DATA_BY_DEPARTMENT({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-curriculum/get-full-data' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&department_id=' + getCookie('DEPARTMENT_ID'));
                if (status === 200) {
                    console.log(data, 'GET_CURRICULUM_DATA_BY_DEPARTMENT')
                    commit("SET_CURRICULUM_DATA_BY_DEPARTMENT", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_TEACHER_COURSES({state}, teacherCourses) {
            console.log(teacherCourses, 'POST_TEACHER_COURSES')

            try {
                const {
                    status,
                    data
                } = await httpClient.post('teaching_load/teacher-courses/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), teacherCourses);
                if (status === 200) {
                    console.log(data, 'teacher courses created')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

    },
    mutations: {
        SET_TEACHING_LOAD_BY_DEPARTMENT(state, teachingLoadInfos) {
            state.teachingLoad_form.teachingLoadInfos = teachingLoadInfos
        },
        SET_CURRICULUM_DATA_BY_DEPARTMENT(state, curriculums) {
            state.teachingLoad_form.curriculums = curriculums
        },
    },
    state: {
        teachingLoad_form: {
            teachingLoadInfos: [],
            curriculums: []

        },

    },
    getters: {}

}

export default teachingLoad