import httpClient from "@/services/http.service"
import router from "@/router"


const transferCoursesService = {
    getCurrentSemesterCourses() {
        return httpClient.get(`education_program/education-courses/get-language-center-current-semester`)
    },
    getNextSemesterCourses(education_program_id, semester) {
        return httpClient.get(`education_program/education-courses/get-language-center-next-semester` + '?education_program_id='+education_program_id + '&semester='+semester)
    },
    postNextSemesterLanguageCourse(data) {
        return httpClient.post(`education_program/education-courses/create-next-semester-language-course`, data)
    },
    getTeachersByFormationEducationProgram(formation_education_program_id) {
        return httpClient.get(`teaching_load/teacher-courses/get-teachers-by-formation-education-program`+'?formation_education_program_id=' + formation_education_program_id)
    },
    getStudentsByCourseId(course_id) {
        return httpClient.get(`student/student-ratings/get-students-by-course-id?course_id=${course_id}`)
    },
}

export default transferCoursesService