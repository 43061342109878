import httpClient from "@/services/http.service"

const studentEventRegistrationService = {
  getStudentEvents() {
      return httpClient.get(`events/student-event-registration/get-events-by-student`)
  },
  postEventRegistration(id){
    return httpClient.post(`events/student-event-registration/create`, {events_id: id})
  }
}

export default studentEventRegistrationService
