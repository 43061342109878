import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const item = {
    namespaced: true,
    actions: {


        async POST_ABITURIENT_ORDERS_ITEM_DATA() {

            let abiturientOrdersItemForm = (item.state.item_form.itemInfos)
            console.log(abiturientOrdersItemForm, 'abiturientOrdersItemForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('abiturients/abit-orders-item/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), abiturientOrdersItemForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_ABITURIENT_ORDERS_ITEM_DATA({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('abiturients/abit-orders-item/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_ABITURIENT_ORDERS_ITEM_DATA', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_PAYMENT_TYPE({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get(`abiturients/abit-orders-item/get-payment-type?access-token=${getCookie('ACCESS_TOKEN')}`);
                if (status === 200) {
                    commit('SET_PAYMENT_TYPE', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {

        SET_ABITURIENT_ORDERS_ITEM_DATA(state, abiturientOrdersItem) {
            state.item_form.abiturientOrdersItem = abiturientOrdersItem
        },
        UPDATE_ABITURIENT_ORDERS_ITEM_DATA(state, {property, value}) {
            state.item_form.itemInfos[property] = value
            if(property=='payment_type_id'){
                state.item_form.itemInfos['discount'] = 0
            }
            state.item_form.itemInfos["abit_orders_id"] = state.abitOrdersId
            console.log(state.item_form.itemInfos, "STATE")
        },
        SET_ABIT_ORDERS_ID(state, abitOrdersId) {
            state.abitOrdersId = abitOrdersId
        },
        SET_PAYMENT_TYPE(state, paymentType) {
            state.item_form.paymentType = paymentType
        },
    },
    state: {
        item_form: {
            itemInfos: {
                discount: 0,
                pre_university: 1,
            },
            abiturientOrdersItem: [],
            paymentType: []
        },
        abitOrdersId: null

    },
    getters: {}
}

export default item