import chooseDisciplinesService from "@/services/student/chooseDisciplines.service"

const chooseDisciplines = {
  namespaced: true,
  actions: {
    async GET_NEXT_DISCIPLINES({commit}, params) {
      try {
        console.log(params.education_module_id, params.barcode)
        const {data} = await chooseDisciplinesService.getElectiveDisciplines(params.barcode, params.education_module_id)
        if (data) {
          console.log('GET_NEXT_DISCIPLINES', data)
          commit('SET_DISCIPLINES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_SUMMER_DISCIPLINES({commit}, barcode) {
      try {
        const {data} = await chooseDisciplinesService.getSummerDisciplines(barcode)
        if (data) {
          console.log('GET_SUMMER_DISCIPLINES', data)
          commit('SET_DISCIPLINES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_CHOOSE_DISCIPLINES({commit}, postData) {
      try {
        const {status, data} = await chooseDisciplinesService.postChooseDisciplines(postData)
        if (status === 200) {
          return {
            success: true,
            data
          }
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async PUT_CHOOSE_DISCIPLINES({commit}, putData) {
      try {
        const {status, data} = await chooseDisciplinesService.putChooseDisciplines(putData)
        if (status === 200) {
          return {
            success: true,
            data
          }
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async RESET_CHOSEN_DISCIPLINES({commit}, student_id) {
      try {
        const {status, data} = await chooseDisciplinesService.resetChosenDisciplines(student_id)
        if (status === 200) {
          return {
            success: true,
            data
          }
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async GET_DISCIPLINE_INFO({commit}, formation_education_program_id) {
      try {
        const {data} = await chooseDisciplinesService.getDisciplineInfo(formation_education_program_id)
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },
    async GET_MINOR_DISCIPLINES({commit}, barcode) {
      try {
        const {data} = await chooseDisciplinesService.getMinorDisciplines(barcode)
        if (data) {
          commit('SET_MINOR_DISCIPLINES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },
    async GET_STUDENT_INFO({commit}, barcode) {
      try {
        const {data} = await chooseDisciplinesService.getStudentInfo(barcode)
        if (data) {
          return data?.success || {}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },
    async GET_STUDENT_CREDITS_INFO({commit}, barcode) {
      try {
        const {data} = await chooseDisciplinesService.getStudentCreditsInfo(barcode)
        if (data) {
          return data || {}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },
  },
  mutations: {
    SET_DISCIPLINES(state, disciplines) {
      state.disciplines = disciplines
    },
    SET_MINOR_DISCIPLINES(state, minorDisciplines) {
      state.minorDisciplines = minorDisciplines
    },
    SET_SEMESTER_TYPES(state, semesters){
      state.semesters = semesters
    }
  },
  state: {
    disciplines: {},
    minorDisciplines: {},
    semesters: {},
  },
  getters: {}
}

export default chooseDisciplines
