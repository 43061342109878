import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const creditTimeNormTemplate = {
    namespaced: true,
    actions: {
        async GET_SP_EDUCATION_WORK_TYPE({commit, state}) {
            const url = 'education_program/credit-time-norm-template/get-sp-education-works' + '?access-token=' + getCookie('ACCESS_TOKEN')
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_SP_EDUCATION_WORK_TYPE')
                    commit("SET_SP_EDUCATION_WORK_TYPE", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        // async GET_CREDIT_TIME_TYPE({commit, state}) {
        //     const url = 'education_program/credit-time-norm-template/get-credit-time-type' + '?access-token=' + getCookie('ACCESS_TOKEN')
        //     try {
        //         const {
        //             status,
        //             data
        //         } = await httpClient.get(url);
        //         if (status === 200) {
        //             console.log(data, 'GET_CREDIT_TIME_TYPE')
        //             commit("SET_CREDIT_TIME_TYPE", data)
        //         }
        //     } catch (e) {
        //         console.log(e.response);
        //         return {
        //             success: false,
        //             error: e.response.data.errors
        //         }
        //     }
        // },
        async GET_CREDIT_TIME_NORM({commit, state}) {
            const url = 'education_program/credit-time-norm-template/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    console.log(data, 'GET_CREDIT_TIME_NORM')
                    const creditTimeNorm = {}
                    data.success.forEach(i => {
                        creditTimeNorm[i.credit_time_type_id] = creditTimeNorm[i.credit_time_type_id] || []
                        i.sum = data.sum[i.value][i.sp_education_work_id]
                        creditTimeNorm[i.credit_time_type_id].push(i)
                    })

                    console.log(creditTimeNorm)
                    commit('SET_CREDIT_TIME_NORM', creditTimeNorm)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_CREDIT_TIME_TYPE({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/credit-time-norm-template/get-time-norm' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_CREDIT_TIME_TYPE', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async POST_CREDIT_TIME_NORM({state}) {
            let newCreditTimeNormTemplateInfos = state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos
            console.log(newCreditTimeNormTemplateInfos, 'newCreditTimeNormTemplateInfos')
            if (newCreditTimeNormTemplateInfos.length) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient().post('education_program/credit-time-norm-template/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), newCreditTimeNormTemplateInfos);
                    if (status === 200) {
                        console.log(data, 'create')
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            }
            return {
                success: true
            }

        },

    },
    mutations: {
        ADD_ROW_CREDIT_TIME_NORM_TEMPLATE_INFO(state, value) {

            const weeks = {}
            for (let i = 1; i <= 15; i++) {
                weeks['w_' + i] = 0
            }

            state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos = state.creditTimeNormTemplate_form.educationWorkTypes.map(item => ({
                value,
                sp_education_work_id: item.id,
                ...weeks
            }))
            console.log(state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos, "STATE")
        },

        // ADD_ROW_WEEKS_HOUR_INFO(state, value) {
        //     const filteredNewCreditTimeNormTemplateInfos = state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos.filter(i => i.value == value)
        //     filteredNewCreditTimeNormTemplateInfos.push({
        //         sp_education_work_id: null,
        //         w_1: '',
        //         w_2: '',
        //         w_3: '',
        //         w_4: '',
        //         w_5: '',
        //
        //     });
        //     console.log(filteredNewCreditTimeNormTemplateInfos, "filteredNewCreditTimeNormTemplateInfos")
        // },

        SET_SP_EDUCATION_WORK_TYPE(state, educationWorkTypes) {
            state.creditTimeNormTemplate_form.educationWorkTypes = educationWorkTypes
        },
        UPDATE_WEEK_HOUR(state, {item, property, value}) {
            const index = state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos.indexOf(item)
            state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos[index][property] = value
            console.log(state.creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos, value)
        },
        SET_CREDIT_TIME_NORM(state, creditTimeNorm) {
            state.creditTimeNormTemplate_form.creditTimeNorm = creditTimeNorm
        },
        SET_CREDIT_TIME_TYPE(state, creditTimeType) {
            console.log(creditTimeType, 'SET_CREDIT_TIME_TYPE')
            state.creditTimeNormTemplate_form.creditTimeType = creditTimeType
        },
    },
    state: {
        creditTimeNormTemplate_form: {
            creditTimeNorm: {},
            creditTimeNormTemplateInfos: [],
            newCreditTimeNormTemplateInfos: [],
            educationWorkTypes: [],
            creditTimeType: []
        },
    },
    getters: {}

}

export default creditTimeNormTemplate