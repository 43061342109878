import commissionHeadDecisionService from "@/services/kkk/commission/commissionHeadDecision.service"
import axios from 'axios'

const commissionHeadDecision = {
    namespaced: true,
    actions: {

        async GET_COMMISSION_DECISION({commit}) {
            try {
                const {data} = await commissionHeadDecisionService.getCommissionDecision();
                if (data) {
                    console.log(data, 'GET_COMMISSION_DECISION')
                    commit('SET_COMMISSION_DECISION', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_COMMISSION_HEAD_DECISION({commit, state}, form) {

            console.log(form, 'put form')
            try {
                const {data} = await commissionHeadDecisionService.putCommissionHeadDecision(form);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


    },
    mutations: {
        SET_COMMISSION_DECISION(state, commissionDecision) {
            state.commissionHeadDecision_form.commissionDecision = commissionDecision
        },

    },
    state: {
        commissionHeadDecision_form: {
            commissionDecision: [],

        }
    },
    getters: {

    }
}

export default commissionHeadDecision