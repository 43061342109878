import axios from 'axios'
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const consolidatedStatement = {
    namespaced: true,
    actions: {

        async GET_STUDY_LEVEL_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/study-level/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_STUDY_LEVEL_NAMES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_PROGRAM_NAMES({state, commit, getters}, study_level_id) {
            commit('SET_STUDY_LEVEL_ID', study_level_id)
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-program/get-education-program-names' + '?access-token=' + getCookie('ACCESS_TOKEN')+
                    '&study_course=' + state.consolidatedStatement_form.studyCourse + '&study_level_id=' + study_level_id);
                if (status === 200) {
                    commit('SET_EDUCATION_PROGRAM_NAMES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_SP_ACADEMIC_YEAR({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-curriculum/get-academic-year' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {



                    commit('SET_SP_ACADEMIC_YEAR', data.filter(i=> i.id >= 17))
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_CONSOLIDATED_STATEMENT({state, commit}) {
            try {
                let url = 'consolidated_statement/consolidated-statement/get-consolidated-statement' + '?access-token=' + getCookie('ACCESS_TOKEN')
                let education_program_id = '&education_program_id=' + state.consolidatedStatement_form.statementInfos['education_program_id']
                let semester ='&semester=' + state.consolidatedStatement_form.statementInfos['semester']
                let statement_type = '&statement_type=' + state.consolidatedStatement_form.statementInfos['statement_type']
                // if (state.consolidatedStatement_form.statementInfos['statement_type'] != 'all') {
                //     statement_type ='&statement_type=' + state.consolidatedStatement_form.statementInfos['statement_type']
                // }


                let study_course ='&study_course=' + state.consolidatedStatement_form.studyCourse
                let sp_academic_year_id ='&sp_academic_year_id=' + state.consolidatedStatement_form.statementInfos['sp_academic_year_id']
                let study_level_id ='&study_level_id=' + state.consolidatedStatement_form.studyLevelId

                const {
                    status,
                    data
                } = await httpClient.get(url + education_program_id + semester + study_course + sp_academic_year_id+study_level_id+statement_type);
                if (status === 200) {
                    console.log('GET_CONSOLIDATED_STATEMENT', data)
                    commit('SET_CONSOLIDATED_STATEMENT', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },




    },
    mutations: {
        SET_STUDY_LEVEL_NAMES(state, studyLevel) {
            state.consolidatedStatement_form.studyLevel = studyLevel
        },
        SET_EDUCATION_PROGRAM_NAMES(state, educationPrograms) {
            state.consolidatedStatement_form.educationPrograms = educationPrograms
        },
        SET_SP_ACADEMIC_YEAR(state, academicYear) {
            state.consolidatedStatement_form.academicYear = academicYear
        },
        SET_STATEMENT_DATA(state, {property, value}) {
            state.consolidatedStatement_form.statementInfos[property] = value
            console.log(state.consolidatedStatement_form.statementInfos, 'STATE')
        },
        SET_CONSOLIDATED_STATEMENT(state, consolidatedStatementData) {
            state.consolidatedStatement_form.consolidatedStatementData = consolidatedStatementData
        },
        SET_STUDY_COURSE(state, studyCourse) {
            state.consolidatedStatement_form.studyCourse = studyCourse
        },
        SET_STUDY_LEVEL_ID(state, studyLevelId) {
            state.consolidatedStatement_form.studyLevelId = studyLevelId
        },
    },
    state: {
        consolidatedStatement_form: {
            statementInfos: {},
            studyLevel:[],
            languages: [
                { id: 39, name: 'English' },
                { id: 82, name: 'Қазақ тілі ' },
                { id: 137, name: 'Русский язык ' }
            ],
            educationPrograms:[],
            academicYear: [],
            consolidatedStatementData: [],
            studyCourse: 1,
            studyLevelId: 0
        },

    },
    getters: {

    }

}

export default consolidatedStatement