import httpClient from "@/services/http.service"

const scientificActivity = {
  namespaced: true,
  actions: {
    async GET_SCIENTIFIC_ACTIVITY() {
      try {
        const {status, data} = await httpClient.get('questionnaire/scientific-activity/index')
        if (status === 200) {
          return data
        }
      } catch (e) {
        console.log(e.response)
      }
      return null
    },
    async POST_SCIENTIFIC_ACTIVITY({commit}, form) {
      try {
        await httpClient.post('questionnaire/scientific-activity/create', form)
        return true
      } catch (e) {
        console.log(e.response)
      }
      return false
    },
    async PUT_SCIENTIFIC_ACTIVITY({commit}, form) {
      try {
        await httpClient.put('questionnaire/scientific-activity/update?id=' + form.id, form)
        return true
      } catch (e) {
        console.log(e.response)
      }
      return false
    },
    async GET_EXPERIENCE_REVIEW() {
      try {
        const {status, data} = await httpClient.get('questionnaire/experience-review/index')
        if (status === 200) {
          return data
        }
      } catch (e) {
        console.log(e.response)
      }
      return []
    },

    async POST_EXPERIENCE_REVIEW({commit}, form) {
      try {
        await httpClient.post('questionnaire/experience-review/create', form)
        return true
      } catch (e) {
        console.log(e.response)
      }
      return false
    },
    async PUT_EXPERIENCE_REVIEW({commit}, form) {
      try {
        await httpClient.put('questionnaire/experience-review/update', form)
        return true
      } catch (e) {
        console.log(e.response)
      }
      return false
    },

    async DELETE_EXPERIENCE_REVIEW({commit}, id) {
      try {
        await httpClient.delete('questionnaire/experience-review/delete?id=' + id)
        return true
      } catch (e) {
        console.log(e.response)
      }
      return false
    },
  },
}

export default scientificActivity
