import kkkService from "@/services/kkk/kkk.service"

const kkk = {
  namespaced: true,
  actions: {
    async GET_TEACHING_POSITIONS({commit}) {
      try {
        const {data} = await kkkService.getTeachingPositions()
        commit('SET_TEACHING_POSITIONS', data)

      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_VACANCY_APPLICATIONS({commit}) {
      try {
        const {data} = await kkkService.getVacancyApplications()
        if (data) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async POST_DH_CONFIRM({commit}, form) {
      try {
        await kkkService.postDHConfirm(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_DH_REJECT({commit}, form) {
      try {
        await kkkService.postDHReject(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_DH_CONFIRM_FAST({commit}, form) {
      try {
        await kkkService.postDHConfirmFast(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_CONTRACT({commit}, form) {
      try {
        await kkkService.postContract(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_CONTRACT({commit}, form) {
      try {
        await kkkService.putContract(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_TEACHER_TYPE({commit}, form) {
      try {
        await kkkService.postTeacherType(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_ADD_TO_ACTIVE_DIRECTORY({commit}, form) {
      try {
        await kkkService.postAddToActiveDirectory(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_DELETE_FROM_ACTIVE_DIRECTORY({commit}, form) {
      try {
        await kkkService.postDeleteFromActiveDirectory(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_TEACHERS({commit}, {page, teacherName}) {
      try {
        const {data, status} = await kkkService.getTeachers(page, teacherName)
        if (status === 200) {
          commit('SET_TEACHER_LIST', data.success)
          commit('SET_TEACHER_PAGE_COUNT', data.page_count)
        }
      } catch (e) {
        commit('SET_TEACHER_LIST', [])
        commit('SET_TEACHER_PAGE_COUNT', 0)
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_KKK_RESULT({commit}, user_id){
      try {
        const {data, status} = await kkkService.getKkkResult(user_id)
        if (status === 200) {
          console.log('GET_KKK_RESULT', data)
          commit('SET_KKK_RESULT', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },


    async GET_TEACHERS_NEW({commit}, {page, limit, teacher_name}){
      try {
        const {data, status} = await kkkService.getTeachersNew(page, limit, teacher_name)
        if (status === 200) {
          console.log('GET_TEACHERS_NEW', data)
          commit('SET_TEACHERS_NEW', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async POST_PPS_DATA_BY_USER({commit}, form) {
      try {
        await kkkService.postPpsDataByUser(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async POST_CREATE_CONTRACT({commit}, form) {
      try {
        await kkkService.postCreateContract(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_UPDATE_CONTRACT({commit}, form) {
      try {
        await kkkService.postUpdateContract(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_UPDATE_CONTRACT_HR_HEAD({commit}, form) {
      try {
        await kkkService.putUpdateContractHrHead(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async POST_INTERVIEW_TIME({commit}, form) {
      try {
        await kkkService.postInterviewTime(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_INTERVIEW_TIME({commit}, form) {
      try {
        await kkkService.putInterviewTime(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_TEACHER_LIST(state, teachers) {
      state.teacher.list = teachers
    },
    SET_TEACHER_PAGE_COUNT(state, pageCount) {
      state.teacher.pageCount = pageCount * state.teacher.pageSize
    },
    SET_TEACHING_POSITIONS(state, teachingPositions) {
      state.teachingPositions = teachingPositions
    },
    SET_TEACHERS_NEW(state, teachers){
      state.teachersNew.list = teachers.success
      state.teachersNew.page_count = teachers.page_count
      state.teachersNew.offset = teachers.offset
      state.teachersNew.total_count = teachers.total_count
      state.teachersNew.status = teachers.status
    },
    SET_KKK_RESULT(state, result){
      state.kkkResult = result;
    }
  },
  state: {
    kkkResult: [],
    teachersNew: {
      page_count: null,
      total_count: null,
      status: null,
      offset: null,
      list: [],
    },
    teachingPositions: [],
    teacher: {
      list: [],
      pageCount: 0,
      pageSize: 50
    }

  },
  getters: {}
}

export default kkk
