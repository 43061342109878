import transferCoursesService from "@/services/admin/transferCourses.service"
import axios from 'axios'

const transferCourses = {
    namespaced: true,
    actions: {
        async GET_CURRENT_SEMESTER_COURSES({commit}) {
            try {
                const {data} = await transferCoursesService.getCurrentSemesterCourses();
                if (data) {
                    console.log(data, 'GET_CURRENT_SEMESTER_COURSES')
                    commit('SET_CURRENT_SEMESTER_COURSES', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_NEXT_SEMESTER_COURSES({commit}, {education_program_id, semester}) {
            console.log(education_program_id, ' '+ semester)
            try {
                const {data} = await transferCoursesService.getNextSemesterCourses(education_program_id, semester);
                if (data) {
                    console.log(data, 'GET_NEXT_SEMESTER_COURSES')
                    commit('SET_NEXT_SEMESTER_COURSES', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_NEXT_SEMESTER_LANGUAGE_COURSE({commit, state}) {

            let nextSemesterLanguageCourseForm = state.transferCourses_form.nextSemesterLanguageCourseInfo

            nextSemesterLanguageCourseForm['education_course_name'] = state.transferCourses_form.education_discipline_name
                + ' ' + state.transferCourses_form.selectable_group_name + ' ' + state.transferCourses_form.sp_academic_year_short_name

            console.log(nextSemesterLanguageCourseForm, 'post nextSemesterLanguageCourseForm')
            try {
                const {data} = await transferCoursesService.postNextSemesterLanguageCourse(nextSemesterLanguageCourseForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM({state, commit}, formation_education_program_id) {
            try {
                const {data} = await transferCoursesService.getTeachersByFormationEducationProgram(formation_education_program_id);
                if (data) {
                    console.log(data, 'GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM')
                    commit('SET_TEACHERS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_STUDENTS_BY_COURSE_ID({commit}, course_id) {
            try {
                const {data} = await transferCoursesService.getStudentsByCourseId(course_id);
                if (data) {
                    commit('SET_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_CURRENT_SEMESTER_COURSES(state, currentSemesterCourses) {
            state.transferCourses_form.currentSemesterCourses = currentSemesterCourses
        },
        SET_NEXT_SEMESTER_COURSES(state, nextSemesterCourses) {
            state.transferCourses_form.nextSemesterCourses = nextSemesterCourses
        },
        SET_NEW_COURSE_DATA(state, data) {
            state.transferCourses_form.nextSemesterLanguageCourseInfo['past_education_course_id'] = data.education_courses_id
            state.transferCourses_form.selectable_group_name = data.selectable_group_name
            state.transferCourses_form.sp_academic_year_short_name = data.sp_academic_year_short_name
            state.transferCourses_form.nextSemesterLanguageCourseInfo['education_curriculum_id'] = data.education_curriculum_id
            state.transferCourses_form.nextSemesterLanguageCourseInfo['lecture_pps_id'] = data.lecture_pps_id
            state.transferCourses_form.nextSemesterLanguageCourseInfo['selectable_group_id'] = data.selectable_group_id
            console.log(state.transferCourses_form.nextSemesterLanguageCourseInfo, 'STATE nextSemesterLanguageCourseInfo')

        },
        ADD_NEW_COURSE_DATA(state, data) {
            state.transferCourses_form.education_discipline_name = data.education_discipline_name
            state.transferCourses_form.nextSemesterLanguageCourseInfo['formation_education_program_id'] = data.formation_education_program_id
            console.log(state.transferCourses_form.nextSemesterLanguageCourseInfo, 'STATE nextSemesterLanguageCourseInfo')
        },
        CLEAR_TRANSFER_COURSE_DATA(state) {
            state.transferCourses_form.education_discipline_name = null
            state.transferCourses_form.selectable_group_name = null
            state.transferCourses_form.sp_academic_year_short_name = null
            state.transferCourses_form.nextSemesterLanguageCourseInfo = {}
        },
        SET_TEACHERS(state, teachers) {
            state.transferCourses_form.teachers = teachers
            console.log(state.transferCourses_form.teachers, 'teachers')
        },
        SET_UPDATE_LECTURE_PPS_ID(state, lecture_pps_id) {
            state.transferCourses_form.nextSemesterLanguageCourseInfo['lecture_pps_id'] = lecture_pps_id
            console.log(state.transferCourses_form.nextSemesterLanguageCourseInfo, 'STATE nextSemesterLanguageCourseInfo')
        },
        SET_STUDENTS(state, students) {
            state.transferCourses_form.students = students
        },

    },
    state: {
        transferCourses_form: {
            currentSemesterCourses: [],
            nextSemesterCourses: [],
            nextSemesterLanguageCourseInfo: {},
            education_discipline_name: null,
            selectable_group_name: null,
            sp_academic_year_short_name: null,
            teachers: [],
            students: []
        }
    },
    getters: {}
}

export default transferCourses