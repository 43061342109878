import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const children = {
    namespaced: true,
    actions: {
        async DELETE_CHILDREN_DATA({commit, state}, index) {
            let id = state.children_form.childrenInfos[index].id
            try {
                const {
                    status,
                    data
                } = await httpClient.delete('questionnaire/user-children/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('children data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_CHILDREN_DATA() {
            let childrenForm = (children.state.children_form.childrenInfos).filter(v => v.put_status == 0)
            if (childrenForm.length > 0) {
                console.log(childrenForm)
                try {
                    const {
                        status,
                        data
                    } = await httpClient().post('questionnaire/user-children/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), childrenForm);
                    if (status === 200) {
                        console.log(data, 'create')
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async PUT_CHILDREN_DATA() {
            let childrenForm = (children.state.children_form.childrenInfos).filter(v => v.put_status == 1)
            if (childrenForm.length > 0) {

                try {
                    console.log(childrenForm)
                    const {
                        status,
                        data
                    } = await httpClient().put('questionnaire/user-children/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), childrenForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_CHILDREN_DATA({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('questionnaire/user-children/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit("SET_CHILDREN_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_CHILDREN_DATA(state, childrenInfos) {
            state.children_form.childrenInfos = childrenInfos
        },
        ADD_ROW_CHILDREN_INFO(state) {
            state.children_form.childrenInfos.push({
                gender: '',
                birth_date: 0,
                put_status: 0
            });
        },
        DELETE_ROW_CHILDREN_INFO(state, index) {
            state.children_form.childrenInfos.splice(index, 1);
        },
        UPDATE_CHILDREN_GENDER(state, payload) {
            state.children_form.childrenInfos[payload.index].gender = payload.value
        },
        UPDATE_CHILDREN_BIRTH_DATE(state, payload) {
            state.children_form.childrenInfos[payload.index].birth_date = parseInt((new Date(payload.value)).getTime() / 1000)
        }
    },
    state: {
        children_form: {
            childrenInfos: [{
                gender: '',
                birth_date: 0,
                put_status: 0
            }],
        },
    },
    getters: {

    }
}

export default children