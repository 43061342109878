import httpClient from "@/services/http.service"
import router from "@/router"


const teachingExperiencesService = {
  getTeachingExperiencesFront(user_id = null) {
    const query = user_id ? `?user_id=${user_id}` : ``
    return httpClient.get(`pps/pps-data/get-teaching-disciplines-history-front${query}`)
  },
  getTeachingExperiences(user_id = null) {
    const query = user_id ? `?user_id=${user_id}` : ``
    return httpClient.get(`pps/pps-data/get-teaching-disciplines-history${query}`)
  },
  postTeachingExperiences(postData) {
    return httpClient.post(`kkk/teaching-experience/create`, postData)
  },
  putTeachingExperiences(putData) {
    return httpClient.put(`kkk/teaching-experience/update?id=${putData.id}`, putData)
  },
  deleteTeachingExperiences(deleteId) {
    return httpClient.delete(`kkk/teaching-experience/delete?id=${deleteId}`)
  },
}

export default teachingExperiencesService