import user from '@/services/auth.service'
import academicDebtService from "@/services/student/academicDebt.service";
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const personal = {
    namespaced: true,
    actions: {
        async getUserInfo({commit}) {
            let userInfo = await user.getUser()
            commit('updateUserInfo', userInfo)
        },
        async getNationalities({commit, getters}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/pps-data/nationality' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateNationalities', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async getGenders({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/pps-data/sex' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateGenders', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async getWarns({commit}) {
            try {
                const {status, data} = await httpClient.get('schedule/teacher-late-time/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_WARNS', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async getMaritalStatuses({commit, getters, dispatch}) {
            try {
                const {status, data} = await httpClient.get('questionnaire/pps-data/marital-status' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('updateMaritalStatuses', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async POST_PPS_DATA_PHOTO({state, commit}, data) {
            let formData = new FormData();
            formData.append("file", data["file"]);
            // console.log(Object.fromEntries(formData), 'formData')
            try {
                const {data} = await httpClient.post('questionnaire/pps-data/update-photo' + '?access-token=' + getCookie('ACCESS_TOKEN'), formData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false

        },
    },
    mutations: {
        updatePersonalForm(state, userPersonalData) {
            state.form.firstname = userPersonalData.firstname
            state.form.lastname = userPersonalData.lastname
            state.form.middlename = userPersonalData.middlename
            state.form.birth_place = userPersonalData.birth_place
            state.form.birth_date = userPersonalData.birth_date
            state.form.phone_number = userPersonalData.phone_number
            state.form.home_phone_number = userPersonalData.home_phone_number
            state.form.disability_group = userPersonalData.disability_group
            state.form.retiree_status = userPersonalData.retiree_status
            state.form.military_status = userPersonalData.military_status
            state.form.conviction_status = userPersonalData.conviction_status
            state.form.business_trip_status = userPersonalData.business_trip_status
            state.form.nationality = userPersonalData.nationality
            state.form.sex = userPersonalData.sex
            state.form.marital_status = userPersonalData.marital_status
            state.form.status = userPersonalData.status
            state.form.science_degree_id = userPersonalData.science_degree_id
            state.form.science_degree_child_id = userPersonalData.science_degree_child_id
            state.form.science_rank_id = userPersonalData.science_rank_id
            state.form.photo = userPersonalData.photo
            state.form.beauty_photo = userPersonalData.beauty_photo
        },
        updateNationalities(state, nationalities) {
            state.nationalities = nationalities
        },
        updateGenders(state, genders) {
            state.genders = genders
        },
        updateMaritalStatuses(state, maritalStatuses) {
            state.marital_statuses = maritalStatuses
        },
        SET_WARNS(state, data){
            state.warns = data;
        },
        updateFirstName(state, firstname) {
            state.form.firstname = firstname
        },
        updateLastName(state, lastname) {
            state.form.lastname = lastname
        },
        updateMiddleName(state, middlename) {
            state.form.middlename = middlename
        },

        updateBirthPlace(state, birth_place) {
            state.form.birth_place = birth_place
        },
        updateBirthDate(state, birth_date) {
            state.form.birth_date = parseInt((new Date(birth_date)).getTime() / 1000)
        },
        updatePhoneNumber(state, phone_number) {
            state.form.phone_number = phone_number
        },
        updateHomePhoneNumber(state, home_phone_number) {
            state.form.home_phone_number = home_phone_number
        },
        updateSex(state, sex) {
            state.form.sex = sex
        },
        updateNationality(state, nationality) {
            state.form.nationality = nationality
        },
        updateMaritalStatus(state, marital_status) {
            state.form.marital_status = marital_status
        },
        updateRetireeStatus(state, retiree_status) {
            state.form.retiree_status = retiree_status
        },
        updateDisabilityGroup(state, disability_group) {
            state.form.disability_group = disability_group
        },
        updateMilitaryStatus(state, military_status) {
            state.form.military_status = military_status
        },
        updateConvictionStatus(state, conviction_status) {
            state.form.conviction_status = conviction_status
        },
        updateBusinessTripStatus(state, business_trip_status) {
            state.form.business_trip_status = business_trip_status
        },
        updatePersonalHeadHunterData(state, headHunterData) {
            console.log('HEAD HUNTER DATA', headHunterData)
            state.headHunterData = headHunterData
            console.log('state hhData', state.headHunterData)
        },
        SET_HEADHUNTER_TO_PERSONAL_DATA(state, hhData) {
            console.log(hhData, 'hhData personal.js')
            state.form.firstname = hhData.first_name
            state.form.lastname = hhData.last_name
            state.form.middlename = hhData.middle_name
            state.form.sex = hhData.gender.id == 'male' ? 1 : 2
        }
    },
    state: {
        form: {
            firstname: ' ',
            lastname: ' ',
            middlename: ' ',
            birth_place: 'Kazakhstan',
            birth_date: parseInt(new Date().getTime() / 1000),
            phone_number: '',
            home_phone_number: '',
            disability_group: '',
            retiree_status: 0,
            military_status: 1,
            conviction_status: 0,
            business_trip_status: 1,
            nationality: 0,
            sex: 1,
            marital_status: 0,
            status: 0,
            photo: ''
        },
        warns: [],
        nationalities: [],
        genders: [],
        marital_statuses: [],
        headHunterData: [],
        resumeIndex: 0
    },
    getters: {
        allNationalities(state) {
            return state.nationalities
        },
        allGenders(state) {
            return state.genders
        },
        allMaritalStatuses(state) {
            return state.marital_statuses
        }
    }
}

export default personal
