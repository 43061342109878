import httpClient from "@/services/http.service"


const studentScheduleService = {

    getDisciplines(student_id) {
        return httpClient.get(`schedule/student-schedule-choose/index`+ '?student_id='+student_id)
    },
    getStudentInfo(student_id) {
        return httpClient.get(`student_data/student-data/get-info-by-barcode`+ '?student_id='+student_id)
    },
    postStudentScheduleChoose(form) {
        return httpClient.post(`schedule/student-schedule-choose/create`, form)
    },
    putStudentScheduleChoose(updateForm) {
        return httpClient.put(`schedule/student-schedule-choose/update`, updateForm)
    },
    deleteStudentScheduleChoose(pps_teaching_load_id) {
        return httpClient.delete(`schedule/student-schedule-choose/delete` + '?pps_teaching_load_id='+ pps_teaching_load_id)
    },
}

export default studentScheduleService