import httpClient from "@/services/http.service"


const managerScheduleService = {
  getDays() {
    return httpClient.get(`schedule/sp-days/index`)
  },
  getScheduleIntervalTimes() {
    return httpClient.get(`schedule/schedule-interval-time/index`)
  },
  getDisciplineSchedule(study_level_id, language_id, study_course) {
    return httpClient.get(`education_program/education-discipline/get-education-discipline-for-schedule?study_level_id=${study_level_id}&language_id=${language_id}&study_course=${study_course}`)
  },
  getPpsTeachingLoadByDisciplineId(queryParam = {}) {
    return httpClient.get(`load_calculation/pps-teaching-load/get-pps-teaching-load-by-discipline-id?${Object.keys(queryParam).map(q => `${q}=${queryParam[q]}`).join('&')}`)
  },
  getFreeRooms(count_of_student, day_id, schedule_interval_time_id, need_computer) {
    return httpClient.get(`schedule/sp-rooms/get-free-rooms?count_of_student=${count_of_student}&day_id=${day_id}&schedule_interval_time_id=${schedule_interval_time_id}&need_computer=${need_computer}`)
  },

  getSchedule(queryParam = {}) {
    return httpClient.get(`schedule/schedule/index?${Object.keys(queryParam).map(q => `${q}=${queryParam[q]}`).join('&')}`)
  },
  postSchedule(form) {
    return httpClient.post(`schedule/schedule/create`, form)
  },
  deleteSchedule(id) {
    return httpClient.delete(`schedule/schedule/delete?id=${id}`)
  },

  putSchedule(form) {
    return httpClient.put(`schedule/schedule/update?id=${form.id}`, form)
  },

  getPpsFreeTime(discipline_id) {
    return httpClient.get(`schedule/pps-free-time/get-pps-free-time?discipline_id=${discipline_id}`)
  },


  getTeacherLate(user_id) {
    return httpClient.get(`schedule/teacher-late-time/index?user_id=${user_id}`)
  },
  postTeacherLate(form) {
    return httpClient.post(`schedule/teacher-late-time/create`, form)
  },

  getRooms() {
    return httpClient.get(`schedule/sp-rooms/index`)
  },
}

export default managerScheduleService