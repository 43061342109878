import statementPrintService from "@/services/admin/statementPrint.service"
import axios from 'axios'

const statementPrint = {
    namespaced: true,
    actions: {
        async GET_COURSES_BY_NAME({commit}, name = '') {
            try {
                const {data} = await statementPrintService.getCoursesByName(name);
                if (data) {
                    //console.log(data, 'GET_COURSES_BY_NAME')
                    //commit('SET_COURSES_BY_NAME', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_COURSES_BY_PPS_LAST_NAME({commit}, lastname = '') {
            try {
                const {data} = await statementPrintService.getCoursesByPpsLastName(lastname);
                if (data) {
                    //console.log(data, 'GET_COURSES_BY_NAME')
                    //commit('SET_COURSES_BY_NAME', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_SUMMER_COURSES({commit}, queryData) {
            try {
                const {data} = await statementPrintService.getSummerCourses(queryData);
                if (data) {
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        // SET_COURSES_BY_NAME(state, courses) {
        //     state.statementPrint_form.courses = courses
        // }

    },
    state: {
        statementPrint_form: {
            //courses: []
        }
    },
    getters: {}
}

export default statementPrint