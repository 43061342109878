import conferenceCoordinatorService from "@/services/conference/coordinator/conferenceCoordinator.service"
import axios from 'axios'

const conferenceCoordinator = {
    namespaced: true,
    actions: {
        async GET_CONFERENCE_ARTICLE({commit}) {
            try {
                const {data} = await conferenceCoordinatorService.getConferenceArticle();
                if (data) {
                    console.log(data, 'GET_CONFERENCE_ARTICLE')
                    commit('SET_CONFERENCE_ARTICLE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_SECTION_MODERATORS({commit}) {
            try {
                const {data} = await conferenceCoordinatorService.getSectionModerators();
                if (data) {
                    commit('SET_SECTION_MODERATORS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async PUT_SECTION_MODERATOR({commit, state}, putData) {

            console.log(putData, 'putData')

            try {
                const {data} = await conferenceCoordinatorService.putSectionModerator(putData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_CONFERENCE_ARTICLE(state, conferenceArticle) {
            state.conferenceCoordinator_form.conferenceArticle = conferenceArticle
        },
        SET_SECTION_MODERATORS(state, sectionModerators) {
            state.conferenceCoordinator_form.sectionModerators = sectionModerators
        },

    },
    state: {
        conferenceCoordinator_form: {
            conferenceArticle: [],
            sectionModerators: []
        }
    },
    getters: {}
}

export default conferenceCoordinator