import curriculumOptionalComponentService
  from "@/services/curriculum-optional-component/curriculumOptionalComponent.service"

const curriculumOptionalComponent = {
  namespaced: true,
  actions: {

    async GET_OPTIONAL_COMPONENT({commit}, {code, admission_year, study_course, language}) {
      try {
        const {data} = await curriculumOptionalComponentService.getOptionalComponent(code, admission_year, study_course, language);
        if (data) {
          commit('SET_OPTIONAL_COMPONENT', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_COURSES({commit}) {
      try {
        const {data} = await curriculumOptionalComponentService.getCourses();
        if (data) {
          commit('SET_COURSES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_MODULE_TYPES({commit}) {
      try {
        const {data} = await curriculumOptionalComponentService.getModuleTypes();
        if (data) {
          commit('SET_MODULE_TYPES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_MINOR_TYPES({commit}) {
      try {
        const {data} = await curriculumOptionalComponentService.getMinorTypes();
        if (data) {
          commit('SET_MINOR_TYPES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_OPTIONAL_DISCIPLINES({commit}, putData) {
      try {
        const {status, data} = await curriculumOptionalComponentService.postOptionalDisciplines(putData)
        if (status === 200) {
          return {success: true, data}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async PUT_UPDATE_STUDENTS_DISCIPLINE_CHOOSE({commit}, {delete_formation_id, change_formation_id}) {
      try {
        const {status, data} = await curriculumOptionalComponentService.postUpdateStudentsDisciplineChoose(delete_formation_id, change_formation_id)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async PUT_DISCIPLINE_CONFIRM_STATUS({commit}, {formation_id, confirm_status}) {
      try {
        const {status} = await curriculumOptionalComponentService.putDisciplineConfirmStatus(formation_id, confirm_status)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async POST_CREATE_STUDENTS_DISCIPLINE_CHOOSE({commit}, {students, formation_id, semester}) {
      try {
        const {status} = await curriculumOptionalComponentService.postCreateStudentsDisciplineChoose(students, formation_id, semester)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async POST_MOVE_ELECTABILITY_STUDENTS({commit}, minor_f_group_id) {
      try {
        const {status} = await curriculumOptionalComponentService.postMoveElectabilityStudents(minor_f_group_id)
        if (status === 200) {
          return {success: true}
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {success: false}
    },
    async GET_GROUPED_EDUCATION_PROGRAMS({state, commit}, {studyLevelId, studyCourse, type, language}) {
      try {
        const {data} = await curriculumOptionalComponentService.getGroupedEducationPrograms(studyLevelId, studyCourse, type, language)
        if (data) {
          console.log('GET_GROUPED_EDUCATION_PROGRAMS', data)
          commit('SET_GROUPED_EDUCATION_PROGRAMS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_COURSES(state, courses) {
      state.courses = courses
    },
    SET_MODULE_TYPES(state, types) {
      state.moduleTypes = types
    },
    SET_MINOR_TYPES(state, types) {
      state.minorTypes = types
    },
    SET_OPTIONAL_COMPONENT(state, optionalComponent) {
      state.optionalComponent = optionalComponent
    },
    SET_GROUPED_EDUCATION_PROGRAMS(state, groupedEducationPrograms) {
      state.groupedEducationPrograms = groupedEducationPrograms
    }
  },
  state: {
    optionalComponent: {},
    groupedEducationPrograms: [],
    courses: [],
    moduleTypes: [],
    minorTypes: [],
  },
  getters: {}
}

export default curriculumOptionalComponent