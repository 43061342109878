import ppsLoadService from "@/services/load-calculation/ppsLoad.service"
import axios from 'axios'
import teacherScheduleService from "@/services/schedule/teacherSchedule.service";

const ppsLoad = {
  namespaced: true,
  actions: {

    // async GET_STUDY_LEVELS({commit}) {
    //     try {
    //         const {data} = await ppsLoadService.getStudyLevels();
    //         if (data) {
    //             console.log(data, 'GET_STUDY_LEVELS')
    //             commit('SET_STUDY_LEVELS', data)
    //             return true
    //         }
    //     } catch (e) {
    //         commit('SET_ERROR', e, {root: true})
    //     }
    //     return false
    // },
    async DELETE_PPS_TEACHING_LOAD({commit}, deleteId) {
      try {
        const {status, data} = await ppsLoadService.deletePpsTeachingLoad(deleteId)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})

      }
      return false
    },

    async GET_LANGUAGE_DISCIPLINES({commit}, {languageId, studyLevelId}) {
      try {
        const {data} = await ppsLoadService.getLanguageDisciplines(languageId, studyLevelId);
        if (data) {
          console.log(data, 'GET_LANGUAGE_DISCIPLINES')
          commit('SET_DISCIPLINES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_DISCIPLINES_NEW({commit}, {languageId, studyLevelId}) {
      try {
        const {data} = await ppsLoadService.getDisciplinesNew(languageId, studyLevelId);
        if (data) {
          console.log(data, 'GET_DISCIPLINES')
          commit('SET_DISCIPLINES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async GET_DISCIPLINES({commit}, {languageId, studyLevelId}) {
      try {
        const {data} = await ppsLoadService.getDisciplines(languageId, studyLevelId);
        if (data) {
          console.log(data, 'GET_DISCIPLINES')
          commit('SET_DISCIPLINES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_FORMED_DISCIPLINES({commit}, {languageId, studyLevelId}) {
      try {
        const {data} = await ppsLoadService.getFormedDisciplines(languageId, studyLevelId);
        if (data) {
          console.log(data, 'GET_FORMED_DISCIPLINES')
          commit('SET_FORMED_DISCIPLINES', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_TEACHERS({commit}, education_discipline_id) {

      try {
        const {data} = await ppsLoadService.getTeachers(education_discipline_id);
        if (data) {
          console.log(data, 'GET_TEACHERS')
          commit('SET_TEACHERS', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_EDUCATION_TYPE({commit}) {

      try {
        const {data} = await ppsLoadService.getEducationType();
        if (data) {
          console.log(data, 'GET_EDUCATION_TYPE')
          commit('SET_EDUCATION_TYPE', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_FORMED_PPS_TEACHING_LOAD({commit}) {

      try {
        const {data} = await ppsLoadService.getFormedPpsTeachingLoad();
        if (data) {
          console.log(data, 'GET_FORMED_PPS_TEACHING_LOAD')
          commit('SET_FORMED_PPS_TEACHING_LOAD', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_PPS_TEACHING_LOAD_BY_PPS_ID({commit}, pps_id) {

      try {
        const {data} = await ppsLoadService.getPpsTeachingLoadByPpsId(pps_id);
        if (data) {
          console.log(data, 'GET_PPS_TEACHING_LOAD_BY_PPS_ID')
          commit('SET_INDIVIDUAL_PPS_TEACHING_LOAD', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_TEACHER_LOAD_CALCULATION({commit}) {

      try {
        const {data} = await ppsLoadService.getTeacherLoadCalculation();
        if (data) {
          console.log(data, 'GET_TEACHER_LOAD_CALCULATION')
          commit('SET_TEACHER_LOAD_CALCULATION', data)
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_FOR_UPDATE_PPS_TEACHING_LOAD({commit, state}, form) {
      console.log(form, 'POST form')

      try {
        const {data} = await ppsLoadService.postPpsTeachingLoad(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_FOR_UPDATE_LANGUAGE_PPS_TEACHING_LOAD({commit, state}) {
      //console.log(form, 'POST language form')

      let updateSeminar = state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad
      let updateExam = state.ppsLoad_form.newUpdateExamPpsTeachingLoad
      let form = updateSeminar.concat(updateExam)
      try {
        const {data} = await ppsLoadService.postPpsTeachingLoad(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async POST_PPS_TEACHING_LOAD({commit, state}, {lectureForm, seminarForm, examForm}) {
      console.log(lectureForm, 'POST lectureForm')
      console.log(seminarForm, 'POST seminarForm')
      console.log(examForm, 'POST examForm')

      //let ppsTeachingLoadForm = state.ppsLoad_form.newLecturePpsTeachingLoad.concat(state.ppsLoad_form.newSeminarPpsTeachingLoad);
      //let ppsTeachingLoadForm = lectureForm.concat(seminarForm);

      // if (lectureForm.find(i=> i.pps_id == seminarForm.find(t2 => t2.pps_id === i.pps_id))) {
      //     console.log("hello")
      // }
      let lecturePpsTeachingLoadForm = lectureForm.concat(state.ppsLoad_form.newLecturePpsTeachingLoad)
      let seminarPpsTeachingLoadForm = seminarForm.concat(state.ppsLoad_form.newSeminarPpsTeachingLoad)


      //let ppsLoadData = lecturePpsTeachingLoadForm.concat(seminarPpsTeachingLoadForm, examForm).filter(i=>i.pps_id != 0)
      let ppsLoadData = lecturePpsTeachingLoadForm.concat(seminarPpsTeachingLoadForm, examForm)

      //let mergedArray = lectureForm.map(t1 => ({...t1, ...seminarForm.find(t2 => t2.pps_id === t1.pps_id)}))
      //
      // let mergedArray2 = mergedArray.map(t1 => ({...t1, ...examForm.find(t2 => t2.pps_id === t1.pps_id)}))
      console.log(ppsLoadData, 'ppsLoadData')

      /*
      let newData = data.reduce(function(acc, curr) {
          let findIndex = acc.findIndex(function(item) {
              return item.pps_id === curr.pps_id
          })

          if (findIndex === -1) {
              acc.push(curr)
          } else {
              acc[findIndex] = (Object.assign({}, acc[findIndex], curr))

          }


          return acc;
      }, [])
      console.log(newData, 'newData')
      */

      // console.log(mergedArray2, 'mergedArray2')

      //let arr3 = lectureForm.map((item, i) => Object.assign({}, item, seminarForm[i]));
      //let arr4 = arr3.map((item, i) => Object.assign({}, item, examForm[i]));


      //console.log(arr3, 'arr3');
      //console.log(arr4, 'arr4');

      //console.log(mergedArray2, 'mergedArray2')
      //
      // let mergedArray = a3.map(t1 => ({...t1, ...a4.find(t2 => t2.pps_id === t1.pps_id)}))
      // console.log(mergedArray, 'mergedArray')
      //console.log(ppsTeachingLoadForm, 'post ppsTeachingLoadForm')

      try {
        const {data} = await ppsLoadService.postPpsTeachingLoad(ppsLoadData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async POST_LANGUAGE_PPS_TEACHING_LOAD({commit, state}, {seminarForm, examForm}) {

      console.log(seminarForm, 'POST seminarForm')
      console.log(examForm, 'POST examForm')

      //let seminarPpsTeachingLoadForm = []
      let ppsLoadData = []
      if (seminarForm) {
        let seminar = seminarForm.concat(state.ppsLoad_form.newSeminarPpsTeachingLoad)
        let exam = examForm.concat(state.ppsLoad_form.newExamPpsTeachingLoad)


        ppsLoadData = seminar.concat(exam)

      } else {
        ppsLoadData = state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad
      }


      console.log(ppsLoadData, 'ppsLoadData')


      //console.log(seminarPpsTeachingLoadForm, 'seminarPpsTeachingLoadForm')


      try {
        const {data} = await ppsLoadService.postPpsTeachingLoad(ppsLoadData);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async POST_DISCIPLINE_VACANCY({commit, state}) {
      let vacancyForm = []
      let discipline_id = state.ppsLoad_form.educationDisciplineId
      console.log(discipline_id, 'discipline_id')
      vacancyForm.push({
        discipline_id: discipline_id
      })
      console.log(vacancyForm, 'vacancyForm')
      try {
        const {data} = await ppsLoadService.postDisciplineVacancy(vacancyForm);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_PPS_TEACHING_LOAD({commit, state}, form) {


      try {
        const {data} = await ppsLoadService.putPpsTeachingLoad(form);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_LANGUAGE_PPS_TEACHING_LOAD({commit, state}) {
      let updateForm = state.ppsLoad_form.updatePpsTeachingLoad


      try {
        const {data} = await ppsLoadService.putLanguagePpsTeachingLoad(updateForm);
        if (data) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async POST_CONFIRM_PPS_LOAD({commit}) {
      try {
        await ppsLoadService.postConfirmPpsLoad()
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async GET_STUDENTS_BY_EDUCATION_PROGRAMS_FOR_KAZAKH_DISCIPLINE({commit}, educationPrograms) {
      try {
        const {data} = await ppsLoadService.getStudentsByEducationProgramsForKazakhLanguage(educationPrograms)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },


  },
  mutations: {
    // SET_STUDY_LEVELS(state, studyLevels) {
    //     state.ppsLoad_form.studyLevels = studyLevels
    // },
    SET_FORMED_PPS_TEACHING_LOAD(state, formedPpsTeachingLoad) {
      state.ppsLoad_form.formedPpsTeachingLoad = formedPpsTeachingLoad
    },
    SET_TEACHER_LOAD_CALCULATION(state, teacherLoadCalculation) {
      state.ppsLoad_form.teacherLoadCalculation = teacherLoadCalculation
    },
    SET_DISCIPLINES(state, disciplines) {
      state.ppsLoad_form.disciplines = disciplines
    },
    SET_FORMED_DISCIPLINES(state, formedDisciplines) {
      state.ppsLoad_form.formedDisciplines = formedDisciplines
    },
    SET_TEACHERS(state, teachers) {
      state.ppsLoad_form.teachers = teachers
    },
    SET_EDUCATION_TYPE(state, educationType) {
      state.ppsLoad_form.educationType = educationType
    },
    CLEAR_DISCIPLINES(state) {
      state.ppsLoad_form.disciplines = []
    },
    ADD_ROW_LECTURE(state, {hour, semester, credit}) {
      state.ppsLoad_form.newLecturePpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        semester: semester,
        credit: credit,
        students_count: 0,

        education_type_id: 1,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 0
      });
      console.log(state.ppsLoad_form.newLecturePpsTeachingLoad)
    },
    ADD_ROW_LECTURE_FORECAST(state, hour) {
      state.ppsLoad_form.newLecturePpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        students_count: 0,

        education_type_id: 1,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 1
      });
      console.log(state.ppsLoad_form.newLecturePpsTeachingLoad)
    },
    DELETE_ROW_LECTURE(state, index) {
      state.ppsLoad_form.newLecturePpsTeachingLoad.splice(index, 1);
    },

    ADD_ROW_SEMINAR(state, {hour, semester, credit}) {
      state.ppsLoad_form.newSeminarPpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        semester: semester,
        credit: credit,
        students_count: 0,
        education_type_id: 2,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 0
      });
      console.log(state.ppsLoad_form.newSeminarPpsTeachingLoad)
    },
    ADD_ROW_SEMINAR_LANGUAGE(state, {hour, semester, credit}) {
      state.ppsLoad_form.newSeminarPpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        semester: semester,
        credit: credit,
        students_count: 0,
        education_type_id: 2,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 0
      });
      console.log(state.ppsLoad_form.newSeminarPpsTeachingLoad)


      state.ppsLoad_form.newExamPpsTeachingLoad.push({
        pps_id: null,
        hour: 15,
        semester: semester,
        credit: credit,
        students_count: 0,
        education_type_id: 3,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 0
      });
      console.log(state.ppsLoad_form.newExamPpsTeachingLoad)
    },
    ADD_ROW_EXAM(state, {hour, semester, credit}) {
      state.ppsLoad_form.newExamPpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        semester: semester,
        credit: credit,
        students_count: 0,
        education_type_id: 3,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 0
      });
      console.log(state.ppsLoad_form.newExamPpsTeachingLoad)
    },

    ADD_ROW_SEMINAR_FORECAST(state, hour) {
      state.ppsLoad_form.newSeminarPpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        students_count: 0,
        education_type_id: 2,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        is_predictive: 1
      });
      console.log(state.ppsLoad_form.newSeminarPpsTeachingLoad)
    },
    ADD_ROW_UPDATE_SEMINAR(state, {hour, semester, credit}) {
      state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad.push({
        pps_id: null,
        hour: hour,
        students_count: 0,
        education_type_id: 2,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        semester: semester,
        credit: credit,
        is_predictive: 0
      });
      console.log('ADD_ROW_UPDATE_SEMINAR newUpdateSeminarPpsTeachingLoad', state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad)

      state.ppsLoad_form.newUpdateExamPpsTeachingLoad.push({
        pps_id: null,
        hour: 15,
        students_count: null,
        education_type_id: 3,
        education_discipline_id: state.ppsLoad_form.educationDisciplineId,
        semester: semester,
        credit: credit,
        is_predictive: 0
      });
      console.log('ADD_ROW_UPDATE_SEMINAR newUpdateExamPpsTeachingLoad', state.ppsLoad_form.newUpdateExamPpsTeachingLoad)
    },
    ADD_ROW_UPDATE(state, {education_discipline_id, semester, credit, education_type_id}) {
      let discipline = state.ppsLoad_form.disciplines.find(i => i.education_discipline_id == education_discipline_id && i.semester == semester && i.credit == credit)
      let hour = discipline.pps_teaching_load.find(i => i.education_type_id == education_type_id).hour
      discipline.pps_teaching_load.push({
        pps_id: null,
        hour: hour,
        students_count: null,

        education_type_id: education_type_id,
        education_discipline_id: discipline.education_discipline_id,
        semester: discipline.semester,
        credit: discipline.credit,
        is_predictive: 0
      });
      console.log(state.ppsLoad_form.disciplines)
    },
    DELETE_ROW_SEMINAR(state, index) {
      state.ppsLoad_form.newSeminarPpsTeachingLoad.splice(index, 1);
    },
    DELETE_ROW_SEMINAR_LANGUAGE(state, index) {
      state.ppsLoad_form.newSeminarPpsTeachingLoad.splice(index, 1);
      state.ppsLoad_form.newExamPpsTeachingLoad.splice(index, 1);
    },
    DELETE_ROW_EXAM(state, index) {
      state.ppsLoad_form.newExamPpsTeachingLoad.splice(index, 1);
    },
    DELETE_ROW_UPDATE_SEMINAR(state, index) {
      state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad.splice(index, 1);
    },
    DELETE_ROW_UPDATE(state, {ppsLoad}) {

      let discipline = state.ppsLoad_form.disciplines.find(i => i.education_discipline_id == ppsLoad.education_discipline_id)
      let index = discipline.pps_teaching_load.indexOf(ppsLoad)

      discipline.pps_teaching_load.splice(index, 1);
      console.log(discipline.pps_teaching_load, 'DELETE_ROW_UPDATE')
    },
    DELETE_ROW_UPDATE_LANGUAGE(state, {ppsLoad}) {

      let discipline = state.ppsLoad_form.disciplines.find(i => i.education_discipline_id == ppsLoad.education_discipline_id)
      let index = discipline.pps_teaching_load.indexOf(ppsLoad)

      discipline.pps_teaching_load.splice(index, 1);
      console.log(discipline.pps_teaching_load, 'DELETE_ROW_UPDATE')
    },
    SET_NEW_LECTURE_PPS_TEACHING_LOAD_DATA(state, {item, property, value}) {
      const index = state.ppsLoad_form.newLecturePpsTeachingLoad.indexOf(item)
      state.ppsLoad_form.newLecturePpsTeachingLoad[index][property] = value
      console.log(state.ppsLoad_form.newLecturePpsTeachingLoad, "STATE")
    },
    SET_NEW_SEMINAR_PPS_TEACHING_LOAD_DATA(state, {item, property, value}) {
      const index = state.ppsLoad_form.newSeminarPpsTeachingLoad.indexOf(item)
      state.ppsLoad_form.newSeminarPpsTeachingLoad[index][property] = value
      console.log(state.ppsLoad_form.newSeminarPpsTeachingLoad, "STATE")
    },
    SET_NEW_EXAM_PPS_TEACHING_LOAD_DATA(state, {item, property, value}) {
      const index = state.ppsLoad_form.newExamPpsTeachingLoad.indexOf(item)
      state.ppsLoad_form.newExamPpsTeachingLoad[index][property] = value
      console.log(state.ppsLoad_form.newExamPpsTeachingLoad, "STATE")
    },
    SET_UPDATE_SEMINAR_PPS_TEACHING_LOAD_DATA(state, {item, property, value}) {
      const index = state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad.indexOf(item)
      state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad[index][property] = value
      console.log(state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad, "STATE")
    },
    SET_EDUCATION_DISCIPLINE_ID(state, education_discipline_id) {
      state.ppsLoad_form.educationDisciplineId = education_discipline_id
      console.log(state.ppsLoad_form.educationDisciplineId, "STATE educationDisciplineId")
    },
    CLEAR_STATE(state) {
      state.ppsLoad_form.newLecturePpsTeachingLoad = []
      state.ppsLoad_form.newSeminarPpsTeachingLoad = []
      state.ppsLoad_form.newExamPpsTeachingLoad = []
      state.ppsLoad_form.educationDisciplineId = null
      state.ppsLoad_form.updatePpsTeachingLoad = []
    },
    CLEAR_UPDATE_PPS_TEACHING_LOAD(state) {
      state.ppsLoad_form.updatePpsTeachingLoad = []
      state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad = []
      state.ppsLoad_form.newUpdateExamPpsTeachingLoad = []
    },
    SET_UPDATE_PPS_TEACHING_LOAD(state, {ppsLoad, property, value}) {
      console.log({ppsLoad, property, value})


      let discipline = state.ppsLoad_form.disciplines.find(i => i.education_discipline_id == ppsLoad.education_discipline_id
        && i.semester == ppsLoad.semester && i.credit == ppsLoad.credit)
      let index = discipline.pps_teaching_load.indexOf(ppsLoad)
      //console.log(index, 'index')
      //let pps_teaching_load = discipline.pps_teaching_load.find(i=>i.id == ppsLoad.id)
      discipline.pps_teaching_load[index][property] = value

      console.log(discipline.pps_teaching_load, 'pps_teaching_load')


      // if (!state.ppsLoad_form.updatePpsTeachingLoad.find(i=>i.id == id)) {
      //     state.ppsLoad_form.updatePpsTeachingLoad.push({
      //         id: id,
      //         [property]: value
      //
      //     })
      // }
      // else {
      //    let updatePpsLoad = state.ppsLoad_form.updatePpsTeachingLoad.find(i=>i.id == id)
      //     updatePpsLoad[property] = value
      // }
      //
      //
      // console.log(state.ppsLoad_form.updatePpsTeachingLoad, "STATE")
    },
    SET_INDIVIDUAL_PPS_TEACHING_LOAD(state, individualPpsTeachingLoad) {
      state.ppsLoad_form.individualPpsTeachingLoad = individualPpsTeachingLoad
    },
    SET_UPDATE_PPS_TEACHING_LOAD_DATA(state, updatePpsTeachingLoad) {
      state.ppsLoad_form.updatePpsTeachingLoad = updatePpsTeachingLoad
    }

  },
  state: {
    ppsLoad_form: {
      //studyLevels: [],
      individualPpsTeachingLoad: [],
      formedPpsTeachingLoad: [],
      disciplines: [],
      teachers: [],
      educationType: [],
      newLecturePpsTeachingLoad: [],
      newSeminarPpsTeachingLoad: [],
      newExamPpsTeachingLoad: [],
      newUpdateSeminarPpsTeachingLoad: [],
      newUpdateExamPpsTeachingLoad: [],
      educationDisciplineId: 0,
      updatePpsTeachingLoad: [],

      formedDisciplines: [],
      teacherLoadCalculation: []
    }
  },
  getters: {
    getRowLectureStudentsCount(state) {
      return parseInt((state.ppsLoad_form.newLecturePpsTeachingLoad.reduce((sum, item) => +item.students_count + sum, 0)))
    },
    getRowSeminarStudentsCount(state) {
      return parseInt((state.ppsLoad_form.newSeminarPpsTeachingLoad.reduce((sum, item) => +item.students_count + sum, 0)))
    },
    getUpdateRowSeminarStudentsCount(state) {
      return parseInt((state.ppsLoad_form.newUpdateSeminarPpsTeachingLoad.reduce((sum, item) => +item.students_count + sum, 0)))
    }
  }
}

export default ppsLoad