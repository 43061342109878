import creativeImaginationTestService from "@/services/sei/creativeImaginationTest.service";

const creativeImaginationTest = {
  namespaced: true,
  actions: {
    async GET_QUESTIONS({commit}) {
      try {
        const {status, data} = await creativeImaginationTestService.getQuestions()
        if (status === 200) {
          commit('SET_QUESTIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async POST_ANSWERS({commit}, form) {
      try {
        await creativeImaginationTestService.postAnswers(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_RESULT({commit}) {
      try {
        const {status, data} = await creativeImaginationTestService.getResult()
        if (status === 200) {
          console.log('GET_IMAGINATION_RESULT', data)
          commit('SET_RESULT', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

  },
  mutations: {
    SET_QUESTIONS(state, questions) {
      state.questions = questions
    },
    SET_RESULT(state, result) {
      state.result = result
    }
  },
  state: {
    questions: [],
    result: null
  }
}

export default creativeImaginationTest
