import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const journalRating = {
    namespaced: true,
    actions: {
        async GET_STATEMENT_BY_MDL_COURSE_ID({commit}, $mdl_course_id) {
            try {
                const url = `teaching_load/teacher-courses/get-statement-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${$mdl_course_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_STATEMENT", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_TEACHER_BY_MDL_COURSE_ID({commit}, $mdl_course_id) {
            try {
                const url = `teaching_load/teacher-courses/get-teacher-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${$mdl_course_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_TEACHER", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_DISCIPLINE_BY_MDL_COURSE_ID({commit}, $mdl_course_id) {
            try {
                const url = `teaching_load/teacher-courses/get-discipline-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${$mdl_course_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_DISCIPLINE", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_SUMMER_STATEMENT_BY_MDL_COURSE_ID({commit}, $mdl_course_id) {
            try {
                const url = `teaching_load/teacher-courses/get-summer-statement-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${$mdl_course_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_STATEMENT", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_SUMMER_TEACHER_BY_MDL_COURSE_ID({commit}, $mdl_course_id) {
            try {
                const url = `teaching_load/teacher-courses/get-summer-teacher-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${$mdl_course_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_TEACHER", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_SUMMER_DISCIPLINE_BY_MDL_COURSE_ID({commit}, $mdl_course_id) {
            try {
                const url = `teaching_load/teacher-courses/get-summer-discipline-by-mdl-course-id?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${$mdl_course_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_DISCIPLINE", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async GET_PRACTICE_RATINGS({commit}, formation_education_program_id) {
            try {
                const url = `student/student-practice-ratings/get-practice-ratings?access-token=${getCookie('ACCESS_TOKEN')}&formation_education_program_id=${formation_education_program_id}`
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_STATEMENT", data)
                    console.log("GET_PRACTICE_RATINGS", data)
                }
            } catch (e) {
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async IMPORT_GRAGES_FROM_MOODLE({state}, {mdl_course_id, type}) {
            try {
                const url = `student/student-ratings/import-grades-from-moodle?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${mdl_course_id}&type=${type}`
                const {
                    status,
                    data
                } = await httpClient.put(url);
                if (status === 200) {
                    console.log(data, 'IMPORT_GRAGE_FROM_MOODLE')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async CLOSE_STATEMENT({state}, {mdl_course_id, type}) {
            try {
                const url = `student/student-ratings/close-statement?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${mdl_course_id}&type=${type}`
                const {
                    status,
                    data
                } = await httpClient.put(url);
                if (status === 200) {
                    console.log(data, 'CLOSE_STATEMENT')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async SAVE_VSK_GRADES({state}, {form, type}) {
            try {
                const url = `student/student-ratings/update-vsk?access-token=${getCookie('ACCESS_TOKEN')}&type=${type}`
                const {
                    status,
                    data
                } = await httpClient.put(url, form);
                if (status === 200) {
                    console.log(data, 'SAVE_VSK_GRADES')
                }
                return {
                    data: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    errors: e.response.data.errors
                }
            }
        },

        async CLOSE_VSK({state}, {mdl_course_id, type}) {
            try {
                const url = `student/student-ratings/update-vsk-status?access-token=${getCookie('ACCESS_TOKEN')}&mdl_course_id=${mdl_course_id}&type=${type}`
                const {
                    status,
                    data
                } = await httpClient.put(url);
                if (status === 200) {
                    console.log(data, 'CLOSE_VSK')
                }
                return {
                    data: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    data: false,
                    errors: e.response.data.errors
                }
            }
        },


    },
    mutations: {
        SET_EDUCATION_COURSES_DATA_BY_PPS_ID(state, teacherCoursesInfos) {
            state.teacher_form.teacherCoursesInfos = teacherCoursesInfos
            console.log(state.teacher_form.teacherCoursesInfos, "STATE")
        },
        SET_STATEMENT(state, data){
            state.journalRating.students = data
        },
        SET_TEACHER(state, data){
            state.journalRating.teacher = data
        },
        SET_DISCIPLINE(state, data){
            state.journalRating.discipline = data
        },
    },
    state: {
        journalRating: {
            teacher: {},
            discipline: {},
            students: []
        }

    },
    getters: {}

}

export default journalRating