import docOrdersStudentsService from "@/services/doc-orders/docOrdersStudents.service"
import httpClient from "@/services/http.service";

const docOrdersStudents = {
    namespaced: true,
    actions: {
        async GET_STUDY_LEVELS({commit}) {
            try {
                const {data} = await docOrdersStudentsService.getStudyLevels();
                if (data) {
                    commit('SET_STUDY_LEVELS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_FOREIGN_UNIVERSITY({commit}) {
            try {
                const {data} = await docOrdersStudentsService.getForeignUniversity();
                if (data) {
                    commit('SET_FOREIGN_UNIVERSITY', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_EDUCATION_PROGRAMS({state, commit}, {studyLevelId, studyCourse}) {
            try {
                const {data} = await docOrdersStudentsService.getEducationPrograms(studyLevelId, studyCourse)
                if (data) {
                    commit('SET_EDUCATION_PROGRAMS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

        async GET_STUDENTS_BY_EDUCATION_PROGRAM_ID({state, commit}, education_program_id) {
            try {
                const {data} = await docOrdersStudentsService.getStudentsByEducationProgramId(education_program_id);
                if (data) {
                    console.log(data, 'GET_STUDENTS_BY_EDUCATION_PROGRAM_ID')
                    commit("SET_STUDENTS", data.students)
                    commit("SET_STUDENTS_GROUPS", data.groups)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_STUDENT_GROUPS_BY_NAME({state, commit}, name) {
            try {
                const {data} = await docOrdersStudentsService.getStudentGroupsByName(name);
                if (data) {
                    console.log(data, 'GET_STUDENT_GROUPS_BY_NAME')
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_EDUCATION_PROGRAMS_BY_NAME({state, commit}, name) {
            try {
                const {data} = await docOrdersStudentsService.getEducationProgramsByName(name);
                if (data) {
                    console.log(data, 'GET_EDUCATION_PROGRAMS_BY_NAME')
                    return data.success
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_DOC_ORDER_STUDENTS({commit}, form) {
            try {
                const {data} = await docOrdersStudentsService.postDocOrderStudents(form)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_SECTION_STUDENTS({commit}, sections_id) {
            try {
                const {data} = await docOrdersStudentsService.getSectionStudents(sections_id);
                commit('SET_STIPEND_STUDENTS', data)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async CREATE_STIPEND({commit}, data) {
            try {
                await docOrdersStudentsService.createStipend(data);
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_CREATE_ACADEMIC_MOBILITY({commit}, data) {
            try {
                await docOrdersStudentsService.createAcademicMobility(data);
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async CREATE_TRANSFER({commit}, data) {
            try {
                await docOrdersStudentsService.createTransfer(data);
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        }
    },
    mutations: {

        SET_STUDY_LEVELS(state, studyLevels) {
            state.docOrdersStudents_form.studyLevels = studyLevels
        },
        SET_FOREIGN_UNIVERSITY(state, foreignUniversity) {
            state.docOrdersStudents_form.foreignUniversity = foreignUniversity
        },
        SET_EDUCATION_PROGRAMS(state, educationPrograms) {
            state.docOrdersStudents_form.educationPrograms = educationPrograms
        },
        SET_STUDY_COURSE(state, studyCourse) {
            state.docOrdersStudents_form.studyCourse = studyCourse
        },
        SET_STUDENTS_GROUPS(state, studentGroups) {
            state.docOrdersStudents_form.studentGroups = studentGroups
            console.log(state.docOrdersStudents_form.studentGroups, "studentGroups")
        },
        SET_STUDENTS(state, students) {
            state.docOrdersStudents_form.students = students
            console.log(state.docOrdersStudents_form.students, "students")
        },
        SET_STIPEND_STUDENTS(state, students) {
            console.log('123')
            state.stipendStudents = students
        }
    },
    state: {
        docOrdersStudents_form: {
            studyLevels: [],
            foreignUniversity: [],
            educationPrograms: [],
            studyCourse: 0,
            studentGroups: [],
            students: []
        },
        stipendStudents: []
    },
    getters: {}
}

export default docOrdersStudents