import conferenceReviewerService from "@/services/conference/reviewer/conferenceReviewer.service"
import axios from 'axios'

const conferenceReviewer = {
    namespaced: true,
    actions: {
        async GET_CONFERENCE_ARTICLE({commit}) {
            try {
                const {data} = await conferenceReviewerService.getConferenceArticle();
                if (data) {
                    console.log(data, 'GET_CONFERENCE_ARTICLE')
                    commit('SET_CONFERENCE_ARTICLE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async PUT_CONFERENCE_REVIEW({commit, state}) {
            let conferenceReviewForm = state.conferenceReviewer_form.newReview
            //conferenceReviewForm['conference_article_id'] = state.conferenceReviewer_form.conferenceArticleId
            console.log(conferenceReviewForm, 'conferenceReviewForm')

            try {
                const {data} = await conferenceReviewerService.putConferenceReview(conferenceReviewForm, state.conferenceReviewer_form.conferenceReviewId);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_CONFERENCE_ARTICLE_COMMENT({commit, state}, postData) {
            console.log(postData, 'postData')
            try {
                const {data} = await conferenceReviewerService.postConferenceArticleComment(postData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_CONFERENCE_ARTICLE(state, conferenceArticle) {
            state.conferenceReviewer_form.conferenceArticle = conferenceArticle
        },
        SET_NEW_REVIEW_DATA(state, {property, value}) {
            state.conferenceReviewer_form.newReview[property] = value
            console.log(state.conferenceReviewer_form.newReview, "STATE")
        },
        SET_CONFERENCE_REVIEW_ID(state, conferenceReviewId) {
            state.conferenceReviewer_form.conferenceReviewId = conferenceReviewId
        },
        // SET_CONFERENCE_ARTICLE_ID(state, conferenceArticleId) {
        //     state.conferenceReviewer_form.conferenceArticleId = conferenceArticleId
        // },
        CLEAR_NEW_REVIEW(state) {
            state.conferenceReviewer_form.newReview = {}
        }

    },
    state: {
        conferenceReviewer_form: {
            conferenceArticle: [],
            newReview: {},
            conferenceReviewId: null,
            //conferenceArticleId: null
        }
    },
    getters: {}
}

export default conferenceReviewer