import teacherPracticeService from "@/services/teacher/teacherPractice.service"

const teacherPractice = {
    namespaced: true,
    actions: {
        async GET_PRACTICE_STUDENTS({commit}) {
            try {
                const {data} = await teacherPracticeService.getPracticeStudents()
                if (data) {
                    commit('SET_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },

        async POST_PRACTICE_RATINGS({commit}, postData) {
            try {
                const {data} = await teacherPracticeService.postPracticeRatings(postData)
                return data
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_STUDENTS(state, students) {
            state.teacherPractice_form.students = students
        },

    },
    state: {
        teacherPractice_form: {
            students: [],
        }
    },
    getters: {}
}

export default teacherPractice