import httpClient from "./http.service";
import router from "../router";
import {checkCookieVariable, deleteAllCookies, getCookie, setCookie} from "@/utils/helpers/cookies.helpers";
import {encryptText, decryptText} from "@/utils/helpers/crypto.helpers"

const authService = {
  currentUser: null,
  isLoggedIn() {
    return !!getCookie('ACCESS_TOKEN')
  },
  getToken() {
    return getCookie('ACCESS_TOKEN')
  },
  async login(formData) {
    try {
      const {status, data} = await httpClient.post('api/user/login', formData);
      if (status === 200) {
        setCookie('ACCESS_TOKEN', data.user.access_token, 3000000);
        setCookie('USER_ID', data.user.id, 3000000);
        setCookie('USERNAME', data.user.username, 3000000);
        setCookie('FIRST_NAME', data.user.firstname, 3000000);
        setCookie('LAST_NAME', data.user.lastname, 3000000);
        setCookie('MIDDLE_NAME', data.user.middlename, 3000000);
        setCookie('EMAIL', data.user.email, 3000000);
        setCookie('ROLE', Object.keys(data[0])[0], 3000000);

        const roles = Object.keys(data[0]);
        const transformedObject = {};

        for (const key of roles) {
          transformedObject[key] = encryptText(key);
        }


        setCookie('ROLES', JSON.stringify(Object.values(transformedObject)), 3000000);
        setCookie('DEPARTMENT_ID', data.user.kafedra_id, 3000000);
      }
      return {
        success: true
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async loginSSO() {
    try {
      const {status, data} = await httpClient.get('api/saml/initiate-sso');
      return {
        success: true
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async register(formData) {
    try {
      const {status, data} = await httpClient.post('api/user/sighup', formData);
      if (status === 200) {
        setCookie('ACCESS_TOKEN', data.user.access_token)
      }
      return {
        success: true
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async checkingEmail(formData) {
    try {
      const {status, data} = await httpClient.post('api/user/send-password-pin', formData);
      return {
        success: true,
        data: data
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async checkingRecoveryCode(formData) {
    try {
      const {status, data} = await httpClient.post('api/user/check-pin', formData);
      return {
        success: true,
        data: data
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async resetPassword(formData) {
    try {
      const {status, data} = await httpClient.post('api/user/password-recovery', formData);
      return {
        success: true,
        data: data
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  logout() {
    localStorage.clear()
    deleteAllCookies()
    router.push('/login')
  },
  async getUser() {
    try {
      if (!this.currentUser) {
        const {status, data} = await httpClient.get('api/user/data');
        if (status === 200) {
          this.currentUser = data;
        }
      }

    } catch (e) {
      return null;
    }

    return this.currentUser;
  },
  async getAnketa() {
    try {
      const {
        status,
        data
      } = await httpClient.get('commission/commission/anketa' + '?access-token=' + getCookie('ACCESS_TOKEN'));
      if (status === 200) {
        this.currentUser = data;
      }

    } catch (e) {
      return null;
    }

    return this.currentUser;
  },
  async getDebts() {
    try {
      const {status, data} = await httpClient.get(`admin/test/get-debts?barcode=${getCookie('USERNAME')}`);
      if (status === 200) {
        return data
      }
    } catch (e) {
      console.log(e)
    }
    return []
  }
};

export default authService;
