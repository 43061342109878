import officeRegistrationEmailChangeService from "@/services/office-registration/officeRegistrationEmailChange.service";

const officeRegistrationEmailChange = {
    namespaced: true,
    actions: {
        async GET_SEARCH_TEACHES({commit}, name) {
            try {
                const {status, data} = await officeRegistrationEmailChangeService.getSearchUser(name)
                if (status == 200){
                    commit('SET_USERS', data.data)
                    console.log('GET_USERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async PUT_CHANGE_EMAIL({commit}, data) {
            try {
                const {status} = await officeRegistrationEmailChangeService.putUserEmail(data.user_id, data.email)
                if (status == 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async POST_ACTIVE_ACCOUNT({commit}, data) {
            try {
                const {status} = await officeRegistrationEmailChangeService.postActiveAccount(data.user_id, data.active_directory)
                if (status == 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_USERS(state, data) {
            state.users = data
        },
    },
    state: {
        users: []
    },
    getters: {}
}

export default officeRegistrationEmailChange