import httpClient from "@/services/http.service"

const chooseLanguageLevelService = {
    getAdmissionTestStudents() {
        return httpClient.get(`student_data/student-data/get-admission-test-students`)
    },

    postAdmissionTestResult(form) {
        return httpClient.post(`student_data/student-data/create-admission-test-student`, form)
    },
    postUpdateAdmissionTestResult(form) {
        return httpClient.post(`student_data/student-data/update-admission-test-student`, form)
    },

}

export default chooseLanguageLevelService