import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const groupBy = (arr, key) => Object.values(
    arr.reduce((objGroupBy, item) => {
        (objGroupBy[item[key]] = objGroupBy[item[key]] || []).push(item)
        return objGroupBy
    }, {})
)

const bindSelectableGroups = {
    namespaced: true,
    actions: {

        async GET_STUDENTS_BY_SELEACTABLE_GROUP_ID({commit}, selectable_groups_id) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('selectable_course/selectable-group-students/get-students-by-selectable-group-id' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&selectable_groups_id=' + selectable_groups_id);
                if (status === 200) {
                    // console.log(data, 'students by selectable_group_id')
                    commit("SET_STUDENTS_FOR_GROUP", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async PUT_STUDENT_SELECTABLE_GROUPS({state}) {
            const studentsForGroup = state.selectableCourses.studentsForGroup
            try {
                const {
                    status,
                    data
                } = await httpClient.put('selectable_course/selectable-group-students/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), studentsForGroup);
                if (status === 200) {
                    console.log(data, 'selectable-group-students/update')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_SELEACTABLE_FLOWS_BY_FORMATION_AND_CURRICULUM({commit}, {formation_education_program, education_curriculum_id}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('teaching_load/teacher-courses/get-selectable-flow-by-formation-and-curriculum' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&formation_education_program=' + formation_education_program + '&education_curriculum_id=' + education_curriculum_id);
                if (status === 200) {
                    commit("SET_SELECTABLE_FLOWS", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },


        async GET_COMPARED_STUDENTS({commit}, {formation_education_program, education_curriculum_id}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-courses/get-compared-students' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&formation_education_program=' + formation_education_program + '&education_curriculum_id=' + education_curriculum_id);
                if (status === 200) {
                    commit("SET_STUDENTS_NOT_ASSIGNED_TO_GROUPS", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },


        async GET_DISCIPLINE_BY_FORMATION_EDUCATION_PROGRAM({commit, state}, formation_education_program_id) {
            try {
                const url = 'education_program/education-discipline/get-discipline-by-formation-education-program' + '?access-token=' + getCookie('ACCESS_TOKEN')
                    + '&formation_education_program_id=' + formation_education_program_id

                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_DISCIPLINE", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },

        async POST_EDUCATION_COURSE({state}, courses) {
            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/education-courses/create-with-selectable' + '?access-token=' + getCookie('ACCESS_TOKEN'), courses);
                if (status === 200) {
                    console.log(data, 'create courses')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
        async PUT_EDUCATION_COURSE({state}, courses) {
            try {
                const {
                    status,
                    data
                } = await httpClient.put('education_program/education-courses/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), courses);
                if (status === 200) {
                    console.log(data, 'update courses')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },


        async POST_SELECTABLE_GROUP_STUDENTS({state}, students) {
            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/education-courses/add-students-from-department-head-to-course' + '?access-token=' + getCookie('ACCESS_TOKEN'), students);
                if (status === 200) {
                    console.log(data, 'create add-students-from-department-head-to-course')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async UPLOAD_TO_MOODLE({state}, courses) {
            try {
                const {
                    status,
                    data
                } = await httpClient.post('education_program/education-courses/upload-to-moodle-selectable' + '?access-token=' + getCookie('ACCESS_TOKEN'), courses);
                if (status === 200) {
                    console.log(data, 'upload-to-moodle-selectable courses')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },


    },
    mutations: {
        SET_STUDENTS_FOR_GROUP(state, studentsForGroup) {
            state.selectableCourses.studentsForGroup = studentsForGroup
        },
        SET_DISCIPLINE(state, discipline) {
            state.selectableCourses.discipline = discipline
            console.log(state.selectableCourses.discipline, 'discipline')
        },
        SET_SELECTABLE_FLOWS(state, flows) {
            state.selectableCourses.flowsWithoutGroupBy = flows.filter(i => i.id)
            const flowsGroupBy = groupBy(flows, 'selectable_flow_id').map(item => ({
                    selectable_flow_id: item[0]?.selectable_flow_id,
                    selectable_flow_name: item[0]?.selectable_flow_name,
                    groups: item
                })
            )

            state.selectableCourses.flows = flowsGroupBy
            console.log(state.selectableCourses.flows, 'flows')
            console.log(state.selectableCourses.flowsWithoutGroupBy, 'flowsWithoutGroupBy')
        },
        SET_STUDENTS_NOT_ASSIGNED_TO_GROUPS(state, studentsNotAssignedToGroups) {
            state.selectableCourses.isStudentsAssigned = studentsNotAssignedToGroups.length ? false : true

            state.selectableCourses.studentsNotAssignedToGroupsWithoutGroupBy = studentsNotAssignedToGroups
            const studentsNotAssignedToGroupsGroupBy = groupBy(studentsNotAssignedToGroups, 'student_group_id').map(item => ({
                    selectable_flow_id: item[0]?.student_group_id,
                    selectable_flow_name: item[0]?.student_group_name,
                    students: item
                })
            )

            state.selectableCourses.studentsNotAssignedToGroups = studentsNotAssignedToGroupsGroupBy
            console.log(state.selectableCourses.studentsNotAssignedToGroups, 'studentsNotAssignedToGroups')
            console.log(state.selectableCourses.studentsNotAssignedToGroupsWithoutGroupBy, 'studentsNotAssignedToGroupsWithoutGroupBy')
        },
        SET_STUDENTS_NOT_ASSIGNED_TO_GROUPS_EMPTY(state) {
            state.selectableCourses.studentsNotAssignedToGroups = []
            state.selectableCourses.studentsNotAssignedToGroupsWithoutGroupBy = []
        },
        CHANGE_GROUP(state, {student_data_id, selectable_groups_id}) {
            const student = state.selectableCourses.studentsNotAssignedToGroupsWithoutGroupBy.find(i => i.student_data_id == student_data_id)
            student.selectable_groups_id = selectable_groups_id
        },
        CHANGE_OF_GROUP_FOR_ASSIGNED_STUDENT(state, {student_data_id, selectable_groups_id}) {
            const student = state.selectableCourses.studentsForGroup.find(i => i.student_data_id == student_data_id)
            student.selectable_groups_id = selectable_groups_id
        },
    },
    state: {
        selectableCourses: {
            discipline: {},
            flows: [],
            studentsNotAssignedToGroups: [],
            flowsWithoutGroupBy: [],
            studentsNotAssignedToGroupsWithoutGroupBy: [],
            isStudentsAssigned: false,
            studentsForGroup: []
        },
    },
}

export default bindSelectableGroups