import httpClient from "../../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const selectableFlows = {
    namespaced: true,
    actions: {
        async POST_SELECTABLE_FLOWS_DATA({state}) {

            let selectableFlowsForm = state.selectableFlows_form.newSelectableFlowsInfos


            selectableFlowsForm['formation_education_program_id'] = state.selectableFlows_form.formation_education_program_id
            selectableFlowsForm['education_curriculum_id'] = state.selectableFlows_form.education_curriculum_id
            console.log(selectableFlowsForm, 'selectableFlowsForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('selectable_course/selectable-flow/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), selectableFlowsForm);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },
        async PUT_SELECTABLE_FLOWS_DATA({state}) {
            const editData = state.selectableFlows_form.editSelectableFlow
            try {
                const {
                    status,
                    data
                } = await httpClient.put('selectable_course/selectable-flow/update' + '?id=' + editData.id + '&access-token=' + getCookie('ACCESS_TOKEN'), editData);
                if (status === 200) {
                    console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async GET_DISCIPLINE_BY_FORMATION_EDUCATION_PROGRAM({commit, state}) {
            try {
                const url = 'education_program/education-discipline/get-discipline-by-formation-education-program' + '?access-token=' + getCookie('ACCESS_TOKEN')
                    + '&formation_education_program_id=' + state.selectableFlows_form.formation_education_program_id

                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_DISCIPLINE", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_SELECTABLE_FLOWS_DATA({commit, state}) {
            try {
                const url = 'selectable_course/selectable-flow/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
                    + '&formation_education_program_id=' + state.selectableFlows_form.formation_education_program_id + '&education_curriculum_id=' + state.selectableFlows_form.education_curriculum_id

                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_SELECTABLE_FLOWS_DATA", data)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },


        async DELETE_SELECTABLE_FLOWS_DATA({state}, deleteSelectableFlowsId) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('selectable_course/selectable-flow/delete' + '?id=' + deleteSelectableFlowsId + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('selectable flow DELETED')
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_DISCIPLINE(state, discipline) {
            state.selectableFlows_form.discipline = discipline
        },
        CHANGE_SELECTABLE_FLOW_DATA(state, {property, value}) {
            state.selectableFlows_form.editSelectableFlow[property] = value
        },
        SET_NEW_SELECTABLE_FLOWS_DATA(state, {property, value}) {
            state.selectableFlows_form.newSelectableFlowsInfos[property] = value
            console.log(state.selectableFlows_form.newSelectableFlowsInfos, 'STATE')
        },
        SET_SELECTABLE_FLOWS_DATA(state, selectableFlowsInfos) {
            state.selectableFlows_form.selectableFlowsInfos = selectableFlowsInfos
            console.log(state.selectableFlows_form.selectableFlowsInfos, "selectableFlowsInfos")
        },
        SET_EDUCATION_CURRICULUM_ID(state, education_curriculum_id) {
            state.selectableFlows_form.education_curriculum_id = education_curriculum_id
        },
        SET_FORMATION_EDUCATION_PROGRAM_ID(state, formation_education_program_id) {
            state.selectableFlows_form.formation_education_program_id = formation_education_program_id
        },
        SET_EDIT_SELECTABLE_FLOW(state, editSelectableFlow) {
            state.selectableFlows_form.editSelectableFlow = editSelectableFlow
        }
    },
    state: {
        selectableFlows_form: {
            newSelectableFlowsInfos: {},
            editSelectableFlow: {},
            selectableFlowsInfos: [],
            education_curriculum_id: null,
            formation_education_program_id: null,
            discipline: null
        }

    },
    getters: {}

}

export default selectableFlows