import httpClient from "@/services/http.service"
import router from "@/router"


const educationProgramResultService = {
    getEducationProgramResult(education_program_id) {
        return httpClient.get(`education_program/education-program-result/index`+ '?education_program_id=' + education_program_id)
    },

    postEducationProgramResult(educationProgramResultForm) {
        console.log(educationProgramResultForm, 'postEducationProgramResult data')
        return httpClient.post(`education_program/education-program-result/create`, educationProgramResultForm)
    },
    putEducationProgramResult(updateForm) {
        console.log(updateForm, 'putEducationProgramResult data')
        return httpClient.put(`education_program/education-program-result/update`, updateForm)
    },

}

export default educationProgramResultService