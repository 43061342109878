import departmentPpsTeachingTrainingDirectionService from "@/services/department/departmentPpsTeachingTrainingDirection.service";
import analyticsService from "@/services/analytics/analytics.service";

const departmentPpsTeachingTrainingDirection = {
    namespaced: true,
    actions: {
        async GET_DEPARTMENT_ACTIVE_TEACHERS({commit}) {
            try {
                const {status, data} =
                    await departmentPpsTeachingTrainingDirectionService.getDepartmentActiveTeachers()
                if (status == 200) {
                    console.log('GET_DEPARTMENT_ACTIVE_TEACHERS', data)
                    commit('SET_DEPARTMENT_ACTIVE_TEACHERS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async DELETE_DIRECTION({commit}, id) {
            try {
                const {status} = await departmentPpsTeachingTrainingDirectionService.deleteDirection(id);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async CREATE_DIRECTION({commit}, data) {
            try {
                const {status} = await departmentPpsTeachingTrainingDirectionService.createDirection(data);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async UPDATE_DIRECTION({commit}, data) {
            console.log('UPDATE_DIRECTION', data)
            try {
                const {status} = await departmentPpsTeachingTrainingDirectionService.updateDirection(data.id, data.user_id, data.direction_id);
                if (status === 200) {
                    return true;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async SEARCH_DIRECTIONS({commit}, name) {
            try {
                const {status, data} = await departmentPpsTeachingTrainingDirectionService.searchDirection(name);
                if (status === 200) {
                    console.log('SEARCH_DIRECTIONS', data)
                    return data;
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
    },
    mutations: {
        SET_DEPARTMENT_ACTIVE_TEACHERS(state, data) {
            state.data.teachers = data
        }
    },
    state: {
        data:{
            teachers: []
        }
    },
}

export default departmentPpsTeachingTrainingDirection