import departmentMagistracyPrerequisiteService from "@/services/department/departmentMagistracyPrerequisite.service"
import departmentPracticeService from "@/services/department/departmentPractice.service";
import docOrdersService from "@/services/doc-orders/docOrders.service";

const departmentMagistracyPrerequisite = {
  namespaced: true,
  actions: {

    async GET_EDUCATION_PROGRAMS({commit}) {
      try {
        const {status, data} = await departmentMagistracyPrerequisiteService.getEducationPrograms()
        if (status === 200) {
          console.log(data, 'GET_EDUCATION_PROGRAMS')
          commit('SET_EDUCATION_PROGRAMS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async POST_MAGISTRACY_PREREQUISITE({commit}, form) {
      try {
        const {status, data} = await departmentMagistracyPrerequisiteService.postMagistracyPrerequisite(form)
        if (status === 200) {
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async GET_STUDENTS({commit}) {
      try {
        const {status, data} = await departmentMagistracyPrerequisiteService.getStudents()
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async PUT_PPS_PREREQUISITES_RATINGS({commit}, form) {
      try {
        await departmentMagistracyPrerequisiteService.putPpsPrerequisitesRatings(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


    async DELETE_MAGISTRACY_PREREQUISITE_DISCIPLINE({commit}, id) {
      try {
        const {data} = await departmentMagistracyPrerequisiteService.deleteMagistracyPrerequisites(id);
        if (data) {
          console.log(data, 'magistracy prerequisites deleted')
          return true
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {

    SET_EDUCATION_PROGRAMS(state, educationPrograms) {
      state.departmentMagistracyPrerequisite_form.educationPrograms = educationPrograms
    },

  },
  state: {
    departmentMagistracyPrerequisite_form: {
      educationPrograms: []
    }
  },
}

export default departmentMagistracyPrerequisite