import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const educationdiscipline = {
  namespaced: true,
  actions: {
    async DELETE_EDUCATION_DISCIPLINE_DATA({commit, state}, id) {
      //let id = state.educationdiscipline_form.educationDisciplineInfos[index].id
      try {
        const {
          status,
          data
        } = await httpClient.delete('education_program/education-discipline/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          console.log('DELETE_EDUCATION_DISCIPLINE_DATA data DELETED')
          // commit("SET_EDUCATION_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async POST_EDUCATION_DISCIPLINE_DATA() {
      console.log(educationdiscipline.state.educationdiscipline_form.newEducationDisciplineInfos, "newEducationDisciplineInfos")
      let educationDisciplineForm = educationdiscipline.state.educationdiscipline_form.newEducationDisciplineInfos
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/education-discipline/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationDisciplineForm);
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data
        }
      }
    },
    async PUT_EDUCATION_DISCIPLINE_DATA({commit, state}, id) {
      console.log(id, "PROGRAM ID")
      let educationDisciplineForm = (educationdiscipline.state.educationdiscipline_form.educationDisciplineInfos).filter(v => v.id == id)
      console.log(educationDisciplineForm, 'educationDisciplineForm')
      if (educationDisciplineForm.length > 0) {

        try {
          console.log(educationDisciplineForm)
          const {
            status,
            data
          } = await httpClient.put('education_program/education-discipline/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationDisciplineForm);
          if (status === 200) {
            console.log(data)
          }
          return {
            success: true
          }
        } catch (e) {
          console.log(e.response);
          return {
            success: false,
            errors: e.response.data.errors
          }
        }
      } else {
        return 0
      }
    },
    async GET_EDUCATION_DISCIPLINE_DATA({commit}, {page, name}) {
      try {
        let urlGetParams = `&page=${page}&department_id=${getCookie('DEPARTMENT_ID')}`
        urlGetParams = name ? `${urlGetParams}&name=${name}` : urlGetParams

        const url = `education_program/education-discipline/index?access-token=${getCookie('ACCESS_TOKEN')}${urlGetParams}`
        const {status, data} = await httpClient.get(url);
        if (status === 200) {
          console.log(url, ' URL')
          console.log(data.page_count, 'PAGE COUNT')

          commit("SET_PAGE_COUNT", data.page_count)
          commit("SET_EDUCATION_DISCIPLINE", data.success)

          // data.success.forEach(d => {
          //   console.log(d, d.id);
          // })
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_EDUCATION_DISCIPLINES_LIST({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-discipline/get-education-disciplines-list' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_EDUCATION_DISCIPLINES_LIST', data)
        }
        return {
          data: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          data: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_PARENT_LANGUAGE_DISCIPLINES({commit, getters}){
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-discipline/get-parent-language-disciplines' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_PARENT_LANGUAGE_DISCIPLINES', data)
        }
        return {
          data: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          data: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_DEPARTMENT_NAMES({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/education-discipline/get-departments' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_EDUCATION_DEPARTMENT_NAMES', data.success)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_STUDY_LEVEL_NAMES({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/study-level/index' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_STUDY_LEVEL_NAMES', data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async GET_CREDIT_TIME_TYPE({commit, getters}) {
      try {
        const {
          status,
          data
        } = await httpClient.get('education_program/credit-time-norm-template/get-time-norm' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          commit('SET_CREDIT_TIME_TYPE', data)
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },

    async GET_PREREQUISITES({commit}, $education_discipline_id) {
      try {
        const {status, data} = await httpClient.get('education_program/prerequisite-disciplines/get-prerequisites?'+'education_discipline_id='+$education_discipline_id)
        if (status === 200) {
          console.log('GET_PREREQUISITES', data)
          commit('SET_PREREQUISITES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_PREREQUISITES_AND_OR_TYPES({commit}) {
      try {
        const {status, data} = await httpClient.get('education_program/prerequisite-disciplines/get-prerequisites-and-or-types')
        if (status === 200) {
          console.log('GET_PREREQUISITES_AND_OR_TYPES', data)
          commit('SET_PREREQUISITES_AND_OR_TYPES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async POST_DISCIPLINE_PREREQUISITE({commit}, form) {
      try {
        await httpClient.post(`education_program/prerequisite-disciplines/create`, form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },
    async DELETE_PREREQUISITES_AND_OR({commit}, id) {
      try {
        const {
          status,
          data
        } = await httpClient.delete('education_program/prerequisite-disciplines/delete-prerequisites-and-or' + '?id='+id);
        if (status === 200) {
          console.log('prerequisite and or deleted')
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

    async DELETE_PREREQUISITE_DISCIPLINE({commit}, id) {
      try {
        const {
          status,
          data
        } = await httpClient.delete('education_program/prerequisite-disciplines/delete' + '?id='+id);
        if (status === 200) {
          console.log('discipline deleted')
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

  },
  mutations: {
    SET_PREREQUISITES(state, prerequisites) {
      state.educationdiscipline_form.prerequisites = prerequisites
    },
    SET_PREREQUISITES_AND_OR_TYPES(state, prerequisitesAndOrTypes){
      state.educationdiscipline_form.prerequisitesAndOrTypes = prerequisitesAndOrTypes
    },
    SET_EDUCATION_DISCIPLINE(state, educationDisciplineInfos) {
      console.log(educationDisciplineInfos, "SET_EDUCATION_DISCIPLINE")
      state.educationdiscipline_form.educationDisciplineInfos = educationDisciplineInfos
    },
    SET_EDUCATION_DISCIPLINES_LIST(state, educationDisciplinesList) {
      state.educationdiscipline_form.educationDisciplinesList = educationDisciplinesList
      console.log(state.educationdiscipline_form.educationDisciplinesList, "STATE educationDisciplinesList")
    },
    SET_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount
    },
    SET_PARENT_LANGUAGE_DISCIPLINES(state, parentLanguageDisciplines){
      state.educationdiscipline_form.parentLanguageDisciplines = parentLanguageDisciplines
    },
    ADD_ROW_EDUCATION_DISCIPLINE_INFO(state) {
      state.educationdiscipline_form.newEducationDisciplineInfos.push({
        credit: 0,
        department_id: 1,
        name: '',
        code: '',
        description: '',
        knowledge: '',
        skills: '',
        abilities: '',
        recommendation: '',
        study_level_id: 1,
        education_language: 137,
      });
    },
    DELETE_ROW_EDUCATION_DISCIPLINE_INFO(state, index) {
      state.educationdiscipline_form.newEducationDisciplineInfos.splice(index, 1);
    },
    UPDATE_EDUCATION_DISCIPLINE_DATA(state, {item, property, value}) {
      const index = state.educationdiscipline_form.educationDisciplineInfos.indexOf(item)
      state.educationdiscipline_form.educationDisciplineInfos[index][property] = value
      console.log(state.educationdiscipline_form.educationDisciplineInfos, value)
    },
    UPDATE_NEW_EDUCATION_DISCIPLINE_DATA(state, {item, property, value}) {
      const index = state.educationdiscipline_form.newEducationDisciplineInfos.indexOf(item)
      state.educationdiscipline_form.newEducationDisciplineInfos[index][property] = value
      console.log(state.educationdiscipline_form.newEducationDisciplineInfos, value)
    },
    // UPDATE_NEW_EDUCATION_DISCIPLINE_THREAD_STATUS(state, {item, property, value}) {
    //     const index = state.educationdiscipline_form.newEducationDisciplineInfos.indexOf(item)
    //     state.educationdiscipline_form.newEducationDisciplineInfos[index][property] = value
    //     console.log(state.educationdiscipline_form.newEducationDisciplineInfos, value)
    // },
    SET_EDUCATION_DEPARTMENT_NAMES(state, departmentField) {
      console.log(departmentField, 'SET_EDUCATION_DEPARTMENT_NAMES')
      state.educationdiscipline_form.departmentField = departmentField
    },
    SET_STUDY_LEVEL_NAMES(state, studyLevel) {
      console.log(studyLevel, 'SET_STUDY_LEVEL_NAMES')
      state.educationdiscipline_form.studyLevel = studyLevel
    },
    SET_UPDATE_ID(state, UpdateId) {
      state.educationdiscipline_form.updateId = UpdateId
    },
    SET_CREDIT_TIME_TYPE(state, creditTimeType) {
      console.log(creditTimeType, 'SET_CREDIT_TIME_TYPE')
      state.educationdiscipline_form.creditTimeType = creditTimeType
    },
  },
  state: {
    educationdiscipline_form: {
      educationDisciplineInfos: [{
        credit: 0,
        department_id: 1,
        name: '',
        code: '',
        description: '',
        knowledge: '',
        skills: '',
        abilities: '',
        recommendation: '',
        study_level_id: 1,
        education_language: 137,
        lang_level_id: null,
        lang_parent_id: null
      }],
      newEducationDisciplineInfos: [{
        credit: 0,
        department_id: 1,
        name: '',
        code: '',
        description: '',
        knowledge: '',
        skills: '',
        abilities: '',
        recommendation: '',
        study_level_id: 1,
        education_language: 137,
        lang_level_id: null,
        lang_parent_id: null
      }],
      languages: [
        {id: 39, name: 'English'},
        {id: 82, name: 'Қазақ тілі '},
        {id: 137, name: 'Русский язык '}
      ],
      departmentField: [],
      studyLevel: [],
      updateId: 0,
      creditTimeType: [],
      parentLanguageDisciplines: [],
      prerequisitesAndOrTypes: [],
      prerequisites: []
    },
    pageCount: 0
  },
  getters: {
    UPDATE_ITEM(state) {
      // console.log(state.educationdiscipline_form.educationDisciplineInfos.find(i => i.id = state.educationdiscipline_form.updateId))
      return state.educationdiscipline_form.educationDisciplineInfos.find(i => i.id == state.educationdiscipline_form.updateId)

    },
    DISCIPLINE_INFO(state) {
      return state.educationdiscipline_form.educationDisciplinesList.find(i => i.id == state.educationdiscipline_form.updateId)
    }
  }

}

export default educationdiscipline