import httpClient from "@/services/http.service"

const diplomaReferenceService = {
    getGraduateStudentList() {
        return httpClient.get(`graduate/graduate-work-student/get-graduate-student-list-with-theme`)
    },
    getReferenceLogs(){
        return httpClient.get('graduate/reference-log/get-reference-logs')
    }
}

export default diplomaReferenceService
