import teachersContractsService from "@/services/kkk/teachersContracts.service"
import axios from 'axios'

const teachersContracts = {
    namespaced: true,
    actions: {

        async GET_TEACHERS_CONTRACTS({commit}) {
            try {
                const {data} = await teachersContractsService.getTeachersContracts();
                if (data) {
                    console.log(data, 'GET_TEACHERS_CONTRACTS')
                    commit('SET_TEACHERS_CONTRACTS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_ADDITIONAL_AGREEMENT({commit}, parent_id) {
            try {
                const {data} = await teachersContractsService.getAdditionalAgreement(parent_id);
                if (data) {
                    console.log(data, 'GET_ADDITIONAL_AGREEMENT')
                    commit('SET_ADDITIONAL_AGREEMENT', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CONTRACT_TEMPLATES({commit}) {
            try {
                const {data} = await teachersContractsService.getContractTemplates();
                if (data) {
                    console.log(data, 'GET_CONTRACT_TEMPLATES')
                    commit('SET_CONTRACT_TEMPLATES', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        // async POST_COMMISSION_DECISION({commit, state}, form) {
        //
        //     console.log(form, 'post form')
        //     try {
        //         const {data} = await commissionDecisionService.postCommissionDecision(form);
        //         if (data) {
        //             return true
        //         }
        //     } catch (e) {
        //         commit('SET_ERROR', e, {root: true})
        //     }
        //     return false
        // },


    },
    mutations: {
        SET_TEACHERS_CONTRACTS(state, contracts) {
            state.teachersContracts_form.contracts = contracts
        },
        SET_ADDITIONAL_AGREEMENT(state, data){
            state.additionalAgreement = data
        },
        SET_CONTRACT_TEMPLATES(state, data){
            state.contractTemplates = data
        }
    },
    state: {
        contractTemplates: null,
        additionalAgreement: null,
        teachersContracts_form: {
            contracts: [
                {
                    id: 1,
                    lastname: 'Иванов',
                    firstname: 'Иван',
                }
            ],

        }
    },
    getters: {

    }
}

export default teachersContracts