import graduateWorkTempService from "@/services/graduate-work/graduateWorkTemp.service"

const graduateWorkTemp = {
    namespaced: true,
    actions: {
        async GET_STUDY_LEVELS({commit}) {
            try {
                const {data} = await graduateWorkTempService.getStudyLevels();
                if (data) {
                    commit('SET_STUDY_LEVELS', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async GET_SELECTION_SUPERVISORS_PPS_BY_DEPARTMENT_ID({commit}) {
            try {
                const {status,data} = await graduateWorkTempService.getSelectionSupervisorsPpsByDepartmentId();
                if (status === 200) {
                    commit('SET_DEPARTMENT_PPS', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE({commit, state}) {
            let graduateWorkTheme = state.graduateWorkTemp.graduateWorkThemes.find(i => i.id == state.graduateWorkTemp.selectedGraduateWorkThemeId)
            console.log(graduateWorkTheme['education_program_code'], 'GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE')
            try {
                const {status,data} = await graduateWorkTempService.getStudentsByEducationProgramCode(graduateWorkTheme['education_program_code']);
                if (status === 200) {
                    commit('SET_STUDENTS_BY_EDUCATION_PROGRAM_CODE', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_STUDENTS_WITH_COURSE_ID({commit, state}, id) {
            try {
                const {status,data} = await graduateWorkTempService.getStudentsWithStudyCourse(id);
                if (status === 200) {
                    console.log(data)
                    commit('SET_STUDENTS_BY_EDUCATION_PROGRAM_CODE', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_GRADUATE_WORK_THEMES({commit}, id) {
            try {
                const {data} = await graduateWorkTempService.getGraduateWorkThemes(id);
                console.log(data, 'GET_GRADUATE_WORK_THEMES')
                commit('SET_GRADUATE_WORK_THEMES', data)
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_EDUCATION_PROGRAMS_BY_NAME_GRADUATE_WORK({commit}, {name = '', study_level_id}) {
            try {
                const {data} = await graduateWorkTempService.getEducationProgramsByNameGraduateWork(name, study_level_id);
                console.log(data, 'GET_EDUCATION_PROGRAMS_BY_NAME_GRADUATE_WORK')
                return data
            } catch (e) {
                commit('SET_ERROR', e, {root: true})

            }
        },
        async POST_GRADUATE_WORK_THEMES({commit, state}) {

            console.log(state.graduateWorkTemp.newGraduateWorkThemes, 'newGraduateWorkThemes')
            try {
                const {data} = await graduateWorkTempService.postGraduateWorkThemes(state.graduateWorkTemp.newGraduateWorkThemes);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_SELECTION_THEME({commit, state}) {

            let selectionThemeForm = []
            let studentsProjectForm = state.graduateWorkTemp.studentsProject
            let graduateWorkTheme = state.graduateWorkTemp.graduateWorkThemes.find(i => i.id == state.graduateWorkTemp.selectedGraduateWorkThemeId)
            if (studentsProjectForm) {
                for (let i = 0; i < studentsProjectForm.length; i++) {
                    selectionThemeForm.push({
                        graduate_work_theme_id: state.graduateWorkTemp.selectedGraduateWorkThemeId,
                        supervisor_id: graduateWorkTheme['teacher_id'],
                        student_id: studentsProjectForm[i].id
                    })
                }
            }
            else {
                selectionThemeForm.push({
                    graduate_work_theme_id: state.graduateWorkTemp.selectedGraduateWorkThemeId,
                    supervisor_id: graduateWorkTheme['teacher_id'],
                    student_id: state.graduateWorkTemp.selectedStudentId
                })
            }

            // selectionThemeForm['graduate_work_theme_id'] = state.graduateWorkTemp.selectedGraduateWorkThemeId
            // selectionThemeForm['supervisor_id'] = graduateWorkTheme['teacher_id']
            // selectionThemeForm['student_id'] = state.graduateWorkTemp.selectedStudentId
            console.log(selectionThemeForm, 'selectionThemeForm')
            try {
                const {data} = await graduateWorkTempService.postSelectionTheme(selectionThemeForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_SELECTION_THEME_EXAM({commit, state}) {

            let selectionThemeForm = []
            let studentsProjectForm = state.graduateWorkTemp.studentsProject
            let graduateWorkTheme = state.graduateWorkTemp.graduateWorkThemes.find(i => i.id == state.graduateWorkTemp.selectedGraduateWorkThemeId)
            if (studentsProjectForm) {
                for (let i = 0; i < studentsProjectForm.length; i++) {
                    selectionThemeForm.push({
                        graduate_work_theme_id: state.graduateWorkTemp.selectedGraduateWorkThemeId,
                        supervisor_id: graduateWorkTheme['teacher_id'],
                        student_id: studentsProjectForm[i].id
                    })
                }
            }
            else {
                selectionThemeForm.push({
                    graduate_work_theme_id: state.graduateWorkTemp.selectedGraduateWorkThemeId,
                    supervisor_id: graduateWorkTheme['teacher_id'],
                    student_id: state.graduateWorkTemp.selectedStudentId
                })
            }

            // selectionThemeForm['graduate_work_theme_id'] = state.graduateWorkTemp.selectedGraduateWorkThemeId
            // selectionThemeForm['supervisor_id'] = graduateWorkTheme['teacher_id']
            // selectionThemeForm['student_id'] = state.graduateWorkTemp.selectedStudentId
            console.log(selectionThemeForm, 'selectionThemeForm')
            try {
                const {data} = await graduateWorkTempService.postSelectionThemeExam(selectionThemeForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async PUT_SELECTION_THEME({commit, state}) {

            let selectionThemeForm = {}
            selectionThemeForm['student_id'] = state.graduateWorkTemp.editStudentId


            console.log(selectionThemeForm, 'selectionThemeForm')
            try {
                const {data} = await graduateWorkTempService.putSelectionTheme(selectionThemeForm, state.graduateWorkTemp.selectionThemeId);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async DELETE_SELECTION_THEME({commit, state}, id) {


            try {
                const {data} = await graduateWorkTempService.deleteSelectionTheme(id);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },


        async DELETE_GRADUATE_WORK_THEME({commit, state}, id) {


            try {
                const {data} = await graduateWorkTempService.deleteGraduateWorkTheme(id);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_STUDY_LEVELS(state, studyLevels) {

            state.graduateWorkTemp.studyLevels = studyLevels
        },
        SET_GRADUATE_WORK_THEMES(state, graduateWorkThemes) {

            state.graduateWorkTemp.graduateWorkThemes = graduateWorkThemes
            console.log(state.graduateWorkTemp.graduateWorkThemes, 'state.graduateWorkTemp.graduateWorkThemes')
        },
        SET_NEW_GRADUATE_WORK_THEMES(state, {property, value}) {

            state.graduateWorkTemp.newGraduateWorkThemes[property] = value

            console.log(state.graduateWorkTemp.newGraduateWorkThemes, "STATE")
        },

        SET_SELECTED_EDUCATION_PROGRAM_SPECIALITY_CODE(state, selectedEducationProgramSpecialityCode) {
            state.graduateWorkTemp.newGraduateWorkThemes['education_program_code'] = selectedEducationProgramSpecialityCode
            console.log(state.graduateWorkTemp.newGraduateWorkThemes, "STATE")
        },
        SET_NEW_GRADUATE_WORK_THEMES_STUDY_LEVEL_ID(state, selectedStudyLevelId) {
            state.graduateWorkTemp.newGraduateWorkThemes['study_level_id'] = selectedStudyLevelId
            console.log(state.graduateWorkTemp.newGraduateWorkThemes, "STATE")
        },
        SET_NEW_GRADUATE_WORK_THEMES_PROJECT_STATUS(state, {property, value}) {
            state.graduateWorkTemp.newGraduateWorkThemes[property] = value
            console.log(state.graduateWorkTemp.newGraduateWorkThemes, value)
        },
        SET_GRADUATE_WORK_THEME_ID(state, selectedGraduateWorkThemeId) {
            state.graduateWorkTemp.selectedGraduateWorkThemeId = selectedGraduateWorkThemeId
        },

        SET_SELECTION_THEME_STUDENT_ID(state, selectedStudentId) {
            state.graduateWorkTemp.selectedStudentId = selectedStudentId
        },
        SET_EDIT_SELECTION_THEME_STUDENT_ID(state, editStudentId) {
            state.graduateWorkTemp.editStudentId = editStudentId
        },
        SET_DEPARTMENT_PPS(state, pps) {

            state.graduateWorkTemp.pps = pps
        },
        SET_STUDENTS_BY_EDUCATION_PROGRAM_CODE(state, students) {

            state.graduateWorkTemp.students = students
        },
        SET_SELECTION_THEME_STUDENTS_PROJECT(state, studentsProject) {
            state.graduateWorkTemp.studentsProject = studentsProject
            console.log(state.graduateWorkTemp.studentsProject, 'studentsProject')
        },
        SET_SELECTION_THEME_ID(state, selectionThemeId) {
            state.graduateWorkTemp.selectionThemeId = selectionThemeId
            console.log(state.graduateWorkTemp.selectionThemeId, 'selectionThemeId')
        }
    },
    state: {
        graduateWorkTemp: {
            graduateWorkThemes: [],
            studyLevels: [],
            newGraduateWorkThemes: {},
            pps: [],
            students: [],
            selectedGraduateWorkThemeId: 0,
            selectedStudentId: 0,
            studentsProject: [],
            editStudentId: 0,
            selectionThemeId: 0
        }
    },
    getters: {}
}

export default graduateWorkTemp
