import httpClient from "@/services/http.service"

const kpiTeacherService = {
  getPpsInfo() {
    return httpClient.get(`pps/pps-data/get-pps-info`)
  },

  getKpiType() {
    return httpClient.get(`kpi/kpi-type`)
  },
  getKpiSubType() {
    return httpClient.get(`kpi/kpi-sub-type`)
  },

  getUserKpi(userId) {
    const query = userId ? `?user_id=${userId}` : ``
    return httpClient.get(`kpi/kpi-sub-type/get-user-kpi-sub-type-values${query}`)
  },
  postKpiConfirm(form) {
    return httpClient.post(`kpi/user-kpi/create-user-kpi`, form)
  },
  getConfirmUserKpi() {
    return httpClient.get(`kpi/kpi-sub-type/get-kpi-values`)
  },

  getTypePps() {
    return httpClient.get(`pps/pps-data/get-pps-teacher-contract`)
  },
  getUserScore() {
    return httpClient.get(`kpi/user-kpi/get-user-kpi`)
  },
}

export default kpiTeacherService
