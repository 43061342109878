import transcriptOrdersService from "@/services/office-registration/transcript-orders/transcriptOrders.service";

const transcriptOrders = {
    namespaced: true,
    actions: {
        async GET_TRANSCRIPT_QUEUE({commit}, listStatus) {
            try {
                const {status, data} = await transcriptOrdersService.getTranscriptQueue(listStatus)
                if (status == 200){
                    listStatus == 1
                        ? commit('SET_TRANSCRIPT_QUEUE', {list: 'orderedList', data: data.success})
                        : commit('SET_TRANSCRIPT_QUEUE', {list: 'printedList', data: data.success})
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
        async POST_TRANSCRIPT_ORDER({commit}) {
            try {
                await transcriptOrdersService.postTranscriptOrder()
                return true
            } catch (e) {
                return e.response.data.message
            }
        },
        async PUT_TRANSCRIPT_ORDER({commit}, {id, orderStatus}) {
            try {
                const {status, data} = await transcriptOrdersService.putTranscriptOrder(id, orderStatus)
                if (status == 200){
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },
    },
    mutations: {
        SET_TRANSCRIPT_QUEUE(state, {list, data}){
            state.transcriptOrders[list].data = data
        }
    },
    state: {
        transcriptOrders: {
            orderedList: {
                data: [],
                name: 'Заказанные транскрипты'
            },
            printedList: {
                data: [],
                name: 'Распечатанные транскрипты'
            },
        }
    },
    getters: {}
}

export default transcriptOrders