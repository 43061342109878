import httpClient from "@/services/http.service"

const transcriptOrdersService = {
    getTranscriptQueue(status) {
        return httpClient.get(`student/transcript-print-queue/get-transcript-print-queue?status=${status}`)
    },
    postTranscriptOrder() {
        return httpClient.post(`student/transcript-print-queue/create`)
    },
    putTranscriptOrder(id, status) {
        return httpClient.put(`student/transcript-print-queue/update-status?id=${id}&status=${status}`)
    },
}

export default transcriptOrdersService