import kpiTeacherService from "@/services/kpi/kpiTeacher.service";


const kpiTeacher = {
  namespaced: true,
  actions: {
    async GET_PPS_INFO({commit}) {
      try {
        const {status, data} = await kpiTeacherService.getPpsInfo()
        if (status === 200) {
          commit('SET_PPS_INFO', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_KPI_TYPE({commit}) {
      try {
        const {status, data} = await kpiTeacherService.getKpiType()
        if (status === 200) {
          commit('SET_KPI_TYPE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_KPI_SUB_TYPE({commit}) {
      try {
        const {status, data} = await kpiTeacherService.getKpiSubType()
        if (status === 200) {
          commit('SET_KPI_SUB_TYPE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_USER_KPI({commit}, userId) {
      try {
        const {status, data} = await kpiTeacherService.getUserKpi(userId)
        if (status === 200) {
          commit('SET_USER_KPI', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_KPI_CONFIRM({state, commit}) {
      try {
        await kpiTeacherService.postKpiConfirm(state.userKpi)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_CONFIRM_USER_KPI({commit}) {
      try {
        const {status, data} = await kpiTeacherService.getConfirmUserKpi()
        if (status === 200) {
          commit('SET_CONFIRM_USER_KPI', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_TYPE_PPS({commit}) {
      try {
        const {status, data} = await kpiTeacherService.getTypePps()
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return 0
    },
    async GET_USER_SCORE({commit}) {
      try {
        const {status, data} = await kpiTeacherService.getUserScore()
        if (status === 200) {
          commit('SET_USER_SCORE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
  },
  mutations: {
    SET_PPS_INFO(state, ppsInfo) {
      state.ppsInfo = ppsInfo
    },
    SET_KPI_TYPE(state, kpiType) {
      state.kpiType = kpiType
    },
    SET_KPI_SUB_TYPE(state, kpiSubType) {
      state.kpiSubType = kpiSubType
    },
    SET_USER_KPI(state, userKpi) {
      state.userKpi = userKpi
    },
    SET_CONFIRM_USER_KPI(state, confirmUserKpi) {
      state.confirmUserKpi = confirmUserKpi
    },
    SET_USER_SCORE(state, userScore) {
      state.userScore = userScore
    },
  },
  state: {
    ppsInfo: {},
    kpiType: [],
    kpiSubType: [],
    userKpi: [],
    confirmUserKpi: [],
    userScore: null
  },
  getters: {}
}

export default kpiTeacher
