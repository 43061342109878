import minorEducationProgramService from "@/services/education-programme/minorEducationProgram.service";
import bigFiveService from "@/services/big-five/bigFive.service";

const minorEducationProgram = {
    namespaced: true,
    actions: {
        async POST_MINOR_EDUCATION_PROGRAM_CREATE({commit}, data) {
            try {
                await minorEducationProgramService.postMinorEducationProgramCreate(data)
                return {status: true}
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
                return {status: false, error: e}
            }
        },
        async POST_MINOR_EDUCATION_PROGRAM_CONFIRM({commit}, data) {
            try {
                await minorEducationProgramService.postMinorEducationProgramConfirm(data)
                return {status: true}
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
                return {status: false, error: e}
            }
        },
        async GET_MINOR_STUDENTS({commit}, {education_program_id, minor_education_program_id}) {
            try {
                const {status, data} = await minorEducationProgramService.getMinorStudents(education_program_id, minor_education_program_id)
                if (status === 200) {
                    console.log('GET_MINOR_STUDENTS', data)
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_MINOR_EDUCATION_PROGRAM({commit}) {
            try {
                const {status, data} = await minorEducationProgramService.getMinorEducationProgram()
                if (status === 200) {
                    console.log('GET_MINOR_EDUCATION_PROGRAM', data)
                    commit('SET_MINOR_EDUCATION_PROGRAM', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_MINOR_EDUCATION_PROGRAM_TYPE({commit}) {
            try {
                const {status, data} = await minorEducationProgramService.getMinorEducationProgramType()
                if (status === 200) {
                    console.log('GET_MINOR_EDUCATION_PROGRAM_TYPE', data)
                    commit('SET_MINOR_EDUCATION_PROGRAM_TYPE', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async GET_CHOOSE_DISCIPLINE({commit}, minor_education_program_id) {
            try {
                const {status, data} = await minorEducationProgramService.getChooseDiscipline(minor_education_program_id)
                if (status === 200) {
                    console.log('GET_CHOOSE_DISCIPLINE', data)
                    commit('SET_CHOOSE_DISCIPLINE', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async DELETE_MINOR_EDUCATION_PROGRAM({commit}, id) {
            try {
                await minorEducationProgramService.deleteMinorEducationProgram(id)
                return {status: true}
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        },
        async PUT_MINOR_EDUCATION_PROGRAM({commit}, data) {
            try {
                let id = data.id
                delete data.id
                await minorEducationProgramService.putMinorEducationProgram(id, data)
                return {status: true}
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        }
    },
    mutations: {
        SET_MINOR_EDUCATION_PROGRAM(state, data) {
            state.minorEducationProgram = data
        },
        SET_CHOOSE_DISCIPLINE(state, data) {
            state.chooseDiscipline = data
        },
        SET_MINOR_EDUCATION_PROGRAM_TYPE(state, data) {
            state.minorEducationProgramType = data
        },
    },
    state: {
        minorEducationProgram: [],
        chooseDiscipline: [],
        minorEducationProgramType: []
    }
}

export default minorEducationProgram
