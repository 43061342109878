import httpClient from "@/services/http.service"

const accreditationExperienceService = {
  getAccreditationTypes() {
    return httpClient.get(`questionnaire/accreditation-experience/get-accreditation-type`)
  },
  getAccreditationAgencies() {
    return httpClient.get(`questionnaire/accreditation-experience/get-accreditation-agencies`)
  },
  getAccreditationRoles() {
    return httpClient.get(`questionnaire/accreditation-experience/get-accreditation-role`)
  },
  getAccreditationExperience(userId = null) {
    const userQuery = userId ? '?user_id=' + userId : ''

    return httpClient.get(`questionnaire/accreditation-experience/index${userQuery}`)
  },
  deleteAccreditationExperience(id) {
    return httpClient.delete(`questionnaire/accreditation-experience/delete?id=${id}`)
  },
  postAccreditationExperience(form) {
    return httpClient.post(`questionnaire/accreditation-experience/create`, form)
  },
  putAccreditationExperience(form) {
    return httpClient.put(`questionnaire/accreditation-experience/update`, form)
  },
}

export default accreditationExperienceService
