import studentAgreementService from "@/services/lawyer/studentAgreement.service"

const studentAgreement = {
    namespaced: true,
    actions: {



        async GET_EDUCATION_PROGRAM_CHANGE_STUDENTS({commit}) {
            try {
                const {status, data} = await studentAgreementService.getEducationProgramChangeStudents()
                if (status === 200) {
                    console.log('GET_EDUCATION_PROGRAM_CHANGE_STUDENTS', data)
                    commit('SET_STUDENTS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return []
        },


        async POST_STUDENT_AGREEMENT({commit}, additionalContractForm) {
            console.log('additionalContractForm', additionalContractForm)
            try {
                await studentAgreementService.postStudentAgreement(additionalContractForm)
                return true
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },




    },
    mutations: {
        SET_STUDENTS(state, students) {
            state.studentAgreement_form.students = students
        },
    },
    state: {
        studentAgreement_form: {
            students: []
        }
    },
    getters: {}
}

export default studentAgreement