import httpClient from "@/services/http.service"

const departmentPpsTeachingTrainingDirectionService = {
    getDepartmentActiveTeachers() {
        return httpClient.get(`education_program/education-direction/get-pps-with-directions`)
    },
    deleteDirection(id) {
        return httpClient.delete(`pps/pps-training-direction/delete?id=${id}`)
    },
    createDirection(data) {
        return httpClient.post(`pps/pps-training-direction/create`, data)
    },
    updateDirection(id, user_id, direction_id) {
        return httpClient.put(`pps/pps-training-direction/update`, {id, user_id, direction_id})
    },
    searchDirection(name) {
        return httpClient.get(`education_program/education-direction/get-directions-by-name?name=${name}`)
    },
}

export default departmentPpsTeachingTrainingDirectionService