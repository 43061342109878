import studentScheduleService from "@/services/schedule/studentSchedule.service"

const studentSchedule = {
  namespaced: true,
  actions: {

    async DELETE_STUDENT_SCHEDULE_CHOOSE({commit}, pps_teaching_load_id) {
      try {
        const {status, data} = await studentScheduleService.deleteStudentScheduleChoose(pps_teaching_load_id)
        if (status === 200) {
          console.log('deleted', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },

    async GET_DISCIPLINES({commit}, student_id) {
      try {
        const {status, data} = await studentScheduleService.getDisciplines(student_id)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_STUDENT_INFO({commit}, student_id) {
      try {
        const {status, data} = await studentScheduleService.getStudentInfo(student_id)
        if (status === 200) {
          return data.success
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return {}
    },


    async POST_STUDENT_SCHEDULE_CHOOSE({commit}, form) {
      try {
        await studentScheduleService.postStudentScheduleChoose(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

    async PUT_STUDENT_SCHEDULE_CHOOSE({commit}, updateForm) {
      try {
        await studentScheduleService.putStudentScheduleChoose(updateForm)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },


  },
  mutations: {
    SET_DISCIPLINES(state, disciplines) {
      state.studentSchedule_form.disciplines = disciplines
    },
  },
  state: {
    studentSchedule_form: {
      disciplines: []
    }
  },
  getters: {}
}

export default studentSchedule