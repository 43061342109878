import teacherSummerService from "@/services/teacher/teacherSummer.service"

const teacherSummer = {
  namespaced: true,
  actions: {
    async GET_SUMMER_COURSES_BY_TEACHER({commit}) {
      try {
        const {data} = await teacherSummerService.getSummerCourses()
        if (data) {
          commit('SET_SUMMER_COURSES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_SUMMER_GRADES_BY_SUMMER_COURSE({commit}, summerCourseId) {
      try {
        const {data} = await teacherSummerService.getSummerGradesBySummerCourse(summerCourseId)
        if (data) {
          commit('SET_SUMMER_GRADES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_IMPORT_SYLLABUS({commit}, postData) {
      try {
        const {data} = await teacherSummerService.postImportSyllabus(postData)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async PUT_SUMMER_COURSE_SYLLABUS_STATUS({commit}, putData) {
      try {
        const {data} = await teacherSummerService.putSummerCourseSyllabusStatus(putData)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async POST_TOTAL_GRADE({commit}, bodyData) {
      try {
        const {data} = await teacherSummerService.postTotalGrade(bodyData)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return null
    },
  },
  mutations: {
    SET_SUMMER_COURSES(state, courses) {
      state.teacherSummer.courses = courses
    },
    SET_SUMMER_GRADES(state, grades) {
      state.teacherSummer.grades = grades
    },
  },
  state: {
    teacherSummer: {
      courses: [],
      grades: []
    }
  },
  getters: {}
}

export default teacherSummer