import httpClient from "@/services/http.service"
import router from "@/router"

const conferenceReviewerService = {

    putConferenceReview(data, conferenceReviewId) {
        return httpClient.put(`conference/conference-review/update` + '?id=' + conferenceReviewId, data)
    },
    postConferenceArticleComment(data) {
        return httpClient.post(`conference/conference-article-comment/create`, data)
    },
    getConferenceArticle() {
        return httpClient.get(`conference/conference-review/get-articles-for-reviewer`)
    },
}

export default conferenceReviewerService