import conferenceAuthorService from "@/services/conference/author/conferenceAuthor.service"
import axios from 'axios'

const conferenceAuthor = {
    namespaced: true,
    actions: {

        async GET_PPS_INFO({commit}) {
            try {
                const {data} = await conferenceAuthorService.getPpsInfo();
                if (data) {
                    console.log(data, 'GET_PPS_INFO')
                    commit('SET_PPS_INFO', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CONFERENCE({commit}) {
            try {
                const {data} = await conferenceAuthorService.getConference();
                if (data) {
                    console.log(data, 'GET_CONFERENCE')
                    commit('SET_CONFERENCE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_SP_UNIVERSITY({commit}) {
            try {
                const {data} = await conferenceAuthorService.getSpUniversity();
                if (data) {
                    console.log(data, 'GET_SP_UNIVERSITY')
                    commit('SET_SP_UNIVERSITY', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CONFERENCE_USER({commit}) {
            try {
                const {data} = await conferenceAuthorService.getConferenceUser();
                if (data) {
                    console.log(data, 'GET_CONFERENCE_USER')
                    commit('SET_CONFERENCE_USER', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CONFERENCE_SECTION({commit}, conference_id) {
            try {
                const {data} = await conferenceAuthorService.getConferenceSection(conference_id);
                if (data) {
                    console.log(data, 'GET_CONFERENCE_SECTION')
                    commit('SET_CONFERENCE_SECTION', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CONFERENCE_ARTICLE({commit}) {
            try {
                const {data} = await conferenceAuthorService.getConferenceArticle();
                if (data) {
                    console.log(data, 'GET_CONFERENCE_ARTICLE')
                    commit('SET_CONFERENCE_ARTICLE', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_AUTHORS_BY_LAST_NAME({commit}, last_name) {
            try {
                const {data} = await conferenceAuthorService.getAuthorsByLastName(last_name);
                if (data) {
                    console.log(data, 'GET_AUTHORS_BY_LAST_NAME')
                    return data
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async GET_CONFERENCE_COMMENT({commit}, conference_article_id) {
            try {
                const {data} = await conferenceAuthorService.getConferenceComment(conference_article_id);
                if (data) {
                    console.log(data, 'GET_CONFERENCE_COMMENT')
                    commit('SET_CONFERENCE_COMMENT', data)
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_CONFERENCE_USER({commit, state}) {

            let conferenceUserForm = state.conferenceAuthor_form.newConferenceUser

            console.log(conferenceUserForm, 'post conferenceUserForm')
            try {
                const {data} = await conferenceAuthorService.postConferenceUser(conferenceUserForm);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async POST_CONFERENCE_ARTICLE({commit, state}, coAuthorInfos) {

            let formData = new FormData();
            let conferenceArticleForm = state.conferenceAuthor_form.newConferenceArticle

            console.log(conferenceArticleForm, 'post conferenceArticleForm')

            formData.append("authors", JSON.stringify(coAuthorInfos));

            formData.append("conference_section_id", conferenceArticleForm["conference_section_id"]);
            //formData.append("conference_id", conferenceArticleForm["conference_id"]);
            formData.append("conference_id", state.conferenceAuthor_form.conferenceUser[0].conference_id);
            formData.append("theme", conferenceArticleForm["theme"]);
            formData.append("language_id", conferenceArticleForm["language_id"]);
            formData.append("file_article", conferenceArticleForm["file_article"]);
            formData.append("literature_file", conferenceArticleForm["literature_file"]);



            console.log(Object.fromEntries(formData), 'hello from formdata')
            try {
                const {data} = await conferenceAuthorService.postConferenceArticle(formData);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

        async PUT_CONFERENCE_ARTICLE({commit, state}) {
            let formData = new FormData();
            let updateConferenceArticleForm = state.conferenceAuthor_form.updateArticle
            let updateArticleId = state.conferenceAuthor_form.updateArticleId
            console.log(updateConferenceArticleForm, 'put updateConferenceArticleForm')


            formData.append("file_article", updateConferenceArticleForm["file_article"]);
            formData.append("literature_file", updateConferenceArticleForm["literature_file"]);


            try {
                const {data} = await conferenceAuthorService.putConferenceArticle(formData, updateArticleId);
                if (data) {
                    return true
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
            return false
        },

    },
    mutations: {
        SET_PPS_INFO(state, ppsInfo) {
            state.conferenceAuthor_form.newConferenceUser['last_name'] = ppsInfo['lastname']
            state.conferenceAuthor_form.newConferenceUser['first_name'] = ppsInfo['firstname']
            state.conferenceAuthor_form.newConferenceUser['middle_name'] = ppsInfo['middlename']
            state.conferenceAuthor_form.newConferenceUser['phone'] = ppsInfo['phone_number']
            state.conferenceAuthor_form.newConferenceUser['email'] = ppsInfo['email']
            console.log(state.conferenceAuthor_form.newConferenceUser, "STATE")
        },
        SET_CONFERENCE(state, conference) {
            state.conferenceAuthor_form.conference = conference
        },
        SET_SP_UNIVERSITY(state, university) {
            state.conferenceAuthor_form.university = university
        },
        SET_CONFERENCE_USER(state, conferenceUser) {
            state.conferenceAuthor_form.conferenceUser = conferenceUser
        },
        SET_CONFERENCE_SECTION(state, conferenceSection) {
            state.conferenceAuthor_form.conferenceSection = conferenceSection
        },
        SET_NEW_CONFERENCE_USER_DATA(state, {property, value}) {
            state.conferenceAuthor_form.newConferenceUser[property] = value
            console.log(state.conferenceAuthor_form.newConferenceUser, "STATE")
        },
        SET_NEW_CONFERENCE_ARTICLE_DATA(state, {property, value}) {
            state.conferenceAuthor_form.newConferenceArticle[property] = value
            console.log(state.conferenceAuthor_form.newConferenceArticle, "STATE")
        },
        SET_NEW_CONFERENCE_ARTICLE_FILE(state, file_article) {
            state.conferenceAuthor_form.newConferenceArticle['file_article'] = file_article
            console.log(state.conferenceAuthor_form.newConferenceArticle, "STATE")
        },
        SET_NEW_CONFERENCE_ARTICLE_LITERATURE_FILE(state, literature_file) {
            state.conferenceAuthor_form.newConferenceArticle['literature_file'] = literature_file
            console.log(state.conferenceAuthor_form.newConferenceArticle, "STATE")
        },
        SET_CONFERENCE_ARTICLE(state, conferenceArticle) {
            state.conferenceAuthor_form.conferenceArticle = conferenceArticle
        },
        SET_CONFERENCE_COMMENT(state, conferenceComment) {
            state.conferenceAuthor_form.conferenceComment = conferenceComment
        },
        CLEAR_CONFERENCE_ARTICLE(state) {
            state.conferenceAuthor_form.newConferenceArticle = {}
        },
        SET_UPDATE_ARTICLE_FILE(state, update_file_article) {
            state.conferenceAuthor_form.updateArticle['file_article'] = update_file_article
            console.log(state.conferenceAuthor_form.updateArticle, "STATE updateArticle")
        },
        SET_UPDATE_LITERATURE_FILE(state, update_literature_file) {
            state.conferenceAuthor_form.updateArticle['literature_file'] = update_literature_file
            console.log(state.conferenceAuthor_form.updateArticle, "STATE updateArticle")
        },
        SET_UPDATE_ARTICLE_ID(state, updateArticleId) {
            state.conferenceAuthor_form.updateArticleId = updateArticleId
            console.log(state.conferenceAuthor_form.updateArticleId, "STATE updateArticleId")
        }
    },
    state: {
        conferenceAuthor_form: {
            ppsUserInfo: {},
            conference: [],
            university: [],
            conferenceUser: [],
            conferenceSection: [],
            conferenceArticle: [],
            newConferenceUser: {},
            newConferenceArticle: {},
            conferenceComment: [],
            updateArticle: {},
            updateArticleId: null
        }
    },
    getters: {
    }
}

export default conferenceAuthor
