import checkAccessService from "@/services/check-access/checkAccess.service";

const checkAccess = {
    namespaced: true,
    actions: {
        async GET_CHECK_ACCESS({commit}, test_type) {
            try {
                const {status, data} = await checkAccessService.getCheckAccess(test_type)
                if (status === 200) {
                    console.log('GET_CHECK_ACCESS', data)
                    commit('SET_CHECK_ACCESS', data)
                }
            } catch (e) {
                commit('SET_ERROR', e, {root: true})
            }
        }
    },
    mutations: {
        SET_CHECK_ACCESS(state, data) {
            state.checkAccess = data
        }
    },
    state: {
        checkAccess: null
    }
}

export default checkAccess