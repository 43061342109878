import httpClient from "../../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const groupBy = (arr, key) => Object.values(
    arr.reduce((objGroupBy, item) => {
        (objGroupBy[item[key]] = objGroupBy[item[key]] || []).push(item)
        return objGroupBy
    }, {})
)
const selectableDisciplines = {
    namespaced: true,
    actions: {

        async GET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID({commit}, education_discipline_id) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('selectable_course/selectable-group-students/get-selectable-student-groups-by-student-id'
                    + '?access-token=' + getCookie('ACCESS_TOKEN') + '&education_discipline_id=' + education_discipline_id);
                if (status === 200) {
                    // console.log(data, 'GET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID')
                    commit('SET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },


        async GET_EDUCATION_COURSES_BY_STUDENT_ID({commit, state}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-courses/get-education-courses-by-student-id' + '?access-token=' + getCookie('ACCESS_TOKEN')
                    + '&education_discipline_id=' + state.selectableDisciplines_form.educationDisciplineId);
                if (status === 200) {
                    // console.log(data, 'student courses')
                    //commit('SET_EDUCATION_COURSES', data)
                    commit('SET_SELECTABLE_FLOWS', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },

        async GET_LANGUAGE_DISCIPLINE_BY_STUDENT_ID({commit}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-discipline/get-language-discipline-by-student-id' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    // console.log(data, 'student lang disciplines')

                    commit('SET_DISCIPLINES', data)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },


        async POST_SELECTABLE_GROUPS_DATA({state}) {

            let selectableGroupsForm = state.selectableGroups_form.newSelectableGroupsInfos


            selectableGroupsForm['selectable_flow_id'] = state.selectableGroups_form.selectable_flow_id
            selectableGroupsForm['pps_id'] = getCookie('USER_ID')

            console.log(selectableGroupsForm, 'selectableGroupsForm')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('selectable_course/selectable-groups/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), selectableGroupsForm);
                if (status === 200) {
                    // console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async PUT_SELECTABLE_GROUPS_DATA({state}, editData) {
            try {
                const {
                    status,
                    data
                } = await httpClient.post('selectable_course/selectable-groups/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), editData);
                if (status === 200) {
                    // console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },

        async POST_SELECTABLE_GROUP_STUDENTS({state}, selectedGroup) {

            let selectableGroupStudent = {
                // 'student_data_id': getCookie('USER_ID'),
                'selectable_groups_id': selectedGroup
            }

            // console.log(selectableGroupStudent, 'selectableGroupStudent')
            try {
                const {
                    status,
                    data
                } = await httpClient.post('selectable_course/selectable-group-students/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), selectableGroupStudent);
                //education_program/education-courses/add-students-from-department-head-to-course
                //selectable_course/selectable-group-students/create-selectable
                if (status === 200) {
                    // console.log(data, 'create')
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data
                }
            }
        },
    },
    mutations: {

        SET_DISCIPLINES(state, disciplines) {
            state.selectableDisciplines_form.disciplines = disciplines
        },
        SET_EDUCATION_DISCIPLINE_ID(state, educationDisciplineId) {
            state.selectableDisciplines_form.educationDisciplineId = educationDisciplineId
        },

        SET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID(state, selectableStudentGroupsByStudentDataId) {
            state.selectableDisciplines_form.selectableStudentGroupsByStudentDataId = selectableStudentGroupsByStudentDataId
            // console.log(state.selectableDisciplines_form.selectableStudentGroupsByStudentDataId, 'selectableStudentGroupsByStudentDataId')

        },

        // SET_EDUCATION_COURSES(state, educationCourses) {
        //     state.disciplinesLanguageCenter.educationCourses = educationCourses
        //     console.log(state.disciplinesLanguageCenter.educationCourses, 'educationCourses')
        // },
        SET_SELECTABLE_FLOWS(state, flows) {
            state.selectableDisciplines_form.flowsWithoutGroupBy = flows
            const flowsGroupBy = groupBy(flows, 'selectable_flow_id').map(item => ({
                    selectable_flow_id: item[0]?.selectable_flow_id,
                    selectable_flow_name: item[0]?.selectable_flow_name,
                    groups: item
                })
            )

            state.selectableDisciplines_form.flows = flowsGroupBy
            // console.log(state.selectableDisciplines_form.flows, 'flows')
            // console.log(state.selectableDisciplines_form.flowsWithoutGroupBy, 'flowsWithoutGroupBy')
        },
        SET_SELECTABLE_GROUP_ID(state, selectableGroupId) {
            state.selectableDisciplines_form.selectableGroupId = selectableGroupId
        }

    },
    state: {
        selectableDisciplines_form: {
            disciplines: [],
            //educationCourses: [],
            flows: [],
            flowsWithoutGroupBy: [],
            selectableGroupId: null,
            selectableStudentGroupsByStudentDataId: [],
            educationDisciplineId: null
        }
    },
}

export default selectableDisciplines