import httpClient from "@/services/http.service"
import router from "@/router"


const disciplinesDifferenceService = {
    getDisciplinesDifference(student_id, order_from, order_to) {
        return httpClient.get(`doc_orders/doc-order-students/get-disciplines-difference` + '?student_id=' + student_id + '&order_from=' + order_from + '&order_to=' + order_to)
    },


    postDifferenceStudentRatings(formData) {
        return httpClient.post(`student/student-ratings/create-student-ratings-difference-disciplines`, formData)
    },

}

export default disciplinesDifferenceService