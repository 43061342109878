import adviserService from "@/services/adviser/adviser.service"


const adviser = {
  namespaced: true,
  actions: {
    async GET_STUDENTS_BY_ADVISER({commit}) {
      try {
        const {data} = await adviserService.getStudentsByAdviser()
        if (data) {
          commit('SET_STUDENTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_DISCIPLINE_SELECTION_STATISTICS({commit}, groupId) {
      try {
        const {data} = await adviserService.getDisciplineSelectionStatistics(groupId)
        if (data) {
          commit('SET_SEMESTER_DISCIPLINES', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_STUDENTS_BY_EDUCATION_PROGRAMS({commit}, educationPrograms) {
      try {
        const {data} = await adviserService.getStudentsByEducationPrograms(educationPrograms)
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },

    async GET_ADVISER_STUDENT({commit}) {
      try {
        const {data} = await adviserService.getAdviserStudent()
        return data
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return []
    },
    async POST_ADVISER_STUDENT({commit}, form) {
      try {
        await adviserService.postAdviserStudent(form)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },

  },
  mutations: {
    SET_STUDENTS(state, advisers) {
      state.advisers = advisers
    },
    SET_SEMESTER_DISCIPLINES(state, semesterDisciplines) {
      state.semesterDisciplines = semesterDisciplines
    },
  },
  state: {
    advisers: [],
    semesterDisciplines: {}
  },
}

export default adviser