import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const formationEducationProgram = {
  namespaced: true,
  actions: {
    async SEARCH_DISCIPLINE({commit, state}, word) {
      try {
        const {status, data} = await httpClient.get(`education_program/education-discipline/search-add?study_lang_id=${state.formationEducationProgram_form.studyLangId}&study_level_id=${state.formationEducationProgram_form.selectedStudyLevelId}&word=${word}`)
        if (status === 200) {
          return data
        }
      } catch (e) {
        console.log(e.response)
      }
      return []
    },
    async GET_STUDY_LEVEL({commit}) {
      try {
        const {status, data} = await httpClient.get(`education_program/study-level/index`)
        if (status === 200) {
          commit("SET_STUDY_LEVEL_DATA", data)
        }
      } catch (e) {
        console.log(e.response)
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_EDUCATION_SEMESTER({commit}) {
      try {
        const {status, data} = await httpClient.get(`education_program/education-semestr/index`)
        if (status === 200) {
          commit("SET_EDUCATION_SEMESTER", data)
        }
      } catch (e) {
        console.log(e.response)
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_EDUCATION_PROGRAM({commit, state}, urlEducationProgramId) {
      try {
        const {status, data} = await httpClient.get(`education_program/education-program/index?id=${urlEducationProgramId}`)
        if (status === 200) {
          commit("SET_EDUCATION_PROGRAM_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response)
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_GOSO_CYCLE_NAME({commit, state}) {
      try {
        const {status, data} = await httpClient.get(`education_program/goso-cycle-name/index?study_level=${state.formationEducationProgram_form.selectedStudyLevelId}`)
        if (status === 200) {
          // commit("SET_GOSO_CYCLE_NAME", data.success)
        }
      } catch (e) {
        console.log(e.response)
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async GET_GOSO_COMPONENTS_NAME({commit, state}) {
      const url = 'education_program/goso-components-name/index' + '?access-token=' + getCookie('ACCESS_TOKEN')
        + '&study_level=' + state.formationEducationProgram_form.selectedStudyLevelId
      try {
        const {status,data} = await httpClient.get(url);
        if (status === 200) {
          console.log(data.success, 'GET_GOSO_COMPONENTS_NAME')
          commit("SET_GOSO_COMPONENTS_NAME", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_EDUCATION_MODULE_DATA({commit, state}) {

      try {

        const {
          status,
          data
        } = await httpClient.get('education_program/education-modules/index' + '?access-token=' + getCookie('ACCESS_TOKEN') +
          '&decree_goso_templates_id=' + state.formationEducationProgram_form.urlDecreeGosoTemplateId +
          '&education_program_id=' + state.formationEducationProgram_form.urlEducationProgramId);
        if (status === 200) {

          commit("SET_EDUCATION_MODULE_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_EDUCATION_DISCIPLINE_DATA({commit, state}) {
      try {
        const url = 'education_program/education-discipline/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&study_level=' + state.formationEducationProgram_form.selectedStudyLevelId
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data.success, 'GET_EDUCATION_DISCIPLINE_DATA')
          commit("SET_EDUCATION_DISCIPLINE_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_GOSO_TEMPLATE_DATA({commit, state}) {
      const url = 'education_program/goso-templates/get-parent' + '?access-token=' + getCookie('ACCESS_TOKEN')
        + '&study_level_id=' + state.formationEducationProgram_form.selectedStudyLevelId + '&decree_goso_templates_id=' + state.formationEducationProgram_form.selectedDecreeGosoTemplatesId
      try {
        const {status, data} = await httpClient.get(url);
        if (status === 200) {
          console.log(data, 'GET_GOSO_TEMPLATE_DATA')
          commit("SET_GOSO_TEMPLATE_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_GOSO_TEMPLATE_CYCLE({commit, state}) {
      const url = 'education_program/goso-templates/get-cycle' + '?access-token=' + getCookie('ACCESS_TOKEN')
        + '&study_level_id=' + state.formationEducationProgram_form.selectedStudyLevelId + '&decree_goso_templates_id=' + state.formationEducationProgram_form.urlDecreeGosoTemplateId
      try {
        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          console.log(data, 'GET_GOSO_TEMPLATE_CYCLE')
          commit("SET_GOSO_TEMPLATE_CYCLE", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async GET_FORMATION_EDUCATION_PROGRAM_DATA({commit, state}) {
      try {
        const {status, data} = await httpClient.get(`education_program/formation-education-program/index?program=${state.formationEducationProgram_form.urlEducationProgramId}&status=1`)
        if (status === 200) {
          const formations = data.success.map(i => ({
            ...i,
            disciplines: [{
              id: i.educationDiscipline.id,
              name: `${i.educationDiscipline.name} - ${i.educationDiscipline.code} (${i.educationDiscipline.language.native_name}, ${i.educationDiscipline.studyLevel.name})`,
            }]
          }))

          console.log(formations, 'formations')
          commit("SET_FORMATION_EDUCATION_PROGRAM_DATA", formations)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

    async POST_FORMATION_EDUCATION_PROGRAM_DATA({state}) {

      let formationEducationProgramForm = (state.formationEducationProgram_form.formationEducationProgramInfos).filter(v => v.id == undefined)
      console.log(formationEducationProgramForm, "POST formationEducationProgramForm")
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/formation-education-program/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), formationEducationProgramForm);
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async PUT_FORMATION_EDUCATION_PROGRAM_DATA({state}, formation) {
      console.log(formation, 'PUT formation')
      try {
        const {status, data} = await httpClient.put(`education_program/formation-education-program/update?id=${formation.id}&access-token=${getCookie('ACCESS_TOKEN')}`, formation)
        if (status === 200) {
          console.log(data, 'update')
        }
        return {success: true}
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async DELETE_FORMATION_EDUCATION_PROGRAM_DATA({commit, state}, id) {

      try {
        const {
          status,
          data
        } = await httpClient.delete('education_program/formation-education-program/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));

        console.log('formation-education-program DELETED')

        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async DELETE_FORMATION_EDUCATION_PROGRAM_EDUCATION_MODULE({commit, state}, education_module_id) {

      try {
        const {
          status,
          data
        } = await httpClient.delete('education_program/formation-education-program/education-module-delete' + '?education_module_id=' + education_module_id + '&access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          console.log('formation-education-program education module DELETED')
          // commit("SET_EDUCATION_DATA", data.success)
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },
    async POST_MODULE({state}, modules) {
      try {
        const {
          status,
          data
        } = await httpClient.post('education_program/education-modules/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), modules)
        if (status === 200) {
          console.log(data, 'create')
        }
        return {
          success: true
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          errors: e.response.data.errors
        }
      }
    },
    async POST_GROUPING_DISCIPLINES({commit}, ids) {
      try {
        await httpClient.post(`education_program/formation-education-program/grouping`, ids)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

    async POST_MODULAR_DISCIPLINES({commit}, ids){
      try {
        await httpClient.post(`education_program/education-modules/create-module-group-id`, ids)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

    async POST_GROUPING_MINOR_DISCIPLINES({commit}, ids) {
      try {
        await httpClient.post(`education_program/formation-education-program/minor-formation-grouping`, ids)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },
    async DELETE_FORMATION_FROM_GROUP({commit}, group_id) {
      try {
        await httpClient.get(`education_program/formation-education-program/delete-grouping?group_id=${group_id}`)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

    async DELETE_FORMATION_FROM_MINOR_GROUP({commit}, minor_f_group_id) {
      try {
        await httpClient.get(`education_program/formation-education-program/delete-minor-formation-grouping?minor_f_group_id=${minor_f_group_id}`)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

    async DELETE_FORMATION_FROM_MODULE_GROUP({commit}, module_group_id) {
      try {
        await httpClient.get(`education_program/education-modules/delete-module-group-id?id=${module_group_id}`)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
        return false
      }
    },

  },
  mutations: {
    SET_STUDY_LEVEL_DATA(state, studyLevels) {
      state.formationEducationProgram_form.studyLevels = studyLevels
    },
    SET_EDUCATION_SEMESTER(state, educationSemesters) {
      state.formationEducationProgram_form.educationSemesters = educationSemesters
    },
    SET_EDUCATION_PROGRAM_DATA(state, educationPrograms) {
      state.formationEducationProgram_form.educationPrograms = educationPrograms
    },
    SET_STUDY_LEVEL_ID(state, studyLevelId) {
      state.formationEducationProgram_form.selectedStudyLevelId = studyLevelId

    },
    SET_DECREE_GOSO_TEMPLATES_ID(state, selectedDecreeGosoTemplatesId) {
      state.formationEducationProgram_form.selectedDecreeGosoTemplatesId = selectedDecreeGosoTemplatesId
    },
    SET_EDUCATION_PROGRAM_ID(state, educationProgramId) {
      state.formationEducationProgram_form.selectedEducationProgramId = educationProgramId
    },
    SET_URL_EDUCATION_PROGRAM_ID(state, urlEducationProgramId) {
      state.formationEducationProgram_form.urlEducationProgramId = urlEducationProgramId
    },
    SET_URL_DECREE_GOSO_TEMPLATE_ID(state, urlDecreeGosoTemplateId) {
      state.formationEducationProgram_form.urlDecreeGosoTemplateId = urlDecreeGosoTemplateId
    },
    SET_GOSO_COMPONENTS_NAME(state, componentNames) {
      state.formationEducationProgram_form.componentNames = componentNames
    },
    SET_EDUCATION_MODULE_DATA(state, educationModules) {
      state.formationEducationProgram_form.educationModules = educationModules
    },
    SET_EDUCATION_DISCIPLINE_DATA(state, educationDisciplines) {
      state.formationEducationProgram_form.educationDisciplines = educationDisciplines
    },
    SET_GOSO_TEMPLATE_DATA(state, gosoTemplate) {
      state.formationEducationProgram_form.gosoTemplateInfos = gosoTemplate
    },
    SET_GOSO_TEMPLATE_CYCLE(state, gosoTemplateCycle) {
      state.formationEducationProgram_form.gosoTemplateCycle = gosoTemplateCycle
    },
    ADD_ROW_FORMATION_EDUCATION_PROGRAM_INFO(state, education_module_id) {
      const goso_cycle_name_id = state.formationEducationProgram_form.gosoTemplateCycle.length ? state.formationEducationProgram_form.gosoTemplateCycle[0].goso_cycle_name_id : null
      const goso_component_name_id = state.formationEducationProgram_form.componentNames.length ? state.formationEducationProgram_form.componentNames[0].id : null
      const semester = state.formationEducationProgram_form.educationSemesters.length ? state.formationEducationProgram_form.educationSemesters[0].id : null

      state.formationEducationProgram_form.formationEducationProgramInfos.push({
        goso_cycle_name_id,
        goso_component_name_id,
        education_module_id,
        semester,
        credit: 0,
        education_discipline_id: 0,
        education_program_id: state.formationEducationProgram_form.urlEducationProgramId,
        //study_level_id: state.formationEducationProgram_form.selectedStudyLevelId,
        //put_status: 0
      });
      console.log(state.formationEducationProgram_form.formationEducationProgramInfos, "STATE")
    },
    DELETE_ROW_FORMATION_EDUCATION_PROGRAM_INFO(state, item) {
      const index = state.formationEducationProgram_form.formationEducationProgramInfos.indexOf(item)
      state.formationEducationProgram_form.formationEducationProgramInfos.splice(index, 1);
    },
    UPDATE_FORMATION_EDUCATION_PROGRAM_DATA(state, {item, property, value}) {
      console.log(item, "ITEM")
      const index = state.formationEducationProgram_form.formationEducationProgramInfos.indexOf(item)
      state.formationEducationProgram_form.formationEducationProgramInfos[index][property] = value

      console.log(state.formationEducationProgram_form.formationEducationProgramInfos, 'AAAAAAAAAAAAAAAAAAAA')
    },
    SET_FORMATION_EDUCATION_PROGRAM_DATA(state, formationEducationProgramInfos) {
      state.formationEducationProgram_form.formationEducationProgramInfos = formationEducationProgramInfos
    },
    SET_STUDY_LANG_ID(state, studyLangId) {
      state.formationEducationProgram_form.studyLangId = studyLangId
    },
  },
  state: {
    formationEducationProgram_form: {
      // formationEducationProgramInfos: [{
      //     // goso_templates_id,
      //     // education_module_id: null,
      //     // semester: null,
      //     // goso_component_id: null,
      //     // education_discipline_id: null,
      //     // education_program_id: null,
      //     // study_level_id: null
      // }],
      urlEducationProgramId: null,
      urlDecreeGosoTemplateId: null,
      formationEducationProgramInfos: [],
      selectedStudyLevelId: 1,
      studyLangId: 0,
      selectedEducationProgramId: null,
      studyLevels: [],
      educationPrograms: [],
      componentNames: [],
      educationModules: [],
      educationDisciplines: [],
      gosoTemplateInfos: [],
      gosoTemplateCycle: [],
      selectedDecreeGosoTemplatesId: null,
      educationSemesters: [],
    }


  },
  getters: {}

}

export default formationEducationProgram