import surveyService from "@/services/sei/survey.service";

const survey = {
  namespaced: true,
  actions: {
    async GET_SURVEY_STUDENTS({commit}) {
      try {
        const {status, data} = await surveyService.getSurveyStudents()
        if (status === 200) {
          console.log('GET_SURVEY_STUDENTS', data)
          commit('SET_SURVEY_STUDENTS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_SURVEY_QUESTIONS({commit}) {
      try {
        const {status, data} = await surveyService.getSurveyQuestions()
        if (status === 200) {
          commit('SET_SURVEY_QUESTIONS', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_SURVEY_SCORE({commit}) {
      try {
        const {status, data} = await surveyService.getSurveyScore()
        if (status === 200) {
          commit('SET_SURVEY_SCORE', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async POST_SURVEY_SCORE({state, commit}, answers) {
      try {
        await surveyService.postSurveyAnswer(answers)
        return true
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
      return false
    },
    async GET_SURVEY_SEND_DATA({commit}, objectData) {
      try {
        const {status, data} = await surveyService.getSurveySendData(objectData)
        if (status === 200) {
          return data
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    },
    async GET_SHORT_STUDENT_INFO({commit}, student_id) {
      try {
        const {status, data} = await surveyService.getShortStudentInfo(student_id)
        if (status === 200) {
          console.log(data)
          commit('SET_SURVEY_STUDENT_INFO', data)
        }
      } catch (e) {
        commit('SET_ERROR', e, {root: true})
      }
    }
  },
  mutations: {
    SET_SURVEY_STUDENTS(state, data) {
      state.surveyStudents = data
    },
    SET_SURVEY_QUESTIONS(state, data) {
      state.surveyQuestions = data
    },
    SET_SURVEY_SCORE(state, data){
      state.surveyScore = data
    },
    SET_SURVEY_STUDENT_INFO(state, data){
      state.surveyStudentInfo = data
    }
  },
  state: {
    surveyQuestions: [],
    surveyScore: [],
    surveyStudents: [],
    surveyStudentInfo: {}
  }
}

export default survey
