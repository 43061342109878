import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

const educationdirection = {
    namespaced: true,
    actions: {
        async DELETE_EDUCATION_DIRECTION_DATA({commit, state}, id) {

            try {
                const {
                    status,
                    data
                } = await httpClient.delete('education_program/education-direction/delete' + '?id=' + id + '&access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    console.log('education direction data DELETED')
                    // commit("SET_EDUCATION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async POST_EDUCATION_DIRECTION_DATA() {
            let educationDirectionForm = (educationdirection.state.educationdirection_form.newEducationDirectionInfos)

                console.log(educationDirectionForm)
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('education_program/education-direction/create' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationDirectionForm);
                    if (status === 200) {
                        console.log(data, 'create')
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }

        },
        async PUT_EDUCATION_DIRECTION_DATA({commit, state}, id) {
            console.log(id, "IDIDIDIDID")
            let educationDirectionForm = (educationdirection.state.educationdirection_form.educationDirectionInfos).filter(v => v.id == id)
            console.log(educationDirectionForm, 'educationDirectionForm')
            if (educationDirectionForm.length > 0) {

                try {
                    console.log(educationDirectionForm)
                    const {
                        status,
                        data
                    } = await httpClient.put('education_program/education-direction/update' + '?access-token=' + getCookie('ACCESS_TOKEN'), educationDirectionForm);
                    if (status === 200) {
                        console.log(data)
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            } else {
                return 0
            }
        },
        async GET_EDUCATION_DIRECTION_DATA({commit},{page, field_id}) {
            console.log(field_id)
            const urlGetParams = `&page=${page}&field_id=${field_id}`
            console.log(urlGetParams)
            const url = 'education_program/education-direction/index' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams
            try {
                const {
                    status,
                    data
                } = await httpClient.get(url);
                if (status === 200) {
                    commit("SET_PAGE_COUNT", data.page_count)
                    commit("SET_EDUCATION_DIRECTION_DATA", data.success)
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    error: e.response.data.errors
                }
            }
        },
        async GET_EDUCATION_FIELD_NAMES({commit, getters}) {
            try {
                const {
                    status,
                    data
                } = await httpClient.get('education_program/education-field/full-data' + '?access-token=' + getCookie('ACCESS_TOKEN'));
                if (status === 200) {
                    commit('SET_EDUCATION_FIELD_NAMES', data.success)
                }
                return {
                    success: true
                }
            } catch (e) {
                console.log(e.response);
                return {
                    success: false,
                    errors: e.response.data.errors
                }
            }
        },
    },
    mutations: {
        SET_EDUCATION_DIRECTION_DATA(state, educationDirectionInfos) {
            state.educationdirection_form.educationDirectionInfos = educationDirectionInfos
        },
        SET_PAGE_COUNT(state, pageCount) {
            state.pageCount = pageCount
        },
        ADD_ROW_EDUCATION_DIRECTION_INFO(state) {
            state.educationdirection_form.newEducationDirectionInfos.push({
                education_field_id: 0,
                name: '',
                put_status: 0
            });
        },
        DELETE_ROW_EDUCATION_DIRECTION_INFO(state, index) {
            state.educationdirection_form.newEducationDirectionInfos.splice(index, 1);
        },
        UPDATE_EDUCATION_DIRECTION_DATA(state, {item, property, value}) {
            const index = state.educationdirection_form.educationDirectionInfos.indexOf(item)
            state.educationdirection_form.educationDirectionInfos[index][property] = value
            console.log(state.educationdirection_form.educationDirectionInfos, value)
        },
        UPDATE_NEW_EDUCATION_DIRECTION_DATA(state, {item, property, value}) {
            const index = state.educationdirection_form.newEducationDirectionInfos.indexOf(item)
            state.educationdirection_form.newEducationDirectionInfos[index][property] = value
            console.log(state.educationdirection_form.newEducationDirectionInfos, value)
        },
        SET_EDUCATION_FIELD_NAMES(state, educationFieldNames) {
            state.educationdirection_form.educationFieldNames = educationFieldNames
        },
        CHANGE_EDIT_STATUS_EDUCATION_DIRECTION_INFO(state, index) {
            //console.log(index, "CHANGE EDIT STATUS INDEX")
            //console.log(state.educationfield_form.educationFieldInfos[index], 'array')
            state.educationdirection_form.educationDirectionInfos[index].edit_status = 1;
            // state.educationfield_form.educationFieldInfos.splice(0, 0,{
            //     edit_status: 1
            // });
        },
    },
    state: {
        educationdirection_form: {
            educationDirectionInfos: [{
                education_field_id: 0,
                name: '',
                put_status: 0
            }],

            newEducationDirectionInfos: [],
            educationFieldNames: []
        },
        pageCount: 0
    },
    getters: {
        ALL_EDUCATION_FIELD_NAMES(state) {
            return state.educationdirection_form.educationFieldNames
        }
    }
}

export default educationdirection